import styled from 'styled-components';

export const Container = styled.div`
	display: grid;
	grid-template-areas: 'icon text';
	grid-template-columns: 2.4rem 1fr;
	gap: 0.9rem;

	padding: 0.9rem 1.6rem;

	border-radius: 4px;
	border: solid 1px #fec84b;
	background-color: #fffcf5;
`;
