import { Controller, FieldError, UseFormReturn } from 'react-hook-form';

import { DynamicFormValue, DynamicFormValues } from 'store/data/entries';
import {
	RadioGroupUncontrolled,
	CheckboxGroupUncontrolled
} from 'components/UI/Interactables/Uncontrolled';
import {
	entryFormDependenciesCheckEvent,
	entryFormReadOnlyModalEvent,
	setEntryFormFieldValue,
	withCustomSuffix
} from 'helpers/entries';
import { useVariablesData } from 'hooks/store';

interface Option {
	label: string;
	value: string;
	tooltip?: string;
}

interface CheckboxRadioGroupInputProps {
	name: string;
	options: Option[];
	required: boolean;
	disabled: boolean;
	readOnly?: boolean;
	initialCustomEnabled: boolean;
	isVertical: boolean;
	allowCreate?: boolean;
	isRadioGroup?: boolean;
	borderError?: boolean;
	value?: DynamicFormValue;
	formContext?: UseFormReturn<DynamicFormValues>;
	dataTestIdEntryNumber?: number;
	isItemDisabled?: (item: string) => boolean;
}

export function CheckboxRadioGroupInput({
	name,
	options,
	required,
	disabled,
	readOnly,
	initialCustomEnabled,
	isVertical,
	allowCreate,
	isRadioGroup,
	borderError,
	value: _value,
	formContext,
	dataTestIdEntryNumber,
	isItemDisabled
}: CheckboxRadioGroupInputProps) {
	function dataTestIdValue() {
		if (dataTestIdEntryNumber) {
			return name.replace(/\s/g, '').toLowerCase() + dataTestIdEntryNumber;
		}

		return name.replace(/\s/g, '').toLowerCase();
	}

	const { variablesMap } = useVariablesData();

	if (_value) {
		return isRadioGroup ? (
			<RadioGroupUncontrolled
				dataTestId={dataTestIdValue()}
				name={name}
				options={options}
				value={_value as string}
				required={required}
				allowCreate={allowCreate}
				isVertical={isVertical}
				allowUnselect
				isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
			/>
		) : (
			<CheckboxGroupUncontrolled
				dataTestId={dataTestIdValue()}
				name={name}
				options={options}
				values={_value as string[]}
				required={required}
				allowCreate={allowCreate}
				isVertical={isVertical}
				isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
			/>
		);
	}

	// USED IN FORM DESIGNER
	if (!formContext) {
		return isRadioGroup ? (
			<RadioGroupUncontrolled
				dataTestId={dataTestIdValue()}
				name={name}
				options={options}
				disabled={disabled}
				required={required}
				allowCreate={allowCreate}
				isVertical={isVertical}
				allowUnselect
				isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
			/>
		) : (
			<CheckboxGroupUncontrolled
				dataTestId={dataTestIdValue()}
				name={name}
				options={options}
				disabled={disabled}
				required={required}
				allowCreate={allowCreate}
				isVertical={isVertical}
				isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
			/>
		);
	}

	// USED IN ADD/EDIT FORM
	const {
		getValues,
		setValue,
		formState: { errors }
	} = formContext;

	function handleSetValue(name: string, value: DynamicFormValue) {
		// CHECK IF USER HAS ACCESS TO CHANGE THE VALUE
		if (readOnly) {
			entryFormReadOnlyModalEvent().dispatch(true);
			return;
		}

		setEntryFormFieldValue(name, value, setValue);
		const fieldNames = Object.keys(variablesMap);
		entryFormDependenciesCheckEvent().dispatch({ fieldNames });
	}

	const initialCustomValue = getValues(withCustomSuffix(name)) as string | undefined;

	const error = (errors[name] as FieldError)?.message;
	// for radio group custom value
	const errorCustom = (errors[withCustomSuffix(name)] as FieldError)?.message;

	const generalProps = {
		name,
		options,
		error,
		errorCustom,
		borderError,
		disabled,
		readOnly,
		required,
		allowCreate,
		initialCustomValue,
		initialCustomEnabled,
		isVertical
	};

	return (
		<Controller
			name={name}
			defaultValue={''}
			render={({ field: { value, onBlur } }) =>
				isRadioGroup ? (
					<RadioGroupUncontrolled
						dataTestId={dataTestIdValue()}
						{...generalProps}
						value={value}
						onBlur={onBlur}
						onChange={newValue => handleSetValue(name, newValue)}
						onChangeCustom={newValue =>
							handleSetValue(withCustomSuffix(name), newValue)
						}
						isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
						allowUnselect
					/>
				) : (
					<CheckboxGroupUncontrolled
						dataTestId={dataTestIdValue()}
						{...generalProps}
						values={value}
						onBlur={onBlur}
						onChange={newValues => handleSetValue(name, newValues)}
						onChangeCustom={newValue =>
							handleSetValue(withCustomSuffix(name), newValue)
						}
						isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
					/>
				)
			}
		/>
	);
}
