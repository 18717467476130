import { Control, useController, useForm } from 'react-hook-form';
import { Variable, Entry } from './types';
import clsx from 'clsx';
import { FormItem, ProjectData, VariableFormItem } from './data/useProjectData';
import {
	defaultEmptyValueForVariables,
	parseBackendValues
} from './utils/parse-backend-values/parseBackendValues';
import { RadioGroup } from './component/RadioGroup';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import { Asterisk } from 'components/UI/Asterisk';
import { DateInput } from './component/DateInput';
import { TimeDurationInput } from './inputs/time-duration/TimeDurationInput';
import { Label } from './component/Label';
import { InputType } from 'types';
import { Input } from 'components/UI/Inputs/Input';

interface Props {
	initialEntry?: Entry;
	projectData: ProjectData;
	contentRef?: React.RefObject<HTMLFormElement>;
}

export const PrintEntryForm = ({ projectData, initialEntry, contentRef }: Props) => {
	const { control } = useForm<Entry>({
		mode: 'onBlur',
		defaultValues: initialEntry
			? parseBackendValues({ variables: projectData.variables, entry: initialEntry })
			: defaultEmptyValueForVariables(projectData.variables)
	});

	return (
		<form
			className="grid grid-cols-2 px-10 gap-10 relative mb-52 lg:w-[756px] lg:mx-auto"
			ref={contentRef}
		>
			{projectData.formItems.map(item => (
				<FormItemComponent
					key={`${item.type}_${item.name}`}
					item={item}
					control={control}
				/>
			))}
		</form>
	);
};

const FormItemComponent = ({ item, control }: { item: FormItem; control: FormControl }) => {
	switch (item.type) {
		case 'variable': {
			return <VariableInput control={control} variable={item.variable} />;
		}
		case 'group': {
			return (
				<GroupContainer
					groupName={item.group.groupName}
					groupLabel={item.group.groupLabel}
					formItems={item.formItems}
					control={control}
				/>
			);
		}
	}
};

const GroupContainer = ({
	groupName,
	groupLabel,
	formItems,
	control
}: {
	groupName: string;
	groupLabel: string;
	formItems: VariableFormItem[];
	control: FormControl;
}) => {
	return (
		<div key={groupName} className="p-6 col-span-full rounded-lg shadow-normal flex flex-col ">
			<div className={clsx('grid grid-cols-2 py-[10px] gap-10 lg:gap-y-10')}>
				<h2 className="text-base font-semibold">{groupLabel}</h2>

				{formItems.map(item => {
					switch (item.type) {
						case 'variable': {
							return (
								<VariableInput
									key={item.name}
									control={control}
									variable={item.variable}
								/>
							);
						}
					}
				})}
			</div>
		</div>
	);
};

export type FormControl = Control<Entry, any>;

const VariableInput = ({
	variable: _variable,
	control
}: {
	variable: Variable;
	control: FormControl;
}) => {
	const variable: Variable = { ..._variable, description: '' };
	const { field } = useController({ control, name: variable.variableName });

	const otherOptions = { label: '______________', value: '' };

	switch (variable.variableType) {
		case 'string':
			return (
				<div className="flex flex-col col-span-full">
					<div className="flex items-center gap-1">
						<Label
							htmlFor={variable.variableName}
							label={variable.variableLabel}
							required={variable.obligatory}
						/>
					</div>

					<textarea
						value={field.value}
						id={variable.variableName}
						className="rounded-md border border-gray-400 p-3 pt-[12px] text-base resize-none min-h-[40px]"
						disabled={variable.entryType === 'calculated'}
						onChange={doNothing}
					/>
				</div>
			);

		case 'float':
		case 'integer': {
			return (
				<Input
					value={field.value}
					className="col-span-full md:col-span-1"
					onChange={doNothing}
					type={InputType.Text}
					label={variable.variableLabel}
					required={variable.obligatory}
					disabled={variable.entryType === 'calculated'}
				/>
			);
		}

		case 'category': {
			return (
				<RadioGroup
					className="col-span-full"
					categories={[
						...variable.categories.map(category => ({
							label: category,
							value: category
						})),
						...(variable.fixedCategories === 'no' ? [otherOptions] : [])
					]}
					description={variable.description}
					variable={variable}
					control={control}
					disabled={variable.entryType === 'calculated'}
				/>
			);
		}

		case 'categoryMultiple': {
			const allowCreate = variable.fixedCategories === 'no';
			const options = [
				...variable.categories.map(category => ({
					label: category,
					value: category
				})),
				...(allowCreate ? [otherOptions] : [])
			];

			return (
				<fieldset className="flex flex-col gap-3 flex-wrap mt-4">
					<div className="flex gap-1">
						<legend className="font-semibold text-base">
							{variable.variableLabel}
						</legend>

						{variable.obligatory && <Asterisk />}
					</div>

					<div className="mt-3 flex gap-4 flex-wrap">
						{options.map(category => {
							const isSelected = field.value?.includes(category.value);

							return (
								<div key={category.value} className="flex gap-1 items-center">
									<label
										className={clsx(
											'relative flex gap-2 items-center hover:scale-105 transition-all text-base'
										)}
									>
										<div
											className={clsx(
												'w-8 h-8 rounded-md border-2 ',
												!isSelected && 'border-gray-600',
												isSelected && 'bg-primary-500 border-primary-500'
											)}
										>
											{isSelected && (
												<div className="inset-0 pt-[2px] flex items-center justify-center">
													<Icon
														size={s => s.s}
														svg={Svgs.Checkmark}
														colors={{ color: Colors.white }}
													/>
												</div>
											)}
										</div>

										{category.label}
									</label>
								</div>
							);
						})}
					</div>
				</fieldset>
			);
		}

		case 'date': {
			return (
				<DateInput
					type="date"
					variable={variable}
					disabled={variable.entryType === 'calculated'}
					description=""
					value={field.value}
				/>
			);
		}

		case 'datetime': {
			return (
				<DateInput
					value={field.value}
					type="datetime-local"
					variable={variable}
					disabled={variable.entryType === 'calculated'}
					description=""
				/>
			);
		}

		case 'file': {
			return null;
		}

		case 'userDefinedUnique': {
			return (
				<Input
					value={field.value}
					type={InputType.Text}
					label={variable.variableLabel}
					required={variable.obligatory}
					readOnly={variable.uniquenessType !== 'Manual'}
				/>
			);
		}

		case 'timeDuration': {
			return (
				<TimeDurationInput
					value={field.value}
					maxTimeUnit={variable.durationFormat.maxTimeUnit}
					minTimeUnit={variable.durationFormat.minTimeUnit}
					label={variable.variableLabel}
					required={variable.obligatory}
					onChange={doNothing}
					onBlur={doNothing}
					onError={doNothing}
				/>
			);
		}
	}

	// @ts-ignore
	console.error('Unhandled variable type: ', variable.variableType);
};

// Helper function to avoid eslint errors
function doNothing(): void {
	/* do nothing */
}
