import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { CategoryMultipleVariable } from '../types';
import { SelectItem } from 'types';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { CheckboxGroup } from '../component/CheckboxGroup';

interface Props {
	control: FormControl;
	variable: CategoryMultipleVariable;
}

export const CategoryMultipleInput = ({ variable, control }: Props) => {
	let allOptions: SelectItem[] = variable.categories.map(category => ({
		label: category,
		value: category
	}));

	if (variable.allowedCategories.length > 0) {
		allOptions = variable.allowedCategories.map(category => ({
			label: category.label,
			value: category.value
		}));
	}

	const { field, fieldState } = useController({
		name: variable.variableName,
		control
	});

	if (variable.optimizeForManyValues) {
		return (
			<CreatableSelect
				className="col-span-full md:col-span-1"
				label={variable.variableLabel}
				placeholder={variable.obligatory ? 'Select at least one' : 'Select'}
				values={field.value ? field.value.map((v: any) => ({ label: v, value: v })) : []}
				items={allOptions}
				error={fieldState.error?.message?.toString()}
				onBlur={field.onBlur}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
				onValuesSelected={values => {
					field.onChange(values);
				}}
				required={variable.obligatory}
				hasMultipleValues
				allowCreate={variable.allowedCategories.length === 0} // todo Martin confirm this
			/>
		);
	}

	return (
		<CheckboxGroup
			className="col-span-full"
			categories={allOptions}
			variable={variable}
			allowCreate={variable.fixedCategories === 'no'}
			control={control}
		/>
	);
};
