import { VariableType } from 'types/data/variables/constants';
import { dynamicTranslate } from 'helpers/dynamicTranslate';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { ApiImportErrorType } from 'store/data/projects/constants';

export function toExcelColumnName(columnNumber: number): string {
	let columnName = '';
	let remainder: number;

	while (columnNumber > 0) {
		remainder = (columnNumber - 1) % 26;
		columnName = String.fromCharCode(65 + remainder) + columnName;
		columnNumber = Math.floor((columnNumber - 1) / 26);
	}

	return columnName;
}

export function getErrorTypeLabel(
	translate: TranslateFunction,
	errorType: ApiImportErrorType,
	variableType: VariableType
): string {
	switch (errorType) {
		case ApiImportErrorType.ImportCouldNotParseValue:
			return dynamicTranslate(
				translate(dict => dict.import.importReview.tableHeaders.couldNotParseValue),
				[getVariableTypeTranslation(translate, variableType)]
			) as string;
		case ApiImportErrorType.DuplicateValueForUniqueVariable:
			return translate(
				dict => dict.import.importReview.tableHeaders.duplicateValuesForUniqueVariable
			);
		case ApiImportErrorType.MissingValueForUniqueVariable:
			return translate(
				dict => dict.import.importReview.tableHeaders.missingValueForUniqueVariable
			);
		case ApiImportErrorType.NotAllowedFixedCategoryValue:
			return translate(
				dict => dict.import.importReview.tableHeaders.notAllowedFixedCategoryValue
			);
		case ApiImportErrorType.NotAllowedToSetValueForUniqueVariable:
			return translate(
				dict => dict.import.importReview.tableHeaders.notAllowedToSetValueForUniqueVariable
			);
		case ApiImportErrorType.NonExistentDatetime:
			return translate(dict => dict.import.importReview.tableHeaders.nonExistentDatetime);
		case ApiImportErrorType.DatetimeExistsTwice:
			return translate(dict => dict.import.importReview.tableHeaders.datetimeExistsTwice);
		case ApiImportErrorType.NoMatchingEntry:
			return translate(dict => dict.import.importReview.tableHeaders.noMatchingEntry);
		case ApiImportErrorType.DuplicateMatchingEntry:
			return translate(dict => dict.import.importReview.tableHeaders.duplicateMatchingEntry);
		case ApiImportErrorType.DuplicateMatchingValue:
			return translate(dict => dict.import.importReview.tableHeaders.duplicateMatchingValue);
		default:
			return '';
	}
}

export function getVariableTypeTranslation(translate: TranslateFunction, variable: VariableType) {
	if (variable) {
		return translate(dict => dict.variableLabels[variable]);
	}

	return '';
}
