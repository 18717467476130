import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { CategoryVariable } from '../types';
import { SelectItem } from 'types';
import { RadioGroup } from '../component/RadioGroup';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';

interface Props {
	control: FormControl;
	variable: CategoryVariable;
}

export const CategoryVariableInput = ({ variable, control }: Props) => {
	// eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
	const showDropdown = variable.optimizeForManyValues || variable.categories.length > 100; // seems to be some logic here, e.g. if it's more than 100 options, show dropdown -- or `optimizeForManyValues` set to true

	const {
		field: { value, onChange },
		fieldState
	} = useController({
		name: variable.variableName,
		control
	});

	let options: SelectItem[] = variable.categories.map(category => ({
		label: category,
		value: category
	}));

	if (variable.allowedCategories.length > 0) {
		options = variable.allowedCategories.map(category => ({
			label: category.label,
			value: category.value
		}));
	}

	if (showDropdown) {
		return (
			<CreatableSelect
				className="col-span-full md:col-span-1"
				label={variable.variableLabel}
				items={options}
				required={variable.obligatory}
				value={
					value
						? {
								label: value,
								value
						  }
						: null
				}
				onValueSelected={onChange}
				allowCreate={variable.fixedCategories === 'no'}
				error={fieldState.error?.message?.toString()}
				disabled={variable.entryType === 'calculated'}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
			/>
		);
	}

	return (
		<RadioGroup
			className="col-span-full"
			categories={options}
			description={variable.description}
			variable={variable}
			allowCreate={variable.fixedCategories === 'no'}
			control={control}
			disabled={variable.entryType === 'calculated'}
		/>
	);
};
