import { useState } from 'react';
import { makeRequest } from './util';

export const useDeleteDataEntryMutation = () => {
	const [data, setData] = useState<{
		wasDeleted: true;
	}>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error>();

	async function mutate({ projectId, entryId }: { projectId: string; entryId: string }) {
		setLoading(true);

		try {
			await makeRequest({
				method: 'deleteDataEntry',
				service: 'data-entry',
				data: {
					projectId: parseInt(projectId),
					datasetentryid: entryId
				}
			});

			setData({ wasDeleted: true });
		} catch (e) {
			setError(e as Error);

			throw e;
		} finally {
			setLoading(false);
		}
	}

	return { data, loading, error, mutate };
};
