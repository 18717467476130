export const ROUTE_MAP = {
	project: {
		byId: {
			dataset: {
				createPath: ({ projectId }: { projectId: string }) =>
					`/projects/${projectId}/dataset`,

				create: {
					path: '/v1.5/projects/:projectId/dataset/create',
					createPath: ({ projectId }: { projectId: string }) =>
						`/v1.5/projects/${projectId}/dataset/create`
				},

				update: {
					path: '/v1.5/projects/:projectId/dataset/update/:entryId',
					createPath: ({ projectId, entryId }: { projectId: string; entryId: string }) =>
						`/v1.5/projects/${projectId}/dataset/update/${entryId}`
				}
			}
		}
	}
} as const;
