import { Dictionary, Svgs } from 'environment';
import { Container, Layout, VariableTypeContainer } from './FixOption.style';
import { Icon } from 'components/UI/Icons';
import { Input } from 'components/UI/Inputs/Input';
import { ImportType, InputType } from 'types/enums';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { PreviewVariable } from '../../../../../types/data/projects/import/types';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { VariableType } from 'types/data/variables/constants';
import { EDITABLE_DATA_TYPES_OPTIONS } from 'consts';

import { useSelector } from 'hooks/utils';
import { SelectItem } from 'types';
import { useImportTimezoneSettings } from 'hooks/store/data/projects/import/useImportTimezoneSettings';
import { Spacer } from 'components/UI/Spacer';
import {
	matchDateTimeFormat,
	showDateFormatPicker
} from 'helpers/projects/imports/importDateAndDatetime';
import { DATE_FORMATS } from 'types/data/projects/import/constants';
import { getTimeZoneOffset } from 'helpers/projects/imports/importTimezone';
import { IntervalDropdown } from '../../IntervalDropdown/IntervalDropdown';
import {
	getDropdownTimeUnits,
	getTimeDurationValue
} from 'helpers/projects/imports/importTimeDuration';
import { ORDERED_TIME_DURATION_KEYS } from 'timeDurationConsts';
import { useMemo } from 'react';
import { selectLanguage } from 'store/ui/i18n';

type Props = {
	variable: PreviewVariable | null;
	selectedOption: ImportType;
	updateVariable: (variable: PreviewVariable) => void;
	handleDateTypeUpdate: (vID: string, value: string) => void;
	handleCustomDateTypeUpdate: (vID: string, value: string, isDatetime?: boolean) => void;
	handleDurationFormatUpdate: (vID: string, item: SelectItem) => void;
	handleSourceUnitUpdate: (vID: string, value: string) => void;
};

export function FixOption({
	variable,
	selectedOption,
	updateVariable,
	handleDateTypeUpdate,
	handleCustomDateTypeUpdate,
	handleDurationFormatUpdate,
	handleSourceUnitUpdate
}: Props) {
	const { translate } = useTranslation();
	const language = useSelector(state => selectLanguage(state.ui.i18n));
	const { requiresTimezoneSettings } = useImportTimezoneSettings();

	const { dateFormats, dateTimeFormats, isExcelOrigin, suggestedVariableTypes, timeZones } =
		useSelector(state => state.data.projects.import);

	const dateFormatsSelectItems: SelectItem[] = Object.values(dateFormats).map(value => ({
		label: matchDateTimeFormat(value),
		value
	}));
	const dateTimeFormatsSelectItems: SelectItem[] = Object.values(dateTimeFormats).map(value => ({
		label: matchDateTimeFormat(value),
		value
	}));

	const variablesWithDateFormat: string[] = [];
	suggestedVariableTypes &&
		suggestedVariableTypes.forEach(suggestion => {
			if (
				suggestion.suggestedVariableType === VariableType.Date ||
				suggestion.suggestedVariableType === VariableType.DateTime
			) {
				variablesWithDateFormat.push(suggestion.name);
			}
		});

	const dataTypeSelectItems = EDITABLE_DATA_TYPES_OPTIONS.map(dataType => ({
		label: translate(() => dataType.label),
		value: dataType.value
	}));

	/*
	 ** TIME DURATION
	 */

	const timeUnitSelectItems = useMemo<SelectItem[]>(() => {
		return ORDERED_TIME_DURATION_KEYS.map(key => {
			const label = translate(
				dict =>
					dict.timeDurationPlaceholder.full[
						key as keyof typeof Dictionary.timeDurationPlaceholder.full
					]
			) as string;
			return {
				label: label.charAt(0).toUpperCase() + label.slice(1),
				value: key
			};
		});
	}, [translate, language]);

	if (!variable) {
		return null;
	}

	function onVariableTypeChanged(type: VariableType) {
		if (!variable) {
			return;
		}

		function getDateFormat(type: VariableType) {
			if (type === VariableType.DateTime) {
				return '%Y-%m-%dT%H:%M:%S%z';
			}
			if (type === VariableType.Date) {
				return '%Y-%m-%d';
			}
			return '';
		}

		updateVariable({
			...variable,
			type,
			...(variable.customDateFormat && { customDateFormat: '' }),
			...([VariableType.DateTime, VariableType.Date].includes(type)
				? {
						dateFormat: getDateFormat(type)
				  }
				: { dateFormat: '' })
		});
	}

	return (
		<Container id={`fix-option-container`} data-testid="fix-option-container">
			<Layout>
				<div>
					<Input
						type={InputType.Text}
						label={translate(dict => dict.import.importReview.fixOption.columnName)}
						value={
							selectedOption === ImportType.ReplaceAll ||
							selectedOption === ImportType.Now ||
							selectedOption === ImportType.MoreDataToExistingEntries
								? variable.label
								: variable.previewVariableLabel
						}
						className="column-name"
						readOnly
					/>
					{!isExcelOrigin &&
						showDateFormatPicker(
							variable.type as VariableType,
							VariableType.Date,
							variable.isExcelDateFormat
						) && (
							<>
								<Spacer size={s => s.s} />
								<CreatableSelect
									canClear={false}
									scrollIntoView
									value={
										variable.dateFormat
											? {
													label: matchDateTimeFormat(variable.dateFormat),
													value: matchDateTimeFormat(variable.dateFormat)
											  }
											: {
													label: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.selectDateFormat
													),
													value: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.selectDateFormat
													)
											  }
									}
									items={[
										{ label: 'Custom', value: 'Custom' },
										...dateFormatsSelectItems
									]}
									onValueSelected={value =>
										value && handleDateTypeUpdate(variable.id, value)
									}
								/>
								{variable.dateFormat === DATE_FORMATS.Custom && (
									<>
										<Spacer size={s => s.s} />
										<Input
											type={InputType.Text}
											value={variable.customDateFormat}
											error={variable.dateFormatError}
											className="select-data-type-custom"
											placeholder={translate(
												dict =>
													dict.projectsPage.dataToEntries.customDateFormat
											)}
											onChange={e =>
												handleCustomDateTypeUpdate(
													variable.id,
													e.target.value.toUpperCase()
												)
											}
										/>
									</>
								)}
							</>
						)}

					{showDateFormatPicker(
						variable.type as VariableType,
						VariableType.DateTime,
						variable.isExcelDateFormat
					) && (
						<>
							<Spacer size={s => s.s} />
							<CreatableSelect
								scrollIntoView
								canClear={false}
								value={
									variable.dateFormat
										? {
												label: matchDateTimeFormat(variable.dateFormat),
												value: matchDateTimeFormat(variable.dateFormat)
										  }
										: {
												label: translate(
													dict =>
														dict.projectsPage.dataToEntries
															.selectDateAndTime
												),
												value: translate(
													dict =>
														dict.projectsPage.dataToEntries
															.selectDateAndTime
												)
										  }
								}
								items={[
									{ label: 'Custom', value: 'Custom' },
									...dateTimeFormatsSelectItems
								]}
								onValueSelected={value =>
									value && handleDateTypeUpdate(variable.id, value)
								}
							/>

							{variable.dateFormat === DATE_FORMATS.Custom && (
								<>
									<Spacer size={s => s.s} />
									<Input
										type={InputType.Text}
										value={variable.customDateFormat}
										error={variable.dateFormatError}
										placeholder={translate(
											dict => dict.projectsPage.dataToEntries.customDateFormat
										)}
										className="select-data-type"
										onChange={e =>
											handleCustomDateTypeUpdate(
												variable.id,
												e.target.value.toUpperCase(),
												true
											)
										}
									/>
								</>
							)}
						</>
					)}

					{/* TIME DURATION SOURCE UNIT DROPDOWN */}
					{variable.type === VariableType.TimeDuration && (
						<>
							<Spacer size={s => s.s} />
							<CreatableSelect
								canClear={true}
								id={`preview-variable-time-duration-source-${variable.id}`}
								items={timeUnitSelectItems}
								placeholder={'Choose source unit'}
								onClear={() => {
									handleSourceUnitUpdate(variable.id, '');
								}}
								onValueSelected={value => {
									value && handleSourceUnitUpdate(variable.id, value);
								}}
								value={
									variable.timeDurationSource
										? {
												label:
													variable.timeDurationSource
														.charAt(0)
														.toUpperCase() +
													variable.timeDurationSource.slice(1),
												value: variable.timeDurationSource
										  }
										: null
								}
								error={variable.timeDurationSourceError}
							/>
						</>
					)}
				</div>
				<Icon svg={Svgs.ArrowLongRight} className="arrow" />
				<VariableTypeContainer>
					<CreatableSelect
						id={'variable-type-select'}
						canClear={false}
						value={
							variable.type
								? {
										label: translate(
											dict =>
												dict.variableLabels[variable.type as VariableType]
										),
										value: variable.type
								  }
								: {
										label: translate(
											({ inputPlaceholder }) => inputPlaceholder.pleaseSelect
										),
										value: translate(
											({ inputPlaceholder }) => inputPlaceholder.pleaseSelect
										)
								  }
						}
						scrollIntoView
						items={dataTypeSelectItems}
						className="select-data-type"
						onValueSelected={value =>
							value && onVariableTypeChanged(value as VariableType)
						}
						label={translate(dict => dict.import.importReview.fixOption.variableType)}
						disabled={!variable.isNew}
					/>
					{/* TIMEZONE SETTINGS IF FORMAT DOES NOT INCLUDE Z / +HH:MM / -HH:MM */}
					{requiresTimezoneSettings(variable) && (
						<>
							<Spacer size={s => s.s} />
							<CreatableSelect
								canClear={true}
								id={`global_timezone_select`}
								items={timeZones}
								onClear={() =>
									updateVariable({
										...variable,
										timeZone: {
											label: '',
											value: ''
										}
									})
								}
								onValueSelected={value =>
									value &&
									updateVariable({
										...variable,
										timeZone: {
											label: `${getTimeZoneOffset(value)} ${value}`,
											value
										}
									})
								}
								value={variable.timeZone}
							/>
						</>
					)}

					{/* TIME DURATION INTERVAL DROPDOWN*/}
					{variable.type === VariableType.TimeDuration && (
						<>
							<IntervalDropdown
								style={{ marginTop: '0.6rem' }}
								id={`preview-variable-time-duration-interval-${variable.id}`}
								value={getTimeDurationValue(variable, translate)}
								onSelect={item => handleDurationFormatUpdate(variable.id, item)}
								items={getDropdownTimeUnits(
									variable.durationFormat,
									timeUnitSelectItems
								)}
								error={variable.durationFormatError}
							/>
						</>
					)}
				</VariableTypeContainer>
			</Layout>
		</Container>
	);
}
