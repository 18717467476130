import {
	EntryVariableType,
	VariableEntryLabels,
	VariableLabels,
	VariableType
} from 'types/data/variables/constants';
import { VariableSubType } from 'api/data/variables/statics';
import { Variable } from 'api/data/variables/types';
import { Colors } from 'environment';
import { DateTimeFormat } from 'store/account/subscription';
import {
	CollaboratorPermissions,
	GeneralPermissions,
	ModulesPermissions
} from 'store/data/collaborators';
import { DependencyType } from 'store/data/dependencies';
import { CreditCardBrands } from 'types/index';

export const SEARCH_DEBOUNCE_TIME = 300;
export const UPDATE_DEBOUNCE_TIME = 400;
export const ANALYSIS_DEBOUNCE_TIME = 800;
export const FETCH_DEBOUNCE_TIME = 1000;
export const DEFAULT_ACTIVITY_TIMEOUT = 6000;

export const YEAR_FORMAT = 'yyyy';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT_COMMA = 'MMM dd, yyyy';
export const PICKER_DATE_FORMAT = 'yyyy/MM/dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATE_TIME_FORMAT__NO_SECONDS = 'yyyy-MM-dd HH:mm';
export const DATE_TIME_12H_FORMAT__NO_SECONDS = 'yyyy-MM-dd h:mm aa';
export const DATE_TIME_FORMAT_FILENAME = 'yyyy_MM_dd-HH_mm_ss';
export const DATE_TIME_PICKER_TIMEZONE_FORMAT = 'yyyy-MM-dd HH:mm:ssxxx';
export const DATE_TIME_TIMEZONE_FORMAT = 'yyyy-MM-dd HH:mm:ssxx';
export const TIME_FORMAT = 'HH:mm';
export const TIME_TIMEZONE_FORMAT = 'HH:mm:ssxx';
export const VERSION_DATE_YEAR_TIME_FORMAT = 'MMM dd, yyyy, h:mm a';

export const DEFAULT_DATE_TIME_FORMAT: DateTimeFormat = '24';

export const ENTRY_FILE_SIZE_LIMIT = 4000000;
export const DATASET_FILE_SIZE_LIMIT = 1000000000;
export const DATASET_FILE_SIZE_LIMIT_EXCEL = 9000000;
export const DOCUMENT_FILE_SIZE_LIMIT = 6000000;

export const VAR_LABEL_PATTERN = /^[^;,:]+$/;
export const SPECIAL_CHARACTERS = /[;,:]+/g;

export const JADBIO_PROBABILITIES_DENSITY_SEGMENTS = 8;

export const DEPENDENCY_OPTIONS = [
	{
		label: 'Visibility',
		value: DependencyType.Visibility
	},
	{
		label: 'Filtering',
		value: DependencyType.Filtering
	}
];

export const DATE_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
export const DATE_TIME_REGEX =
	/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T(2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9](?:\.\d+)?(?:Z|[+-](?:2[0-3]|[01][0-9]):?[0-5][0-9])$/;
export const EMAIL_REGEX =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX_WITHOUT_PREFIX = /[0-9]{2,14}/;
export const PHONE_REGEX_WITH_PLUS_PREFIX = /(([+])\d[0-9]{6,14})/;

export const DEFAULT_TABLE_PAGE_SIZE = 10;

export const DROPDOWN_ITEM = {
	regular: {
		minHeight: 50
	},
	button: {
		minHeight: 42
	}
};

export const DATA_TYPES_OPTIONS = [
	{
		label: VariableLabels.Category,
		value: VariableType.Category
	},
	{
		label: VariableLabels.CategoryMultiple,
		value: VariableType.CategoryMultiple
	},
	{
		label: VariableLabels.Integer,
		value: VariableType.Integer
	},
	{
		label: VariableLabels.Float,
		value: VariableType.Float
	},
	{
		label: VariableLabels.Date,
		value: VariableType.Date
	},
	{
		label: VariableLabels.DateTime,
		value: VariableType.DateTime
	},
	{
		label: VariableLabels.String,
		value: VariableType.String
	},
	{
		label: VariableLabels.File,
		value: VariableType.File
	},
	{
		label: VariableLabels.Unique,
		value: VariableType.Unique
	},
	{
		label: VariableLabels.TimeDuration,
		value: VariableType.TimeDuration
	}
];

export const EDITABLE_DATA_TYPES_OPTIONS = DATA_TYPES_OPTIONS.filter(
	type => ![VariableType.File].includes(type.value)
);

export const CALCULATED_VARIABLE_TYPES = DATA_TYPES_OPTIONS.filter(
	type =>
		![
			VariableType.File,
			VariableType.String,
			VariableType.TimeDuration,
			VariableType.CategoryMultiple,
			VariableType.Unique
		].includes(type.value)
);

export const newCreditCard = {
	id: '',
	countryCode: '',
	addressLine1: '',
	addressLine2: '',
	billingCity: '',
	firstName: '',
	lastName: '',
	brand: CreditCardBrands.Invalid,
	billingZip: '',
	expiryMonth: 0,
	expiryYear: 0,
	isPrimary: false,
	maskedNumber: '',
	billingState: '',
	cardValid: false
};

export const ENTRY_TYPES_OPTIONS = [
	{
		label: VariableEntryLabels.Entry,
		value: EntryVariableType.Entry
	},
	{
		label: VariableEntryLabels.Calculated,
		value: EntryVariableType.Calculated
	}
];

export const AVATARS_COLORS_X = [
	Colors.avatar.paleGreyLight,
	Colors.avatar.hospitalGreen,
	Colors.avatar.mutedPink,
	Colors.avatar.lightSalmon,
	Colors.avatar.lightSalmonMedium,
	Colors.avatar.darkCream,
	Colors.avatar.lightBlue,
	Colors.avatar.lightGreyBlue,
	Colors.avatar.heather,
	Colors.avatar.paleTeal
];

export const CHART_HEIGHT = 450;

export const CHART_COLORS = [
	Colors.chart.primary.dark,
	Colors.chart.red.dark,
	Colors.chart.violet.dark,
	Colors.chart.lightBlue.dark,
	Colors.chart.lightGreen.dark,
	Colors.chart.darkGreen.dark,
	Colors.chart.orange.dark,
	Colors.chart.yellow.dark,
	Colors.chart.pink.dark,
	Colors.chart.darkBlue.dark,
	Colors.chart.primary.medium,
	Colors.chart.red.medium,
	Colors.chart.violet.medium,
	Colors.chart.lightBlue.medium,
	Colors.chart.lightGreen.medium,
	Colors.chart.darkGreen.medium,
	Colors.chart.orange.medium,
	Colors.chart.yellow.medium,
	Colors.chart.pink.medium,
	Colors.chart.darkBlue.medium,
	Colors.chart.primary.light,
	Colors.chart.red.light,
	Colors.chart.violet.light,
	Colors.chart.lightBlue.light,
	Colors.chart.lightGreen.light,
	Colors.chart.darkGreen.light,
	Colors.chart.orange.light,
	Colors.chart.yellow.light,
	Colors.chart.pink.light,
	Colors.chart.darkBlue.light
];

export const DEFAULT_MAX_LABELS = 10;

export const RESPONSE_RATE_CHART = [
	Colors.green.light,
	Colors.blue.light,
	Colors.yellow.dark,
	Colors.gray.lighter
];

export const RESPONSE_RATE_CHART_LEGEND = [
	Colors.yellow.faded,
	Colors.blue.faded,
	Colors.green.faded
];

export const VARIABLE_DATA_DEFAULT_VALUES: Variable = {
	name: '',
	type: VariableType.Category,
	subType: VariableSubType.Days,
	entryType: EntryVariableType.Entry,
	label: '',
	description: '',
	categories: [],
	fixedCategories: false,
	optimizeForManyValues: false,
	obligatory: false,
	personalData: false,
	validationRange: false,
	validationCases: {
		minValue: '',
		maxValue: ''
	},
	cases: []
};

export const DEFAULT_CHART_OPTIONS = {
	globals: {
		fontFamily: 'Roboto Flex'
	},
	gui: {
		contextMenu: {
			empty: true
		}
	},
	legend: {
		adjustLayout: true,
		align: 'left',
		borderWidth: 0,
		highlightPlot: true,
		toggleAction: 'remove',
		layout: 'float',
		marker: {
			borderRadius: 2,
			cursor: 'hand'
		},
		item: {
			fontSize: 14,
			offsetX: -10,
			color: Colors.text.hint,
			cursor: 'hand'
		},
		header: {
			fontSize: 14,
			fontWeight: 'normal',
			color: Colors.text.main,
			borderBottom: '0px',
			margin: 0,
			padding: '0px 0px 0px 14px',
			maxChars: 60
		}
	},
	plot: {
		error: {
			lineColor: Colors.text.disabled,
			lineWidth: 1,
			hoverState: {
				visible: false
			}
		},
		barMaxWidth: 200
	},
	plotarea: {
		adjustLayout: true
	},
	scaleX: {
		label: {
			fontSize: 14,
			fontWeight: 'bold',
			color: Colors.text.main
		},
		item: {
			fontSize: 14,
			padding: '5%'
		},
		guide: {
			lineColor: Colors.text.disabled
		}
	},
	scaleY: {
		label: {
			fontSize: 14,
			fontWeight: 'bold',
			color: Colors.text.main
		},
		item: {
			fontSize: 14
		},
		guide: {
			lineColor: Colors.text.disabled
		}
	},
	tooltip: {
		wrapText: true,
		htmlMode: true,
		shadow: false,
		borderRadius: 5,
		borderWidth: 1,
		padding: 10,
		borderColor: Colors.text.disabled
	},
	options: {
		lineMinLevel: {
			lineColor: Colors.text.hint
		},
		lineMaxLevel: {
			lineColor: Colors.text.hint
		},
		lineMinConnector: {
			lineColor: Colors.text.hint
		},
		lineMaxConnector: {
			lineColor: Colors.text.hint
		}
	}
};

export const DEFAULT_EXPORT_CHART_OPTIONS = {
	legend: {
		visible: true,
		item: {
			fontSize: 24,
			maxChars: undefined
		},
		marker: {
			size: 12
		},
		header: {
			fontSize: 24,
			padding: '0px 0px 0px 20px'
		}
	},
	scaleY: {
		item: {
			fontSize: 24
		},
		label: {
			fontSize: 24,
			maxChars: undefined
		}
	},
	scaleX: {
		item: {
			fontSize: 24,
			maxChars: undefined
		},
		label: {
			fontSize: 24,
			maxChars: undefined
		}
	},
	plot: {
		valueBox: {
			fontSize: 24
		}
	}
};

export const DefaultGeneralPermissions: GeneralPermissions = {
	accessAnalysisAll: false,
	accessAnalysisOrg: false,
	accessAnalysisOwnonly: false,
	accessEntriesAllRead: false,
	accessEntriesAllWrite: false,
	accessEntriesAllDelete: false,
	accessEntriesOrgRead: false,
	accessEntriesOrgWrite: false,
	accessEntriesOrgDelete: false,
	accessEntriesOwnonlyRead: false,
	accessEntriesOwnonlyWrite: false,
	accessEntriesOwnonlyDelete: false,
	accessExport: false,
	accessProjectRead: false,
	accessProjectWrite: false,
	accessVariablesRead: false,
	accessVariablesWrite: false,
	accessPersonalData: false,
	projectOwnerHasAccessEntriesAllTransferOwnership: true
};

export const DefaultModulesPermissions: ModulesPermissions = {
	projectDesign: true,
	collaborators: true
};

export const DefaultCollaboratorPermissions: CollaboratorPermissions = {
	...DefaultGeneralPermissions,
	modules: DefaultModulesPermissions,
	projectOrganizationAccesses: []
};

export const AUTO_GENERATED_PROM_VARIABLES = [
	'filledtimedefaultvariableauto',
	'firstnamedefaultvariableauto',
	'lastnamedefaultvariableauto',
	'distnrdefaultvariableauto',
	'timeofupdate',
	'distributionnr',
	'firstname',
	'lastname'
];

export const systemGeneratedVariables = [
	'enteredbyuserwithname',
	'ownedbyuserwithname',
	'userProjectOrgId',
	'creationdate',
	'lastmodifieddate',
	'datasetentryid',
	'enteredbyuser',
	'ownedbyuser'
];

export const systemGeneratedVariableLabels = {
	[systemGeneratedVariables[0]]: 'Last modified by',
	[systemGeneratedVariables[1]]: 'Owned by',
	[systemGeneratedVariables[2]]: 'Group',
	[systemGeneratedVariables[3]]: 'Created date',
	[systemGeneratedVariables[4]]: 'Last modified date',
	[systemGeneratedVariables[5]]: 'Entry ID',
	[systemGeneratedVariables[6]]: 'Last modified by (id)', // hidden field
	[systemGeneratedVariables[7]]: 'Owned by (id)' // hidden field
};

export const PatientReservedVariables = [
	...systemGeneratedVariables,
	...AUTO_GENERATED_PROM_VARIABLES
];

export const keyMaps = {
	escape: ['Escape', 'Esc', '27', 27],
	enter: ['Enter', '13', 13],
	backspace: ['Backspace'],
	tab: ['Tab'],
	arrowLeft: ['ArrowLeft', '37', 37],
	arrowRight: ['ArrowRight', '39', 39],
	arrowUp: ['ArrowUp', '38', 38],
	arrowDown: ['ArrowDown', '40', 40],
	delete: ['Delete', '46', 46],
	backSlash: ['/', 'Backslash', '220', 220],
	forwardSlash: ['/', 'Slash', 'NumpadDivide', '191', '111', 191, 111],
	comma: [',', 'Comma', '188', 188],
	letter: {
		c: ['c', 'KeyC', '67', 67]
	}
};

export const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

export const SYSTEM_GENERATED_STATUS_NAME = 'none_generated_by_ledidi';

export const STATUS_COLUMN = {
	name: 'status_column',
	label: 'Status'
};

export const SELECT_COLUMN = {
	name: 'select_column',
	label: ''
};

export const dateTimeFormatMap: Record<DateTimeFormat, string> = {
	'24': DATE_TIME_FORMAT__NO_SECONDS,
	'12': DATE_TIME_12H_FORMAT__NO_SECONDS
};

export const ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD = 100;

export enum ExportType {
	Series = 'series'
}

export enum EditCategoryFixedValuesType {
	CategoryFixedValuesChanged = 'CategoryFixedValuesChanged'
}

export const ENTRY_METADATA_FIELD_NAMES = [
	'enteredbyuser',
	'ownedbyuser',
	'ownedbyuserwithname',
	'enteredbyuserwithname',
	'userProjectOrgId',
	'creationdate',
	'lastmodifieddate',
	'datasetentryid'
];

export const PROJECT_METADATA_LOCAL_IDENTIFIER = 'metadata_';
