import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Label } from './Label';
import { Variable } from '../types';
import { OptionalDescriptionTooltip } from './OptionalDescriptionTooltip';

type DateInputProps = {
	variable: Variable;
	error?: string;
	type: 'date' | 'datetime-local';
	description?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;

type Variant = 'normal' | 'error' | 'disabled';

const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
	({ error, className, variable, disabled, description, ...forwardProps }, ref) => {
		const id = variable.variableName;

		let variant: Variant = 'normal';
		if (error) {
			variant = 'error';
		} else if (disabled) {
			variant = 'disabled';
		}

		return (
			<div className={clsx('flex flex-col gap-1')}>
				<div className="flex items-center gap-1">
					<Label
						disabled={disabled}
						htmlFor={id}
						label={variable.variableLabel}
						required={variable.obligatory}
					/>
					<OptionalDescriptionTooltip description={description} />
				</div>

				<input
					{...forwardProps}
					id={id}
					ref={ref}
					className={clsx(
						// idk why h-10 doesnt give 40px, please enlighten me if you do
						'rounded-md border w-full p-2 px-3 text-base h-[40px]',
						variant === 'normal' && 'border-gray-400',
						variant === 'error' && 'border-error-500',
						variant === 'disabled' && 'border-gray-300 bg-gray-300 text-gray-500'
					)}
					disabled={disabled}
				/>

				{error && <p className="text-error-500 font-bold">{error}</p>}
			</div>
		);
	}
);

DateInput.displayName = 'DateInput';

export { DateInput };
