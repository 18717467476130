import { DateVariable } from '../types';
import { FormControl } from '../EntryForm';
import { useController } from 'react-hook-form';
import { DateInput } from '../component/DateInput';

interface Props {
	variable: DateVariable;
	control: FormControl;
}

export const DateVariableInput = ({ control, variable }: Props) => {
	const { field, fieldState } = useController({
		control,
		name: variable.variableName
	});

	return (
		<DateInput
			{...field}
			type="date"
			variable={variable}
			error={fieldState.error?.message?.toString()}
			disabled={variable.entryType === 'calculated'}
			description={variable.description}
		/>
	);
};
