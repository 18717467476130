import { Input } from 'components/UI/Inputs/Input';
import { FormControl } from '../EntryForm';
import { UserDefinedUniqueVariable } from '../types';
import { InputType } from 'types';
import { useController } from 'react-hook-form';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';

interface Props {
	variable: UserDefinedUniqueVariable;
	control: FormControl;
}

export const UserDefinedUniqueVariableInput = ({ control, variable }: Props) => {
	const { field, fieldState } = useController({
		name: variable.variableName,
		control
	});

	return (
		<Input
			{...field}
			type={InputType.Text}
			label={variable.variableLabel}
			error={fieldState.error?.message?.toString()}
			LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
			required={variable.obligatory}
			readOnly={variable.uniquenessType !== 'Manual'}
		/>
	);
};
