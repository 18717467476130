import { TimeDurationFormat } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import {
	getMicrosecondsFromTimeDurationString,
	getTimeDurationStringFromMicroseconds
} from 'helpers/entries';
import { cloneDeep, entries, isEqual } from 'lodash';
import {
	DependenciesData,
	Dependency,
	DependencyType,
	SubRows,
	TableDependency
} from 'store/data/dependencies';
import { VariablesMap } from 'store/data/variables';
import { excludeTimeDurationLetterRegex } from 'timeDurationConsts';
import { GenericMap, NumberMap, StringArrayMap } from 'types/index';

export function isDependencyValid(dependency: Dependency): boolean {
	let valid = true;

	const { dependencyType, dependantVariables } = dependency;

	const isVisibilityCondition = dependencyType === DependencyType.Visibility;
	const isFilteringCondition = dependencyType === DependencyType.Filtering;

	dependantVariables.forEach(dependantVariable => {
		const { dependantVariableName, supplierValueCondition, filteredValues } = dependantVariable;

		const isSupplierValueConditionValid = supplierValueCondition.trim() !== '';
		const isDependantVariableNameValid = dependantVariableName !== '';
		const areFilteredValuesValid = filteredValues.length > 0;

		// VISIBILITY
		if (isVisibilityCondition) {
			const isDependantVariableValid =
				isSupplierValueConditionValid && isDependantVariableNameValid;

			if (!isDependantVariableValid) valid = false;
		}

		// FILTERING
		if (isFilteringCondition) {
			const isDependantVariableValid =
				areFilteredValuesValid &&
				isSupplierValueConditionValid &&
				isDependantVariableNameValid;

			if (!isDependantVariableValid) valid = false;
		}
	});

	const hasDependantVariables = dependantVariables.length > 0;

	if (!hasDependantVariables) valid = false;

	return valid;
}

export function isTableDependencyValid(dependency: TableDependency | SubRows): boolean {
	let valid = true;

	const {
		dependencyType,

		dependantVariableName,
		supplierValueCondition,
		filteredValues
	} = dependency;

	const isVisibilityCondition = dependencyType === DependencyType.Visibility;
	const isFilteringCondition = dependencyType === DependencyType.Filtering;

	const isSupplierValueConditionValid = supplierValueCondition.trim() !== '';
	const isDependantVariableNameValid = dependantVariableName !== '';
	const areFilteredValuesValid = filteredValues.length > 0;

	// VISIBILITY
	if (isVisibilityCondition) {
		const isDependantVariableValid =
			isSupplierValueConditionValid && isDependantVariableNameValid;

		if (!isDependantVariableValid) valid = false;
	}

	// FILTERING
	if (isFilteringCondition) {
		const isDependantVariableValid =
			areFilteredValuesValid && isSupplierValueConditionValid && isDependantVariableNameValid;

		if (!isDependantVariableValid) valid = false;
	}

	return valid;
}

/**
 * Returns a list of unique variable names used in the dependency rule
 */
export function getAllVariableNamesInDependency(dependency: Dependency) {
	const variableNames: string[] = [];

	const { supplierVariableName, dependantVariables } = dependency;

	const dependantVariableNames = dependantVariables.map(
		dependantVariable => dependantVariable.dependantVariableName
	);

	variableNames.push(supplierVariableName, ...dependantVariableNames);

	const uniqueVariableNames = [...new Set(variableNames)];

	return uniqueVariableNames;
}

/**
 * Returns a dictionary of dependency indexes in the main array by `dependencyName`
 */
export function buildDependenciesIndexByName(dependencies: Dependency[]): NumberMap {
	const dependenciesIndexByName: NumberMap = {};

	dependencies.forEach((dependency, index) => {
		dependenciesIndexByName[dependency.dependencyName] = index;
	});

	return dependenciesIndexByName;
}

export function parseTimeDurationDependencies(deps: Dependency[], variablesMap: VariablesMap) {
	const dependencies = cloneDeep(deps);

	dependencies.forEach(dependency => {
		const variable = variablesMap[dependency.supplierVariableName];
		if (variable.type === VariableType.TimeDuration && variable.durationFormat) {
			dependency.dependantVariables.forEach(dependant => {
				const { supplierValueCondition } = dependant;

				// backward compatibility with previous values that are still hardcoded strings!
				if (excludeTimeDurationLetterRegex.test(supplierValueCondition)) {
					const microseconds = getMicrosecondsFromTimeDurationString(
						supplierValueCondition,
						variable.durationFormat as TimeDurationFormat
					);

					dependant['supplierValueCondition'] = getTimeDurationStringFromMicroseconds(
						microseconds,
						variable.durationFormat as TimeDurationFormat
					) as string;

					return;
				}
			});
		}
	});

	return dependencies;
}

export function parseTimeDurationSetDependencies(
	deps: Record<string, { active: boolean; dependencies: Dependency[] }>,
	variablesMap: VariablesMap
) {
	const dependenciesBySetName = cloneDeep(deps);
	entries(dependenciesBySetName).forEach(([_, bySetName]) => {
		const { dependencies } = bySetName;
		dependencies.forEach(dependency => {
			const variable = variablesMap[dependency.supplierVariableName];
			if (variable.type === VariableType.TimeDuration && variable.durationFormat) {
				dependency.dependantVariables.forEach(dependant => {
					const { supplierValueCondition } = dependant;

					// backward compatibility with previous values that are still hardcoded strings!
					if (excludeTimeDurationLetterRegex.test(supplierValueCondition)) {
						const microseconds = getMicrosecondsFromTimeDurationString(
							supplierValueCondition,
							variable.durationFormat as TimeDurationFormat
						);

						dependant['supplierValueCondition'] = getTimeDurationStringFromMicroseconds(
							microseconds,
							variable.durationFormat as TimeDurationFormat
						) as string;

						return;
					}
				});
			}
		});
	});

	return dependenciesBySetName;
}

export function parseTimeDurationDependenciesData(
	deps: DependenciesData,
	variablesMap: VariablesMap
): DependenciesData {
	return {
		...deps,
		dependencies: parseTimeDurationDependencies(deps.dependencies, variablesMap),
		dependenciesBySetName: parseTimeDurationSetDependencies(
			deps.dependenciesBySetName,
			variablesMap
		)
	};
}

export function areDependenciesEqual(
	initial: Dependency[],
	current: Dependency[],
	variablesMap: VariablesMap
) {
	return isEqual(
		parseTimeDurationDependencies(initial, variablesMap),
		parseTimeDurationDependencies(current, variablesMap)
	);
}

function getAffectedDependencyNamesByDependency(
	dependency: Dependency,
	dependenciesByVariableName: GenericMap<Dependency>,
	recursiveDependencyNames?: string[]
) {
	const dependencyNames: string[] = recursiveDependencyNames ?? [];

	const { dependantVariables } = dependency;

	dependantVariables.forEach(dependantVariable => {
		const { dependantVariableName } = dependantVariable;

		const isDependantSupplierVariable = dependantVariableName in dependenciesByVariableName;

		if (isDependantSupplierVariable) {
			const affectedDependency = dependenciesByVariableName[dependantVariableName];

			const isCircularStructure = dependencyNames.includes(affectedDependency.dependencyName);

			if (isCircularStructure) return;

			dependencyNames.push(affectedDependency.dependencyName);

			const affectedDependencyNames = getAffectedDependencyNamesByDependency(
				affectedDependency,
				dependenciesByVariableName,
				dependencyNames
			);

			dependencyNames.push(...affectedDependencyNames);
		}
	});

	return [...new Set(dependencyNames)];
}

export function buildDependencyNamesByVariableName(dependencies: Dependency[]): StringArrayMap {
	const dependenciesByVariableName = buildDependenciesByVariableName(dependencies);

	const dependencyNamesByVariableName: StringArrayMap = {};

	dependencies.forEach(dependency => {
		const { dependencyName, supplierVariableName } = dependency;

		if (supplierVariableName in dependencyNamesByVariableName) {
			dependencyNamesByVariableName[supplierVariableName].push(dependencyName);
		} else {
			dependencyNamesByVariableName[supplierVariableName] = [dependencyName];
		}

		const affectedDependencyNames = getAffectedDependencyNamesByDependency(
			dependency,
			dependenciesByVariableName
		);

		dependencyNamesByVariableName[supplierVariableName].push(...affectedDependencyNames);
	});

	return dependencyNamesByVariableName;
}

export function getDependenciesNamesWhichIncludeVariableName({
	variableName,
	dependenciesMap
}: {
	variableName: string;
	dependenciesMap: GenericMap<Dependency>;
}) {
	const dependencyNames: string[] = [];
	entries(dependenciesMap).forEach(([depName, dep]) => {
		const dependantVariableNames = dep.dependantVariables.map(
			variable => variable.dependantVariableName
		);
		if (
			dependantVariableNames.includes(variableName) ||
			dep.supplierVariableName === variableName
		)
			dependencyNames.push(depName);
	});

	return [...new Set(dependencyNames)];
}

export function buildDependenciesByVariableName(
	dependencies: Dependency[]
): GenericMap<Dependency> {
	const map: GenericMap<Dependency> = {};

	dependencies.forEach(dependency => (map[dependency.supplierVariableName] = dependency));

	return map;
}

export function getDependantVariables(dependencies: Dependency[]): string[] {
	// First create a Set to ensure unique values
	const uniqueVariables = dependencies.reduce((acc, dependency) => {
		const dependantVariables = dependency.dependantVariables.map(d => d.dependantVariableName);
		dependantVariables.forEach(variable => acc.add(variable));
		return acc;
	}, new Set<string>());

	// Convert Set back to array before returning
	return Array.from(uniqueVariables);
}

export function getVariableNamesAffectedByDependencies(dependencies: Dependency[]) {
	return dependencies.reduce((acc, dependency) => {
		const dependantVariables = dependency.dependantVariables.map(d => d.dependantVariableName);
		return [...acc, ...dependantVariables, dependency.supplierVariableName];
	}, [] as string[]);
}
