import { useEffect, useState } from 'react';

import {
	Columns,
	CompareNumericAnalysisV2,
	CorrelationsAnalysis,
	JADBioAnalysis,
	TimeCourseAnalysisV2,
	PlotNumericAnalysisV2,
	LogisticRegressionAnalysisV2,
	DensityPlotAnalysisV2,
	ExploreAnalysisV2,
	CrosstabAnalysisV2,
	FrequenciesAnalysisV2,
	ComparePairedAnalysisV2,
	KaplanMeierAnalysisV2
} from 'api/data/analyses';
import { CompareNumeric, Correlations, JADBio, TimeCourse } from 'components/Analysis/Analyses';
import {
	AnalysisCard,
	AnalysisCardContainer
} from 'components/Analysis/Analyses/AnalysisList/Analysis/Analysis.style';
import { AnalysisOptions } from 'components/Analysis/Analyses/AnalysisList/Analysis/AnalysisOptions';
import { Filters } from 'components/Analysis/Filters';
import { MediaQueries, Svgs } from 'environment';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';

import { AnalysisFullScreenRow } from './AnalysisModal.style';
import { Modal } from 'components/UI/Modal';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { Grid } from 'components/UI/Grid';
import { Button } from 'components/UI/Interactables/Button';
import { getHeaderHeight } from 'helpers/generic';
import {
	useTranslation,
	useAnalysisById,
	useDeleteAnalysis,
	useAnalysisOpenState,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useFilters
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { AnalysisType } from 'api/data/analyses/constants';
import { PlotNumericV2 } from 'components/Analysis/Analyses/PlotNumericAnalysisV2/PlotNumericV2';
import { LogisticRegressionV2 } from 'components/Analysis/Analyses/LogisticRegressionV2/LogisticRegressionV2';
import { DensityPlotV2 } from 'components/Analysis/Analyses/DensityPlotV2/DensityPlotV2';
import { ExploreV2 } from 'components/Analysis/Analyses/ExploreAnalysisV2/ExploreV2';
import { CrosstabV2 } from 'components/Analysis/Analyses/CrosstabAnalysisV2/CrosstabV2';
import { FrequenciesV2 } from 'components/Analysis/Analyses/FrequenciesAnalysisV2/FrequenciesV2';
import { ComparePairedV2 } from 'components/Analysis/Analyses/ComparePairedAnalysisV2/ComparePairedV2';
import { KaplanMeierV2 } from 'components/Analysis/Analyses/KaplanMeierAnalysisV2/KaplanMeierV2';

interface Props {
	id: string;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
}

export function AnalysisModal({ id, variablesData, variablesDataSelectItems }: Props) {
	const { translate } = useTranslation();
	const analysis = useAnalysisById(id);
	const deleteAnalysis = useDeleteAnalysis();
	const [openState, setOpenState] = useAnalysisOpenState(id);
	const [exporting, setExporting] = useState(false);
	const [{ isConfigPanelOpen }, { openConfigPanel }] = useAnalysisConfigPanel(id);
	const [{ areFiltersOpen }, { toggleOpenFilters }] = useFilters();
	const [fullscreenAnalysis, setFullscreenAnalysis] = useFullscreenAnalysis();

	const [activeColumn] = useAnalysesActiveColum();
	const isDesktop = useMediaQuery(`only screen and ${MediaQueries.minWidth.sm}`);

	useEffect(() => {
		if (exporting) {
			setExporting(false);
		}
	}, [exporting]);

	useEffect(() => {
		openConfigPanel({ analysisId: id, openConfig: true });
		areFiltersOpen && toggleOpenFilters();
	}, []);

	function handleToggle() {
		setOpenState({ analysisId: id, open: !openState });
	}

	function handleDelete() {
		setFullscreenAnalysis({ fullscreen: '' });
		deleteAnalysis(id);
	}

	function handleCloseModal() {
		setFullscreenAnalysis({ fullscreen: '' });
	}

	function handleExport() {
		setExporting(true);
	}
	function handleToggleConfig() {
		openConfigPanel({ analysisId: id, openConfig: !isConfigPanelOpen });
	}

	function handleFullScreen(fullScreenId: string) {
		setFullscreenAnalysis({ fullscreen: fullScreenId });
	}

	if (analysis.invalid) {
		const title = `${translate(() => analysis.type)} ${translate(
			({ analysis }) => analysis.errors.snapshots.invalid
		)}`;

		return (
			<CollapsibleCard
				title={title}
				open={false}
				actionComponent={
					<AnalysisOptions
						open={false}
						onDelete={handleDelete}
						onExport={() => undefined}
					/>
				}
				onToggle={handleToggle}
			>
				&nbSp;
			</CollapsibleCard>
		);
	}

	return (
		<Modal
			fullSizeConfig={{
				headerNavigationConfig: {
					rightComponent: (
						<Flex
							align={a => a.center}
							css={`
								.custom-dropdown-container {
									width: unset;
								}
							`}
						>
							{!isDesktop ? (
								<Icon
									svg={Svgs.Filter}
									variant={v => v.button}
									active={areFiltersOpen}
									marginOffset={{ right: 2.4 }}
									onClick={toggleOpenFilters}
								/>
							) : (
								<Switch
									label={translate(({ filters }) => filters.showFilters)}
									on={areFiltersOpen}
									marginOffset={{ right: 2.4 }}
									onChange={toggleOpenFilters}
								/>
							)}

							<Icon
								svg={Svgs.Edit}
								variant={v => v.button}
								active={isConfigPanelOpen}
								disabled={!openState}
								marginOffset={{ right: 0.8 }}
								onClick={handleToggleConfig}
							/>
							<AnalysisOptions
								open={openState}
								onDelete={handleDelete}
								onExport={handleExport}
							/>
						</Flex>
					)
				},
				headerMainConfig: {
					leftComponent: (
						<Flex align={a => a.center}>
							<Typography.H6 title={translate(() => analysis.type)} ellipsis>
								{translate(() => analysis.type)}
							</Typography.H6>
						</Flex>
					),
					rightComponent: (
						<Flex align={a => a.center}>
							<Icon
								svg={Svgs.Close}
								variant={v => v.buttonActive}
								onClick={handleCloseModal}
							/>
						</Flex>
					)
				}
			}}
			size={s => s.full}
			onClose={handleCloseModal}
			bodySpacing={false}
			visible
		>
			<Spacer size={s => s.s} />

			<AnalysisFullScreenRow>
				{areFiltersOpen || (!isDesktop && areFiltersOpen) ? (
					<Grid.Col
						sizes={areFiltersOpen ? { xl: 3, l: 3, m: 3 } : { xl: 0, l: 0, m: 0 }}
					>
						<Filters
							fullscreen={!!fullscreenAnalysis}
							heightOffset={getHeaderHeight().rem}
						/>
					</Grid.Col>
				) : null}

				<Grid.Col sizes={areFiltersOpen ? { xl: 13, l: 9, m: 3 } : { xl: 16, l: 12, m: 6 }}>
					{analysis.invalid ? (
						<AnalysisCard isFullScreen={fullscreenAnalysis}>
							<Typography.Paragraph>
								{translate(() => analysis.type)}
								{translate(({ analysis }) => analysis.errors.snapshots.invalid)}
							</Typography.Paragraph>
							<AnalysisOptions
								open={false}
								onDelete={handleDelete}
								onExport={() => undefined}
							/>
						</AnalysisCard>
					) : (
						<AnalysisCardContainer>
							<AnalysisCard isFullScreen={fullscreenAnalysis}>
								<Flex
									fullWidth
									justify={j => j.between}
									align={a => a.center}
									paddingOffset={{ y: 0, x: 2.4 }}
								>
									<Flex>
										{openState &&
											!fullscreenAnalysis &&
											activeColumn === Columns.OneColumn && (
												<Flex>
													<Icon
														variant={v => v.button}
														svg={Svgs.Edit}
														onClick={handleToggleConfig}
														active={isConfigPanelOpen}
														disabled={!openState}
														marginOffset={{ right: 1 }}
													/>
													<Icon
														variant={v => v.button}
														svg={Svgs.Maximize}
														onClick={() => handleFullScreen(id)}
														disabled={!openState}
														marginOffset={{ right: 0.4, left: 0.4 }}
													/>
												</Flex>
											)}
										{!fullscreenAnalysis &&
											activeColumn === Columns.OneColumn && (
												<AnalysisOptions
													open={openState}
													onDelete={handleDelete}
													onExport={handleExport}
												/>
											)}
									</Flex>
								</Flex>
								{analysis.type === AnalysisType.FrequenciesV2 && (
									<FrequenciesV2
										analysis={analysis as FrequenciesAnalysisV2}
										variablesDataSelectItems={variablesDataSelectItems}
										variablesData={variablesData}
										exporting={exporting}
									/>
								)}
								{analysis.type === AnalysisType.ExploreV2 && (
									<ExploreV2
										analysis={analysis as ExploreAnalysisV2}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}
								{analysis.type === AnalysisType.CompareNumericV2 && (
									<CompareNumeric
										analysis={analysis as CompareNumericAnalysisV2}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}

								{analysis.type === AnalysisType.CrosstabV2 && (
									<CrosstabV2
										analysis={analysis as CrosstabAnalysisV2}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}

								{analysis.type === AnalysisType.KaplanV2 && (
									<KaplanMeierV2
										analysis={analysis as KaplanMeierAnalysisV2}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}

								{analysis.type === AnalysisType.CorrelationsV2 && (
									<Correlations
										analysis={analysis as CorrelationsAnalysis}
										variablesDataSelectItems={variablesDataSelectItems}
										variablesData={variablesData}
										exporting={exporting}
									/>
								)}

								{analysis.type === AnalysisType.PlotNumericV2 && (
									<PlotNumericV2
										analysis={analysis as PlotNumericAnalysisV2}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}

								{analysis.type === AnalysisType.DensityPlotV2 && (
									<DensityPlotV2
										analysis={analysis as DensityPlotAnalysisV2}
										variablesDataSelectItems={variablesDataSelectItems}
										variablesData={variablesData}
										exporting={exporting}
									/>
								)}
								{analysis.type === AnalysisType.TimeCourseV2 && (
									<TimeCourse
										analysis={analysis as TimeCourseAnalysisV2}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}
								{analysis.type === AnalysisType.ComparePairedV2 && (
									<ComparePairedV2
										analysis={analysis as ComparePairedAnalysisV2}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}

								{analysis.type === AnalysisType.JADBio && (
									<JADBio
										analysis={analysis as JADBioAnalysis}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}

								{analysis.type === AnalysisType.LogisticRegressionV2 && (
									<LogisticRegressionV2
										analysis={analysis as LogisticRegressionAnalysisV2}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										exporting={exporting}
									/>
								)}
							</AnalysisCard>
						</AnalysisCardContainer>
					)}
				</Grid.Col>
			</AnalysisFullScreenRow>

			{!isDesktop && analysis.options.configPanel.open && (
				<Button
					marginOffset={{ y: 0, x: 2 }}
					title={translate(button => button.buttons.done)}
					onClick={() => {
						openConfigPanel({ analysisId: id, openConfig: false });
					}}
				/>
			)}
		</Modal>
	);
}
