import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { Disabled } from 'helpers/cssGenerators';
import { Typography } from 'components/UI/Typography';

interface ButtonProps extends Disabled {
	open: boolean;
	focused?: boolean;
	error?: string;
}

export const Title = styled(Typography.Paragraph)`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

export const Placeholder = styled(Typography.Paragraph)`
	color: #888888;
`;

export const Icon = styled(DefaultIcon)<IconProps>`
	transition: transform 0.25s;
	transform: ${({ open }) => open && 'rotate(180deg)'};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	user-select: none;
`;

export const Button = styled.div<ButtonProps>`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0.8rem;
	height: 4rem;
	border-radius: 0.4rem;
	cursor: pointer;
	transition: all 0.2s;
	outline: none;

	${({ open }) =>
		open
			? css`
					border: 0.12rem solid ${Colors.primary.normal};
			  `
			: css`
					border: 0.12rem solid ${Colors.text.disabled};
			  `}

	${({ focused }) =>
		focused !== undefined
			? focused
				? css`
						border: 0.12rem solid ${Colors.primary.normal};
				  `
				: css`
						border: 0.12rem solid ${Colors.text.disabled};
				  `
			: css``}

	${({ error }) =>
		error &&
		css`
			border: 0.12rem solid ${Colors.text.error};
		`}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
			border: 0.12rem solid ${Colors.background.disabled};
			background-color: ${Colors.background.disabled};

			${Title} {
				color: ${Colors.text.disabled};
			}
		`}
`;

interface IconProps {
	open: boolean;
}
