import { useState } from 'react';
import { makeRequest } from './util';
import { Entry } from '../types';
import { extractFilesFromEntry } from '../CreateEntryPageV1_5';
import { submitFiles } from './submitFiles';

export type DataEntryMutationResult = {
	httpStatusCode: number;
	insertedEntry: {
		datasetentryid: string;
	};
	errors?: {
		code: string; // some unique field value is already taken
	}[];
};

export const useInsertDataEntryMutation = () => {
	const [data, setData] = useState<{}>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error>();

	async function mutate({
		projectId,
		entry,
		organizationGroupId
	}: {
		projectId: string;
		entry: Entry;
		organizationGroupId?: string;
	}) {
		setLoading(true);

		try {
			const { entryWithoutFiles, files } = extractFilesFromEntry({
				entry
			});

			const result: DataEntryMutationResult = await makeRequest({
				method: 'insertDataEntry',
				service: 'data-entry',
				data: {
					projectId,
					observationData: entryWithoutFiles,
					organizationId: organizationGroupId
				}
			});

			if (files.length > 0) {
				await submitFiles({
					entryId: result.insertedEntry.datasetentryid,
					files,
					projectId
				});
			}

			setData(result);
			return result;
		} catch (e) {
			setError(e as Error);

			// In order for the code to stop running if we await this and it fails, we need to rethrow the error
			// not sure how to solve this in a best possible way
			throw e;
		} finally {
			setLoading(false);
		}
	}

	return {
		data,
		loading,
		error,
		mutate,
		reset: () => {
			setData(undefined);
			setLoading(false);
			setError(undefined);
		}
	};
};
