import { useState, useEffect } from 'react';
import { makeRequest } from './util';

type Result = {
	statusCode: string;

	project: {
		organizations: {
			organizationId: string;
			organizationName: string;

			organizationCollaborators: {
				userId: string;
			}[];
		}[];
	};
};

export const useGetProjectOrganizationCollaboratorsQuery = ({
	projectId
}: {
	projectId: string;
}) => {
	const [data, setData] = useState<Result>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();

	useEffect(() => {
		async function asyncShit() {
			setLoading(true);

			try {
				const result: Result = await makeRequest({
					method: 'getProjectOrganizationCollaborators',
					service: 'projects',
					data: {
						project: {
							projectId: parseInt(projectId)
						}
					}
				});

				if (result.statusCode !== '200') {
					console.error('Non 200 status code', { result });

					throw new Error('Failed to fetch');
				}

				setData(result);
			} catch (e) {
				console.error(e);
				setError('Something unexpected happened, please try again later.');
			} finally {
				setLoading(false);
			}
		}

		asyncShit();
	}, []);

	return { data, loading, error };
};
