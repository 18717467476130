import type {
	CalculationActions,
	CategoryNode,
	Variable,
	VariableCategory
} from 'api/data/variables';
import { OperandType, VariableType } from 'types/data/variables/constants';
import { SelectItem, InputType } from 'types/index';

import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Input } from 'components/UI/Inputs/Input';
import { ArithmeticNode, VariableNode } from 'api/data/variables/types';
import { ArithmeticOperation } from './ArithmeticOperation';
import { useTranslation } from 'hooks/store';
import { useState } from 'react';

import { integerSchema, floatSchema } from '../helpers';

type Props = {
	type: OperandType;
	value: string | number | CategoryNode | VariableNode | ArithmeticNode | null;
	valuePath: string | null;
	valueDepth: number;
	variables: Variable[];
	draftVariableType: VariableType;
	calculationActions: CalculationActions;
	categories?: VariableCategory[];
	isDateField?: boolean;

	onChange: (value: string | number | CategoryNode | VariableNode) => void;
	index?: number;
};

export function DynamicCalculationInput({
	type,
	value,
	valuePath,
	valueDepth,
	categories,
	variables,
	draftVariableType,
	calculationActions,
	isDateField,
	onChange,
	index
}: Props) {
	const { translate } = useTranslation();

	const [inputError, setInputError] = useState('');

	function handleChange(value: string) {
		if (value === '') {
			setInputError(translate(dict => dict.terms.insertValue));
			onChange(value);
			return;
		} else {
			setInputError('');
		}

		if (type == OperandType.NumberInput) {
			const matchesFloat = value.match(/^[+-]?(?!0\d)\d+(\.\d+)?([eE][+-]?\d+)?$/);
			const matchesInteger = value.match(/^[+-]?([1-9]\d*|0)(?!.)$/);

			if (
				matchesFloat &&
				(draftVariableType === VariableType.Float ||
					draftVariableType === VariableType.Category ||
					draftVariableType === VariableType.Date ||
					draftVariableType === VariableType.DateTime)
			) {
				const matchesIntermediaryFloat = value.match(/^[+-]?([1-9]\d*|0)\.$/);

				if (matchesIntermediaryFloat) {
					onChange('');
				} else {
					floatSchema
						.validate(value)
						.then(() => {
							setInputError('');
						})
						.catch(err => {
							setInputError(err.message);
						});

					onChange(parseFloat(value));
				}
			}

			if (matchesInteger && draftVariableType === VariableType.Integer) {
				integerSchema
					.validate(value)
					.then(() => {
						setInputError('');
					})
					.catch(err => {
						setInputError(err.message);
					});

				onChange(parseInt(value));
				return;
			}
		}

		if (type == OperandType.Category) {
			onChange({ catVal: value });
		}

		if (type == OperandType.String) {
			onChange(value);
		}

		if (
			type == OperandType.NumberVariable ||
			type == OperandType.DateVariable ||
			type == OperandType.DurationVariable
		) {
			const variable = variables?.find(variable => variable.name === value);
			variable && onChange({ var: [variable.name, variable.type] });
		}

		if (type == OperandType.DateInput) {
			const dateValue = new Date(value).toISOString();

			onChange(dateValue);
		}
	}

	if (type === OperandType.Category) {
		const categoryValues = categories || [];

		const selectItems: SelectItem[] = categoryValues.map(category => ({
			label: category.label || category.value,
			value: category.value
		}));

		const categoryValue = (value as CategoryNode)?.catVal || '';

		return (
			<CreatableSelect
				id={`category_select_${valuePath}`}
				dataTestId={'category_select_' + index}
				items={selectItems}
				value={selectItems.find(item => item.value === categoryValue)}
				onValueSelected={categoryValue => categoryValue && handleChange(categoryValue)}
				disabled={value === '' || categoryValues.length === 0}
				error={inputError !== '' ? inputError : undefined}
				canClear={false}
			/>
		);
	}

	if (type === OperandType.NumberInput) {
		return (
			<Input
				id={`number_input_${valuePath}`}
				type={InputType.Number}
				value={value as string}
				onChange={e => handleChange(e.target.value)}
				error={inputError !== '' ? inputError : undefined}
			/>
		);
	}

	if (
		type === OperandType.DateVariable ||
		type === OperandType.NumberVariable ||
		type === OperandType.DurationVariable
	) {
		const variableName = (value as VariableNode).var[0];

		const isIntegerType = draftVariableType === VariableType.Integer;
		const isDurationType = draftVariableType === VariableType.TimeDuration;

		const hasNumberVariables =
			[
				VariableType.Integer,
				VariableType.Float,
				VariableType.Date,
				VariableType.DateTime,
				VariableType.Category
			].includes(draftVariableType) && !isDateField;

		const validVariables = hasNumberVariables
			? variables.filter(v =>
					!isIntegerType
						? v.type === VariableType.Integer || v.type === VariableType.Float
						: v.type === VariableType.Integer
			  )
			: isDurationType
			? variables.filter(v => v.type === VariableType.TimeDuration)
			: variables.filter(
					v => v.type === VariableType.Date || v.type === VariableType.DateTime
			  );

		const selectItems: SelectItem[] = validVariables.map(variable => ({
			label: variable.label,
			value: variable.name
		}));

		return (
			<CreatableSelect
				id={`variable_select_${valuePath}`}
				items={selectItems}
				value={selectItems.find(item => item.value === variableName)}
				onValueSelected={variableValue => variableValue && handleChange(variableValue)}
				disabled={value === ''}
				canClear={false}
			/>
		);
	}

	if (type === OperandType.DateInput) {
		return (
			<Input
				id={`date_input_${valuePath}`}
				type={InputType.Date}
				value={value as string}
				onDateChange={e => handleChange(e.formattedDate)}
				error={inputError !== '' ? inputError : undefined}
			/>
		);
	}

	if (type == OperandType.ArithmeticOperation) {
		return (
			<ArithmeticOperation
				node={value as ArithmeticNode}
				nodePath={valuePath}
				variables={variables}
				draftVariableType={draftVariableType}
				depth={valueDepth + 1}
				calculationActions={calculationActions}
				isChild
			/>
		);
	}

	return null;
}
