import { useMemo } from 'react';
import { Variable, VariableGroup } from '../types';
import { useVariablesQuery, VariablesData } from './useVariablesQuery';

export type VariableFormItem = {
	type: 'variable';
	name: string;
	variable: Variable;
};

export type GroupFormItem = {
	type: 'group';
	name: string;
	group: VariableGroup;
	formItems: VariableFormItem[];
};

export type FormItem = VariableFormItem | GroupFormItem;

export type ProjectData = {
	/** Form items represents the tree structure which we should render for this project and the order of the items */
	formItems: FormItem[];
	/** Variables are all the variables for this project */
	variables: Variable[];
};

export const toProjectData = (variablesData: VariablesData): ProjectData => {
	const formItems: FormItem[] = [];
	for (const item of variablesData.order) {
		if (item.variable && variablesData.variables[item.variable]) {
			formItems.push({
				type: 'variable' as const,
				name: item.variable,
				variable: variablesData.variables[item.variable]
			});
			continue;
		}
		if (item.group && variablesData.groups[item.group]) {
			formItems.push({
				type: 'group' as const,
				name: item.group,
				group: variablesData.groups[item.group],
				formItems: variablesData.groups[item.group].variablesBelongingToGroup.map(
					variableName => ({
						type: 'variable' as const,
						name: variableName,
						variable: variablesData.variables[variableName]
					})
				)
			});
			continue;
		}
		console.error('Unknown item in order', item);
	}

	return { formItems, variables: Object.values(variablesData.variables) };
};

export const useProjectData = ({ projectId }: { projectId: string }) => {
	const {
		data: variablesData,
		loading: variablesLoading,
		error: variablesError,
		fetching: variablesFetching,
		refetch
	} = useVariablesQuery({
		projectId
	});

	const processedData = useMemo(() => {
		if (variablesData) {
			return toProjectData(variablesData);
		}
	}, [variablesData]);

	return {
		data: processedData,
		loading: variablesLoading,
		fetching: variablesFetching,
		error: variablesError,
		refetch
	};
};
