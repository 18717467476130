import { useEffect, useMemo, useState } from 'react';
import { Variable, VariableActions, VariableUniquenessType } from 'api/data/variables';
import {
	CALCULATED_VARIABLE_TYPES,
	DATA_TYPES_OPTIONS,
	EDITABLE_DATA_TYPES_OPTIONS,
	ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD,
	ENTRY_TYPES_OPTIONS
} from 'consts';
import { Colors, Dictionary, Svgs } from 'environment';
import { InputType, SelectItem } from 'types/index';
import { CategoryValuesBasic } from '../CategoryValuesBasic';
import {
	InfoMessageDecorator,
	Row,
	Input,
	InfoMessagePrompt,
	ConversionRow
} from './AddVariableFields.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { Gap } from 'components/UI/Gap';
import { InfoMessage } from 'components/UI/InfoMessage';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { isEditableType, isCalculatedType } from 'helpers/variables';
import {
	useTranslation,
	useVariableName,
	useIsVariableInCalculationCases,
	useEntriesErrors
} from 'hooks/store';
import { TimeDurationFields } from '../TimeDurationFields/TimeDurationFields';
import { Icon } from 'components/UI/Icons';
import { ORDERED_TIME_DURATION_KEYS, TimeDurationKey } from 'timeDurationConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { keys } from 'lodash';
import { selectLanguage } from 'store/ui/i18n';
import { useSelector } from 'hooks/utils';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';
interface Errors {
	label: string;
	categories: string;
	validationCases: {
		minValue: string;
		maxValue: string;
	};
}

interface Props {
	variable: Variable;
	disabledVariableTypes: VariableType[];
	initialVariable: Variable;
	variables: Variable[];
	variableActions: VariableActions;
	errors?: Partial<Errors> | null;
	isOnUpdateRoute: boolean;
	sourceTimeUnits?: TimeDurationKey;
	onCategoryValuesClick: () => void;
	onSubmit: () => void;
}

export function AddVariableFields({
	variable,
	initialVariable,
	variables,
	disabledVariableTypes = [],
	errors,
	sourceTimeUnits,
	variableActions: {
		onToggleObligatory,
		onTogglePersonalData,
		onLabelChanged,
		onDescriptionChange,
		onToggleOptimizeForManyValues,
		onTypeChange,
		onToggleRange,
		onUniquenessTypeChange,
		onEntryTypeChange,
		onValidationChange,
		onToggleFixedCategory,
		onCategoriesChange,
		onVisiblePrecisionChange,
		onFormatChange,
		onSourceTimeUnitsChange
	},
	isOnUpdateRoute,
	onCategoryValuesClick,
	onSubmit
}: Props) {
	const {
		name,
		label,
		description,
		type,
		entryType,
		optimizeForManyValues,
		uniquenessType,
		obligatory,
		personalData,
		validationRange,
		validationCases,
		visiblePrecision
	} = variable;

	const { timeDurationFlag } = useFlags();
	const { translate } = useTranslation();
	const language = useSelector(state => selectLanguage(state.ui.i18n));

	const [variableName] = useVariableName();
	const calculatedVariableLabels = useIsVariableInCalculationCases(variableName ?? '');

	const isOnCreateVariableRoute = variableName === null;

	const sourceTimeUnitSelctItems = useMemo<SelectItem[]>(() => {
		keys(ORDERED_TIME_DURATION_KEYS).map(key => ({
			label: translate(
				dict =>
					dict.timeDurationPlaceholder.full[
						key as keyof typeof Dictionary.timeDurationPlaceholder.full
					]
			),
			value: key
		}));
		return ORDERED_TIME_DURATION_KEYS.map(key => ({
			label: translate(
				dict =>
					dict.timeDurationPlaceholder.full[
						key as keyof typeof Dictionary.timeDurationPlaceholder.full
					]
			),
			value: key
		}));
	}, [translate, language]);

	// used when variable type change functionality is enabled
	const dropdownTypeDisabled = useMemo(
		() =>
			(!!variableName && calculatedVariableLabels.length > 0) ||
			initialVariable.type === VariableType.TimeDuration ||
			(!!initialVariable &&
				!!variableName &&
				(!isEditableType(type) || entryType === EntryVariableType.Calculated)),
		[variableName, calculatedVariableLabels, initialVariable, type, entryType]
	);

	const hasNumericVariables = variables.some(v =>
		[VariableType.Integer, VariableType.Float].includes(v.type)
	);
	const hasDateVariables = variables.some(v =>
		[VariableType.Date, VariableType.DateTime].includes(v.type)
	);
	const hasTimeDurationVariables = variables.some(v => v.type === VariableType.TimeDuration);

	const entryTypeSelectItems: SelectItem[] = ENTRY_TYPES_OPTIONS.map(item => ({
		label: translate(() => item.label),
		value: item.value
	}));

	const dataTypeSelectItems: SelectItem[] = useMemo(() => {
		const ENTRY_VARIABLE_TYPES =
			!!variableName && isEditableType(type)
				? EDITABLE_DATA_TYPES_OPTIONS
				: DATA_TYPES_OPTIONS;

		const isCalculated = entryType === EntryVariableType.Calculated;

		let finalOptions = isCalculated ? CALCULATED_VARIABLE_TYPES : ENTRY_VARIABLE_TYPES;

		if (!timeDurationFlag) {
			finalOptions = finalOptions.filter(opt => opt.value !== VariableType.TimeDuration);
		}

		return finalOptions.map(item => ({
			label: translate(() => item.label),
			value: item.value,
			disabled: disabledVariableTypes.includes(item.value)
		}));
	}, [variableName, type, entryType, disabledVariableTypes]);

	const [{ errors: entriesErrors }] = useEntriesErrors();

	const isVariableErrored =
		entriesErrors && entriesErrors.columns && entriesErrors.columns.includes(name);

	const forceDropdown = variable.categories.length > ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD;

	const uniquenessTypeChanged = initialVariable.uniquenessType !== variable.uniquenessType;
	const uniquenessTypeWarning = isOnUpdateRoute && uniquenessTypeChanged;

	const showMinTimeUnitWarning = useMemo(() => {
		if (!initialVariable.durationFormat) return false;
		//@ts-ignore https://github.com/microsoft/TypeScript/issues/49453
		const prevMinTimeUnitIndex = ORDERED_TIME_DURATION_KEYS.findLastIndex(timeKey =>
			initialVariable.durationFormat?.includes(timeKey)
		);
		//@ts-ignore https://github.com/microsoft/TypeScript/issues/49453
		const currentMinTimeUnitIndex = ORDERED_TIME_DURATION_KEYS.findLastIndex(timeKey =>
			variable.durationFormat?.includes(timeKey)
		);

		if (currentMinTimeUnitIndex === -1 || prevMinTimeUnitIndex === -1) return false;

		return currentMinTimeUnitIndex < prevMinTimeUnitIndex;
	}, [variable, initialVariable]);

	const [touchedFields, setTouchedFields] = useState({
		label: variableName ? true : false,
		categories: false
	});

	/**
	 * Reset touched field `categories` when toggle goes `false`, +UX
	 */
	useEffect(() => {
		if (!variable.fixedCategories) {
			setTouchedFields(prev => ({
				...prev,
				categories: false
			}));
		}
	}, [variable.fixedCategories]);

	const isTouched = (field: keyof typeof touchedFields, error: string | undefined) =>
		touchedFields[field] ? error : '';

	useEffect(() => {
		if (type === VariableType.Unique && personalData) {
			onTogglePersonalData();
		}
	}, [type]);

	return (
		<>
			<Row>
				<Input
					label={translate(dict => dict.variableFields.variableLabel)}
					type={InputType.Text}
					value={label}
					error={isTouched('label', errors?.label)}
					onChange={e => {
						setTouchedFields(prev => ({
							...prev,
							label: true
						}));
						onLabelChanged(e.target.value);
					}}
					autoFocus={isOnCreateVariableRoute}
					onSubmit={onSubmit}
					id="variable_name"
					required
					dataTestId="variable-name-input"
				/>
				<Input
					type={InputType.Textarea}
					label={translate(dict => dict.variableFields.description)}
					value={description}
					placeholder={translate(dict => dict.variableFields.noDescription)}
					onChange={e => onDescriptionChange(e.target.value)}
					onSubmit={onSubmit}
					id="variable_description"
					dataTestId="variable-description-input"
				/>
			</Row>

			<Spacer size={s => s.l} />

			<Row>
				<CreatableSelect
					label={translate(dict => dict.variableFields.entryType)}
					items={entryTypeSelectItems}
					disabled={
						!isCalculatedType(type) ||
						(variableName !== '' && calculatedVariableLabels.length > 0)
					}
					isItemDisabled={item =>
						(!hasDateVariables &&
							!(type === VariableType.Category ? hasNumericVariables : true) &&
							!(item.value === 'entry')) ||
						(!hasTimeDurationVariables &&
							type === VariableType.TimeDuration &&
							item.value === 'entry')
					}
					value={entryTypeSelectItems.find(item => item.value === entryType)}
					onValueSelected={item => item && onEntryTypeChange(item as EntryVariableType)}
					canClear={false}
					id="variable_type"
					dataTestId="select-variable-type"
				/>

				<CreatableSelect
					label={translate(dict => dict.variableFields.dataType)}
					items={dataTypeSelectItems}
					disabled={dropdownTypeDisabled}
					error={
						isVariableErrored && isOnUpdateRoute
							? variable.targetType === null ||
							  variable.type === VariableType.Category
								? `${translate(
										dict =>
											dict.variablesPage.addVariableFields
												.firstErrorBeforeVariable
								  )} "${
										(translate(
											dict => dict.variableLabels[variable.type]
										) as string) || ''
								  }" ${translate(
										dict =>
											dict.variablesPage.addVariableFields
												.firstErrorAfterVariable
								  )}`
								: `${translate(
										dict =>
											dict.variablesPage.addVariableFields
												.secondErrorBeforeVariable
								  )} "${
										(translate(dict =>
											variable.targetType
												? dict.variableLabels[variable.targetType]
												: ''
										) as string) || ''
								  }"${translate(
										dict =>
											dict.variablesPage.addVariableFields
												.secondErrorAfterVariable
								  )}`
							: undefined
					}
					isItemDisabled={item => item?.disabled || false}
					value={dataTypeSelectItems.find(item => item.value === type)}
					onValueSelected={item =>
						item &&
						onTypeChange(item as VariableType, !isCalculatedType(item as VariableType))
					}
					canClear={false}
					id="data_type"
					dataTestId="select-data-type"
				/>
			</Row>
			{initialVariable.type !== variable.type &&
				variable.type === VariableType.TimeDuration &&
				!!initialVariable.name && (
					<>
						<Spacer size={size => size.m} />
						<InfoMessagePrompt>
							<InfoMessage
								paddingOffset={{
									top: 0.8,
									bottom: 0.8,
									right: 1.6,
									left: 1.6
								}}
								large
								message={translate(
									dict => dict.variables.typeToTimeDurationWarning
								)}
							/>
						</InfoMessagePrompt>
						<Spacer size={size => size.m} />
						<ConversionRow>
							<CreatableSelect
								disabled
								items={[]}
								label={translate(dict => dict.variables.dataTypeConversion)}
								placeholder={translate(dict => dict.terms.number)}
							/>
							<Icon svg={Svgs.ArrowLongRight} size={s => s.m} />
							<CreatableSelect
								items={sourceTimeUnitSelctItems}
								onValueSelected={selectedValue =>
									onSourceTimeUnitsChange(selectedValue as TimeDurationKey)
								}
								canClear
								onClear={() => onSourceTimeUnitsChange(undefined)}
								value={
									sourceTimeUnits
										? {
												label: translate(
													dict =>
														dict.timeDurationPlaceholder.full[
															sourceTimeUnits as keyof typeof Dictionary.timeDurationPlaceholder.full
														]
												),
												value: sourceTimeUnits
										  }
										: undefined
								}
								required
								label={translate(dict => dict.variables.sourceUnit)}
								placeholder={translate(dict => dict.variables.sourceUnit)}
							/>
						</ConversionRow>
					</>
				)}

			{type === VariableType.Float && (
				<>
					<Spacer size={s => s.l} />
					<Input
						minValue={0}
						label={translate(dict => dict.variablesPage.precision.label)}
						placeholder={translate(dict => dict.variablesPage.precision.placeholder)}
						type={InputType.Number}
						value={visiblePrecision ?? ''}
						onChange={e => {
							isNaN(parseInt(e.target.value))
								? onVisiblePrecisionChange(null)
								: onVisiblePrecisionChange(parseInt(e.target.value));
						}}
						id="variable_precision"
						error={
							visiblePrecision === 0
								? translate(dict => dict.variablesPage.precision.error)
								: ''
						}
					/>
				</>
			)}

			{type === VariableType.Unique && (
				<>
					<Typography.Caption
						title={translate(
							dict => dict.variablesPage.addVariableFields.uniqueOptions
						)}
						color={Colors.text.main}
						fontweight={w => w.medium}
						marginOffset={{ top: 3.2, bottom: 1.6 }}
					>
						{translate(dict => dict.variablesPage.addVariableFields.uniqueOptions)}
					</Typography.Caption>

					<Gap marginGap={{ bottom: 1.6 }} notLastChild>
						<RadioButton
							label={translate(
								dict => dict.variableFields.uniquenessConfig.manual.label
							)}
							selected={uniquenessType === VariableUniquenessType.Manual}
							onSelect={() => onUniquenessTypeChange(VariableUniquenessType.Manual)}
							description={translate(
								dict => dict.variableFields.uniquenessConfig.manual.description
							)}
							id="variable_unique_manual"
							data-testid="variable_unique_manual"
						/>

						<RadioButton
							label={translate(
								dict => dict.variableFields.uniquenessConfig.sequence.label
							)}
							disabled={!isOnCreateVariableRoute}
							selected={uniquenessType === VariableUniquenessType.Sequence}
							onSelect={() => onUniquenessTypeChange(VariableUniquenessType.Sequence)}
							id="variable_unique_sequence"
							data-testid="variable_unique_sequence"
							description={translate(
								dict => dict.variableFields.uniquenessConfig.sequence.description
							)}
						/>

						<RadioButton
							label={translate(
								dict => dict.variableFields.uniquenessConfig.uuid.label
							)}
							disabled={!isOnCreateVariableRoute}
							selected={uniquenessType === VariableUniquenessType.UUID}
							onSelect={() => onUniquenessTypeChange(VariableUniquenessType.UUID)}
							id="variable_unique_uuid"
							data-testid="variable_unique_uuid"
							description={translate(
								dict => dict.variableFields.uniquenessConfig.uuid.description
							)}
						/>
					</Gap>

					{uniquenessTypeWarning && (
						<>
							<Spacer size={s => s.s} />
							<InfoMessageDecorator>
								<InfoMessage
									message={`${translate(
										dict => dict.variablesPage.addVariableFields.infoMessage
									)} “${initialVariable.uniquenessType}” ${translate(
										dict => dict.filterInputs.toLowerCase
									)} “${variable.uniquenessType}”.`}
								/>
							</InfoMessageDecorator>
						</>
					)}

					<Spacer size={s => s.xs} />
				</>
			)}

			{[VariableType.Category, VariableType.CategoryMultiple].includes(type) && (
				<>
					{entryType === EntryVariableType.Entry && (
						<>
							<Spacer size={s => s.s} />
							<Switch
								label={translate(
									dict => dict.variableFields.optimizeForManyValues.label
								)}
								description={translate(
									dict => dict.variableFields.optimizeForManyValues.description
								)}
								dataTestId="showAsDropdownToggle"
								on={forceDropdown ? true : !!optimizeForManyValues}
								disabled={forceDropdown}
								onChange={onToggleOptimizeForManyValues}
							/>

							{forceDropdown && (
								<>
									<Spacer size={s => s.s} />
									<InfoMessageDecorator>
										<InfoMessage
											message={translate(
												dict =>
													dict.variableFields.optimizeForManyValues
														.warning
											)}
										/>
									</InfoMessageDecorator>
								</>
							)}
						</>
					)}

					<Spacer size={s => s.l} />

					<CategoryValuesBasic
						variable={variable}
						initialVariable={initialVariable}
						error={isTouched('categories', errors?.categories)}
						readOnly={isOnUpdateRoute}
						onPreviewClick={onCategoryValuesClick}
						onToggle={onToggleFixedCategory}
						onChange={e => {
							setTouchedFields(prev => ({
								...prev,
								categories: true
							}));
							onCategoriesChange(e);
						}}
						onSubmit={onSubmit}
					/>

					<Spacer size={s => s.l} />
				</>
			)}

			{[
				VariableType.Integer,
				VariableType.Float,
				VariableType.Date,
				VariableType.DateTime
			].includes(type) &&
				entryType === EntryVariableType.Entry && (
					<>
						<Spacer size={s => s.s} />
						<Switch
							data-test-id="validationRangeToggle"
							label={translate(dict => dict.variableFields.validationRange)}
							onChange={onToggleRange}
							on={validationRange}
						/>
						{validationRange && (
							<>
								<Spacer size={s => s.s} />

								{type === VariableType.DateTime ? (
									<Row>
										<DateTimeInput
											value={validationCases?.minValue}
											onChange={value =>
												onValidationChange({
													minValue: value
												})
											}
											options={{
												error: errors?.validationCases?.minValue,
												label: translate(
													dict => dict.variableFields.minValidationLabel
												)
											}}
										/>

										<DateTimeInput
											value={validationCases?.maxValue}
											onChange={value =>
												onValidationChange({
													maxValue: value
												})
											}
											options={{
												error: errors?.validationCases?.maxValue,
												label: translate(
													dict => dict.variableFields.maxValidationLabel
												)
											}}
										/>
									</Row>
								) : (
									<Row>
										<Input
											error={errors?.validationCases?.minValue}
											type={
												type === VariableType.Date
													? InputType.Date
													: type === VariableType.Integer
													? InputType.Text
													: InputType.Number
											}
											label={translate(
												dict => dict.variableFields.minValidationLabel
											)}
											value={validationCases?.minValue}
											dataTestId="min-value_input"
											onChange={e => {
												if (type === VariableType.Integer) {
													const matches =
														e.target.value.match(/^(\+|-)?\d+$/);
													const value = e.target.value;
													if (matches || value === '' || value === '-')
														onValidationChange({
															minValue: e.target.value
														});
												} else {
													onValidationChange({
														minValue: e.target.value
													});
												}
											}}
											onDateChange={({ formattedDate }) =>
												onValidationChange({
													minValue: formattedDate
												})
											}
										/>

										<Input
											error={errors?.validationCases?.maxValue}
											type={
												type === VariableType.Date
													? InputType.Date
													: type === VariableType.Integer
													? InputType.Text
													: InputType.Number
											}
											label={translate(
												dict => dict.variableFields.maxValidationLabel
											)}
											dataTestId="max-value_input"
											value={validationCases?.maxValue}
											onChange={e => {
												if (type === VariableType.Integer) {
													const matches =
														e.target.value.match(/^(\+|-)?\d+$/);
													const value = e.target.value;
													if (matches || value === '' || value === '-')
														onValidationChange({
															maxValue: value
														});
												} else {
													onValidationChange({
														maxValue: e.target.value
													});
												}
											}}
											onDateChange={({ formattedDate }) =>
												onValidationChange({
													maxValue: formattedDate
												})
											}
										/>
									</Row>
								)}
							</>
						)}
						<Spacer size={s => s.s} />
					</>
				)}

			{type === VariableType.TimeDuration && (
				<>
					<Spacer size={s => s.s} />
					<TimeDurationFields onFormatChange={onFormatChange} variable={variable} />
				</>
			)}
			<Spacer size={s => s.xs} />
			{showMinTimeUnitWarning && (
				<>
					<InfoMessagePrompt>
						<InfoMessage
							paddingOffset={{
								top: 0.8,
								bottom: 0.8,
								right: 1.6,
								left: 1.6
							}}
							large
							message={translate(dict => dict.variables.minTimeUnitWarning)}
						/>
					</InfoMessagePrompt>
					<Spacer size={size => size.l} />
				</>
			)}

			{entryType === EntryVariableType.Entry && (
				<>
					<Switch
						label={translate(dict => dict.variableFields.obligatory)}
						description={translate(
							dict => dict.variablesPage.addVariableFields.description
						)}
						dataTestId="mandatoryToggle"
						on={obligatory || type === VariableType.Unique}
						disabled={personalData || type === VariableType.Unique}
						onChange={onToggleObligatory}
					/>

					<Spacer size={s => s.s} />
				</>
			)}

			<Switch
				label={translate(dict => dict.variableFields.personalData)}
				description={translate(dict => dict.variableFields.personalDataInfo)}
				dataTestId="personalDataToggle"
				on={personalData}
				disabled={obligatory || type === VariableType.Unique}
				onChange={onTogglePersonalData}
			/>
		</>
	);
}
