import format from 'date-fns/format';

import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { Revision } from 'store/data/revisions';
import { StatusesMap } from 'store/data/statuses';

import { Container, Text } from './RevisionCard.style';
import { useTranslation } from 'hooks/store';
import { isValidDate } from 'helpers/isValidDate';

const VERSION_DATE_YEAR_TIME_FORMAT = 'MMM dd, yyyy, h:mm a';

interface Props {
	revision: Revision;
	statusesMap: StatusesMap;
	active: boolean;
	isFirst: boolean;
	current?: boolean;
	onClick: () => void;
	index: number;
	nameFromUserId: string;
}

export function RevisionCard({
	revision,
	statusesMap,
	active,
	isFirst,
	current,
	onClick,
	index,
	nameFromUserId
}: Props) {
	const { translate } = useTranslation();

	const { creationDate, statusChanges } = revision;

	const { from, to } = statusChanges;

	function getDate() {
		const creationValidDate = isValidDate(creationDate)
			? creationDate.replace(/\s/g, 'T')
			: creationDate;

		return creationDate && format(new Date(creationValidDate), VERSION_DATE_YEAR_TIME_FORMAT);
	}

	function getMessage() {
		// STATUS CHANGE
		if (from || to) {
			return (
				<>
					<Typography.Caption color={Colors.text.main}>
						<b>{nameFromUserId}</b> {getFromTo()}
					</Typography.Caption>

					{/* COMMENT */}
					{to?.comment && <Typography.Hint>{to.comment}</Typography.Hint>}
				</>
			);
		}

		// FORM DATA CHANGE
		return (
			<Typography.Caption color={Colors.text.main}>
				<b>{nameFromUserId}</b>{' '}
				{isFirst ? (
					<>
						{translate(dict => dict.dataset.revisionCard.created)}{' '}
						<b>{translate(dict => dict.dataset.revisionCard.entry)}</b>
					</>
				) : (
					<>
						{translate(dict => dict.dataset.revisionCard.edited)}{' '}
						<b>{translate(dict => dict.dataset.revisionCard.dataEntry)}</b>
					</>
				)}
			</Typography.Caption>
		);
	}

	function getFromTo() {
		if (from && to) {
			const renewedStatus = from.variableName === to.variableName;

			if (renewedStatus) {
				return (
					<>
						{translate(dict => dict.dataset.revisionCard.renewedStatus)}{' '}
						<b>{getStatusLabel(from.variableName)}</b>
					</>
				);
			}

			return (
				<>
					{translate(dict => dict.dataset.revisionCard.changedStatusFrom)}{' '}
					<b>{getStatusLabel(from.variableName)}</b>{' '}
					{translate(dict => dict.dataset.revisionCard.to)}{' '}
					<b>{getStatusLabel(to.variableName)}</b>
				</>
			);
		}

		if (from && !to) {
			return (
				<>
					{translate(dict => dict.dataset.revisionCard.removedStatus)}{' '}
					<b>{getStatusLabel(from.variableName)}</b>
				</>
			);
		}

		if (to && !from) {
			return (
				<>
					{translate(dict => dict.dataset.revisionCard.setStatus)}{' '}
					<b>{getStatusLabel(to.variableName)}</b>
				</>
			);
		}
	}

	function getStatusLabel(statusName: string) {
		const status = statusesMap[statusName];

		const label = status ? status.label : '';

		return label;
	}

	function handleClick() {
		onClick();
		// setCurrentRevision(revision);
	}

	return (
		<Container
			data-testid={`revision-card-${revision.revisionId}`}
			active={active}
			onClick={handleClick}
			index={index}
		>
			<Text active={active}>{getDate()}</Text>

			{current && (
				<Text active={active} current>
					{translate(dict => dict.revisionCard.latest)}
				</Text>
			)}

			{/* FORM DATA CASE */}
			{getMessage()}
		</Container>
	);
}
