import { useEffect, useState } from 'react';
import { makeRequest } from './util';

type NamesFromUserIds = Record<string, string>;

export const useGetNamesFromUserIds = (
	{
		userIds
	}: {
		userIds: string[];
	},
	{ skip }: { skip?: boolean }
) => {
	const [data, setData] = useState<{
		namesFromUserIds: NamesFromUserIds;
	}>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [fetching, setFetching] = useState(false);

	async function fetch() {
		setFetching(true);

		try {
			const result: {
				namesFromUserIds: NamesFromUserIds;
			} = await makeRequest({
				method: 'getNamesFromUserIds',
				service: 'user',
				data: {
					userIds
				}
			});

			setData({
				namesFromUserIds: result.namesFromUserIds
			});
		} catch (e) {
			console.error(e);
			setError('Something unexpected happened, please try again later.');
		} finally {
			setLoading(false);
			setFetching(false);
		}
	}

	useEffect(() => {
		if (skip) {
			return;
		}

		fetch();
	}, [skip]);

	return { data, loading, error, refetch: fetch, fetching };
};
