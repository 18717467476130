import { useMemo } from 'react';
import { SubscriptionAddonCode } from 'types/index';
import { Dropdown } from 'components/UI/Dropdown';
import { buildAnalysis } from 'helpers/analysis';
import { stringAsBoolean } from 'helpers/generic';
import {
	useTranslation,
	useVariablesData,
	useCreateAnalysis,
	useIsUserAddonActive,
	useIsProjectAddonActive,
	useVariablesDataSelectItems
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
import { useTracking } from 'app/tracking/TrackingProvider';
import { type Analyses, handlersMap } from 'hooks/store/data/analysis/useFetchAnalysis';
import { useDispatch } from 'hooks/utils';
import { AnalysisV2 } from 'api/data/analyses';
interface Props {
	addButton: (AddButtonParams: {
		label: string;
		disabled?: boolean;
		action: () => void;
	}) => React.ReactNode;
}

export function AddAnalysis({ addButton }: Props) {
	const { translate } = useTranslation();
	const dispatch = useDispatch();

	const { track } = useTracking();

	const analyses = [
		AnalysisType.FrequenciesV2,
		AnalysisType.ExploreV2,
		AnalysisType.CrosstabV2,
		AnalysisType.KaplanV2,
		AnalysisType.CorrelationsV2,
		AnalysisType.CompareNumericV2,
		AnalysisType.DensityPlotV2,
		AnalysisType.PlotNumericV2,
		AnalysisType.TimeCourseV2,
		AnalysisType.ComparePairedV2,
		AnalysisType.LogisticRegressionV2
	];

	const variablesData = useVariablesData({ initial: true });

	const createAnalysis = useCreateAnalysis();

	const IS_JADBIO_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_JADBIO!);

	const [{ data: isJADBioUserAddonActive }] = useIsUserAddonActive(SubscriptionAddonCode.JADBio);
	const [{ data: isJADBioProjectAddonActive }] = useIsProjectAddonActive(
		SubscriptionAddonCode.JADBio
	);

	const showJADBioOption = useMemo(() => {
		return IS_JADBIO_ENABLED && isJADBioUserAddonActive && isJADBioProjectAddonActive;
	}, [isJADBioProjectAddonActive, isJADBioUserAddonActive]);

	function handleSelect(type: AnalysisType) {
		const analysis = buildAnalysis({ type, variablesData });
		if (!analysis) return;

		createAnalysis(analysis as AnalysisV2);

		track({
			eventName: 'analysis_added',
			data: {
				analysisType: type
			}
		});

		if (![AnalysisType.PlotNumeric, AnalysisType.PlotNumericV2].includes(analysis.type)) {
			// @ts-ignore
			const handler = handlersMap[analysis.type];
			if (!handler) return;
			dispatch(handler(analysis as Analyses));
		}
	}

	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);
	const hasCategoryOrNumberOrDurationVariable =
		variablesDataSelectItems.categorySelectItems.length > 0 ||
		variablesDataSelectItems.numericSelectItems.length > 0 ||
		variablesDataSelectItems.durationSelectItems.length > 0;

	function getSelectItemLabel(type: string) {
		return translate(() => type);
	}

	const selectItems = [...analyses, ...(showJADBioOption ? [AnalysisType.JADBio] : [])].map(
		type => ({
			label: getSelectItemLabel(type),
			value: type
		})
	);

	return (
		<Dropdown
			toggleComponent={({ ref, toggle }) => {
				return (
					<div ref={ref}>
						{addButton({
							label: translate(dict => dict.analysis.actions.add),
							disabled: !hasCategoryOrNumberOrDurationVariable,
							action: toggle
						})}
					</div>
				);
			}}
			width={25}
			offset={{ top: 4, right: 0 }}
		>
			{selectItems.map((item, i) => (
				<Dropdown.Item
					close={() => undefined}
					onClick={() => {
						handleSelect(item.value as AnalysisType);
					}}
					title={item.label}
					key={`analisys_item_${i}`}
				/>
			))}
		</Dropdown>
	);
}
