import { Modal } from 'components/UI/Modal';
import { Skeleton } from '../component/Skeleton';
import { useGetDataEntryQuery } from '../data/useGetDataEntryQuery';
import { useGetNamesFromUserIds } from '../data/useGetNamesFromUserIdsQuery';
import { DateTime } from 'luxon';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useEffect, useMemo } from 'react';
import { Entry, OurInternalCustomEntryFields } from '../types';
import { useTracking } from 'app/tracking/TrackingProvider';

interface Props {
	visible: boolean;
	onClose: () => void;
	entryId: string;
	projectId: string;
}

export const EntryDetailsModal = ({ visible, onClose, entryId, projectId }: Props) => {
	const { track } = useTracking();
	useEffect(() => {
		if (visible) {
			track({ eventName: 'entry_details_viewed' });
		}
	}, [visible]);

	const { data, error, loading } = useEntryDetailsData({ entryId, projectId });

	return (
		<Modal
			title="Entry Details"
			visible={visible}
			onClose={onClose}
			size={s => s.m}
			primary={{
				label: 'Done',
				onClick: onClose
			}}
		>
			{loading && (
				<ul className="flex flex-col gap-10">
					{new Array(6).fill(null).map((_, index) => (
						<li key={index} className="flex flex-col ">
							<Skeleton className="w-32 h-6" />
							<Skeleton className="w-48 h-6 mt-1" />
						</li>
					))}
				</ul>
			)}

			{error && <p className="text-red-500">{error}</p>}

			{data && (
				<ul className="flex flex-col gap-10">
					<ListItem header="Entry ID" text={data.entryId} />
					<ListItem header="Owned by" text={data.ownedByUserFullName} />
					<ListItem header="Group" text={data.groupName} />
					<ListItem header="Last modified by" text={data.lastModifiedByUserFullName} />
					<ListItem header="Created date" text={formatDate(data.createdAt)} />
					<ListItem header="Last modified date" text={formatDate(data.lastModifiedAt)} />
				</ul>
			)}
		</Modal>
	);
};

const formatDate = (date: string) =>
	DateTime.fromJSDate(new Date(date)).toLocaleString(DateTime.DATE_MED);

const ListItem = ({ header, text }: { header: string; text: string }) => (
	<li>
		<p className="text-base font-semibold">{header}</p>

		<p className="text-base">{text}</p>
	</li>
);

const useEntryDetailsData = ({ entryId, projectId }: { entryId: string; projectId: string }) => {
	const entryQuery = useGetDataEntryQuery({
		entryId,
		projectId
	});
	const getProjectQuery = useGetProjectQuery({
		projectId
	});

	const userIds = entryQuery.data?.entry
		? [entryQuery.data.entry.ownedbyuser, entryQuery.data.entry.enteredbyuser]
		: [];
	const getNamesFromUserIdsQuery = useGetNamesFromUserIds(
		{
			userIds
		},
		{ skip: userIds.length === 0 }
	);

	const loading =
		entryQuery.loading || getNamesFromUserIdsQuery.loading || getProjectQuery.loading;
	const error = entryQuery.error || getNamesFromUserIdsQuery.error || getProjectQuery.error;

	const data: FormattedEntryDetails | undefined = useMemo(() => {
		if (!entryQuery.data || !getNamesFromUserIdsQuery.data || !getProjectQuery.data) {
			return undefined;
		}

		const typedEntry = entryQuery.data.entry as Entry & OurInternalCustomEntryFields;

		const projectGroup = getProjectQuery.data.project.organizations.find(
			organization => organization.organizationId === typedEntry.userProjectOrgId
		);

		return {
			entryId,
			ownedByUserFullName:
				getNamesFromUserIdsQuery.data.namesFromUserIds[entryQuery.data.entry.ownedbyuser] ||
				'',
			groupName: projectGroup?.organizationName || 'Error fetching group name',
			lastModifiedByUserFullName:
				getNamesFromUserIdsQuery.data.namesFromUserIds[typedEntry.enteredbyuser] || '',
			createdAt: typedEntry.creationdate,
			lastModifiedAt: typedEntry.lastmodifieddate
		};
	}, [entryQuery.data, getNamesFromUserIdsQuery.data, getProjectQuery.data]);

	return {
		loading,
		data,
		error
	};
};

type FormattedEntryDetails = {
	entryId: string;
	ownedByUserFullName: string;
	groupName: string;
	lastModifiedByUserFullName: string;
	createdAt: string;
	lastModifiedAt: string;
};
