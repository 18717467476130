import clsx from 'clsx';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { useRef, useState } from 'react';

export const InlineTextInputForm = ({
	onNewOptionSubmitted,
	bannedOptions
}: {
	onNewOptionSubmitted: (newOption: string) => void;
	bannedOptions?: string[];
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const [showInput, setShowInput] = useState(false);
	const [error, setError] = useState<string>();

	const onSubmit = () => {
		if (!inputRef.current) return;

		const value = inputRef.current.value.trim();
		if (!value) {
			setShowInput(false);
			return;
		}

		if (bannedOptions?.find(option => option.toLowerCase() === value.toLowerCase())) {
			setError('This value already exists');
			return;
		}

		setShowInput(false);
		onNewOptionSubmitted(value);
	};

	if (showInput) {
		return (
			<div className="flex gap-2 items-center">
				<div>
					<input
						ref={inputRef}
						autoFocus
						placeholder="New value"
						type="text"
						className={clsx(
							'border border-gray-300 rounded-md p-2 px-3 text-base',
							error && 'border-error-500'
						)}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault();
								onSubmit();
							}
						}}
						onBlur={onSubmit}
					/>

					{error && <p className="text-error-500 text-sm">{error}</p>}
				</div>

				<Icon
					onClick={() => {
						setShowInput(false);
					}}
					svg={Svgs.Close}
					size={s => s.m}
				/>
			</div>
		);
	}

	return (
		<button
			onClick={() => setShowInput(true)}
			type="button"
			className="text-blue-600 ml-4 hover:underline text-base"
		>
			+ Add new value
		</button>
	);
};
