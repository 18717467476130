import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { FileVariable } from '../types';
import { FileInput } from './file/FileInput';

interface Props {
	control: FormControl;
	variable: FileVariable;
	onError: (name: string, error: string | undefined) => void;
}

export const FileVariableInput = ({ control, variable, onError }: Props) => {
	const {
		field: { onChange, value },
		fieldState
	} = useController({
		control,
		name: variable.variableName
	});

	return (
		<FileInput
			label={variable.variableLabel}
			variableName={variable.variableName}
			onValueChange={frontendFile => onChange(frontendFile)}
			required={variable.obligatory}
			initialFile={value}
			onError={error => onError(variable.variableName, error)}
			error={fieldState.error?.message}
			description={variable.description}
		/>
	);
};
