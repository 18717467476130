import { useMatch, useLocation } from 'react-router-dom';

export function useRouteMatch(path: string | string[]) {
	const match = useMatch;
	const location = useLocation();

	if (Array.isArray(path)) {
		return path.reduce(
			(acc, value) =>
				!!match(value) ||
				matchesIgnoreV2({
					currentPath: location.pathname,
					pathToMatch: value
				}) ||
				acc,
			false
		);
	}

	return (
		!!match(path) ||
		matchesIgnoreV2({
			currentPath: location.pathname,
			pathToMatch: path
		})
	);
}

const matchesIgnoreV2 = ({
	currentPath,
	pathToMatch
}: {
	currentPath: string;
	pathToMatch: string;
}) => currentPath.split('/')[0] === 'v2' && currentPath.split('/')[1] === pathToMatch;
