import { Loader } from 'components/UI/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLatestDataEntryVersionQuery } from './data/useGetLatestDataEntryVersionQuery';
import { EntryForm } from './EntryForm';
import { useUpdateDataEntry } from './data/useUpdateDataEntry';
import { StrictMode, useEffect, useState, useRef } from 'react';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Button } from './component/Button';
import { Menu } from '@headlessui/react';
import { Modal } from 'components/UI/Modal';
import { useDeleteDataEntryMutation } from './data/deleteDataEntryMutation';
import { useProjectData } from './data/useProjectData';
import { ROUTE_MAP } from './utils/routeMap';
import { ErrorModal } from './ErrorModal';
import clsx from 'clsx';
import { EntryHistoryModal } from './smart-components/EntryHistoryModal';
import { Entry, Variable } from './types';
import { useReactToPrint } from 'react-to-print';
import { PrintEntryForm } from './PrintEntryForm';
import { EntryDetailsModal } from './smart-components/EntryDetailsModal';
import { useTracking } from 'app/tracking/TrackingProvider';
import { ConfirmDiffModal } from './confirm-diff-modal/ConfirmDiffModal';
import { parseBackendValues } from './utils/parse-backend-values/parseBackendValues';
import { DiffedField } from './utils/formUtils';

export const UpdateEntryPageV1_5 = () => {
	const navigate = useNavigate();
	const { track } = useTracking();

	const params = useParams();
	const contentRef = useRef<HTMLFormElement>(null);
	const entryId = params.entryId as string;
	const projectId = params.projectId as string;

	const reactToPrint = useReactToPrint({ contentRef, pageStyle });
	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	const projectDataQuery = useProjectData({
		projectId
	});

	const updateDataEntryMutation = useUpdateDataEntry();

	const [dataPendingSubmission, setDataPendingSubmission] = useState<Entry | undefined>(
		undefined
	);

	useEffect(() => {
		// Make sure the url matches the latest entry id so updates are most likely to work
		if (dataEntryQuery.data && dataEntryQuery.data.entry.datasetentryid !== entryId) {
			navigate(
				ROUTE_MAP.project.byId.dataset.update.createPath({
					projectId,
					entryId: dataEntryQuery.data.entry.datasetentryid
				}),
				{ replace: true }
			);
		}
	}, [dataEntryQuery.data]);

	const loading = projectDataQuery.loading || dataEntryQuery.loading;
	const error = projectDataQuery.error || dataEntryQuery.error;

	if (loading) {
		return (
			<div className="w-[100vw] h-[100vh]">
				<Loader center />
			</div>
		);
	}

	if (error || !dataEntryQuery.data || !projectDataQuery.data) {
		return (
			<div className="v-[100vw] h-[100vh] flex items-center justify-center flex-col">
				<Icon svg={Svgs.LedidiLogo} customSize={5} />

				<p className="text-base font-bold mt-20">
					Error loading data, please try again. Contact support if the problem persists.
				</p>

				<div className="flex flex-col gap-4 mt-10">
					<Button
						title="Reload"
						variant="primary"
						loading={dataEntryQuery.fetching || projectDataQuery.fetching}
						onClick={() => {
							dataEntryQuery.refetch();
							projectDataQuery.refetch();
						}}
					/>

					<button
						className="text-sm underline"
						onClick={() =>
							navigate(ROUTE_MAP.project.byId.dataset.createPath({ projectId }))
						}
					>
						Back to project
					</button>
				</div>
			</div>
		);
	}

	const navigateToProjectDataset = () => {
		navigate(ROUTE_MAP.project.byId.dataset.createPath({ projectId }));
	};

	return (
		<StrictMode>
			<div
				className={clsx(
					'mt-40 flex flex-col',
					dataEntryQuery.fetching && 'opacity-50' // TODO MARITN: might want to indicate refetching in another way, but we probably want to indicate it somehow without showing a full screen loader?
				)}
				data-testid="update-entry-page"
			>
				<ErrorModal
					onClose={updateDataEntryMutation.reset}
					error={updateDataEntryMutation.error}
				/>

				<div className="hidden print:block">
					<PrintEntryForm
						projectData={projectDataQuery.data}
						initialEntry={dataEntryQuery.data.entry}
						contentRef={contentRef}
					/>
				</div>

				<SubmitDiffModal
					dataPendingSubmission={dataPendingSubmission}
					initialEntry={dataEntryQuery.data.entry}
					onClose={() => setDataPendingSubmission(undefined)}
					onConfirm={async confirmedData => {
						if (!projectDataQuery.data) {
							console.error(
								"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
							);
							return;
						}

						try {
							const result = await updateDataEntryMutation.mutate({
								projectId,
								entryId,
								entry: confirmedData
							});
							track({
								eventName: 'entry_updated',
								data: {
									entryHasStatus: false
								}
							});

							navigate(
								ROUTE_MAP.project.byId.dataset.update.createPath({
									projectId,
									entryId: result.updatedEntry.datasetentryid
								}),
								{ replace: true }
							);

							await dataEntryQuery.refetch();
						} catch (e) {
							console.error(e);
						} finally {
							setDataPendingSubmission(undefined);
						}
					}}
					onDelete={() => {
						navigateToProjectDataset();
						setDataPendingSubmission(undefined);
					}}
					submitting={updateDataEntryMutation.loading}
					variables={projectDataQuery.data.variables}
				/>

				<EntryForm
					Header={
						<div className="bg-gray-300 p-4 flex items-center justify-between fixed top-0 right-0 left-0 z-50">
							<div className="flex items-center">
								<Icon
									svg={Svgs.LedidiLogoGrey}
									customSize={3}
									onClick={navigateToProjectDataset}
									id="main_header_logo"
								/>

								<p className="ml-10 text-2xl font-semibold">Update entry</p>
							</div>

							<div className="flex gap-4 items-center">
								{projectDataQuery.data.variables && (
									<>
										<EntryDetailsButton
											entryId={entryId}
											projectId={projectId}
										/>

										<EntryHistoryButton
											entryId={entryId}
											projectId={projectId}
											variables={projectDataQuery.data.variables}
										/>
									</>
								)}

								<OptionsMenu
									onEntryDeleted={navigateToProjectDataset}
									entryId={entryId}
									projectId={projectId}
									hasDeleteAccess={dataEntryQuery.data.entryAccess.delete}
									handlePrint={reactToPrint}
								/>

								{dataEntryQuery.data.entryAccess.write ? (
									<Button
										loading={updateDataEntryMutation.loading}
										className="col-start-4 col-span-2"
										title="Save"
										variant="primary"
										data-testid="entry-form-header_save-button"
									/>
								) : (
									<ViewOnly />
								)}

								<Icon
									svg={Svgs.Close}
									variant={v => v.buttonActive}
									onClick={navigateToProjectDataset}
									dataTestId="entry-form-header_close-icon"
								/>
							</div>
						</div>
					}
					projectData={projectDataQuery.data}
					initialEntry={dataEntryQuery.data.entry}
					submitting={updateDataEntryMutation.loading || !!dataPendingSubmission}
					onSubmit={async entry => {
						setDataPendingSubmission(entry);
					}}
				/>
			</div>
		</StrictMode>
	);
};

const EntryDetailsButton = ({ entryId, projectId }: { entryId: string; projectId: string }) => {
	const [showEntryDetailsModal, setShowEntryDetailsModal] = useState(false);

	return (
		<>
			<Icon
				svg={Svgs.Information}
				onClick={() => setShowEntryDetailsModal(true)}
				variant={v => v.buttonActive}
			/>

			{showEntryDetailsModal && (
				<EntryDetailsModal
					visible
					onClose={() => setShowEntryDetailsModal(false)}
					entryId={entryId}
					projectId={projectId}
				/>
			)}
		</>
	);
};

const EntryHistoryButton = ({
	entryId,
	projectId,
	variables
}: {
	entryId: string;
	projectId: string;
	variables: Variable[];
}) => {
	const [showEntryHistoryModal, setShowEntryHistoryModal] = useState(false);

	return (
		<>
			<Icon
				svg={Svgs.Clock}
				onClick={() => setShowEntryHistoryModal(true)}
				variant={v => v.buttonActive}
			/>

			{showEntryHistoryModal && (
				<EntryHistoryModal
					visible
					onClose={() => setShowEntryHistoryModal(false)}
					entryId={entryId}
					projectId={projectId}
					variables={variables}
				/>
			)}
		</>
	);
};

const ViewOnly = () => (
	<div className="border-gray-700 rounded-md border px-4 py-2 group hover:cursor-help relative">
		<p>View only</p>

		<p className="absolute right-0 z-50 w-80 top-full mt-2 px-4 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 shadow-lg">
			You can only view this entry due to read-only rights
		</p>
	</div>
);

const OptionsMenu = ({
	entryId,
	projectId,
	onEntryDeleted,
	hasDeleteAccess,
	handlePrint
}: {
	entryId: string;
	projectId: string;
	onEntryDeleted: () => void;
	hasDeleteAccess: boolean;
	handlePrint: () => void;
}) => {
	const { track } = useTracking();

	const [show, setShow] = useState(false);

	if (!hasDeleteAccess) {
		return null;
	}

	return (
		<div className="relative">
			<DeleteEntryModal
				entryId={entryId}
				projectId={projectId}
				show={show}
				onClose={() => setShow(false)}
				onEntryDeleted={onEntryDeleted}
			/>

			<Menu>
				<Menu.Button>
					<Icon svg={Svgs.More} variant={v => v.buttonActive} />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-12 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<button
							className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
							type="button"
							onClick={() => setShow(true)}
						>
							Delete entry
						</button>
					</Menu.Item>
					<Menu.Item>
						<button
							className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
							onClick={() => {
								handlePrint();
								track({
									eventName: 'print_entry_clicked'
								});
							}}
						>
							Print entry
						</button>
					</Menu.Item>
				</Menu.Items>
			</Menu>
		</div>
	);
};

const DeleteEntryModal = ({
	show,
	onClose,
	entryId,
	projectId,
	onEntryDeleted
}: {
	show: boolean;
	onClose: () => void;
	onEntryDeleted: () => void;
	entryId: string;
	projectId: string;
}) => {
	const deleteDataEntryMutation = useDeleteDataEntryMutation();
	const { track } = useTracking();

	return (
		<Modal
			visible={show}
			onClose={onClose}
			primary={{
				warning: true,
				label: 'Delete',
				loading: deleteDataEntryMutation.loading,
				onClick: async () => {
					try {
						await deleteDataEntryMutation.mutate({
							entryId,
							projectId
						});
						track({
							eventName: 'entry_deleted'
						});

						onEntryDeleted();
					} catch (e) {
						console.error(e);
					}
				}
			}}
			neutral={{
				label: 'Cancel',
				onClick: onClose
			}}
			size={s => s.s}
			title="Delete entry"
		>
			<div className="flex flex-col gap-3">
				<p className="text-base">Are you sure you want to delete this entry?</p>

				<p className="font-bold text-error-500 text-base">
					Warning! This action cannot be undone!
				</p>

				{deleteDataEntryMutation.error && (
					<p className="text-error-500 text-base">
						Failed to delete entry, please try again.
					</p>
				)}
			</div>
		</Modal>
	);
};

const SubmitDiffModal = ({
	dataPendingSubmission,
	initialEntry,
	onClose,
	onConfirm,
	onDelete,
	submitting,
	variables
}: {
	dataPendingSubmission: Entry | undefined;
	initialEntry: Entry;
	onClose: () => void;
	onConfirm: (data: Entry) => void;
	submitting?: boolean;
	variables: Variable[];
	onDelete: () => void;
}) => {
	if (!dataPendingSubmission) {
		return null;
	}

	const initialValues = parseBackendValues({
		variables,
		entry: initialEntry
	});
	const currentValues = {
		...initialValues,
		...parseBackendValues({ variables, entry: dataPendingSubmission }) // there is a bug here if there is a file variable they can't be parsed like this but it will work because the
	};

	const diffedFields: Record<string, DiffedField> = {};
	for (const key of Object.keys(dataPendingSubmission)) {
		if (variables.find(v => v.variableName === key)?.variableType === 'file') {
			diffedFields[key] = {
				from: initialValues[key],
				to: dataPendingSubmission[key]
			};
		} else {
			diffedFields[key] = {
				from: initialValues[key],
				to: currentValues[key]
			};
		}
	}

	return (
		<ConfirmDiffModal
			diff={diffedFields}
			onClose={onClose}
			onConfirm={() => onConfirm(dataPendingSubmission)}
			submitting={submitting}
			variables={variables}
			onDelete={onDelete}
		/>
	);
};

const pageStyle = `
	@media print {
		body {
			height: auto !important;
		}

		textarea {
			box-sizing: content-box !important;
		}
	}
`;
