import { TIME_UNIT_DICT } from './dict';
import { TimeUnit } from '../../types';

const timeUnitOrder: TimeUnit[] = [
	'weeks',
	'days',
	'hours',
	'minutes',
	'seconds',
	'milliseconds',
	'microseconds'
];

const TIME_UNITS = {
	microseconds: { divisor: 1, label: TIME_UNIT_DICT.prefix.microseconds },
	milliseconds: { divisor: 1000, label: TIME_UNIT_DICT.prefix.milliseconds },
	seconds: { divisor: 1000 * 1000, label: TIME_UNIT_DICT.prefix.seconds },
	minutes: { divisor: 60 * 1000 * 1000, label: TIME_UNIT_DICT.prefix.minutes },
	hours: { divisor: 60 * 60 * 1000 * 1000, label: TIME_UNIT_DICT.prefix.hours },
	days: { divisor: 24 * 60 * 60 * 1000 * 1000, label: TIME_UNIT_DICT.prefix.days },
	weeks: { divisor: 1 * 7 * 24 * 60 * 60 * 1000 * 1000, label: TIME_UNIT_DICT.prefix.weeks }
};

/**
 * Converts microseconds to a formatted string showing time units within specified range
 * @param microseconds - Total number of microseconds
 * @param prefixLabel - Optional boolean to include unit labels (default: false)
 * @param maxTimeUnit - Highest time unit to include (e.g., 'weeks')
 * @param minTimeUnit - Lowest time unit to include (e.g., 'microseconds')
 * @returns Formatted string with optional unit labels, separated by colons
 * @example
 * formatMicroseconds({
 *   microseconds: 61001001,
 *   prefixLabel: false,
 *   maxTimeUnit: 'hours',
 *   minTimeUnit: 'milliseconds'
 * })
 * // Returns "1:1:1"
 */
export function formatMicroseconds({
	microseconds,
	showUnitLabel = false,
	maxTimeUnit,
	minTimeUnit
}: {
	microseconds: number | null;
	showUnitLabel: boolean;
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
}): string {
	if (microseconds === null) return '';

	// Constants for time unit conversions

	// Get the indices for max and min time units
	const maxIndex = timeUnitOrder.indexOf(maxTimeUnit);
	const minIndex = timeUnitOrder.indexOf(minTimeUnit);

	// Get relevant time units for our range
	const relevantUnits = timeUnitOrder.slice(maxIndex, minIndex + 1);

	// Helper function to calculate units and remainder
	const getTimeUnit = (value: number, divisor: number) => ({
		value: Math.floor(value / divisor),
		remainder: value % divisor
	});

	// Calculate only the units we need
	const result: { unit: TimeUnit; label: string; value: number }[] = [];
	let remaining = microseconds;

	for (const unit of relevantUnits) {
		const { divisor, label } = TIME_UNITS[unit];
		const calculation = getTimeUnit(remaining, divisor);

		remaining = calculation.remainder;

		result.push({
			unit,
			label,
			value: calculation.value
		});
	}

	// If all values are zero, return "0"
	if (result.every(unit => unit.value === 0)) return '0';

	// Return formatted string based on prefixLabel parameter
	return result
		.map(({ label, value }) => (showUnitLabel ? `${value}${label}` : `${value}`))
		.join(':');
}

export const addTimeUnits = ({
	colonSeparatedValues,
	maxTimeUnit,
	minTimeUnit
}: {
	colonSeparatedValues: string | null;
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
}) => {
	if (!colonSeparatedValues) return '';

	// Get the indices for max and min time units
	const maxIndex = timeUnitOrder.indexOf(maxTimeUnit);
	const minIndex = timeUnitOrder.indexOf(minTimeUnit);

	const values = colonSeparatedValues.split(':');

	const relevantUnits = timeUnitOrder.slice(maxIndex, minIndex + 1);

	return values
		.map((value, index) => `${value}${TIME_UNIT_DICT.prefix[relevantUnits[index]]}`)
		.join(':');
};
