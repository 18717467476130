import { Entry } from '../types';
import isEqual from 'lodash/isEqual';
import { BackendFormFile, FrontendFormFile } from './zodUtils';

export const getDirtyFields = (currentFormValues: Entry, defaultFormValues: Entry) => {
	const dirtyFields: string[] = [];
	for (const key of Object.keys(currentFormValues)) {
		const currentValue = currentFormValues[key];
		const defaultValue = defaultFormValues[key];

		// If the value is an array, we need to compare the sets instead of the arrays
		// as we don't care about the order of the elements
		if (Array.isArray(currentValue) && Array.isArray(defaultValue)) {
			const currentSet = new Set([...currentValue]);
			const defaultSet = new Set([...defaultValue]);

			if (!isEqual(currentSet, defaultSet)) {
				dirtyFields.push(key);
			}
			continue;
		}

		if (!isEqual(currentValue, defaultValue)) {
			dirtyFields.push(key);
		}
	}
	return dirtyFields;
};

export type EntryValue = string | string[] | FrontendFormFile | BackendFormFile;
export type DiffedField = {
	from: EntryValue;
	to: EntryValue;
};
