import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { Variable } from '../types';
import { InputType } from 'types';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { Input } from 'components/UI/Inputs/Input';

interface Props {
	control: FormControl;
	variable: Variable;
}

export const NumericVariableInput = ({ control, variable }: Props) => {
	const { field, fieldState } = useController({
		name: variable.variableName,
		control
	});

	return (
		<Input
			{...field}
			className="col-span-full md:col-span-1"
			onChange={e => {
				field.onChange(e);
			}}
			dataTestId={`input-${variable.variableName}`}
			value={field.value ?? ''}
			type={InputType.Text}
			label={variable.variableLabel}
			error={fieldState.error?.message?.toString()}
			LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
			required={variable.obligatory}
			disabled={variable.entryType === 'calculated'}
		/>
	);
};
