import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { VariableUniquenessType, Variable } from 'api/data/variables';
import { VariableType, EntryVariableType } from 'types/data/variables/constants';
import { dateTimeFormatMap, DATE_FORMAT, ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD } from 'consts';
import { TIME_DURATION_OPTIONS_PREFIX_KEY_MAP } from 'timeDurationConsts';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import {
	DynamicFormValue,
	DynamicFormValues,
	EntryValue,
	VariableFilteringMap
} from 'store/data/entries';
import { Revision, RevisionChanges } from 'store/data/revisions';
import { BooleanMap, HTMLInput, InputType, Nullable, SelectItem } from 'types/index';

import {
	FileWrapper,
	InputContainer,
	InputWrapper,
	RadioWrapper,
	SelectWrapper
} from './AddEditInput.style';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import {
	CheckboxGroupUncontrolled,
	RadioGroupUncontrolled
} from 'components/UI/Interactables/Uncontrolled';
import { EntryFileInput } from 'components/UI/Inputs/EntryFileInput';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { Input } from 'components/UI/Inputs/Input';
import {
	entryFormReadOnlyModalEvent,
	validateTimeDurationInput,
	setEntryFormFieldValue,
	withCustomSuffix,
	sanitizeTimeDurationInput,
	parseDateToAPIStorage,
	zeroPadDate,
	entryFormDependenciesCheckEvent
} from 'helpers/entries';
import { buildVariableCategoriesMap } from 'helpers/variables';
import { useEntry, useTimeDurationEntries, useTranslation, useVariablesData } from 'hooks/store';
import { useMeasureText } from 'hooks/ui';
import { useDeepCompareEffect, useSelector } from 'hooks/utils';
import { Dictionary } from 'environment';

interface Props {
	tooltipContainer?: HTMLDivElement;
	variable: Variable;
	openCustomsMap: BooleanMap;
	variableFilteringMap: VariableFilteringMap;
	initialValue: DynamicFormValue;
	revision?: Revision;
	readOnly?: boolean;
	isRevisionSelected?: boolean;
	uniqueError?: string;
	borderError?: boolean;
	dataTestId?: string;
}

export function AddEditInput({
	tooltipContainer,
	variable,
	openCustomsMap,
	initialValue,
	variableFilteringMap,
	revision,
	readOnly,
	isRevisionSelected,
	uniqueError,
	borderError,
	dataTestId
}: Props) {
	const {
		type,
		label,
		name,
		description,
		categories,
		fixedCategories,
		optimizeForManyValues: isDropdown,
		obligatory: required,
		entryType,
		uniquenessType
	} = variable;

	const isDate = type === VariableType.Date;
	const isDateTime = type === VariableType.DateTime;
	const isText = type === VariableType.String;
	const isNumber = type === VariableType.Float || type === VariableType.Integer;
	const isCalculated = entryType === EntryVariableType.Calculated;
	const isRadioGroup = type === VariableType.Category;
	const isCheckboxGroup = type === VariableType.CategoryMultiple;
	const isCategory = isRadioGroup || isCheckboxGroup;
	const isFile = type === VariableType.File;
	const isTimeDuration = type === VariableType.TimeDuration && variable.durationFormat;
	const isUnique = type === VariableType.Unique;

	const isUniqueManual = isUnique && uniquenessType === VariableUniquenessType.Manual;

	const allowCreate = !fixedCategories;

	const LongCategoryValuesTextWidthPx = 295;
	const forceDropdown = categories.length > ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD;

	const { translate } = useTranslation();
	const { measureText } = useMeasureText();

	const [{ data: entry }] = useEntry({ lazy: true });
	const { getTimeDurationInputPreview } = useTimeDurationEntries({ withTranslation: true });
	const { variablesMap } = useVariablesData();

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);

	const [inputRef, setInputRef] = useState<HTMLInput | null>(null);

	const getInputRef = useCallback((node: HTMLInput | null) => {
		if (node) {
			!inputRef && setInputRef(node);
		}
	}, []);

	useDeepCompareEffect(() => {
		if (inputRef) {
			inputRef.focus();
			inputRef.blur();
		}
	}, [inputRef, entry]);

	// Determines the disabled fixed category value based on the entry and variable information
	const disabledFixedCategory = useMemo(() => {
		if (variable.fixedCategories && entry) {
			const {
				enteredbyuser,
				enteredbyuserwithname,
				ownedbyuser,
				ownedbyuserwithname,
				creationdate,
				lastmodifieddate,
				userProjectOrgId,
				datasetentryid,
				...entryValues
			} = entry;
			let variableValue: EntryValue | undefined;

			for (const key in entryValues) {
				if (key === name) {
					variableValue = entryValues[key];
					break;
				}
			}

			if (variableValue) {
				let containsValue = false;

				if (typeof variableValue == 'string') {
					containsValue = categories.some(category => category.value === variableValue);
				} else {
					containsValue = categories.some(category =>
						variableValue?.includes(category.value)
					);
				}

				if (!containsValue) {
					return variableValue.toString();
				}
			}
		}

		return undefined;
	}, [entry, name, variable.fixedCategories, categories]);

	const {
		getValues,
		setValue,
		resetField,
		formState: { errors }
	} = useFormContext<DynamicFormValues>();

	function getDropdownMultipleValuesProps(
		name: string,
		values: string[],
		onBlur: () => void,
		hasMultipleValues?: boolean
	) {
		if (!hasMultipleValues) return;

		const props = {
			values: values.map(value => ({ label: value, value })),
			onValuesSelected: (newValues: string[]) => {
				handleSetValue(name, newValues);
				onBlur();
			},
			hasMultipleValues: true,
			allowCreateOnlyOne: true
		};

		return props;
	}

	function handleSetValue(name: string, value: DynamicFormValue) {
		const fieldNames = Object.keys(variablesMap);
		// CHECK IF USER HAS ACCESS TO CHANGE THE VALUE
		if (readOnly) {
			entryFormReadOnlyModalEvent().dispatch(true);
			return;
		}
		const parsedValue = [VariableType.Date, VariableType.DateTime].includes(type)
			? zeroPadDate(value as string)
			: value;

		// bypass shallow float changes
		// do not mark field dirty if float field changed but resolved number value is identical
		if (type === VariableType.Float) {
			if (parseFloat(initialValue as string) == parseFloat(value as string)) {
				// mark as not dirty
				// very important to provide new default value as current input value because otherwise rhf would still mark it as dirty aftewards;
				resetField(name, { defaultValue: value });
				// add value to form but do not signal the change (keepDirty);
				setEntryFormFieldValue(name, parsedValue, setValue, {
					shouldDirty: false,
					shouldValidate: true
				});
				entryFormDependenciesCheckEvent().dispatch({ fieldNames });
				return;
			}
		}
		setEntryFormFieldValue(name, parsedValue, setValue);
		entryFormDependenciesCheckEvent().dispatch({ fieldNames });
	}

	function computeDropdownValue(items: SelectItem[], value: string, customValue?: string) {
		if (customValue) {
			return {
				label: customValue,
				value: customValue
			};
		}

		if (value !== '') return items.find(item => item.value === value);

		return null;
	}

	function computeDropdownItems(items: SelectItem[], customValue?: string) {
		if (customValue) {
			return [
				...items,
				{
					label: customValue,
					value: customValue
				}
			];
		}

		return items;
	}

	function handleSetDropdownValue(value: Nullable<string>, newValue: Nullable<string>) {
		// CHECK IF USER HAS ACCESS TO CHANGE THE VALUE
		if (readOnly) {
			entryFormReadOnlyModalEvent().dispatch(true);
			return;
		}

		// VALUE SELECTED
		if (newValue) {
			// CUSTOM VALUE SELECTED
			if (isCustomValue(newValue)) {
				// CLEAR MAIN VALUE
				if (value) handleSetValue(name, '');

				/*
				 * used to clear the last custom value when creating another one
				 * and to re-render the component
				 */
				handleSetValue(withCustomSuffix(name), '');
				handleSetValue(withCustomSuffix(name), newValue);
			}
			// MAIN VALUE SELECTED
			else {
				// CLEAR CUSTOM VALUE
				if (initialCustomValue) {
					handleSetValue(withCustomSuffix(name), '');
				}

				handleSetValue(name, newValue);
			}
		}
		// CLEAR VALUE
		else {
			if (value) handleSetValue(name, '');
			if (initialCustomValue) {
				handleSetValue(withCustomSuffix(name), '');
			}
		}
	}

	function isCustomValue(value: string) {
		const filteredCategories = variableFilteringMap[name];
		const computedCategories = filteredCategories ?? categories.map(c => c.value);

		return !computedCategories.includes(value);
	}

	function parseCalculatedValue(value: string) {
		let parsedValue = value;

		if (value) {
			// `date`
			if (variable.type === VariableType.Date) {
				parsedValue = format(new Date(value), DATE_FORMAT);
			}

			// `datetime`
			if (variable.type === VariableType.DateTime) {
				parsedValue = format(new Date(value), dateTimeFormatMap[dateTimeFormat]);
			}
		}

		return parsedValue;
	}

	/**
	 * TIME DURATION
	 */
	function onTimeDurationChange(e: React.ChangeEvent<HTMLInput>) {
		const value = e.target.value;
		if (!variable.durationFormat) return;
		if (validateTimeDurationInput(value, variable.durationFormat))
			return handleSetValue(name, value);
	}

	// automatically calculate entry value on blur (eg (hh:mm) 21:60 will become 22:00)
	function onTimeDurationBlur(e: React.FocusEvent<HTMLInput>) {
		if (!variable.durationFormat) return;
		const value = e.target.value;

		const previewValue = getTimeDurationInputPreview(value, variable.durationFormat);
		handleSetValue(name, previewValue);
	}

	function onTimeDurationFocus(e: React.FocusEvent<HTMLInput>) {
		const value = e.target.value;
		const format = variable.durationFormat;
		if (!format) return;
		const sanitizedValue = sanitizeTimeDurationInput(value);
		setValue(variable.name, sanitizedValue);
	}

	const onChangeDate = useCallback((value: string) => {
		return handleSetValue(name, value);
	}, []);

	const onChangeDateTime = useCallback((value: string) => {
		return handleSetValue(name, parseDateToAPIStorage(zeroPadDate(value)));
	}, []);

	const initialCustomValue = getValues(withCustomSuffix(name)) as string | undefined;

	const error = errors[name]?.message ?? uniqueError ?? '';
	// for radio group custom value
	const errorCustom = errors[withCustomSuffix(name)]?.message;

	const hasChanges = revision ? !!revision.changes.variableNames.includes(name) : false;

	const tooltipComponent = (
		<InfoTooltip
			renderContext={tooltipContainer}
			marginOffset={{ left: 1.2, bottom: 0.4 }}
			iconVisible
			zIndex={100}
			text={description}
		/>
	);

	function dataTestIdValue() {
		if (dataTestId) {
			return dataTestId.replace(/\s/g, '').toLowerCase();
		}
		return label.replace(/\s/g, '').toLowerCase();
	}

	// CALCULATED VARIABLE
	if (isCalculated) {
		let labelHint: string | undefined = undefined;
		if (isTimeDuration) {
			const placeholder =
				variable.durationFormat
					?.map(timeKey => TIME_DURATION_OPTIONS_PREFIX_KEY_MAP[timeKey])
					.join(':') ?? '';
			labelHint = `(${placeholder})`;
		}
		return (
			<InputWrapper id={name} data-test-id={dataTestIdValue()} hasChanges={hasChanges}>
				<InputContainer>
					<Controller
						name={name}
						defaultValue={''}
						render={({ field: { value } }) => (
							<Input
								data-testId={dataTestIdValue()}
								labelHint={labelHint}
								type={InputType.Textarea}
								label={label}
								value={parseCalculatedValue(value)}
								placeholder={translate(dict => dict.variableFields.calculated)}
								tooltipComponent={tooltipComponent}
								error={error}
								borderError={borderError}
							/>
						)}
					/>
				</InputContainer>
			</InputWrapper>
		);
	}

	// CATEGORY VARIABLE
	if (isCategory) {
		const filteredCategories = variableFilteringMap[name];
		const computedCategories = filteredCategories ?? categories.map(c => c.value);
		const disabledOptions: string[] = [];

		const categoriesMap = buildVariableCategoriesMap(categories);

		const categoriesWithLabelAndValue = computedCategories.map(categoryValue => ({
			label: categoriesMap[categoryValue]?.label || categoryValue,
			value: categoriesMap[categoryValue]?.value || categoryValue,
			...(categoriesMap[categoryValue]?.description.length > 0 && {
				tooltip: categoriesMap[categoryValue].description
			})
		}));

		if (disabledFixedCategory) {
			categoriesWithLabelAndValue.push({
				label: disabledFixedCategory,
				value: disabledFixedCategory
			});
			disabledOptions.push(disabledFixedCategory);
		}

		let revisionChange: RevisionChanges | undefined = undefined;
		const newRevisionChange: string[] = [];
		if (hasChanges) {
			revisionChange = revision?.changes.list.find(change => change.variableName === name);
			let check = false;
			if (Array.isArray(revisionChange?.to)) {
				revisionChange?.to.forEach(value => {
					if (
						!categoriesWithLabelAndValue.filter(category => category.label === value)
							.length
					) {
						check = true;
						categoriesWithLabelAndValue.push({
							label: value,
							value: value
						});
						newRevisionChange.push(value);
						if (!disabledOptions.includes(value)) disabledOptions.push(value);
					}
				});
			} else if (
				revisionChange?.to &&
				!categoriesWithLabelAndValue.filter(
					category => category.label === revisionChange?.to
				).length
			) {
				check = true;
				categoriesWithLabelAndValue.push({
					label: revisionChange?.to as string,
					value: revisionChange?.to as string
				});
				if (!disabledOptions.includes(revisionChange?.to as string)) {
					newRevisionChange.push(revisionChange?.to as string);
					disabledOptions.push(revisionChange?.to as string);
				}
			}

			if (!check) {
				revisionChange = undefined;
			}
		}
		const hasLongCategoryValues = computedCategories.reduce(
			(acc, category) => measureText(category) > LongCategoryValuesTextWidthPx || acc,
			false
		);

		const generalProps = {
			name,
			label,
			options: categoriesWithLabelAndValue,
			error,
			errorCustom,
			borderError,
			readOnly,
			required,
			tooltipComponent,
			allowCreate,
			initialCustomValue: initialCustomValue,
			initialCustomEnabled: openCustomsMap[name]
		};

		// DROPDOWN WITH SELECT
		if (isDropdown || forceDropdown) {
			return (
				<SelectWrapper
					id={name}
					data-test-id={dataTestIdValue()}
					hasChanges={hasChanges}
					longOptions={hasLongCategoryValues}
				>
					<InputContainer>
						<Controller
							name={name}
							defaultValue={isCheckboxGroup ? [] : ''}
							render={({ field: { value, onBlur } }) => (
								<CreatableSelect
									label={label}
									placeholder={translate(({ radioGroups }) =>
										required
											? radioGroups.valueRequired
											: radioGroups.noSelection
									)}
									value={computeDropdownValue(
										computeDropdownItems(
											categoriesWithLabelAndValue,
											initialCustomValue
										),
										value,
										initialCustomValue
									)}
									dropdownIconTestId={`${name}-dropdown-icon`}
									items={computeDropdownItems(
										categoriesWithLabelAndValue,
										initialCustomValue
									)}
									isItemDisabled={value =>
										newRevisionChange.includes(value.label) &&
										!!isRevisionSelected
									}
									error={error}
									borderError={borderError}
									tooltipComponent={tooltipComponent}
									allowCreate={allowCreate}
									required={required}
									onValueSelected={newValue => {
										handleSetDropdownValue(value, newValue);
										onBlur();
									}}
									onBlur={onBlur}
									scrollIntoView
									{...getDropdownMultipleValuesProps(
										name,
										value,
										onBlur,
										isCheckboxGroup
									)}
								/>
							)}
						/>
					</InputContainer>
				</SelectWrapper>
			);
		}

		// CHECKBOX GROUP
		if (isCheckboxGroup) {
			return (
				<RadioWrapper id={name} data-test-id={dataTestIdValue()} hasChanges={hasChanges}>
					<Controller
						name={name}
						defaultValue={[]}
						render={({ field: { value, onBlur } }) => (
							<CheckboxGroupUncontrolled
								dataTestId={dataTestId}
								{...generalProps}
								// CONTROLLED PROPS
								values={value}
								onBlur={onBlur}
								isItemDisabled={value => newRevisionChange.includes(value)}
								onChange={newValues => handleSetValue(name, newValues)}
								onChangeCustom={newValue =>
									handleSetValue(withCustomSuffix(name), newValue)
								}
							/>
						)}
					/>
				</RadioWrapper>
			);
		}

		// RADIO GROUP
		if (isRadioGroup) {
			return (
				<RadioWrapper id={name} data-testid={dataTestIdValue()} hasChanges={hasChanges}>
					<Controller
						name={name}
						defaultValue={''}
						render={({ field: { value, onBlur } }) => (
							<RadioGroupUncontrolled
								dataTestId={dataTestId}
								{...generalProps}
								allowUnselect
								// CONTROLLED PROPS
								value={value}
								disabledOptions={disabledOptions}
								onBlur={onBlur}
								onChange={newValue => {
									handleSetValue(name, newValue);
								}}
								onChangeCustom={newValue =>
									handleSetValue(withCustomSuffix(name), newValue)
								}
							/>
						)}
					/>
				</RadioWrapper>
			);
		}
	}

	// FILE VARIABLE
	if (isFile) {
		return (
			<FileWrapper hasChanges={hasChanges}>
				<Controller
					name={name}
					defaultValue={''}
					render={({ field: { value, onBlur } }) => (
						<EntryFileInput
							name={name}
							label={label}
							required={required}
							error={error}
							value={value}
							readOnly={readOnly}
							tooltipComponent={
								<InfoTooltip
									renderContext={tooltipContainer}
									zIndex={100}
									text={description}
									marginOffset={{ left: 1.2 }}
								/>
							}
							onBlur={onBlur}
							onValueChange={value => handleSetValue(name, value)}
						/>
					)}
				/>
			</FileWrapper>
		);
	}

	if (isDateTime) {
		return (
			<InputWrapper id={name} data-test-id={dataTestIdValue()} hasChanges={hasChanges}>
				<Controller
					name={name}
					defaultValue={''}
					render={({ field: { value, onBlur } }) => (
						<DateTimeInput
							value={value}
							onChange={onChangeDateTime}
							options={{
								readOnlyController: readOnly,
								name,
								label,
								error,
								tooltipComponent,
								required,
								onBlur,
								onReadOnly: () => entryFormReadOnlyModalEvent().dispatch(true)
							}}
						/>
					)}
				/>
			</InputWrapper>
		);
	}

	// TEXT, DATE, NUMBER (INTEGER / FLOAT) VARIABLE
	if (isDate) {
		return (
			<InputWrapper id={name} data-test-id={dataTestIdValue()} hasChanges={hasChanges}>
				<InputContainer>
					<Controller
						name={name}
						defaultValue={''}
						render={({ field: { value, onBlur } }) => (
							<Input
								name={name}
								type={InputType.Date}
								label={label}
								value={value}
								required={required || isUniqueManual}
								error={error}
								borderError={borderError}
								tooltipComponent={tooltipComponent}
								onChange={e => onChangeDate(e.target.value)}
								onDateChange={({ formattedDate }) => onChangeDate(formattedDate)}
								onBlur={onBlur}
								readOnly={isUnique && !isUniqueManual}
							/>
						)}
					/>
				</InputContainer>
			</InputWrapper>
		);
	}

	if (isTimeDuration) {
		const placeholder =
			variable.durationFormat
				?.map(timeKey => {
					return translate(
						dict =>
							dict.timeDurationPlaceholder.prefix[
								timeKey as keyof typeof Dictionary.timeDurationPlaceholder.prefix
							]
					);
				})
				.join(':') ?? '';
		const labelHint = `(${placeholder})`;

		return (
			<InputWrapper id={name} data-test-id={label} hasChanges={hasChanges}>
				<InputContainer>
					<Controller
						name={name}
						defaultValue={''}
						render={({ field: { value } }) => (
							<Input
								ref={getInputRef}
								labelHint={labelHint}
								name={name}
								type={InputType.Text}
								placeholder={placeholder}
								label={label}
								value={value}
								required={required || isUniqueManual}
								error={error}
								borderError={borderError}
								tooltipComponent={tooltipComponent}
								onChange={onTimeDurationChange}
								onBlur={onTimeDurationBlur}
								onFocus={onTimeDurationFocus}
								readOnly={isUnique && !isUniqueManual}
							/>
						)}
					/>
				</InputContainer>
			</InputWrapper>
		);
	}

	// TEXT, DATE, NUMBER (INTEGER / FLOAT) VARIABLE
	if (isText || isNumber || isUnique) {
		let inputType = InputType.Textarea;

		// NUMBER VARIABLE (INTEGER OR FLOAT)
		if (isNumber || isUnique) inputType = InputType.Text;

		return (
			<InputWrapper id={name} data-test-id={dataTestIdValue()} hasChanges={hasChanges}>
				<InputContainer>
					<Controller
						name={name}
						defaultValue={''}
						render={({ field: { value, onBlur } }) => (
							<Input
								name={name}
								type={inputType}
								label={label}
								value={value}
								required={required || isUniqueManual}
								error={error}
								borderError={borderError}
								tooltipComponent={tooltipComponent}
								onChange={e => handleSetValue(name, e.target.value)}
								onDateChange={({ formattedDate }) =>
									handleSetValue(name, formattedDate)
								}
								onBlur={onBlur}
								readOnly={isUnique && !isUniqueManual}
								dataTestId={dataTestIdValue()}
							/>
						)}
					/>
				</InputContainer>
			</InputWrapper>
		);
	}

	return null;
}
