import { nanoid as generate } from 'nanoid';

import {
	type Analysis,
	type CompareNumericAnalysisV1,
	type ComparePairedAnalysis,
	type CorrelationsAnalysis,
	type CorrelationsV1Analysis,
	type CrosstabAnalysis,
	type DensityPlotAnalysis,
	type ExploreAnalysis,
	type FrequenciesAnalysis,
	type JADBioAnalysis,
	type KaplanMeierAnalysis,
	type PlotNumericAnalysis,
	type TimeCourseAnalysisV1,
	type TimeCourseAnalysisV2,
	type LogisticRegressionAnalysis,
	ExploreAnalysisV2,
	AnalysisStatisticAggregationType,
	JADBioProbabilitiesDisplayType,
	JADBioReductionType,
	ComparePairedDataModels,
	JADBioAnalysisModels,
	JADBioFeatureImportancePlots,
	KaplanMeierDataModels,
	TimeWindowSizeType,
	AnalysisErrorBarType,
	CompareNumericAnalysisV2,
	DensityPlotAnalysisV2,
	PlotNumericAnalysisV2,
	LogisticRegressionAnalysisV2,
	CrosstabAnalysisV2,
	FrequenciesAnalysisV2,
	KaplanMeierAnalysisV2,
	DataModel,
	ComparePairedAnalysisV2
} from 'api/data/analyses';
import type { Variable } from 'api/data/variables';
import type { VariablesData } from 'store/data/variables';

import { getDefaultVariableName } from './getDefaultVariableName';
import {
	buildVariablesRichData,
	getAggregatorVariableNameByAggregationRuleName,
	variablesDataArrayIterator,
	buildVariableSetVariablesData
} from 'helpers/variables';
import { AnalysisType } from 'api/data/analyses/constants';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	type: AnalysisType;
	variablesData: VariablesData;
}

export function buildAnalysis({ type, variablesData }: Props): Analysis {
	const { variablesDataArray, variablesMap, variableSetsMap } =
		buildVariablesRichData(variablesData);

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const numericVariableNames: string[] = [];
	const durationVariableNames: string[] = [];
	const mainLevelNumericVariableNames: string[] = [];
	const integerVariableNames: string[] = [];
	const categoryVariableNames: string[] = [];
	const dateVariableNames: string[] = [];
	const stringVariableNames: string[] = [];
	const variablesFromSet: {
		numericVariableNames: string[];
		categoryVariableNames: string[];
	} = { numericVariableNames: [], categoryVariableNames: [] };

	// BUILD VARIABLES BY TYPE ARRAYS
	variablesDataArrayIterator(
		variablesDataArray,
		// VARIABLE
		variable => addVariableToNamesArray(variable),
		// GROUP
		groupData => {
			const { groupVariables } = groupData;

			groupVariables.forEach(variable => addVariableToNamesArray(variable));
		},
		// VARIABLE SET
		variableSetData => {
			const { aggregationRules } = variableSetData;

			aggregationRules.forEach(aggregationRule => {
				const variable = variablesMap[aggregationRule.aggregator.variableName];

				if (!variable) return;

				addVariableToNamesArray(variable, aggregationRule.name);
			});
		}
	);

	if (
		type === AnalysisType.ComparePaired &&
		Object.values(variableSetsMap)[0] &&
		Object.values(variableSetsMap)[0].setName
	) {
		const variableSetVariablesData = buildVariableSetVariablesData({
			setName: Object.values(variableSetsMap)[0].setName,
			variablesData
		});
		variablesDataArrayIterator(
			Object.values(variableSetVariablesData.variablesMap),
			variable =>
				addVariableToNamesArray(
					variable,
					undefined,
					Object.values(variableSetsMap)[0].setName
				),
			groupData => {
				const { groupVariables } = groupData;

				groupVariables.forEach(variable =>
					addVariableToNamesArray(
						variable,
						undefined,
						Object.values(variableSetsMap)[0].setName
					)
				);
			},
			() => null
		);
	}

	function addVariableToNamesArray(variable: Variable, customName?: string, setName?: string) {
		const isNumericVariable = [VariableType.Integer, VariableType.Float].includes(
			variable.type
		);
		const isDurationVariable = [VariableType.TimeDuration].includes(variable.type);
		const isCategoryVariable = VariableType.Category === variable.type;
		const isDateVariable = [VariableType.Date, VariableType.DateTime].includes(variable.type);
		const isStringVariable = VariableType.String === variable.type;
		const isIntegerVariable = VariableType.Integer === variable.type;

		if (setName) {
			if (isNumericVariable) variablesFromSet.numericVariableNames.push(variable.name);
			if (isCategoryVariable) variablesFromSet.categoryVariableNames.push(variable.name);
		}

		if (isNumericVariable) {
			if (!customName) {
				mainLevelNumericVariableNames.push(variable.name);
			}
			numericVariableNames.push(customName ?? variable.name);
		}
		if (isIntegerVariable) integerVariableNames.push(customName ?? variable.name);
		if (isDurationVariable) durationVariableNames.push(customName ?? variable.name);
		if (isCategoryVariable) categoryVariableNames.push(customName ?? variable.name);
		if (isDateVariable) dateVariableNames.push(customName ?? variable.name);
		if (isStringVariable) stringVariableNames.push(customName ?? variable.name);
	}

	function getDefaultCategoryValue(variableName: string, second?: boolean): string {
		let variable = variablesMap[variableName];

		if (variableName in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName =
				aggregatorVariableNameByAggregationRuleName[variableName];

			variable = variablesMap[aggregatorVariableName];
		}

		if (variable)
			return second ? variable.categories[1]?.value : variable.categories[0]?.value ?? '';

		return '';
	}

	switch (type) {
		case AnalysisType.CompareNumericV1: {
			const analysis: CompareNumericAnalysisV1 = {
				id: generate(),
				type: AnalysisType.CompareNumericV1,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					}
				},
				input: {
					variables: {
						categoryVariable: getDefaultVariableName(categoryVariableNames),
						exploreVariable: getDefaultVariableName(numericVariableNames)
					},
					statistics: {
						shapiroV1: false,
						mannWhitneyV1: false,
						independentV1: false,
						oneWayAnovaV1: false,
						twoWayManovaV1: false,
						tukeyV1: false,
						kruskalV1: false,
						twoWayAnovaV1: false,
						oneWayManovaV1: false
					}
				},
				output: {
					dataset: {},
					statistics: {
						shapiro: {
							data: null
						},
						mannWhitney: {
							data: null
						},
						independent: {
							data: null
						},
						oneWayAnova: {
							data: null
						},
						tukey: {
							data: null
						},
						kruskal: {
							data: null
						},
						twoWayManova: {
							data: null
						},
						twoWayAnova: {
							data: null
						},
						oneWayManova: {
							data: null
						}
					}
				}
			};

			return analysis;
		}
		case AnalysisType.CompareNumericV2: {
			const analysis: CompareNumericAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					}
				},
				input: {
					dataModel: DataModel.main,
					variables: {
						categoryVariable: null,
						exploreVariable: null
					},
					statistics: {
						shapiroV2: false,
						mannWhitneyV2: false,
						independentV2: false,
						oneWayAnovaV2: false,
						twoWayManovaV2: false,
						tukeyV2: false,
						kruskalV2: false,
						twoWayAnovaV2: false,
						oneWayManovaV2: false
					}
				},
				output: {
					dataset: { data: {} },
					statistics: {
						shapiro: {
							data: null
						},
						mannWhitney: {
							data: null
						},
						independent: {
							data: null
						},
						oneWayAnova: {
							data: null
						},
						tukey: {
							data: null
						},
						kruskal: {
							data: null
						},
						twoWayManova: {
							data: null
						},
						twoWayAnova: {
							data: null
						},
						oneWayManova: {
							data: null
						}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.CorrelationsV1: {
			const analysis: CorrelationsV1Analysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true
				},
				input: {
					variables: {
						xNumericVariable: getDefaultVariableName(numericVariableNames),
						yNumericVariable: getDefaultVariableName(numericVariableNames, {
							second: true
						})
					},
					statistics: {
						pearsonV1: false,
						spearmanV1: false,
						linearRegressionV1: false
					}
				},
				output: {
					dataset: [],
					statistics: {
						pearson: {
							data: null
						},
						spearman: {
							data: null
						},
						linearRegression: {
							data: null
						}
					}
				}
			};

			return analysis;
		}
		case AnalysisType.CorrelationsV2: {
			const analysis: CorrelationsAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true
				},
				input: {
					dataModel: DataModel.main,
					variables: {
						// xVariable: { name: getDefaultVariableName(numericVariableNames) },
						// yVariable: {
						// 	name: getDefaultVariableName(numericVariableNames, {
						// 		second: true
						// 	})
						// },
						xVariable: null,
						yVariable: null,
						groupVariables: []
					},
					statistics: {
						pearsonV2: false,
						spearmanV2: false,
						linearRegressionV2: false
					}
				},
				output: {
					dataset: { data: [] },
					statistics: {
						pearsonV2: {
							data: null
						},
						spearmanV2: {
							data: null
						},
						linearRegressionV2: {
							data: null
						}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.Crosstab: {
			const analysis: CrosstabAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true,
						chartType: true
					},
					chartLegend: true,
					activeTab: 0
				},
				input: {
					variables: {
						yVariable: getDefaultVariableName(categoryVariableNames),
						groupingVariable: getDefaultVariableName(categoryVariableNames, {
							second: true
						})
					},
					statistics: {
						fisher: false,
						chiSquare: false,
						mcNemar: false
					}
				},
				output: {
					dataset: {
						rows: {
							label: '',
							name: '',
							rows: []
						},
						columns: {
							categoryLabels: [],

							categoryTotals: [],

							label: '',
							name: ''
						}
					},
					statistics: {
						fisher: {
							data: null
						},
						chiSquare: {
							data: null
						},
						mcNemar: {
							data: null
						}
					}
				}
			};

			return analysis;
		}
		case AnalysisType.CrosstabV2: {
			const analysis: CrosstabAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true,
						chartType: true
					},
					chartLegend: true,
					activeTab: 0
				},
				input: {
					dataModel: DataModel.main,
					variables: {
						columnVariable: null,
						rowVariable: null
					},
					statistics: {
						fisherV2: false,
						chiSquareV2: false,
						mcNemarV2: false
					}
				},
				output: {
					dataset: {
						data: {
							columnLabels: [],
							rowLabels: [],
							columnVariable: {
								name: ''
							},
							rowVariable: {
								name: ''
							},
							counts: [],
							percentages: []
						}
					},
					statistics: {
						fisher: {
							data: null
						},
						chiSquare: {
							data: null
						},
						mcNemar: {
							data: null
						}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.Explore: {
			const analysis: ExploreAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					}
				},
				input: {
					variables: [
						{
							variableName: getDefaultVariableName([
								...numericVariableNames,
								...durationVariableNames
							])
						}
					]
				},
				output: {
					dataset: []
				}
			};

			return analysis;
		}

		case AnalysisType.ExploreV2: {
			const analysis: ExploreAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					}
				},
				input: {
					variables: [],
					dataModel: DataModel.main
				},
				output: {
					dataset: { data: [] }
				}
			};

			return analysis;
		}

		case AnalysisType.Frequencies: {
			const analysis: FrequenciesAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true,
						chartType: true
					},
					chartLegend: true,
					activeTab: 0
				},
				input: {
					variables: {
						categoryVariable: getDefaultVariableName(categoryVariableNames)
					}
				},
				output: {
					dataset: {
						results: {}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.FrequenciesV2: {
			const analysis: FrequenciesAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true,
						chartType: true
					},
					chartLegend: true,
					activeTab: 0
				},
				input: {
					variables: {
						categoryVariable: null
					},
					dataModel: DataModel.main
				},
				output: {
					dataset: {
						data: null
					}
				}
			};

			return analysis;
		}

		case AnalysisType.Kaplan: {
			const analysis: KaplanMeierAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true
				},
				input: {
					selectedDataModel: KaplanMeierDataModels.duration,
					variables: {
						durationVariable: getDefaultVariableName(numericVariableNames),
						eventVariable: getDefaultVariableName(categoryVariableNames),
						positiveEvent: [
							getDefaultVariableName(categoryVariableNames)
								? getDefaultCategoryValue(
										getDefaultVariableName(categoryVariableNames)
								  )
								: ''
						],
						startDate: getDefaultVariableName(dateVariableNames),
						endDate: getDefaultVariableName(dateVariableNames, {
							second: true,
							fallbackToFirst: false
						}),
						timeUnit: TimeWindowSizeType.Days
					},
					statistics: {
						patients: false,
						confidenceIntervals: false,
						logRank: false
					}
				},
				output: {
					dataset: {},
					statistics: {
						logRank: {
							data: null
						}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.KaplanV2: {
			const analysis: KaplanMeierAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true,
					showCensorTicks: true
				},
				input: {
					dataModel: DataModel.main,
					selectedDataModel: null,
					variables: {
						durationVariable: null,
						eventVariable: null,
						positiveEvent: [],
						startDate: null,
						endDate: null,
						timeUnit: TimeWindowSizeType.Days
					},
					statistics: {
						patientsV2: false,
						confidenceIntervalsV2: false,
						logRankV2: false
					}
				},
				output: {
					dataset: { data: {} },
					statistics: {
						logRank: {
							data: null
						}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.PlotNumeric: {
			const analysis: PlotNumericAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true,
						chartType: true
					},
					chartLegend: true,
					activeTab: 0
				},
				input: {
					variables: {
						categoryVariable: getDefaultVariableName(categoryVariableNames),
						numericVariable: getDefaultVariableName(numericVariableNames),
						errorBar: AnalysisErrorBarType.Mean
					}
				},
				output: {
					dataset: {
						columns: [],
						boxplot: [],
						scatter: []
					},
					grouping: false
				}
			};

			return analysis;
		}

		case AnalysisType.PlotNumericV2: {
			const analysis: PlotNumericAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true,
						chartType: true
					},
					chartLegend: true,
					activeTab: 0
				},
				input: {
					variables: {
						categoryVariable: null,
						numericVariable: null,
						errorBar: AnalysisStatisticAggregationType.Mean,
						groupingVariable: null
					},
					series: undefined,
					dataModel: DataModel.main
				},
				output: {
					dataset: {
						columns: { data: [] },
						boxplot: { data: [] },
						scatter: { data: [] }
					},
					grouping: false
				}
			};

			return analysis;
		}

		case AnalysisType.DensityPlot: {
			const analysis: DensityPlotAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true,
					histogram: false
				},
				input: {
					variables: {
						numericVariable: getDefaultVariableName(numericVariableNames)
					}
				},
				output: {
					dataset: []
				}
			};

			return analysis;
		}

		case AnalysisType.DensityPlotV2: {
			const analysis: DensityPlotAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true,
					histogram: false
				},
				input: {
					variables: {
						numericVariable: null
					},
					dataModel: DataModel.main
				},
				output: {
					dataset: { data: [] }
				}
			};

			return analysis;
		}

		case AnalysisType.TimeCourseV1: {
			const analysis: TimeCourseAnalysisV1 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true
				},
				input: {
					variables: {
						numericVariable: getDefaultVariableName(numericVariableNames),
						dateVariable: getDefaultVariableName(dateVariableNames),
						timeWindowSize: TimeWindowSizeType.Years,
						errorBar: AnalysisErrorBarType.Mean
					}
				},
				output: {
					dataset: null,
					grouping: false
				}
			};

			return analysis;
		}

		case AnalysisType.TimeCourseV2: {
			const analysis: TimeCourseAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true
				},
				input: {
					variables: {
						numericVariable: null,
						timeVariable: null,
						timeUnit: null,
						groupVariables: [],
						statistic: AnalysisStatisticAggregationType.Mean
					},
					dataModel: DataModel.main
				},
				output: {
					dataset: {
						data: null
					},
					grouping: false
				}
			};

			return analysis;
		}

		case AnalysisType.ComparePaired: {
			let dataModel = ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT;
			const singleEntryCase = [
				getDefaultVariableName(numericVariableNames),
				getDefaultVariableName(numericVariableNames, {
					second: true
				})
			];
			if (!singleEntryCase.every(variableRef => variableRef !== '')) {
				// case 2 - 1x numeric 1x category 1x text/numeric
				const multipleEntriesCase = [
					getDefaultVariableName(numericVariableNames),
					getDefaultVariableName(categoryVariableNames),
					getDefaultCategoryValue(getDefaultVariableName(categoryVariableNames)),
					getDefaultCategoryValue(getDefaultVariableName(categoryVariableNames), true),
					getDefaultVariableName(stringVariableNames) ||
						(getDefaultVariableName(numericVariableNames) !==
						getDefaultVariableName(integerVariableNames)
							? getDefaultVariableName(integerVariableNames)
							: getDefaultVariableName(integerVariableNames, { second: true }))
				];
				if (multipleEntriesCase.every(variableRef => variableRef !== '')) {
					dataModel = ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT;
				} else {
					dataModel = ComparePairedDataModels.USING_SERIES;
				}
			}

			const analysis: ComparePairedAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					}
				},
				input: {
					dataModel,
					variables: {
						numericVariableOne: getDefaultVariableName(numericVariableNames),
						numericVariableTwo: getDefaultVariableName(numericVariableNames, {
							second: true
						}),
						catVarnameDiffSamples: getDefaultVariableName(categoryVariableNames),
						firstCategoryValue: getDefaultCategoryValue(
							getDefaultVariableName(categoryVariableNames)
						),
						secondCategoryValue: getDefaultCategoryValue(
							getDefaultVariableName(categoryVariableNames),
							true
						),
						patientIdentifierVarname:
							getDefaultVariableName(stringVariableNames) ||
							(getDefaultVariableName(numericVariableNames) !==
							getDefaultVariableName(integerVariableNames)
								? getDefaultVariableName(integerVariableNames)
								: getDefaultVariableName(integerVariableNames, { second: true })),
						setName: Object.values(variableSetsMap)[0]
							? Object.values(variableSetsMap)[0].setName
							: '',
						testVariable: getDefaultVariableName(variablesFromSet.numericVariableNames),
						groupVariable: getDefaultVariableName(
							variablesFromSet.categoryVariableNames
						),
						groupOne: getDefaultCategoryValue(
							getDefaultVariableName(variablesFromSet.categoryVariableNames)
						),
						groupTwo: getDefaultCategoryValue(
							getDefaultVariableName(variablesFromSet.categoryVariableNames),
							true
						)
					},
					statistics: {
						pairedTTest: false,
						pairedWilcoxon: false
					}
				},
				output: {
					dataset: {
						data: null
					},
					statistics: {
						pairedTTest: {
							data: null
						},
						pairedWilcoxon: {
							data: null
						}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.ComparePairedV2: {
			const analysis: ComparePairedAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					}
				},
				input: {
					dataModel: null,
					variables: {
						afterVariable: null,
						beforeVariable: null,
						groupVariable: null,
						numericVariable: null,
						pairIdentifier: null
					},
					statistics: {
						pairedTTestV2: false,
						pairedWilcoxonV2: false
					}
				},
				output: {
					dataset: {
						data: null
					},
					statistics: {
						pairedTTest: {
							data: null
						},
						pairedWilcoxon: {
							data: null
						}
					}
				}
			};

			return analysis;
		}

		case AnalysisType.LogisticRegression: {
			const analysis: LogisticRegressionAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true
				},
				input: {
					variables: {
						independentVariable: getDefaultVariableName(numericVariableNames),
						dependentVariable: getDefaultVariableName(categoryVariableNames),
						positiveEvent: getDefaultCategoryValue(
							getDefaultVariableName(categoryVariableNames)
						)
							? [
									getDefaultCategoryValue(
										getDefaultVariableName(categoryVariableNames)
									)
							  ]
							: []
					}
				},
				output: {
					dataset: null
				}
			};

			return analysis;
		}

		case AnalysisType.LogisticRegressionV2: {
			const analysis: LogisticRegressionAnalysisV2 = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					},
					chartLegend: true
				},
				input: {
					dataModel: DataModel.main,
					variables: {
						xVariable: null,
						yVariable: null,
						outcomes: []
					}
				},
				output: {
					dataset: { data: null }
				}
			};

			return analysis;
		}

		case AnalysisType.JADBio: {
			const analysis: JADBioAnalysis = {
				id: generate(),
				type,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true,
						jadBioPerformance: true
					}
				},
				input: {
					model: null,
					datasetInitialized: null,
					jadBioDatasetId: null,
					performanceReductionType: JADBioReductionType.UMAP,
					featureImportanceType: JADBioFeatureImportancePlots.FeatureImportance,
					probabilitiesDisplayType: JADBioProbabilitiesDisplayType.BoxPlot,
					variables: {
						classificationVariable: '',
						eventVariableName: '',
						timeToEventVariableName: '',
						regressionVariable: ''
					}
				},
				output: {
					dataset: {
						[JADBioAnalysisModels.Classification]: {
							jadBioAnalysisId: '',
							progress: null,
							pauseProgress: false,
							stopProgress: false,
							summary: [],
							plots: null
						},
						[JADBioAnalysisModels.SurvivalAnalysis]: {
							jadBioAnalysisId: '',
							progress: null,
							pauseProgress: false,
							stopProgress: false,
							summary: [],
							plots: null
						},
						[JADBioAnalysisModels.Regression]: {
							jadBioAnalysisId: '',
							progress: null,
							pauseProgress: false,
							stopProgress: false,
							summary: [],
							plots: null
						}
					}
				}
			};

			return analysis;
		}

		default:
			return {
				id: '',
				type: AnalysisType.Frequencies,
				fetched: false,
				options: {
					open: true,
					configPanel: {
						open: true,
						formatting: true,
						parameters: true
					}
				},
				input: {
					variables: {
						categoryVariable: ''
					}
				},
				output: {
					dataset: {
						results: {}
					}
				}
			};
	}
}
