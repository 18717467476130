import { useEffect, useState } from 'react';
import { makeRequest } from './util';

export type RevisionWithChanges = {
	active: boolean;
	changes: {
		variableName: string;
		from: any;
		to: any;
	}[];
	creationDate: string;
	revisionId: string;
	userName: string;
};

export const useGetDataEntryHistoryQuery = ({
	entryId,
	projectId
}: {
	entryId: string;
	projectId: string;
}) => {
	const [data, setData] = useState<{
		revisionsWithChanges: RevisionWithChanges[];
	}>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [fetching, setFetching] = useState(false);

	async function fetch() {
		setFetching(true);

		try {
			const result: {
				revisionsWithChanges: RevisionWithChanges[];
			} = await makeRequest({
				method: 'getDataEntryHistory',
				service: 'data-entry',
				data: {
					datasetentryid: entryId,
					projectId
				}
			});

			setData({
				revisionsWithChanges: result.revisionsWithChanges
			});
		} catch (e) {
			console.error(e);
			setError('Something unexpected happened, please try again later.');
		} finally {
			setLoading(false);
			setFetching(false);
		}
	}

	useEffect(() => {
		fetch();
	}, []);

	return { data, loading, error, refetch: fetch, fetching };
};
