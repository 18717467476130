import { ImportErrorsTable } from './ImportErrorsTable/ImportErrorsTable';
import { Container, MessageContainer } from './ImportReview.style';
// import { useState } from 'react';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { dynamicTranslate } from 'helpers/dynamicTranslate';
import { useMemo, useState } from 'react';
import { NarrowContainer } from '../styles';
import { Icon } from '../../../UI/Dropdown/DropdownToggle/DropdownToggle.style';
import { Colors, Svgs } from 'environment';
import { VariableErrorPagination } from './VariableErrorPagination/VariableErrorPagination';
import { Spacer } from 'components/UI/Spacer';
import { useImport, useImportVariableSet, useUploadUrl } from 'hooks/store';
import { StickyFooter } from 'components/UI/StickyFooter';
import type {
	ApiImportErrorsByVariableName,
	ApiVariableErrorCount,
	ImportErrorsByVariableName
} from 'types/data/projects/types';
import { ErrorTableWrapper } from './ErrorTableWrapper/ErrorTableWrapper';
import { FixOption } from './FixOption/FixOption';
import { Typography } from 'components/UI/Typography';
import type { PreviewVariable } from '../../../../types/data/projects/import/types';
import { ImportType, SelectItem } from 'types';
import { ButtonVariants } from '../../../UI/Interactables/Button/Button';
import { useUserOrganizationId } from 'hooks/store/data/projects/import/useUserOrganizationId';
import { useResetImportPercentage } from 'hooks/store/data/projects/import/useResetImportPercentage';
import {
	handleCustomDateFormatChange,
	handleVariableDateTypeChange
} from 'helpers/projects/imports/importDateAndDatetime';
import { getHasErrors } from 'helpers/projects/imports/importErrros';
import { ApiImportErrorType } from 'store/data/projects/constants';
import { useImportTimezoneSettings } from 'hooks/store/data/projects/import/useImportTimezoneSettings';
import {
	handleDurationFormatChange,
	handleSourceUnitChange
} from 'helpers/projects/imports/importTimeDuration';

interface Props {
	errorMap: ImportErrorsByVariableName;
	errorCountMap: ApiVariableErrorCount | null;
	selectedOption: ImportType | null;
	handleBack: () => void;
	handleFinishImport?: () => void;
	handleReupload?: () => void;
	handleApiImportErrors?: (
		apiErrorMap: ApiImportErrorsByVariableName,
		errorCountMap: ApiVariableErrorCount
	) => void;
}

export function ImportReview({
	errorMap,
	errorCountMap,
	selectedOption,
	handleFinishImport,
	handleReupload,
	handleApiImportErrors,
	handleBack
}: Props) {
	const { translate } = useTranslation();

	const { globalTimezone } = useImportTimezoneSettings();

	const [
		{
			data: { isBinary }
		}
	] = useUploadUrl();

	const [{ isImportVariableSet, variableToMatchOnMainLevel, columnToMatchWithMainLevel }] =
		useImportVariableSet();

	const userOrganizationId = useUserOrganizationId();
	const resetImportPercentage = useResetImportPercentage();

	const [
		{
			data: { formattedVariables, columnToMatch }
		},
		{ setFormattedVariables, uploadReplaceAll, uploadEntriesToDataset, uploadDataToEntries }
	] = useImport({
		handleFinishImport,
		...(handleApiImportErrors && {
			callbacks: {
				handleApiImportErrors
			}
		}),
		...(!isImportVariableSet && { projectOrganizationId: userOrganizationId })
	});

	const [showContinueWithMapping, setShowContinueWithMapping] = useState(false);

	const isAddMoreEntries = selectedOption === ImportType.MoreEntriesToDataset;

	const variableNames = useMemo(() => Object.keys(errorMap || {}), [errorMap]);

	const [activeVariableNameIdx, setActiveVariableNameIdx] = useState<number | null>(
		variableNames.length > 0 ? 0 : null
	);

	const activeVariable = useMemo(() => {
		if (
			!formattedVariables ||
			!variableNames ||
			activeVariableNameIdx === null ||
			selectedOption === null
		) {
			return null;
		}

		let variable = formattedVariables.find(variable => {
			return variableNames[activeVariableNameIdx] && isAddMoreEntries
				? variable.previewVariableLabel.toLocaleLowerCase() ===
						variableNames[activeVariableNameIdx].toLocaleLowerCase()
				: variable.label.toLocaleLowerCase() ===
						variableNames[activeVariableNameIdx].toLocaleLowerCase();
		});

		if (columnToMatch && !variable) {
			if (
				variableNames[activeVariableNameIdx].toLocaleLowerCase() ===
				columnToMatch.name.toLocaleLowerCase()
			) {
				variable = columnToMatch as PreviewVariable;
			}
		}
		if (isImportVariableSet && columnToMatchWithMainLevel && !variable) {
			if (
				variableNames[activeVariableNameIdx].toLocaleLowerCase() ===
				columnToMatchWithMainLevel.name.toLocaleLowerCase()
			) {
				variable = columnToMatchWithMainLevel as PreviewVariable;
			}
		}

		if (!variable) {
			return null;
		}

		return variable;
	}, [activeVariableNameIdx, formattedVariables, variableNames]);

	const infoMessageData = useMemo(() => {
		if (!Object.keys(errorMap).length) {
			return [];
		}

		const data: string[] = [];

		const columnCount = Object.keys(errorMap).length;

		data.push(columnCount.toString());
		if (columnCount === 1) {
			data.push(translate(dict => dict.import.importReview.column));
			data.push(translate(dict => dict.varGroup.variable));
		} else {
			data.push(translate(dict => dict.import.importReview.columns));
			data.push(translate(dict => dict.varGroup.variables));
		}

		return data;
	}, [errorMap]);

	function handlePreviousVariable() {
		if (activeVariableNameIdx === null || activeVariableNameIdx === 0) {
			return;
		}

		setActiveVariableNameIdx(activeVariableNameIdx - 1);
	}

	function handleNextVariable() {
		if (activeVariableNameIdx === null || activeVariableNameIdx === variableNames.length - 1) {
			return;
		}

		setActiveVariableNameIdx(activeVariableNameIdx + 1);
	}

	function updateVariable(variable: PreviewVariable) {
		if (!formattedVariables || !setFormattedVariables) {
			return;
		}
		const newVariables = formattedVariables.map(formattedVariable => {
			if (formattedVariable.id === variable.id) {
				return variable;
			}

			return formattedVariable;
		});

		setShowContinueWithMapping(true);
		setFormattedVariables(newVariables);
	}

	function handleDateTypeUpdate(vID: string, value: string) {
		if (!setFormattedVariables || !formattedVariables) {
			return;
		}
		setShowContinueWithMapping(true);
		handleVariableDateTypeChange(
			vID,
			value,
			formattedVariables,
			setFormattedVariables,
			translate,
			globalTimezone
		);
	}

	function handleCustomDateTypeUpdate(vID: string, value: string, isDatetime?: boolean) {
		if (!setFormattedVariables || !formattedVariables) {
			return;
		}

		handleCustomDateFormatChange(
			vID,
			value,
			formattedVariables,
			setFormattedVariables,
			translate,
			isDatetime
		);
		setShowContinueWithMapping(true);
	}

	function handleDurationFormatUpdate(vID: string, item: SelectItem) {
		handleDurationFormatChange(vID, formattedVariables, item, setFormattedVariables, translate);

		if (!showContinueWithMapping && !getHasErrors(formattedVariables, isBinary)) {
			setShowContinueWithMapping(true);
		}
	}

	function handleSourceUnitUpdate(vID: string, value: string) {
		handleSourceUnitChange(vID, value, formattedVariables, setFormattedVariables, translate);

		if (!showContinueWithMapping && !getHasErrors(formattedVariables, isBinary)) {
			setShowContinueWithMapping(true);
		}
	}

	function handleContinueWithMapping() {
		if (!selectedOption) return;
		resetImportPercentage();

		if ([ImportType.ReplaceAll, ImportType.Now].includes(selectedOption)) {
			uploadReplaceAll();
		}

		if (selectedOption === ImportType.MoreEntriesToDataset) {
			uploadEntriesToDataset();
		}

		if (selectedOption === ImportType.MoreDataToExistingEntries) {
			uploadDataToEntries();
		}
	}

	function canUploadReplaceAll() {
		if (isImportVariableSet) {
			return (
				getHasErrors(formattedVariables, isBinary) ||
				!variableToMatchOnMainLevel ||
				!columnToMatchWithMainLevel
			);
		}

		return getHasErrors(formattedVariables, isBinary);
	}

	function showOptionToFix() {
		if (!selectedOption) return false;

		if (
			activeVariable &&
			(errorMap[activeVariable.label]?.errorsByType[ApiImportErrorType.NoMatchingEntry] ||
				errorMap[activeVariable.label]?.errorsByType[
					ApiImportErrorType.DuplicateMatchingEntry
				] ||
				errorMap[activeVariable.label]?.errorsByType[
					ApiImportErrorType.DuplicateMatchingValue
				])
		) {
			return false;
		}

		if (isAddMoreEntries && activeVariable) {
			return (
				activeVariable.isNew ||
				(activeVariable &&
					(errorMap[activeVariable.label]?.errorsByType[
						ApiImportErrorType.NonExistentDatetime
					] ||
						errorMap[activeVariable.label]?.errorsByType[
							ApiImportErrorType.DatetimeExistsTwice
						] ||
						errorMap[activeVariable.label]?.errorsByType[
							ApiImportErrorType.ImportCouldNotParseValue
						]))
			);
		}

		return true;
	}

	return (
		<>
			<NarrowContainer>
				<MessageContainer>
					<Icon
						svg={Svgs.Information}
						size={s => s.l}
						open={false}
						colors={{ color: Colors.text.errorHover }}
						style={{ display: 'block' }}
					/>
					<div>
						{dynamicTranslate(
							translate(dict => dict.import.importReview.infoMessage),
							infoMessageData,
							{ fontWeight: 'bold' }
						)}
					</div>
				</MessageContainer>
				{showOptionToFix() && (
					<>
						<Spacer size={s => s.l} />
						<Typography.Paragraph style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
							{translate(dict => dict.import.importReview.fixOption.title)}
						</Typography.Paragraph>
						<FixOption
							variable={activeVariable}
							updateVariable={updateVariable}
							handleDateTypeUpdate={handleDateTypeUpdate}
							handleCustomDateTypeUpdate={handleCustomDateTypeUpdate}
							handleDurationFormatUpdate={handleDurationFormatUpdate}
							handleSourceUnitUpdate={handleSourceUnitUpdate}
							selectedOption={selectedOption!}
						/>
					</>
				)}

				<Spacer size={s => s.l} />

				<Container>
					<VariableErrorPagination
						previousDisabled={activeVariableNameIdx === 0}
						nextDisabled={activeVariableNameIdx === variableNames.length - 1}
						handleNext={handleNextVariable}
						handlePrevious={handlePreviousVariable}
					/>

					{errorMap &&
						errorCountMap &&
						activeVariableNameIdx !== null &&
						selectedOption &&
						activeVariable && (
							<ErrorTableWrapper
								activeVariable={activeVariable}
								selectedOption={selectedOption}
							>
								<ImportErrorsTable
									errorData={errorMap[variableNames[activeVariableNameIdx]]}
									countData={errorCountMap[variableNames[activeVariableNameIdx]]}
								/>
							</ErrorTableWrapper>
						)}
				</Container>
			</NarrowContainer>
			<StickyFooter
				neutral={{
					label: translate(({ buttons }) => buttons.back),
					onClick: handleBack
				}}
				primary={
					selectedOption &&
					showOptionToFix() &&
					showContinueWithMapping &&
					[
						ImportType.ReplaceAll,
						ImportType.Now,
						ImportType.MoreEntriesToDataset,
						ImportType.MoreDataToExistingEntries
					].includes(selectedOption)
						? {
								label: translate(
									dict => dict.import.importReview.buttons.continueWithMapping
								),
								disabled: canUploadReplaceAll(),
								onClick: () => handleContinueWithMapping()
						  }
						: undefined
				}
				danger={
					selectedOption &&
					[
						ImportType.ReplaceAll,
						ImportType.Now,
						ImportType.MoreEntriesToDataset,
						ImportType.MoreDataToExistingEntries
					].includes(selectedOption)
						? {
								label: translate(
									dict => dict.import.importReview.buttons.reuploadWithChanges
								),
								onClick: handleReupload
						  }
						: undefined
				}
				styleOptions={{
					dangerButtonType: ButtonVariants.outline
				}}
				maxWidth={65.2}
			/>
		</>
	);
}
