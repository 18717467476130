import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { TimeDurationVariable } from '../types';
import { TimeDurationInput } from './time-duration/TimeDurationInput';

interface Props {
	variable: TimeDurationVariable;
	control: FormControl;
	onError: (name: string, error: string | undefined) => void;
}

export const TimeDurationVariableInput = ({ control, variable, onError }: Props) => {
	const { field, fieldState } = useController({
		name: variable.variableName,
		control
	});

	return (
		<TimeDurationInput
			value={field.value}
			maxTimeUnit={variable.durationFormat.maxTimeUnit}
			minTimeUnit={variable.durationFormat.minTimeUnit}
			label={variable.variableLabel}
			required={variable.obligatory}
			onChange={field.onChange}
			onBlur={field.onBlur}
			onError={error => onError(field.name, error)}
			error={fieldState.error?.message}
			ref={field.ref}
			description={variable.description}
		/>
	);
};
