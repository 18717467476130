import { useState, useEffect } from 'react';
import { makeRequest } from './util';
import { VariableGroup, Variable } from '../types';

type Order = { variable: string; group?: never } | { group: string; variable?: never };

export type VariablesData = {
	variables: Record<string, Variable>;
	order: Order[];
	groups: Record<string, VariableGroup>;
};

export const useVariablesQuery = ({ projectId }: { projectId: string }) => {
	const [data, setData] = useState<VariablesData>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error>();
	const [fetching, setFetching] = useState(false);

	async function fetch() {
		setFetching(true);

		try {
			const result: VariablesData = await makeRequest({
				method: 'getVariablesData',
				service: 'stats',
				data: {
					projectId: parseInt(projectId)
				}
			});

			setData(result);
		} catch (e) {
			setError(e as Error);

			console.error(e);
		} finally {
			setLoading(false);
			setFetching(false);
		}
	}

	useEffect(() => {
		fetch();
	}, []);

	return { data, loading, error, fetching, refetch: fetch };
};
