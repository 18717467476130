import { useCallback, useState } from 'react';
import { Modal } from 'components/UI/Modal';
import {
	OpenCustomsMapChecker,
	AddEditInputsAndGroupsFormDesigner,
	DependenciesMapChecker
} from 'components/Dataset/AddEditForm';
import {
	VariableFilteringMap,
	FormFieldPageLocationByName,
	FormGroupPaginationDataByname,
	SetGroupPaginationDataInput
} from 'store/data/entries';
import { Form } from 'store/data/forms';
import { BooleanMap } from 'types/index';
import {
	useTranslation,
	useVariablesData,
	useEntriesForm,
	useEntryGoToFirstFieldError,
	useEntryFormGroupsState
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useStatic } from 'hooks/utils';
import { FormWrapper } from './PreviewFormWrapper.style';

interface Props {
	form: Form;
	onClose: () => void;
}

export function PreviewFormModal({ form, onClose }: Props) {
	const { translate } = useTranslation();

	const variablesData = useVariablesData({ initial: true });

	const { values: initialValues, validationSchema } = useEntriesForm();
	const { goToFirstFieldError } = useEntryGoToFirstFieldError({ form });

	const [tooltipContainer, setTooltipContainer] = useState<HTMLDivElement | null>(null);

	const getTooltipRef = useCallback((node: HTMLDivElement | null) => {
		if (node) setTooltipContainer(node);
	}, []);

	const [openCustomsMap, setOpenCustomsMap] = useState<BooleanMap>({});
	const [variableVisibilityMap, setVariableVisibilityMap] = useState<BooleanMap>({});
	const [variableFilteringMap, setVariableFilteringMap] = useState<VariableFilteringMap>({});

	const [getFormFieldPageLocationByName, setFormFieldPageLocationByName] =
		useStatic<FormFieldPageLocationByName>({});
	const [getGroupPaginationDataByName, setGroupPaginationDataByName] =
		useStatic<FormGroupPaginationDataByname>({});

	const { Form, FormProvider, handleSubmit, formProviderProps, errors, isSubmitting } =
		useReactForm({
			initialValues,
			validationSchema,
			// set `shouldFocusError` to `false` to enable custom scroll-into-view with sorted errors
			shouldFocusError: false,
			enableReinitialize: true,
			registerInitialValues: true
		});

	const { groupsExpanded, setGroupExpanded } = useEntryFormGroupsState({
		errors,
		isSubmitting
	});

	const handleOnSubmit = handleSubmit(
		// successful submit => no errors
		() => null,
		// failed submit => has errors
		errors => {
			goToFirstFieldError({
				fieldNames: Object.keys(errors),
				formFieldPageLocationByName: getFormFieldPageLocationByName()
			});
		}
	);

	function setGroupPaginationData(input: SetGroupPaginationDataInput) {
		const { groupName, paginationData } = input;

		setGroupPaginationDataByName(state => {
			const groupPaginationData = state[groupName];
			const { pageIndex, pageSize } = paginationData;

			if (groupPaginationData) {
				if (pageIndex !== undefined) groupPaginationData.pageIndex = pageIndex;
				if (pageSize !== undefined) groupPaginationData.pageSize = pageSize;
			} else {
				state[groupName] = {
					pageIndex: pageIndex !== undefined ? pageIndex : 0,
					pageSize: pageSize !== undefined ? pageSize : 10
				};
			}
		});
	}

	return (
		<Modal
			size={s => s.full}
			// primary={buttonProps.primary}
			// neutral={buttonProps.neutral}
			primary={{
				label: translate(dict => dict.buttons.done),
				onClick: onClose
			}}
			fullSizeConfig={{
				narrow: true
			}}
			bodySpacing={false}
			onClose={onClose}
			visible
			close
			customBodyStyle={{ padding: '0 2rem', maxWidth: 'unset' }}
		>
			<FormWrapper ref={getTooltipRef}>
				<FormProvider {...formProviderProps}>
					<OpenCustomsMapChecker
						openCustomsMap={openCustomsMap}
						setOpenCustomsMap={setOpenCustomsMap}
					/>
					<DependenciesMapChecker
						variableVisibilityMapState={{
							variableVisibilityMap,
							setVariableVisibilityMap
						}}
						variableFilteringMapState={{
							variableFilteringMap,
							setVariableFilteringMap
						}}
						customFormContext={formProviderProps}
					/>

					<Form onSubmit={handleOnSubmit}>
						<AddEditInputsAndGroupsFormDesigner
							form={form}
							initialValues={initialValues}
							tooltipContainer={tooltipContainer ?? undefined}
							variablesData={variablesData}
							openCustomsMap={openCustomsMap}
							groupsExpanded={groupsExpanded}
							variableVisibilityMap={variableVisibilityMap}
							variableFilteringMap={variableFilteringMap}
							setGroupExpanded={setGroupExpanded}
							setFormFieldPageLocationByName={setFormFieldPageLocationByName}
							getGroupPaginationDataByName={getGroupPaginationDataByName}
							setGroupPaginationData={setGroupPaginationData}
						/>
					</Form>
				</FormProvider>
			</FormWrapper>
		</Modal>
	);
}
