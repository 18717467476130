import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import { useTranslation, useVariablesData } from 'hooks/store';
import { DynamicFormValue, DynamicFormValues } from 'store/data/entries';
import { Nullable, SelectItem } from 'types/index';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import {
	entryFormDependenciesCheckEvent,
	entryFormReadOnlyModalEvent,
	setEntryFormFieldValue,
	withCustomSuffix
} from 'helpers/entries';
import { FormElementDisplayType } from 'store/data/forms';

interface Option {
	label: string;
	value: string;
}

interface DropdownInputProps {
	name: string;
	categories: string[];
	displayType?: string;
	options: Option[];
	required: boolean;
	disabled: boolean;
	readOnly?: boolean;
	allowCreate?: boolean;
	hasMultipleValues?: boolean;
	borderError?: boolean;
	value?: DynamicFormValue;
	formContext?: UseFormReturn<DynamicFormValues>;
	isItemDisabled?: (item: SelectItem) => boolean;
}

export function DropdownInput({
	name,
	categories,
	displayType,
	options,
	required,
	disabled,
	readOnly,
	allowCreate,
	hasMultipleValues,
	borderError,
	value: _value,
	formContext,
	isItemDisabled
}: DropdownInputProps) {
	const { translate } = useTranslation();

	const { variablesMap } = useVariablesData();

	// USED SERIES FIELD PREVIEW
	if (_value) {
		return (
			<CreatableSelect
				placeholder={translate(({ radioGroups }) =>
					required ? radioGroups.valueRequired : radioGroups.noSelection
				)}
				dropdownIconTestId={`${name}-dropdown-icon`}
				value={computeDropdownValue(options, _value as string)}
				isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
				items={options}
				{...(hasMultipleValues && {
					values: (_value as string[]).map(value => ({ label: value, value }))
				})}
			/>
		);
	}

	// USED IN FORM DESIGNER
	if (!formContext) {
		return (
			<CreatableSelect
				placeholder={translate(({ radioGroups }) =>
					required ? radioGroups.valueRequired : radioGroups.noSelection
				)}
				dropdownIconTestId={`${name}-dropdown-icon`}
				items={options}
				isItemDisabled={value => (isItemDisabled ? isItemDisabled(value) : false)}
				disabled={disabled}
			/>
		);
	}

	// USED IN ADD/EDIT FORM
	const {
		getValues,
		setValue,
		formState: { errors }
	} = formContext;

	function handleSetValue(name: string, value: DynamicFormValue) {
		// CHECK IF USER HAS ACCESS TO CHANGE THE VALUE
		if (readOnly) {
			entryFormReadOnlyModalEvent().dispatch(true);
			return;
		}

		setEntryFormFieldValue(name, value, setValue);
		const fieldNames = Object.keys(variablesMap);
		entryFormDependenciesCheckEvent().dispatch({ fieldNames });
	}

	function isCustomValue(value: string) {
		return !categories.includes(value);
	}

	const initialCustomValue = getValues(withCustomSuffix(name)) as string | undefined;

	const error = (errors[name] as FieldError)?.message;

	function getDropdownMultipleValuesProps(
		name: string,
		values: string[],
		onBlur: () => void,
		hasMultipleValues?: boolean
	) {
		if (!hasMultipleValues) return;

		const props = {
			values: values.map(value => ({
				label:
					displayType === FormElementDisplayType.LABELS
						? options.find(item => item.value === value)?.label ?? value
						: value,
				value
			})),
			onValuesSelected: (newValues: string[]) => {
				handleSetValue(name, newValues);
				onBlur();
			},
			hasMultipleValues: true,
			allowCreateOnlyOne: true
		};

		return props;
	}

	function computeDropdownValue(items: SelectItem[], value: string, customValue?: string) {
		if (customValue) {
			return {
				label: customValue,
				value: customValue
			};
		}

		if (value !== '') return items.find(item => item.value === value);

		return null;
	}

	function computeDropdownItems(items: SelectItem[], customValue?: string) {
		if (customValue) {
			return [
				...items,
				{
					label: customValue,
					value: customValue
				}
			];
		}

		return items;
	}

	function handleSetDropdownValue(value: Nullable<string>, newValue: Nullable<string>) {
		// VALUE SELECTED
		if (newValue) {
			// CUSTOM VALUE SELECTED
			if (isCustomValue(newValue)) {
				// CLEAR MAIN VALUE
				if (value) handleSetValue(name, '');

				/*
				 * Used to clear the last custom value when creating another one
				 * and to re-render the component
				 */
				handleSetValue(withCustomSuffix(name), '');
				handleSetValue(withCustomSuffix(name), newValue);
			}
			// MAIN VALUE SELECTED
			else {
				// CLEAR CUSTOM VALUE
				if (initialCustomValue) {
					handleSetValue(withCustomSuffix(name), '');
				}

				handleSetValue(name, newValue);
			}
		}
		// CLEAR VALUE
		else {
			if (value) handleSetValue(name, '');
			if (initialCustomValue) {
				handleSetValue(withCustomSuffix(name), '');
			}
		}
	}

	return (
		<Controller
			name={name}
			defaultValue={hasMultipleValues ? [] : ''}
			render={({ field: { value, onBlur } }) => (
				<CreatableSelect
					placeholder={translate(({ radioGroups }) =>
						required ? radioGroups.valueRequired : radioGroups.noSelection
					)}
					value={computeDropdownValue(
						computeDropdownItems(options, initialCustomValue),
						value,
						initialCustomValue
					)}
					items={computeDropdownItems(options, initialCustomValue)}
					disabled={disabled}
					isItemDisabled={() => (isItemDisabled ? isItemDisabled(value) : false)}
					error={error}
					borderError={borderError}
					allowCreate={allowCreate}
					onValueSelected={newValue => {
						handleSetDropdownValue(value, newValue);
						onBlur();
					}}
					dropdownIconTestId={`${name}-dropdown-icon`}
					onBlur={onBlur}
					scrollIntoView
					{...getDropdownMultipleValuesProps(name, value, onBlur, hasMultipleValues)}
					tooltipPlace="right"
				/>
			)}
		/>
	);
}
