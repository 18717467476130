import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	ClearSnapshotAction,
	CreateSnapshotAction,
	DeleteSnapshotAction,
	GetSnapshotAction,
	GetSnapshotsAction,
	SetActiveSnapshotAction,
	UpdateSnapshotAction
} from './types';
import { deleteAnalysesAction } from '../analyses';
import { deleteJADBioAnalyses, extractJADBioAnalysesIds } from 'store/addons/jadbio';
import {
	ComparePairedAnalysis,
	ComparePairedDataModels,
	CorrelationsAnalysis,
	CrosstabAnalysis,
	CrosstabAnalysisV2,
	LogisticRegressionAnalysis,
	PlotNumericAnalysis,
	CompareNumericAnalysisV2,
	CompareNumericAnalysisV1,
	KaplanMeierAnalysis,
	DensityPlotAnalysis,
	ExploreAnalysis,
	FrequenciesAnalysis,
	KaplanMeierDataModels,
	ExploreAnalysisV2,
	AnalysisVariable,
	CorrelationsV1Analysis,
	DensityPlotAnalysisV2,
	TimeCourseAnalysisV2,
	TimeCourseAnalysisV1,
	PlotNumericAnalysisV2,
	LogisticRegressionAnalysisV2,
	FrequenciesAnalysisV2,
	KaplanMeierAnalysisV2,
	GetKaplanMeierDurationRequestInputV2,
	GetLogRankDurationStatisticsV2,
	GetLogRankTimeRangeStatisticsV2,
	ComparePairedAnalysisV2,
	GetKaplanMeierTimeRangeRequestInputV2,
	DataModel,
	KaplanMeierVariablesV2,
	AnalysisErrorBarType,
	AnalysisStatisticAggregationType,
	AnalysisV2,
	AnalysisV1,
	NumberPlotXYAnalysis
} from 'api/data/analyses/types';
import { Dictionary } from 'environment';
import { AnalysisType } from 'api/data/analyses/constants';
import { parseApiEntryFilters } from 'helpers/analysis';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	buildVariablesDataFromStoreData,
	buildVariablesDataLocation
} from 'helpers/variables';
import { parseAnalysisInput } from 'helpers/analysis/parseAnalysisInput';

const ERROR_BAR_TO_ANALYSIS_STATISTIC_AGGREGATION_TYPE: Record<
	AnalysisErrorBarType,
	AnalysisStatisticAggregationType
> = {
	[AnalysisErrorBarType.Mean]: AnalysisStatisticAggregationType.Mean,
	[AnalysisErrorBarType.MeanCI]: AnalysisStatisticAggregationType.MeanCI,
	[AnalysisErrorBarType.MeanRange]: AnalysisStatisticAggregationType.MeanRange,
	[AnalysisErrorBarType.MeanSD]: AnalysisStatisticAggregationType.MeanSD,
	[AnalysisErrorBarType.Median]: AnalysisStatisticAggregationType.Median,
	[AnalysisErrorBarType.MedianCI]: AnalysisStatisticAggregationType.MedianCI,
	[AnalysisErrorBarType.MedianIQR]: AnalysisStatisticAggregationType.MedianIQR,
	[AnalysisErrorBarType.MedianRange]: AnalysisStatisticAggregationType.MedianRange
};

export const getSnapshotAction = (
	payload: ActionPayload<GetSnapshotAction>
): GetSnapshotAction => ({
	type: ActionTypes.GET_SNAPSHOT,
	payload
});

export const getSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				const data = await context.api.data.snapshots().getSnapshot(snapshotId, projectId);
				const {
					variables: {
						byProjectId: {
							[projectId]: { current: variablesStoreData }
						}
					}
				} = getState().data;

				const variablesData = buildVariablesDataFromStoreData(variablesStoreData);
				const { variablesLocation } = buildVariablesDataLocation(variablesData);

				// TODO: refactor when BE resolves this issue
				try {
					const variablesStoreData =
						getState().data.variables.byProjectId[projectId].current;
					const { variablesMap, variableSetsMap } =
						buildVariablesDataFromStoreData(variablesStoreData);

					const aggRuleNameToAggRuleMap =
						buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

					const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
						(acc, [ruleName, rule]) => {
							return {
								...acc,
								[ruleName]: variablesMap[rule.aggregator.variableName]
							};
						},
						{}
					);

					const filters = parseApiEntryFilters(data.filters, {
						...variablesMap,
						...aggRuleToVariableMap
					});

					const analysesPromises = await Promise.allSettled(
						data.analysisList.map(async (a: AnalysisV1 | AnalysisV2) => {
							if (a.type === AnalysisType.CompareNumericV2) {
								const oldCompareNumericAnalysis = a as CompareNumericAnalysisV2;
								const {
									categoryVariable,
									categoryVariableTwo,
									exploreVariable,
									exploreVariableTwo
								} = oldCompareNumericAnalysis.input.variables;

								const compareNumericAnalysisDataset = await context.api.data
									.analyses()
									.getCompareNumericV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										groupVariables:
											categoryVariableTwo && categoryVariable
												? [categoryVariable, categoryVariableTwo]
												: categoryVariable
												? [categoryVariable]
												: [],
										numericVariables:
											exploreVariableTwo && exploreVariable
												? [exploreVariable, exploreVariableTwo]
												: exploreVariable
												? [exploreVariable]
												: [],
										filters
									});

								const {
									independentV2,
									independent,
									kruskalV2,
									kruskal,
									mannWhitneyV2,
									mannWhitney,
									oneWayAnovaV2,
									oneWayAnova,
									oneWayManovaV2,
									oneWayManova,
									shapiroV2,
									shapiro,
									tukeyV2,
									tukey,
									twoWayAnovaV2,
									twoWayAnova,
									twoWayManovaV2,
									twoWayManova
								} = oldCompareNumericAnalysis.input.statistics;

								oldCompareNumericAnalysis.output.dataset =
									compareNumericAnalysisDataset;

								if (
									(independentV2 || independent) &&
									categoryVariable &&
									exploreVariable
								) {
									const independentStatistic = await context.api.data
										.analyses()
										.getIndependentStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.independent =
										independentStatistic;
								}

								if ((kruskalV2 || kruskal) && exploreVariable && categoryVariable) {
									const kruskalStatistic = await context.api.data
										.analyses()
										.getKruskalStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.kruskal =
										kruskalStatistic;
								}

								if (
									(mannWhitneyV2 || mannWhitney) &&
									exploreVariable &&
									categoryVariable
								) {
									const mannWhitneyStatistic = await context.api.data
										.analyses()
										.getMannWhitneyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.mannWhitney =
										mannWhitneyStatistic;
								}

								if (
									(oneWayAnovaV2 || oneWayAnova) &&
									exploreVariable &&
									categoryVariable
								) {
									const oneWayAnovaStatistic = await context.api.data
										.analyses()
										.getOneWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariables: [categoryVariable],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.oneWayAnova =
										oneWayAnovaStatistic;
								}

								if (
									(oneWayManovaV2 || oneWayManova) &&
									exploreVariableTwo &&
									exploreVariable &&
									categoryVariable
								) {
									const oneWayManovaStatistic = await context.api.data
										.analyses()
										.getOneWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable],
											numericVariables: [
												exploreVariable,
												exploreVariableTwo
											] as AnalysisVariable[],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.oneWayManova =
										oneWayManovaStatistic;
								}

								if (
									(shapiroV2 || shapiro) &&
									categoryVariableTwo &&
									exploreVariableTwo &&
									categoryVariable &&
									exploreVariable
								) {
									const shapiroStatistic = await context.api.data
										.analyses()
										.getShapiroStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariables: [exploreVariable, exploreVariableTwo],
											groupVariables: [categoryVariable, categoryVariableTwo],

											filters
										});

									oldCompareNumericAnalysis.output.statistics.shapiro =
										shapiroStatistic;
								}

								if ((tukeyV2 || tukey) && exploreVariable && categoryVariable) {
									const tukeyStatistic = await context.api.data
										.analyses()
										.getTukeyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariable: exploreVariable,
											groupVariable: categoryVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.tukey =
										tukeyStatistic;
								}

								if (
									(twoWayAnovaV2 || twoWayAnova) &&
									categoryVariable &&
									categoryVariableTwo &&
									exploreVariable
								) {
									const twoWayAnovaStatistic = await context.api.data
										.analyses()
										.getTwoWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariable: exploreVariable,
											filters
										});

									oldCompareNumericAnalysis.output.statistics.twoWayAnova =
										twoWayAnovaStatistic;
								}

								if (
									(twoWayManovaV2 || twoWayManova) &&
									categoryVariableTwo &&
									exploreVariableTwo &&
									exploreVariable &&
									categoryVariable
								) {
									const twoWayManovaStatistic = await context.api.data
										.analyses()
										.getTwoWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									oldCompareNumericAnalysis.output.statistics.twoWayManova =
										twoWayManovaStatistic;
								}

								return oldCompareNumericAnalysis;
							}

							if (a.type === AnalysisType.CompareNumericV1) {
								const oldCompareNumericAnalysis = {
									...a,
									type: AnalysisType.CompareNumericV1
								} as CompareNumericAnalysisV1;

								const {
									categoryVariable: oldCategoryVariable,
									categoryVariableTwo: oldCategoryVariableTwo,
									exploreVariable: oldExploreVariable,
									exploreVariableTwo: oldExploreVariableTwo
								} = oldCompareNumericAnalysis.input.variables;

								const newAnalysis: CompareNumericAnalysisV2 = {
									...oldCompareNumericAnalysis,
									type: AnalysisType.CompareNumericV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											categoryVariable: oldCategoryVariable
												? {
														name: oldCategoryVariable
												  }
												: null,
											categoryVariableTwo: oldCategoryVariableTwo
												? {
														name: oldCategoryVariableTwo
												  }
												: null,
											exploreVariable: oldExploreVariable
												? {
														name: oldExploreVariable
												  }
												: null,
											exploreVariableTwo: oldExploreVariableTwo
												? {
														name: oldExploreVariableTwo
												  }
												: null
										},
										// implementation error in v1 now leads to 2 flags, not sure which one is relevant, but if `v1` suffixed one is ever used the checks below ensure that the outcome is correct
										statistics: {
											independentV2:
												oldCompareNumericAnalysis.input.statistics
													.independent ||
												oldCompareNumericAnalysis.input.statistics
													.independentV1,
											kruskalV2:
												oldCompareNumericAnalysis.input.statistics
													.kruskal ||
												oldCompareNumericAnalysis.input.statistics
													.kruskalV1,
											mannWhitneyV2:
												oldCompareNumericAnalysis.input.statistics
													.mannWhitney ||
												oldCompareNumericAnalysis.input.statistics
													.mannWhitneyV1,
											oneWayAnovaV2:
												oldCompareNumericAnalysis.input.statistics
													.oneWayAnova ||
												oldCompareNumericAnalysis.input.statistics
													.oneWayAnovaV1,
											twoWayAnovaV2:
												oldCompareNumericAnalysis.input.statistics
													.twoWayAnova ||
												oldCompareNumericAnalysis.input.statistics
													.twoWayAnovaV1,
											oneWayManovaV2:
												oldCompareNumericAnalysis.input.statistics
													.oneWayAnova ||
												oldCompareNumericAnalysis.input.statistics
													.oneWayAnovaV1,
											tukeyV2:
												oldCompareNumericAnalysis.input.statistics.tukey ||
												oldCompareNumericAnalysis.input.statistics.tukeyV1,
											twoWayManovaV2:
												oldCompareNumericAnalysis.input.statistics
													.twoWayManova ||
												oldCompareNumericAnalysis.input.statistics
													.twoWayManovaV1,
											shapiroV2:
												oldCompareNumericAnalysis.input.statistics
													.shapiro ||
												oldCompareNumericAnalysis.input.statistics.shapiroV1
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											independent: { data: null },
											kruskal: { data: null },
											mannWhitney: { data: null },
											oneWayAnova: { data: null },
											oneWayManova: { data: null },
											shapiro: { data: null },
											tukey: { data: null },
											twoWayAnova: { data: null },
											twoWayManova: { data: null }
										}
									}
								};

								const {
									input: {
										variables: {
											categoryVariable,
											categoryVariableTwo,
											exploreVariable,
											exploreVariableTwo
										}
									}
								} = newAnalysis as CompareNumericAnalysisV2;

								const compareNumericAnalysisDataset = await context.api.data
									.analyses()
									.getCompareNumericV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										groupVariables: [
											...(categoryVariable && categoryVariableTwo
												? [categoryVariable, categoryVariableTwo]
												: categoryVariable
												? [categoryVariable]
												: categoryVariableTwo
												? [categoryVariableTwo]
												: [])
										],
										numericVariables: [
											...(exploreVariable && exploreVariableTwo
												? [exploreVariable, exploreVariableTwo]
												: exploreVariable
												? [exploreVariable]
												: exploreVariableTwo
												? [exploreVariableTwo]
												: [])
										],
										filters
									});

								const {
									independentV2,
									kruskalV2,
									mannWhitneyV2,
									oneWayAnovaV2,
									oneWayManovaV2,
									shapiroV2,
									tukeyV2,
									twoWayAnovaV2,
									twoWayManovaV2
								} = newAnalysis.input.statistics;

								newAnalysis.output.dataset = compareNumericAnalysisDataset;

								if (independentV2 && categoryVariable && exploreVariable) {
									const independentStatistic = await context.api.data
										.analyses()
										.getIndependentStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.independent =
										independentStatistic;
								}

								if (kruskalV2 && exploreVariable && categoryVariable) {
									const kruskalStatistic = await context.api.data
										.analyses()
										.getKruskalStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.kruskal = kruskalStatistic;
								}

								if (mannWhitneyV2 && exploreVariable && categoryVariable) {
									const mannWhitneyStatistic = await context.api.data
										.analyses()
										.getMannWhitneyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.mannWhitney =
										mannWhitneyStatistic;
								}

								if (
									oneWayAnovaV2 &&
									exploreVariable &&
									categoryVariable &&
									categoryVariableTwo
								) {
									const oneWayAnovaStatistic = await context.api.data
										.analyses()
										.getOneWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.oneWayAnova =
										oneWayAnovaStatistic;
								}

								if (
									oneWayManovaV2 &&
									exploreVariable &&
									exploreVariableTwo &&
									categoryVariable
								) {
									const oneWayManovaStatistic = await context.api.data
										.analyses()
										.getOneWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									newAnalysis.output.statistics.oneWayManova =
										oneWayManovaStatistic;
								}

								if (
									shapiroV2 &&
									categoryVariable &&
									categoryVariableTwo &&
									exploreVariable &&
									exploreVariableTwo
								) {
									const shapiroStatistic = await context.api.data
										.analyses()
										.getShapiroStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									newAnalysis.output.statistics.shapiro = shapiroStatistic;
								}

								if (tukeyV2 && exploreVariable && categoryVariable) {
									const tukeyStatistic = await context.api.data
										.analyses()
										.getTukeyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.tukey = tukeyStatistic;
								}

								if (
									twoWayAnovaV2 &&
									categoryVariableTwo &&
									categoryVariable &&
									exploreVariable
								) {
									const twoWayAnovaStatistic = await context.api.data
										.analyses()
										.getTwoWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.twoWayAnova =
										twoWayAnovaStatistic;
								}

								if (
									twoWayManovaV2 &&
									exploreVariable &&
									exploreVariableTwo &&
									categoryVariable &&
									categoryVariableTwo
								) {
									const twoWayManovaStatistic = await context.api.data
										.analyses()
										.getTwoWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									newAnalysis.output.statistics.twoWayManova =
										twoWayManovaStatistic;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.ComparePaired) {
								const oldComparePairedAnalysis = a as ComparePairedAnalysis;

								const isSingleEntryPerSubject =
									oldComparePairedAnalysis.input.dataModel ===
									ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT;
								const isMultipleEntryPerSubject =
									oldComparePairedAnalysis.input.dataModel ===
									ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT;
								// OLD key to overriden by new key at some point
								const isUsingSeries =
									oldComparePairedAnalysis.input.dataModel ===
										ComparePairedDataModels.USING_SERIES ||
									oldComparePairedAnalysis.input.dataModel ===
										ComparePairedDataModels.OLD_USING_SERIES;

								const newAnalysis: ComparePairedAnalysisV2 = {
									...oldComparePairedAnalysis,
									type: AnalysisType.ComparePairedV2,
									input: {
										dataModel: isUsingSeries
											? ComparePairedDataModels.USING_SERIES
											: oldComparePairedAnalysis.input.dataModel,
										variables: {
											afterVariable: null,
											beforeVariable: null,
											groupVariable: null,
											numericVariable: null,
											pairIdentifier: null
										},
										statistics: {
											pairedTTestV2:
												oldComparePairedAnalysis.input.statistics
													.pairedTTest,
											pairedWilcoxonV2:
												oldComparePairedAnalysis.input.statistics
													.pairedWilcoxon
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											pairedTTest: {
												data: null
											},
											pairedWilcoxon: {
												data: null
											}
										}
									}
								};

								if (isSingleEntryPerSubject) {
									newAnalysis.input.variables.beforeVariable =
										oldComparePairedAnalysis.input.variables.numericVariableTwo
											? {
													name: oldComparePairedAnalysis.input.variables
														.numericVariableTwo
											  }
											: null;
									newAnalysis.input.variables.afterVariable =
										oldComparePairedAnalysis.input.variables.numericVariableOne
											? {
													name: oldComparePairedAnalysis.input.variables
														.numericVariableOne
											  }
											: null;

									const { beforeVariable, afterVariable } =
										newAnalysis.input.variables;

									if (beforeVariable && afterVariable) {
										const dataset = await context.api.data
											.analyses()
											.getComparePairedV2(
												{
													beforeVariable,
													afterVariable,
													filters,
													projectId: Number(projectId)
												},
												true
											);

										newAnalysis.output.dataset = dataset;
									}
								} else if (isMultipleEntryPerSubject) {
									newAnalysis.input.variables.pairIdentifier =
										oldComparePairedAnalysis.input.variables
											.patientIdentifierVarname
											? {
													name: oldComparePairedAnalysis.input.variables
														.patientIdentifierVarname
											  }
											: null;
									newAnalysis.input.variables.groupVariable =
										oldComparePairedAnalysis.input.variables
											.catVarnameDiffSamples
											? {
													name: oldComparePairedAnalysis.input.variables
														.catVarnameDiffSamples
											  }
											: null;
									newAnalysis.input.variables.numericVariable =
										oldComparePairedAnalysis.input.variables.numericVariableOne
											? {
													name: oldComparePairedAnalysis.input.variables
														.numericVariableOne
											  }
											: null;

									const { numericVariable, groupVariable, pairIdentifier } =
										newAnalysis.input.variables;

									if (numericVariable && groupVariable && pairIdentifier) {
										const dataset = await context.api.data
											.analyses()
											.getComparePairedV2(
												{
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable,
													pairingVariable: pairIdentifier
												},
												false
											);

										newAnalysis.output.dataset = dataset;
									}
								} else {
									newAnalysis.input.series =
										oldComparePairedAnalysis.input.variables.setName;

									newAnalysis.input.variables.numericVariable =
										oldComparePairedAnalysis.input.variables.testVariable
											? {
													name: oldComparePairedAnalysis.input.variables
														.testVariable,
													series: newAnalysis.input.series
											  }
											: null;
									newAnalysis.input.variables.groupVariable =
										oldComparePairedAnalysis.input.variables.groupVariable
											? {
													name: oldComparePairedAnalysis.input.variables
														.groupVariable,
													series: newAnalysis.input.series
											  }
											: null;

									const { numericVariable, groupVariable } =
										newAnalysis.input.variables;

									if (numericVariable && groupVariable) {
										const dataset = await context.api.data
											.analyses()
											.getComparePairedV2(
												{
													projectId: Number(projectId),
													filters,
													groupVariable,
													numericVariable
												},
												false
											);
										newAnalysis.output.dataset = dataset;
									}
								}

								const { pairedTTestV2, pairedWilcoxonV2 } =
									newAnalysis.input.statistics;

								if (pairedTTestV2) {
									if (isSingleEntryPerSubject) {
										newAnalysis.input.variables.beforeVariable =
											oldComparePairedAnalysis.input.variables
												.numericVariableTwo
												? {
														name: oldComparePairedAnalysis.input
															.variables.numericVariableTwo
												  }
												: null;
										newAnalysis.input.variables.afterVariable =
											oldComparePairedAnalysis.input.variables
												.numericVariableOne
												? {
														name: oldComparePairedAnalysis.input
															.variables.numericVariableOne
												  }
												: null;

										const { beforeVariable, afterVariable } =
											newAnalysis.input.variables;

										if (beforeVariable && afterVariable) {
											const pairedTTextStatistic = await context.api.data
												.analyses()
												.getPairedTTestStatisticsV2({
													input: {
														projectId: Number(projectId),
														filters,
														afterVariable,
														beforeVariable
													},
													dataModel:
														oldComparePairedAnalysis.input.dataModel
												});

											newAnalysis.output.statistics.pairedTTest =
												pairedTTextStatistic;
										}
									} else if (isMultipleEntryPerSubject) {
										newAnalysis.input.variables.pairIdentifier =
											oldComparePairedAnalysis.input.variables
												.patientIdentifierVarname
												? {
														name: oldComparePairedAnalysis.input
															.variables.patientIdentifierVarname
												  }
												: null;
										newAnalysis.input.variables.groupVariable =
											oldComparePairedAnalysis.input.variables
												.catVarnameDiffSamples
												? {
														name: oldComparePairedAnalysis.input
															.variables.catVarnameDiffSamples
												  }
												: null;
										newAnalysis.input.variables.numericVariable =
											oldComparePairedAnalysis.input.variables
												.numericVariableOne
												? {
														name: oldComparePairedAnalysis.input
															.variables.numericVariableOne
												  }
												: null;
										const { numericVariable, groupVariable, pairIdentifier } =
											newAnalysis.input.variables;

										if (numericVariable && groupVariable && pairIdentifier) {
											const pairedTTestStatistic = await context.api.data
												.analyses()
												.getPairedTTestStatisticsV2({
													input: {
														projectId: Number(projectId),
														filters,
														groupVariable,
														numericVariable,
														pairingVariable: pairIdentifier
													},
													dataModel:
														oldComparePairedAnalysis.input.dataModel
												});

											newAnalysis.output.statistics.pairedTTest =
												pairedTTestStatistic;
										}
									} else {
										newAnalysis.input.series =
											oldComparePairedAnalysis.input.variables.setName;

										newAnalysis.input.variables.numericVariable =
											oldComparePairedAnalysis.input.variables.testVariable
												? {
														name: oldComparePairedAnalysis.input
															.variables.testVariable,
														series: newAnalysis.input.series
												  }
												: null;
										newAnalysis.input.variables.groupVariable =
											oldComparePairedAnalysis.input.variables.groupVariable
												? {
														name: oldComparePairedAnalysis.input
															.variables.groupVariable,
														series: newAnalysis.input.series
												  }
												: null;

										const { numericVariable, groupVariable } =
											newAnalysis.input.variables;

										if (numericVariable && groupVariable) {
											const pairedTTestStatistic = await context.api.data
												.analyses()
												.getPairedTTestStatisticsV2({
													input: {
														projectId: Number(projectId),
														filters,
														groupVariable,
														numericVariable
													},
													dataModel:
														oldComparePairedAnalysis.input.dataModel
												});

											newAnalysis.output.statistics.pairedTTest =
												pairedTTestStatistic;
										}
									}
								}

								if (pairedWilcoxonV2) {
									if (isSingleEntryPerSubject) {
										newAnalysis.input.variables.beforeVariable =
											oldComparePairedAnalysis.input.variables
												.numericVariableTwo
												? {
														name: oldComparePairedAnalysis.input
															.variables.numericVariableTwo
												  }
												: null;
										newAnalysis.input.variables.afterVariable =
											oldComparePairedAnalysis.input.variables
												.numericVariableOne
												? {
														name: oldComparePairedAnalysis.input
															.variables.numericVariableOne
												  }
												: null;

										const { beforeVariable, afterVariable } =
											newAnalysis.input.variables;

										if (beforeVariable && afterVariable) {
											const pairedWilcoxonStatistic = await context.api.data
												.analyses()
												.getPairedWilcoxonStatisticsV2({
													dataModel:
														oldComparePairedAnalysis.input.dataModel,
													input: {
														projectId: Number(projectId),
														filters,
														beforeVariable,
														afterVariable
													}
												});

											newAnalysis.output.statistics.pairedWilcoxon =
												pairedWilcoxonStatistic;
										}
									} else if (isMultipleEntryPerSubject) {
										newAnalysis.input.variables.pairIdentifier =
											oldComparePairedAnalysis.input.variables
												.patientIdentifierVarname
												? {
														name: oldComparePairedAnalysis.input
															.variables.patientIdentifierVarname
												  }
												: null;
										newAnalysis.input.variables.groupVariable =
											oldComparePairedAnalysis.input.variables
												.catVarnameDiffSamples
												? {
														name: oldComparePairedAnalysis.input
															.variables.catVarnameDiffSamples
												  }
												: null;
										newAnalysis.input.variables.numericVariable =
											oldComparePairedAnalysis.input.variables
												.numericVariableOne
												? {
														name: oldComparePairedAnalysis.input
															.variables.numericVariableOne
												  }
												: null;

										const { numericVariable, groupVariable, pairIdentifier } =
											newAnalysis.input.variables;

										if (numericVariable && groupVariable && pairIdentifier) {
											const pairedWilcoxonStatistic = await context.api.data
												.analyses()
												.getPairedWilcoxonStatisticsV2({
													dataModel:
														oldComparePairedAnalysis.input.dataModel,
													input: {
														projectId: Number(projectId),
														filters,
														numericVariable,
														groupVariable,
														pairingVariable: pairIdentifier
													}
												});

											newAnalysis.output.statistics.pairedWilcoxon =
												pairedWilcoxonStatistic;
										}
									} else {
										newAnalysis.input.series =
											oldComparePairedAnalysis.input.variables.setName;

										newAnalysis.input.variables.numericVariable =
											oldComparePairedAnalysis.input.variables.testVariable
												? {
														name: oldComparePairedAnalysis.input
															.variables.testVariable,
														series: newAnalysis.input.series
												  }
												: null;
										newAnalysis.input.variables.groupVariable =
											oldComparePairedAnalysis.input.variables.groupVariable
												? {
														name: oldComparePairedAnalysis.input
															.variables.groupVariable,
														series: newAnalysis.input.series
												  }
												: null;

										const { numericVariable, groupVariable } =
											newAnalysis.input.variables;

										if (numericVariable && groupVariable) {
											const pairedWilcoxonStatistic = await context.api.data
												.analyses()
												.getPairedWilcoxonStatisticsV2({
													dataModel:
														oldComparePairedAnalysis.input.dataModel,
													input: {
														projectId: Number(projectId),
														filters,
														numericVariable,
														groupVariable
													}
												});

											newAnalysis.output.statistics.pairedWilcoxon =
												pairedWilcoxonStatistic;
										}
									}
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.ComparePairedV2) {
								const oldComparePairedAnalysis = a as ComparePairedAnalysisV2;
								const {
									input: {
										dataModel,
										variables: {
											afterVariable,
											beforeVariable,
											groupVariable,
											numericVariable,
											pairIdentifier
										}
									}
								} = oldComparePairedAnalysis;

								if (
									dataModel ===
										ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
									numericVariable &&
									groupVariable
								) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePairedV2({
											projectId: Number(projectId),
											filters,
											numericVariable,
											groupVariable
										});

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								} else if (
									dataModel ===
										ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
									beforeVariable &&
									afterVariable
								) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePairedV2(
											{
												projectId: Number(projectId),
												filters,
												beforeVariable,
												afterVariable
											},
											true
										);

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								} else if (
									dataModel ===
										ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT &&
									pairIdentifier &&
									numericVariable &&
									groupVariable
								) {
									const comparePairedAnalysis = await context.api.data
										.analyses()
										.getComparePairedV2({
											projectId: Number(projectId),
											filters,
											pairingVariable: pairIdentifier,
											numericVariable,
											groupVariable
										});

									oldComparePairedAnalysis.output.dataset = comparePairedAnalysis;
								}

								const { pairedTTestV2, pairedWilcoxonV2 } =
									oldComparePairedAnalysis.input.statistics;

								if (pairedTTestV2) {
									if (
										dataModel === ComparePairedDataModels.USING_SERIES &&
										numericVariable &&
										groupVariable
									) {
										const pairedTTestStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTestStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										beforeVariable &&
										afterVariable
									) {
										const pairedTTextStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												input: {
													projectId: Number(projectId),
													filters,
													beforeVariable,
													afterVariable
												},
												dataModel
											});

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTextStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										numericVariable &&
										groupVariable &&
										pairIdentifier
									) {
										const pairedTTestStatistic = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable,
													pairingVariable: pairIdentifier
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedTTest =
											pairedTTestStatistic;
									}

									return oldComparePairedAnalysis;
								}

								if (pairedWilcoxonV2) {
									if (
										dataModel === ComparePairedDataModels.USING_SERIES &&
										numericVariable &&
										groupVariable
									) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										beforeVariable &&
										afterVariable
									) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													beforeVariable,
													afterVariable
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										numericVariable &&
										groupVariable &&
										pairIdentifier
									) {
										const pairedWilcoxonStatistic = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable,
													pairingVariable: pairIdentifier
												}
											});

										oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
											pairedWilcoxonStatistic;
									}
								}
							}

							if (a.type === AnalysisType.CorrelationsV2) {
								const oldCorrelationsAnalysis = a as CorrelationsAnalysis;

								const correlationsAnalysis = await context.api.data
									.analyses()
									.getCorrelations({
										projectId: Number(projectId),
										filters,
										...oldCorrelationsAnalysis.input.variables
									});

								oldCorrelationsAnalysis.output.dataset = correlationsAnalysis;

								const { linearRegressionV2, pearsonV2, spearmanV2 } =
									oldCorrelationsAnalysis.input.statistics;

								if (linearRegressionV2) {
									const linearRegressionStatistic = await context.api.data
										.analyses()
										.getLinearRegressionStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.linearRegressionV2 =
										linearRegressionStatistic;
								}

								if (pearsonV2) {
									const pearsonStatistic = await context.api.data
										.analyses()
										.getPearsonStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.pearsonV2 =
										pearsonStatistic;
								}

								if (spearmanV2) {
									const spearmanStatistic = await context.api.data
										.analyses()
										.getSpearmanStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...oldCorrelationsAnalysis.input.variables,
											filters
										});

									oldCorrelationsAnalysis.output.statistics.spearmanV2 =
										spearmanStatistic;
								}

								return oldCorrelationsAnalysis;
							}

							if (a.type === AnalysisType.CorrelationsV1) {
								const old = a as CorrelationsV1Analysis;
								// backwards compatibility
								const oldCorrelationsAnalysis = {
									...old,
									output: {
										...old.output,
										statistics: {
											...old.output.statistics,
											linearRegression:
												old.output.statistics.linearRegression,
											pearson: old.output.statistics.pearson,
											spearman: old.output.statistics.spearman
										} as CorrelationsV1Analysis['output']['statistics']
									}
								} as CorrelationsV1Analysis;

								const newAnalysis: CorrelationsAnalysis = {
									...oldCorrelationsAnalysis,
									type: AnalysisType.CorrelationsV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											xVariable: {
												name: oldCorrelationsAnalysis.input.variables
													.xNumericVariable
											},
											yVariable: {
												name: oldCorrelationsAnalysis.input.variables
													.yNumericVariable
											},
											groupVariables: [
												...(oldCorrelationsAnalysis.input.variables
													.groupingVariable
													? [
															{
																name: oldCorrelationsAnalysis.input
																	.variables.groupingVariable
															}
													  ]
													: [])
											]
										},
										statistics: {
											linearRegressionV2:
												oldCorrelationsAnalysis.input.statistics
													.linearRegression ||
												oldCorrelationsAnalysis.input.statistics
													.linearRegressionV1,
											pearsonV2:
												oldCorrelationsAnalysis.input.statistics.pearson ||
												oldCorrelationsAnalysis.input.statistics.pearsonV1,
											spearmanV2:
												oldCorrelationsAnalysis.input.statistics.spearman ||
												oldCorrelationsAnalysis.input.statistics.spearmanV1
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											linearRegressionV2: {
												data: null
											},
											pearsonV2: {
												data: null
											},
											spearmanV2: {
												data: null
											}
										}
									}
								};
								const { groupVariables, xVariable, yVariable } =
									newAnalysis.input.variables;

								const correlationsAnalysis = await context.api.data
									.analyses()
									.getCorrelations({
										projectId: Number(projectId),
										filters,
										groupVariables,
										xVariable,
										yVariable
									});

								newAnalysis.output.dataset = correlationsAnalysis;

								const { linearRegressionV2, pearsonV2, spearmanV2 } =
									newAnalysis.input.statistics;

								if (linearRegressionV2) {
									const linearRegressionStatistic = await context.api.data
										.analyses()
										.getLinearRegressionStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables,
											xVariable,
											yVariable,
											filters
										});

									newAnalysis.output.statistics.linearRegressionV2 =
										linearRegressionStatistic;
								}

								if (pearsonV2) {
									const pearsonStatistic = await context.api.data
										.analyses()
										.getPearsonStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables,
											xVariable,
											yVariable,
											filters
										});

									newAnalysis.output.statistics.pearsonV2 = pearsonStatistic;
								}

								if (spearmanV2) {
									const spearmanStatistic = await context.api.data
										.analyses()
										.getSpearmanStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables,
											xVariable,
											yVariable,
											filters
										});

									newAnalysis.output.statistics.spearmanV2 = spearmanStatistic;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.Crosstab) {
								const oldCrosstabAnalysis = a as CrosstabAnalysis;

								const { groupingVariable, yVariable } =
									oldCrosstabAnalysis.input.variables;

								const newAnalysis: CrosstabAnalysisV2 = {
									...oldCrosstabAnalysis,
									type: AnalysisType.CrosstabV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											rowVariable: { name: yVariable },
											columnVariable: { name: groupingVariable }
										},
										statistics: {
											chiSquareV2:
												oldCrosstabAnalysis.input.statistics.chiSquare,
											fisherV2: oldCrosstabAnalysis.input.statistics.fisher,
											mcNemarV2: oldCrosstabAnalysis.input.statistics.mcNemar
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											chiSquare: { data: null },
											fisher: { data: null },
											mcNemar: { data: null }
										}
									}
								};

								const { columnVariable, rowVariable } = newAnalysis.input.variables;

								if (columnVariable && rowVariable) {
									const crosstabAnalysis = await context.api.data
										.analyses()
										.getCrosstabV2({
											projectId: Number(projectId),
											filters,
											rowVariable,
											columnVariable
										});

									newAnalysis.output.dataset = crosstabAnalysis;

									const { chiSquareV2, fisherV2, mcNemarV2 } =
										newAnalysis.input.statistics;

									if (chiSquareV2) {
										const chiSquareStatistic = await context.api.data
											.analyses()
											.getChiStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										newAnalysis.output.statistics.chiSquare =
											chiSquareStatistic;
									}

									if (fisherV2) {
										const fisherStatistic = await context.api.data
											.analyses()
											.getFisherStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										newAnalysis.output.statistics.fisher = fisherStatistic;
									}

									if (mcNemarV2) {
										const mcNemarStatistic = await context.api.data
											.analyses()
											.getMcNemarStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												columnVariable,
												rowVariable,
												filters
											});

										newAnalysis.output.statistics.mcNemar = mcNemarStatistic;
									}

									return newAnalysis;
								}
							}

							if (a.type === AnalysisType.CrosstabV2) {
								const oldCrosstabAnalysis = a as CrosstabAnalysisV2;

								const {
									input: {
										variables: { columnVariable, rowVariable },
										statistics: { chiSquareV2, fisherV2, mcNemarV2 }
									}
								} = oldCrosstabAnalysis;

								if (rowVariable && columnVariable) {
									const dataset = await context.api.data
										.analyses()
										.getCrosstabV2({
											projectId: Number(projectId),
											filters,
											rowVariable,
											columnVariable
										});

									oldCrosstabAnalysis.output.dataset = dataset;

									if (chiSquareV2) {
										const chiSquareStatistic = await context.api.data
											.analyses()
											.getChiStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										oldCrosstabAnalysis.output.statistics.chiSquare =
											chiSquareStatistic;
									}

									if (fisherV2) {
										const fisherStatistic = await context.api.data
											.analyses()
											.getFisherStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										oldCrosstabAnalysis.output.statistics.fisher =
											fisherStatistic;
									}

									if (mcNemarV2) {
										const mcNemarStatistic = await context.api.data
											.analyses()
											.getMcNemarStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										oldCrosstabAnalysis.output.statistics.mcNemar =
											mcNemarStatistic;
									}
								}

								return oldCrosstabAnalysis;
							}

							if (a.type === AnalysisType.DensityPlot) {
								const oldDensityPlotAnalysis = a as DensityPlotAnalysis;

								const newAnalysis: DensityPlotAnalysisV2 = {
									...oldDensityPlotAnalysis,
									type: AnalysisType.DensityPlotV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											numericVariable: {
												name: oldDensityPlotAnalysis.input.variables
													.numericVariable
											},
											groupVariables: [
												...(oldDensityPlotAnalysis.input.variables
													.groupingVariable
													? [
															{
																name: oldDensityPlotAnalysis.input
																	.variables.groupingVariable
															}
													  ]
													: [])
											]
										}
									},
									output: {
										dataset: {
											data: null
										}
									}
								};

								const densityPlotAnalysis = await context.api.data
									.analyses()
									.getDensityPlotV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										...newAnalysis.input.variables,
										filters
									});

								newAnalysis.output.dataset = densityPlotAnalysis;

								return newAnalysis;
							}

							if (a.type === AnalysisType.DensityPlotV2) {
								const oldDensityPlotAnalysis = a as DensityPlotAnalysisV2;

								const densityPlotAnalysis = await context.api.data
									.analyses()
									.getDensityPlotV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										...oldDensityPlotAnalysis.input.variables,
										filters
									});

								oldDensityPlotAnalysis.output.dataset = densityPlotAnalysis;

								return oldDensityPlotAnalysis;
							}

							if (a.type === AnalysisType.Explore) {
								const oldExploreAnalysis = a as ExploreAnalysis;

								const newAnalysis: ExploreAnalysisV2 = {
									...oldExploreAnalysis,
									type: AnalysisType.ExploreV2,
									input: {
										dataModel: DataModel.main,
										variables: oldExploreAnalysis.input.variables.map(d => ({
											name: d.variableName
										}))
									},
									output: {
										dataset: { data: null }
									}
								};

								const exploreAnalysis = await context.api.data
									.analyses()
									.getExploreV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										numericVariables: newAnalysis.input.variables,
										filters
									});

								newAnalysis.output.dataset = exploreAnalysis;

								return newAnalysis;
							}

							if (a.type === AnalysisType.ExploreV2) {
								const oldExploreAnalysis = a as ExploreAnalysisV2;

								const exploreAnalysis = await context.api.data
									.analyses()
									.getExploreV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										numericVariables: oldExploreAnalysis.input.variables,
										filters
									});

								oldExploreAnalysis.output.dataset = exploreAnalysis;

								return oldExploreAnalysis;
							}

							if (a.type === AnalysisType.Frequencies) {
								const oldAnalysis = a as FrequenciesAnalysis;

								const partialAnalysis: Omit<FrequenciesAnalysisV2, 'output'> = {
									...oldAnalysis,
									type: AnalysisType.FrequenciesV2,
									input: {
										dataModel: DataModel.main,
										variables: parseAnalysisInput(
											oldAnalysis,
											variablesLocation
										) as FrequenciesAnalysisV2['input']['variables']
									}
								};

								if (partialAnalysis.input.variables.categoryVariable) {
									const dataset = await context.api.data
										.analyses()
										.getFrequenciesV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											filters,
											categoryVariable:
												partialAnalysis.input.variables.categoryVariable
										});

									return {
										...partialAnalysis,
										output: {
											dataset
										}
									} as FrequenciesAnalysisV2;
								}
							}

							if (a.type === AnalysisType.FrequenciesV2) {
								const oldFrequencyAnalysis = a as FrequenciesAnalysisV2;

								const {
									input: {
										variables: { categoryVariable }
									}
								} = oldFrequencyAnalysis;

								if (categoryVariable) {
									const frequencyAnalysis = await context.api.data
										.analyses()
										.getFrequenciesV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											categoryVariable,
											filters
										});

									oldFrequencyAnalysis.output.dataset = frequencyAnalysis;

									return oldFrequencyAnalysis;
								}
							}

							if (a.type === AnalysisType.Kaplan) {
								const oldKaplanMeierAnalysis = a as KaplanMeierAnalysis;

								const partialAnalysis: KaplanMeierAnalysisV2 = {
									...oldKaplanMeierAnalysis,
									output: {
										dataset: { data: {} },
										statistics: { logRank: { data: null } }
									},
									type: AnalysisType.KaplanV2,
									input: {
										...oldKaplanMeierAnalysis.input,
										dataModel: DataModel.main,
										variables: parseAnalysisInput(
											oldKaplanMeierAnalysis,
											variablesLocation
										) as KaplanMeierVariablesV2,
										statistics: {
											confidenceIntervalsV2:
												oldKaplanMeierAnalysis.input.statistics
													.confidenceIntervals,
											logRankV2:
												oldKaplanMeierAnalysis.input.statistics.logRank,
											patientsV2:
												oldKaplanMeierAnalysis.input.statistics.patients
										}
									}
								};

								const {
									input: {
										selectedDataModel: model,
										variables: {
											durationVariable,
											endDate,
											eventVariable,
											positiveEvent,
											startDate,
											timeUnit,
											autofillDate,
											groupVariable,
											endTimeCensorVariable
										}
									}
								} = partialAnalysis;

								if (model === KaplanMeierDataModels.duration) {
									const input: GetKaplanMeierDurationRequestInputV2 = {
										projectId: Number(projectId),
										durationVariable,
										events: positiveEvent,
										eventVariable,
										filters,
										...(groupVariable
											? { groupVariables: [groupVariable] }
											: {})
									};

									const dataset = await context.api.data
										.analyses()
										.getKaplanMeierV2(input, model);
									partialAnalysis.output.dataset = dataset;
								}

								if (model === KaplanMeierDataModels.timeRangeWithEvent) {
									const input: GetKaplanMeierTimeRangeRequestInputV2 = {
										projectId: Number(projectId),
										eventVariable,
										events: positiveEvent,
										startTimeVariable: startDate,
										endTimeVariable: endDate,
										endTimeFillValue: autofillDate,
										timeUnit,
										filters,
										endTimeCensorVariable,
										...(groupVariable
											? { groupVariables: [groupVariable] }
											: {})
									};

									const dataset = await context.api.data
										.analyses()
										.getKaplanMeierV2(input, model);

									partialAnalysis.output.dataset = dataset;
								}

								if (groupVariable && model) {
									if (
										model === KaplanMeierDataModels.duration &&
										durationVariable &&
										eventVariable
									) {
										const input: GetLogRankDurationStatisticsV2 = {
											events: positiveEvent,
											durationVariable,
											eventVariable,
											groupVariable
										};

										const logRankStatistic = await context.api.data
											.analyses()
											.getLogRankStatisticsV2(
												{
													...input,
													projectId: Number(projectId),
													datasetId: Number(projectId),
													filters
												},
												model
											);
										partialAnalysis.output.statistics.logRank =
											logRankStatistic;
									} else if (
										model === KaplanMeierDataModels.timeRangeWithEvent &&
										endDate &&
										startDate &&
										eventVariable
									) {
										const input: GetLogRankTimeRangeStatisticsV2 = {
											events: positiveEvent,
											eventVariable,
											endTimeVariable: endDate,
											startTimeVariable: startDate,
											timeUnit,
											endTimeCensorVariable,
											endTimeFillValue: autofillDate,
											groupVariable
										};

										const logRankStatistic = await context.api.data
											.analyses()
											.getLogRankStatisticsV2(
												{
													...input,
													projectId: Number(projectId),
													datasetId: Number(projectId),
													filters
												},
												model
											);
										partialAnalysis.output.statistics.logRank =
											logRankStatistic;
									}
								}
								return partialAnalysis;
							}

							if (a.type === AnalysisType.KaplanV2) {
								const oldKaplanMeierAnalysis = a as KaplanMeierAnalysisV2;

								const {
									input: {
										selectedDataModel: model,
										variables: {
											durationVariable,
											endDate,
											eventVariable,
											positiveEvent,
											startDate,
											timeUnit,
											autofillDate,
											groupVariable,
											endTimeCensorVariable
										}
									}
								} = oldKaplanMeierAnalysis;

								if (model === KaplanMeierDataModels.duration) {
									const input: GetKaplanMeierDurationRequestInputV2 = {
										projectId: Number(projectId),
										durationVariable,
										events: positiveEvent,
										eventVariable,
										filters,
										...(groupVariable
											? { groupVariables: [groupVariable] }
											: {})
									};

									try {
										const kaplanMeierAnalysis = await context.api.data
											.analyses()
											.getKaplanMeierV2(input, model);

										oldKaplanMeierAnalysis.output.dataset = kaplanMeierAnalysis;
									} catch (e) {
										console.error(e);
									}
								}

								if (model === KaplanMeierDataModels.timeRangeWithEvent) {
									const input: GetKaplanMeierTimeRangeRequestInputV2 = {
										projectId: Number(projectId),
										eventVariable,
										events: positiveEvent,
										startTimeVariable: startDate,
										endTimeVariable: endDate,
										endTimeFillValue: autofillDate,
										timeUnit,
										filters,
										endTimeCensorVariable,
										...(groupVariable
											? { groupVariables: [groupVariable] }
											: {})
									};

									const kaplanMeierAnalysis = await context.api.data
										.analyses()
										.getKaplanMeierV2(input, model);

									oldKaplanMeierAnalysis.output.dataset = kaplanMeierAnalysis;
								}

								const { logRankV2 } = oldKaplanMeierAnalysis.input.statistics;

								if (logRankV2) {
									const {
										input: {
											selectedDataModel: dataModel,
											variables: {
												durationVariable,
												eventVariable,
												positiveEvent: events,
												endDate: endTimeVariable,
												startDate: startTimeVariable,
												groupVariable,
												endTimeCensorVariable,
												timeUnit,
												autofillDate
											}
										}
									} = oldKaplanMeierAnalysis;

									if (groupVariable && dataModel) {
										if (
											model === KaplanMeierDataModels.duration &&
											durationVariable &&
											eventVariable
										) {
											const input: GetLogRankDurationStatisticsV2 = {
												events,
												durationVariable,
												eventVariable,
												groupVariable
											};

											const logRankStatistic = await context.api.data
												.analyses()
												.getLogRankStatisticsV2(
													{
														...input,
														projectId: Number(projectId),
														datasetId: Number(projectId),
														filters
													},
													dataModel
												);
											oldKaplanMeierAnalysis.output.statistics.logRank =
												logRankStatistic;
										} else if (
											model === KaplanMeierDataModels.timeRangeWithEvent &&
											startTimeVariable &&
											endTimeVariable &&
											eventVariable
										) {
											const input: GetLogRankTimeRangeStatisticsV2 = {
												events,
												eventVariable,
												endTimeVariable,
												startTimeVariable,
												timeUnit,
												endTimeCensorVariable,
												endTimeFillValue: autofillDate,
												groupVariable
											};

											const logRankStatistic = await context.api.data
												.analyses()
												.getLogRankStatisticsV2(
													{
														...input,
														projectId: Number(projectId),
														datasetId: Number(projectId),
														filters
													},
													dataModel
												);
											oldKaplanMeierAnalysis.output.statistics.logRank =
												logRankStatistic;
										}
									}
								}
								return oldKaplanMeierAnalysis;
							}

							if (a.type === AnalysisType.LogisticRegression) {
								const oldLogisticRegressionAnalysis =
									a as LogisticRegressionAnalysis;

								const newAnalysis: LogisticRegressionAnalysisV2 = {
									...oldLogisticRegressionAnalysis,
									type: AnalysisType.LogisticRegressionV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											outcomes:
												oldLogisticRegressionAnalysis.input.variables
													.positiveEvent,
											xVariable: {
												name: oldLogisticRegressionAnalysis.input.variables
													.independentVariable
											},
											yVariable: {
												name: oldLogisticRegressionAnalysis.input.variables
													.dependentVariable
											},
											groupVariables: [
												...(oldLogisticRegressionAnalysis.input.variables
													.groupVariable
													? [
															{
																name: oldLogisticRegressionAnalysis
																	.input.variables.groupVariable
															}
													  ]
													: [])
											]
										}
									},
									output: {
										dataset: { data: null }
									}
								};

								const { yVariable, xVariable, outcomes } =
									newAnalysis.input.variables;

								if (yVariable && xVariable && outcomes.length) {
									const logisticRegressionAnalysis = await context.api.data
										.analyses()
										.getLogisticReggresionV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											xVariable,
											yVariable,
											outcomes,
											filters
										});

									newAnalysis.output.dataset = logisticRegressionAnalysis;

									return newAnalysis;
								}
							}

							if (a.type === AnalysisType.LogisticRegressionV2) {
								const oldLogisticRegressionAnalysis =
									a as LogisticRegressionAnalysisV2;

								const { yVariable, xVariable, outcomes, groupVariables } =
									oldLogisticRegressionAnalysis.input.variables;

								if (yVariable && xVariable && outcomes.length) {
									const logisticRegressionAnalysis = await context.api.data
										.analyses()
										.getLogisticReggresionV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											xVariable,
											yVariable,
											outcomes,
											groupVariables,
											filters
										});

									oldLogisticRegressionAnalysis.output.dataset =
										logisticRegressionAnalysis;

									return oldLogisticRegressionAnalysis;
								}
							}

							if (a.type === AnalysisType.NumberPlotXY) {
								const oldNumberPlotXYAnalysis = a as NumberPlotXYAnalysis;

								return {
									...oldNumberPlotXYAnalysis,
									deprecated: true
								} as NumberPlotXYAnalysis;
							}

							if (a.type === AnalysisType.PlotNumeric) {
								const oldPlotNumericAnalysis = a as PlotNumericAnalysis;

								const newAnalysis: PlotNumericAnalysisV2 = {
									...oldPlotNumericAnalysis,
									type: AnalysisType.PlotNumericV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											categoryVariable: {
												name: oldPlotNumericAnalysis.input.variables
													.categoryVariable
											},
											errorBar:
												ERROR_BAR_TO_ANALYSIS_STATISTIC_AGGREGATION_TYPE[
													oldPlotNumericAnalysis.input.variables.errorBar
												],
											numericVariable: {
												name: oldPlotNumericAnalysis.input.variables
													.numericVariable
											},
											groupingVariable: oldPlotNumericAnalysis.input.variables
												.groupingVariable
												? {
														name: oldPlotNumericAnalysis.input.variables
															.groupingVariable
												  }
												: null
										}
									},
									output: {
										dataset: {
											boxplot: {
												data: null
											},
											columns: {
												data: null
											},
											scatter: {
												data: null
											}
										},
										grouping:
											!!oldPlotNumericAnalysis.input.variables
												.groupingVariable
									}
								};

								const { categoryVariable, numericVariable, groupingVariable } =
									newAnalysis.input.variables;

								const prevActiveTab = oldPlotNumericAnalysis.options.activeTab ?? 0;

								if (prevActiveTab === 0 && categoryVariable && numericVariable) {
									const plotNumericColumnAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													...(groupingVariable
														? [categoryVariable, groupingVariable]
														: [categoryVariable])
												],
												numericVariable,
												filters
											},
											0
										);

									newAnalysis.output.dataset.columns =
										plotNumericColumnAnalysis.columns;
								}

								if (prevActiveTab === 1 && categoryVariable && numericVariable) {
									const plotNumericBoxplotAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													...(groupingVariable
														? [categoryVariable, groupingVariable]
														: [categoryVariable])
												],
												numericVariable,
												filters
											},
											1
										);

									newAnalysis.output.dataset.boxplot =
										plotNumericBoxplotAnalysis.boxplot;
								}

								if (prevActiveTab === 2 && categoryVariable && numericVariable) {
									const plotNumericScatterAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													...(groupingVariable
														? [categoryVariable, groupingVariable]
														: [categoryVariable])
												],
												numericVariable,
												filters
											},
											2
										);

									newAnalysis.output.dataset.scatter =
										plotNumericScatterAnalysis.scatter;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.PlotNumericV2) {
								const oldPlotNumericAnalysis = a as PlotNumericAnalysisV2;

								const {
									input: {
										variables: {
											numericVariable,
											groupingVariable,
											categoryVariable
										}
									}
								} = oldPlotNumericAnalysis;

								const prevActiveTab = oldPlotNumericAnalysis.options.activeTab ?? 0;

								if (prevActiveTab === 0 && numericVariable && categoryVariable) {
									const plotNumericColumnAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [
													categoryVariable,
													...(groupingVariable ? [groupingVariable] : [])
												],
												filters
											},
											0
										);

									oldPlotNumericAnalysis.output.dataset.columns =
										plotNumericColumnAnalysis.columns;
								}

								if (prevActiveTab === 1 && numericVariable && categoryVariable) {
									const plotNumericBoxplotAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [
													categoryVariable,
													...(groupingVariable ? [groupingVariable] : [])
												],
												filters
											},
											1
										);

									oldPlotNumericAnalysis.output.dataset.boxplot =
										plotNumericBoxplotAnalysis.boxplot;
								}

								if (prevActiveTab === 2 && numericVariable && categoryVariable) {
									const plotNumericScatterAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [categoryVariable],
												filters
											},
											2
										);

									oldPlotNumericAnalysis.output.dataset.scatter =
										plotNumericScatterAnalysis.scatter;
								}

								return oldPlotNumericAnalysis;
							}

							if (a.type === AnalysisType.TimeCourseV2) {
								const oldTimeCourseAnalysis = a as TimeCourseAnalysisV2;

								const timeCourseAnalysis = await context.api.data
									.analyses()
									.getTimeCourseV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										format: 'unix',
										...oldTimeCourseAnalysis.input.variables,
										filters
									});

								oldTimeCourseAnalysis.output.dataset = timeCourseAnalysis;

								return oldTimeCourseAnalysis;
							}

							if (a.type === AnalysisType.TimeCourseV1) {
								const oldTimeCourseAnalysis = a as TimeCourseAnalysisV1;

								const newAnalysis: TimeCourseAnalysisV2 = {
									...oldTimeCourseAnalysis,
									type: AnalysisType.TimeCourseV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											numericVariable: {
												name: oldTimeCourseAnalysis.input.variables
													.numericVariable
											},
											timeVariable: {
												name: oldTimeCourseAnalysis.input.variables
													.dateVariable
											},
											statistic:
												ERROR_BAR_TO_ANALYSIS_STATISTIC_AGGREGATION_TYPE[
													oldTimeCourseAnalysis.input.variables.errorBar
												],
											groupVariables: [
												...(oldTimeCourseAnalysis.input.variables
													.groupingVariable
													? [
															{
																name: oldTimeCourseAnalysis.input
																	.variables.groupingVariable
															}
													  ]
													: [])
											]
										}
									},
									output: {
										dataset: {
											data: null
										},
										grouping:
											!!oldTimeCourseAnalysis.input.variables.groupingVariable
									}
								};

								const timeCourseAnalysis = await context.api.data
									.analyses()
									.getTimeCourseV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										format: 'unix',
										...newAnalysis.input.variables,
										filters
									});

								newAnalysis.output.dataset = timeCourseAnalysis;

								return newAnalysis;
							}

							return a;
						})
					);

					// filter the analyses that were not fetched
					const newAnalysisList = await getFulfilledValues(analysesPromises);
					data.analysisList = newAnalysisList;
					dispatch(getSnapshotAction({ projectId, snapshotId, data }));
				} catch (e: any) {
					throw new Error(Dictionary.errors.api.snapshots.couldNotLoadSnapshot);
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

async function getFulfilledValues(results: PromiseSettledResult<any>[]): Promise<AnalysisV2[]> {
	// Filter out only the fulfilled promises and extract their values
	const fulfilledValues = results
		.filter(
			(result): result is PromiseFulfilledResult<AnalysisV2> => result.status === 'fulfilled'
		)
		.map((result: { value: AnalysisV2 }) => result.value);

	return fulfilledValues;
}

const getSnapshotsAction = (payload: ActionPayload<GetSnapshotsAction>): GetSnapshotsAction => ({
	type: ActionTypes.GET_SNAPSHOTS,
	payload
});

export const getSnapshots = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SNAPSHOTS,
		dispatch
	});

	const { projectId } = getState().data.projects;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const snapshots = await context.api.data.snapshots().getSnapshots(projectId);

			dispatch(getSnapshotsAction({ projectId, snapshots }));
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

const createSnapshotAction = (
	payload: ActionPayload<CreateSnapshotAction>
): CreateSnapshotAction => ({
	type: ActionTypes.CREATE_SNAPSHOT,
	payload
});

export const createSnapshot =
	(name: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const {
				projects: { projectId },
				filters: {
					dataset: { byProjectId: filtersByProjectId, byId: filtersById }
				},
				analyses: { byProjectId: analysesByProjectId, byId: analysesById }
			} = getState().data;

			if (projectId) {
				const filters = filtersByProjectId[projectId].active.map(id => filtersById[id]);
				const analysisList = analysesByProjectId[projectId].active.map(
					id => analysesById[id]
				);

				const snapshot = {
					projectId,
					snapName: name,
					snapshotJson: JSON.stringify({
						filters,
						analysisList
					})
				};

				const snapshotId = await context.api.data.snapshots().createSnapshot(snapshot);

				dispatch(createSnapshotAction({ projectId, snapshotId, name }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateSnapshotAction = (
	payload: ActionPayload<UpdateSnapshotAction>
): UpdateSnapshotAction => ({
	type: ActionTypes.UPDATE_SNAPSHOT,
	payload
});

export const updateSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const {
				projects: { projectId },
				analyses: { byProjectId: analysesByProjectId, byId: analysesById },
				filters: {
					dataset: { byProjectId: filtersByProjectId, byId: filtersById }
				},
				snapshots: { byId: bySnapshotId }
			} = getState().data;

			if (projectId) {
				const filters = filtersByProjectId[projectId].active.map(id => filtersById[id]);
				const analysisList = analysesByProjectId[projectId].active.map(
					id => analysesById[id]
				);

				const snapshot = {
					snapshotId: Number(snapshotId),
					projectId,
					snapName: bySnapshotId[snapshotId].snapName,
					snapshotJson: JSON.stringify({
						filters,
						analysisList
					})
				};

				await context.api.data.snapshots().updateSnapshot(snapshot);

				dispatch(updateSnapshotAction({ projectId, snapshotId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const deleteSnapshotAction = (
	payload: ActionPayload<DeleteSnapshotAction>
): DeleteSnapshotAction => ({
	type: ActionTypes.DELETE_SNAPSHOT,
	payload
});

export const deleteSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				await context.api.data.snapshots().deleteSnapshot(snapshotId, projectId);

				// remove analyses from the snapshot from store
				const snapshotAnalysesIds = getState().data.analyses.bySnapshotId[snapshotId];
				const jadBioAnalysesIds = extractJADBioAnalysesIds(snapshotAnalysesIds, getState);

				if (jadBioAnalysesIds.length)
					dispatch(deleteJADBioAnalyses(jadBioAnalysesIds, projectId));

				// Check if there are other snapshots that use the same analyses and delete the unused ones
				const analisesToRemove = [...snapshotAnalysesIds];

				for (const [snapId, analysesList] of Object.entries(
					getState().data.analyses.bySnapshotId
				)) {
					if (snapId !== snapshotId) {
						for (const analysisId of analysesList) {
							if (analisesToRemove.includes(analysisId)) {
								analisesToRemove.splice(analisesToRemove.indexOf(analysisId), 1);
							}
						}
					}
				}

				dispatch(deleteAnalysesAction({ projectId, analysisIds: analisesToRemove }));

				dispatch(deleteSnapshotAction({ projectId, snapshotId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setActiveSnapshot = (
	payload: ActionPayload<SetActiveSnapshotAction>
): SetActiveSnapshotAction => ({
	type: ActionTypes.SET_ACTIVE_SNAPSHOT,
	payload
});

export const clearSnapshot = (): ClearSnapshotAction => ({
	type: ActionTypes.CLEAR_SNAPSHOT
});
