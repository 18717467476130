// TIME DURATION VARIABLE

export enum TimeDurationKey {
	Weeks = 'weeks',
	Days = 'days',
	Hours = 'hours',
	Minutes = 'minutes',
	Seconds = 'seconds',
	Milliseconds = 'milliseconds',
	Microseconds = 'microseconds'
}

export const TIME_DURATION_KEYS = [
	TimeDurationKey.Weeks,
	TimeDurationKey.Days,
	TimeDurationKey.Hours,
	TimeDurationKey.Minutes,
	TimeDurationKey.Seconds,
	TimeDurationKey.Milliseconds,
	TimeDurationKey.Microseconds
];

export const ORDERED_TIME_DURATION_KEYS = [
	TimeDurationKey.Weeks,
	TimeDurationKey.Days,
	TimeDurationKey.Hours,
	TimeDurationKey.Minutes,
	TimeDurationKey.Seconds,
	TimeDurationKey.Milliseconds,
	TimeDurationKey.Microseconds
] as const;

export const REVERSE_ORDER_TIME_DURATION_KEYS = [
	TimeDurationKey.Microseconds,
	TimeDurationKey.Milliseconds,
	TimeDurationKey.Seconds,
	TimeDurationKey.Minutes,
	TimeDurationKey.Hours,
	TimeDurationKey.Days,
	TimeDurationKey.Weeks
] as const;

export const TIME_DURATION_OPTIONS_MAP = {
	[TimeDurationKey.Weeks]: 'Weeks',
	[TimeDurationKey.Days]: 'Days',
	[TimeDurationKey.Hours]: 'Hours',
	[TimeDurationKey.Minutes]: 'Minutes',
	[TimeDurationKey.Seconds]: 'Seconds',
	[TimeDurationKey.Milliseconds]: 'Milliseconds',
	[TimeDurationKey.Microseconds]: 'Microseconds'
};

// VARIABLE MODAL FORMAT PLACEHOLDER;
export enum TimeDurationShortKey {
	Weeks = 'ww',
	Days = 'dd',
	Hours = 'hh',
	Minutes = 'min',
	Seconds = 'ss',
	Milliseconds = 'ms',
	Microseconds = 'mss'
}

export enum TimeDurationPrefixKey {
	Weeks = 'w',
	Days = 'd',
	Hours = 'h',
	Minutes = 'm',
	Seconds = 's',
	Milliseconds = 'ms',
	Microseconds = 'µs'
}

export const TIME_DURATION_OPTIONS_PREFIX_KEY_MAP: Record<TimeDurationKey, TimeDurationPrefixKey> =
	{
		[TimeDurationKey.Days]: TimeDurationPrefixKey.Days,
		[TimeDurationKey.Seconds]: TimeDurationPrefixKey.Seconds,
		[TimeDurationKey.Hours]: TimeDurationPrefixKey.Hours,
		[TimeDurationKey.Milliseconds]: TimeDurationPrefixKey.Milliseconds,
		[TimeDurationKey.Weeks]: TimeDurationPrefixKey.Weeks,
		[TimeDurationKey.Minutes]: TimeDurationPrefixKey.Minutes,
		[TimeDurationKey.Microseconds]: TimeDurationPrefixKey.Microseconds
	};

export const TIME_DURATION_TRANSLATION_MAP: Record<TimeDurationKey, number> = {
	[TimeDurationKey.Weeks]: 604800000000,
	[TimeDurationKey.Days]: 86400000000,
	[TimeDurationKey.Hours]: 3600000000,
	[TimeDurationKey.Minutes]: 60000000,
	[TimeDurationKey.Seconds]: 1000000,
	[TimeDurationKey.Milliseconds]: 1000,
	[TimeDurationKey.Microseconds]: 1
};

// there’s 2 instances depending on where you get your letter from and they look exactly the same
// \u00b5 -> unicode "micro" greek letter
// \u03BC -> unicode "small MU" greek letter
export const excludeTimeDurationLetterRegex = /[a-zA-Z\u03BC\u00b5]/g;

// regex to test for forbidden symbols (anything that is not: letter, number, ':')
export const forbiddenSymbols = /[^:A-Za-z0-9]/;
