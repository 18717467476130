import ReactDOM from 'react-dom';
import { basicLogger, withLDProvider } from 'launchdarkly-react-client-sdk';
import { App } from './app/App';
import { nanoid as generate } from 'nanoid';

import './sentry';

import './amplify';
import './progress';

import './style.css';

const storedContext = localStorage.getItem('LDContext');

const ldcontext = storedContext
	? JSON.parse(storedContext)
	: {
			kind: 'multi',
			user: {
				name: 'anonymous',
				key: generate()
			},
			project: {
				key: generate()
			}
	  };

/**
 * Using the withLDProvider HOC instead of asyncWithLDProvider will make the initial render quicker
 * as it initializes the underlying JavaScript SDK at componentDidMount.
 * This means the flags only become available after the app has mounted.
 * If we ever need to have instant access to our flags we should switch to asyncWithLDProvider,
 * which first initializes the SDK and then renders the app - which leads to a longer loading time.
 */
const LDWrappedApp = withLDProvider({
	clientSideID: process.env.REACT_APP_LAUNCH_DARKLY || '',
	context: ldcontext,
	options: {
		bootstrap: 'localStorage',
		logger: basicLogger({ level: 'none' })
	}
})(App);

ReactDOM.render(<LDWrappedApp />, document.getElementById('root'));
