import {
	AnalysisOutputData,
	AnalysisVariable,
	CompareNumericAnalysisV2,
	ComparePairedAnalysisV2,
	ComparePairedDataModels,
	CorrelationsAnalysis,
	CrosstabAnalysisV2,
	DensityPlotAnalysisV2,
	ExploreAnalysisV2,
	FrequenciesAnalysisV2,
	GetKaplanMeierDurationRequestInputV2,
	GetKaplanMeierTimeRangeRequestInputV2,
	GetLogRankDurationStatisticsV2,
	GetLogRankTimeRangeStatisticsV2,
	KaplanMeierAnalysisV2,
	KaplanMeierDataModels,
	LogisticRegressionAnalysisV2,
	PlotNumericAnalysisV2,
	PlotNumericBoxPlotsV2,
	PlotNumericColumnsV2,
	PlotNumericScatterV2,
	TimeCourseAnalysisV2
} from 'api/data/analyses';
import { parseApiEntryFilters, rebuildInvalidAnalyses } from 'helpers/analysis';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	buildVariablesDataFromStoreData
} from 'helpers/variables';
import { ActionPayload, Thunk } from 'store/types';
import { createActivity } from 'store/ui/activities';

import {
	ActionTypes,
	CreateAnalysisAction,
	DeleteAnalysisAction,
	GetCompareNumericActionV2,
	GetCorrelationsV2Action,
	GetIndependentStatisticsActionV2,
	GetKruskalStatisticsActionV2,
	GetMannWhitneyStatisticsActionV2,
	GetOneWayAnovaStatisticsActionV2,
	GetOneWayManovaStatisticsActionV2,
	GetShapiroStatisticsActionV2,
	GetTukeyStatisticsActionV2,
	GetTwoWayAnovaStatisticsActionV2,
	GetTwoWayManovaStatisticsActionV2,
	RebuildAnalysesAction,
	SetAnalysesColumnAction,
	SetAnalysisActiveTabAction,
	SetAnalysisConfigPanelAction,
	SetAnalysisFormattingAction,
	SetAnalysisOpenStateAction,
	SetAnalysisParametersAction,
	SetAnalysisChartTypesAction,
	SetRefetchAnalysesAction,
	UpdateAnalysisAction,
	SetAnalysisFullscreenAction,
	ToggleChartPlotAction,
	SetJadBioPerformanceAction,
	SetRefetchedAnalysesAction,
	DeleteAnalysesAction,
	GetSpearmanStatisticsV2Action,
	GetPearsonStatisticsV2Action,
	GetLinearRegressionStatisticsV2Action,
	GetExploreActionV2,
	GetDensityPlotActionV2,
	GetTimeCourseActionV2,
	GetPlotNumericColumnsActionV2,
	GetPlotNumericBoxplotActionV2,
	GetPlotNumericScatterActionV2,
	GetLogisticRegressionActionV2,
	GetCrosstabActionV2,
	GetFisherStatisticsActionV2,
	GetChiSquareStatisticsActionV2,
	GetMcNemarStatisticsActionV2,
	GetFrequenciesActionV2,
	GetComparePairedActionV2,
	GetPairedTTestStatisticsActionV2,
	GetPairedWilcoxonStatisticsActionV2,
	GetKaplanMeierActionV2,
	GetLogRankTestStatisticsActionV2
} from './types';
import { deleteJADBioAnalyses, extractJADBioAnalysesIds } from 'store/addons/jadbio';

export const createAnalysis = (
	payload: ActionPayload<CreateAnalysisAction>
): CreateAnalysisAction => ({
	type: ActionTypes.CREATE_ANALYSIS,
	payload
});

export const updateAnalysis = (
	payload: ActionPayload<UpdateAnalysisAction>
): UpdateAnalysisAction => ({
	type: ActionTypes.UPDATE_ANALYSIS,
	payload
});

const deleteAnalysisAction = (
	payload: ActionPayload<DeleteAnalysisAction>
): DeleteAnalysisAction => ({
	type: ActionTypes.DELETE_ANALYSIS,
	payload
});

export const deleteAnalysis =
	(analysisId: string): Thunk =>
	(dispatch, getState) => {
		const { projectId, byProjectId } = getState().data.snapshots;

		if (!projectId) return;

		let snapshotId: string | null = null;
		if (byProjectId[projectId]) snapshotId = byProjectId[projectId].active;

		// send call to delete JADBio analysis in case it's this type
		const jadBioAnalysesIds = extractJADBioAnalysesIds([analysisId], getState);
		if (jadBioAnalysesIds.length) dispatch(deleteJADBioAnalyses(jadBioAnalysesIds, projectId));

		dispatch(deleteAnalysisAction({ projectId, analysisId, snapshotId }));
	};

export const deleteAnalysesAction = (
	payload: ActionPayload<DeleteAnalysesAction>
): DeleteAnalysesAction => ({
	type: ActionTypes.DELETE_ANALYSES,
	payload
});

/**
 * Removes all active-draft analyses from the current project,
 * except the snapshot's analyses (if a snapshot is selected/active)
 * @param analysisId
 * @returns
 */
export const analysesCleanUp =
	(projectId: string): Thunk =>
	(dispatch, getState) => {
		let analysesToDelete: string[] = [];

		const { active: activeAnalysesIds } = getState().data.analyses.byProjectId[projectId];

		analysesToDelete = activeAnalysesIds;

		// check if there is an active snapshot in order to not remove the active analyses that are part of the snapshot
		const { projectId: activeSnapshotProjectId, byProjectId: snapshotsByProjectId } =
			getState().data.snapshots;

		if (projectId == activeSnapshotProjectId) {
			let activeSnapshotId: string | null = null;
			if (snapshotsByProjectId[projectId])
				activeSnapshotId = snapshotsByProjectId[projectId].active;

			if (activeSnapshotId) {
				const snapshotAnalysesIds = getState().data.analyses.bySnapshotId[activeSnapshotId];
				analysesToDelete = analysesToDelete.filter(id => !snapshotAnalysesIds.includes(id));
			}
		}

		// delete JADBio analyses
		const jadBioAnalysesIds = extractJADBioAnalysesIds(analysesToDelete, getState);
		if (jadBioAnalysesIds.length) dispatch(deleteJADBioAnalyses(jadBioAnalysesIds, projectId));

		dispatch(deleteAnalysesAction({ projectId, analysisIds: analysesToDelete }));
	};

export const setAnalysisActiveTab = (
	payload: ActionPayload<SetAnalysisActiveTabAction>
): SetAnalysisActiveTabAction => ({
	type: ActionTypes.SET_ANALYSIS_ACTIVE_TAB,
	payload
});

export const setAnalysisConfigPanel = (
	payload: ActionPayload<SetAnalysisConfigPanelAction>
): SetAnalysisConfigPanelAction => ({
	type: ActionTypes.SET_ANALYSIS_CONFIG_PANEL,
	payload
});

export const setJadBioPerformanceAction = (
	payload: ActionPayload<SetJadBioPerformanceAction>
): SetJadBioPerformanceAction => ({
	type: ActionTypes.SET_ANALYSIS_JADBIO_PERFORMANCE_STATE,
	payload
});

export const setAnalysisFormatting = (
	payload: ActionPayload<SetAnalysisFormattingAction>
): SetAnalysisFormattingAction => ({
	type: ActionTypes.SET_ANALYSIS_FORMATTING,
	payload
});

export const setAnalysisParameters = (
	payload: ActionPayload<SetAnalysisParametersAction>
): SetAnalysisParametersAction => ({
	type: ActionTypes.SET_ANALYSIS_PARAMETERS,
	payload
});

export const setAnalysisChartTypes = (
	payload: ActionPayload<SetAnalysisChartTypesAction>
): SetAnalysisChartTypesAction => ({
	type: ActionTypes.SET_ANALYSIS_CHART_TYPES,
	payload
});

export const setAnalysisOpenState = (
	payload: ActionPayload<SetAnalysisOpenStateAction>
): SetAnalysisOpenStateAction => ({
	type: ActionTypes.SET_ANALYSIS_OPEN_STATE,
	payload
});

export const setRefetchAnalyses = (): SetRefetchAnalysesAction => ({
	type: ActionTypes.SET_REFETCH_ANALYSES
});

export const setRefetchedAnalyses = (): SetRefetchedAnalysesAction => ({
	type: ActionTypes.SET_REFETCHED_ANALYSES
});

export const setAnalysesColumn = (
	payload: ActionPayload<SetAnalysesColumnAction>
): SetAnalysesColumnAction => ({
	type: ActionTypes.SET_ANALYSES_COLUMN,
	payload
});

export const setAnalysisFullscreen = (
	payload: ActionPayload<SetAnalysisFullscreenAction>
): SetAnalysisFullscreenAction => ({
	type: ActionTypes.SET_ANALYSIS_FULLSCREEN,
	payload
});

export const rebuildAnalysesAction = (
	payload: ActionPayload<RebuildAnalysesAction>
): RebuildAnalysesAction => ({
	type: ActionTypes.REBUILD_ANALYSES,
	payload
});

/**
 * Rebuilds analyses with empty variable references
 */
export const rebuildAnalyses = (): Thunk => (dispatch, getState) => {
	const {
		variables: { byProjectId: variablesByProjectId },
		analyses: { projectId, byProjectId: analysesByProjectId, byId: analysesById }
	} = getState().data;

	if (projectId && analysesByProjectId[projectId]) {
		const storeVariablesData = variablesByProjectId[projectId].initial;
		const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

		const analyses = analysesByProjectId[projectId].active.map(
			analysisId => analysesById[analysisId]
		);

		const { shouldRebuildAnalyses, newAnalysesById } = rebuildInvalidAnalyses({
			analyses,
			analysesById,
			variablesData
		});

		if (shouldRebuildAnalyses) dispatch(rebuildAnalysesAction({ byId: newAnalysesById }));
	}
};

const toggleChartPlotAction = (
	payload: ActionPayload<ToggleChartPlotAction>
): ToggleChartPlotAction => ({
	type: ActionTypes.TOGGLE_CHART_PLOT,
	payload
});

export const toggleChartPlot =
	({ id, plotIndex }: ActionPayload<ToggleChartPlotAction>): Thunk =>
	async (dispatch, getState) => {
		const activity = createActivity({ type: ActionTypes.TOGGLE_CHART_PLOT, dispatch });

		try {
			activity.begin({ payload: { id, plotIndex } });

			const { projectId } = getState().data.projects;

			if (projectId) {
				dispatch(toggleChartPlotAction({ id, plotIndex }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { id, plotIndex } });
		} finally {
			activity.end();
		}
	};

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

const getCompareNumericActionV2 = (
	payload: ActionPayload<GetCompareNumericActionV2>
): GetCompareNumericActionV2 => ({
	type: ActionTypes.GET_COMPARE_NUMERIC_V2,
	payload
});

export const getCompareNumericV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_COMPARE_NUMERIC_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const {
					categoryVariable,
					categoryVariableTwo,
					exploreVariable,
					exploreVariableTwo
				} = analysis.input.variables;

				const dataset = await context.api.data.analyses().getCompareNumericV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					groupVariables: [categoryVariable, categoryVariableTwo].filter(
						item => !!item
					) as AnalysisVariable[],
					numericVariables: [exploreVariable, exploreVariableTwo].filter(
						item => !!item
					) as AnalysisVariable[],
					...(filters.length ? { filters } : {})
				});

				dispatch(getCompareNumericActionV2({ dataset, analysisId }));
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getCorrelationsV2Action = (
	payload: ActionPayload<GetCorrelationsV2Action>
): GetCorrelationsV2Action => ({
	type: ActionTypes.GET_CORRELATIONS_V2,
	payload
});

export const getCorrelationsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_CORRELATIONS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CorrelationsAnalysis;

				const dataset = await context.api.data.analyses().getCorrelations({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					...analysis.input.variables,
					filters
				});

				dispatch(getCorrelationsV2Action({ dataset, analysisId }));
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getCrosstabActionV2 = (payload: ActionPayload<GetCrosstabActionV2>): GetCrosstabActionV2 => ({
	type: ActionTypes.GET_CROSSTAB_V2,
	payload
});

export const getCrosstabV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_CROSSTAB_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CrosstabAnalysisV2;

				const {
					input: {
						variables: { rowVariable, columnVariable }
					}
				} = analysis;

				if (!rowVariable || !columnVariable) return;

				const dataset = await context.api.data.analyses().getCrosstabV2({
					projectId: Number(projectId),
					rowVariable,
					columnVariable,
					filters
				});

				dispatch(getCrosstabActionV2({ dataset, analysisId }));
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getExploreActionV2 = (payload: ActionPayload<GetExploreActionV2>): GetExploreActionV2 => ({
	type: ActionTypes.GET_EXPLORE_V2,
	payload
});

export const getExploreV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_EXPLORE_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				variables: { byProjectId: variablesByProjectId },
				analyses: { byId: analysesById }
			} = getState().data;

			const analysis = { ...analysesById[analysisId] } as ExploreAnalysisV2;
			const variables = analysis.input.variables;

			if (projectId && variables.length) {
				const allFilters =
					filtersByProjectId[projectId]?.active.map(id => filtersById[id]) ?? [];
				const variablesStoreData = variablesByProjectId[projectId].current;

				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const dataset = await context.api.data.analyses().getExploreV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					numericVariables: analysis.input.variables,
					filters
				});

				dispatch(getExploreActionV2({ dataset, analysisId }));
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getFrequenciesActionV2 = (
	payload: ActionPayload<GetFrequenciesActionV2>
): GetFrequenciesActionV2 => ({
	type: ActionTypes.GET_FREQUENCIES_V2,
	payload
});

export const getFrequenciesV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_FREQUENCIES_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as FrequenciesAnalysisV2;

				if (analysis.input.variables.categoryVariable) {
					const dataset = await context.api.data.analyses().getFrequenciesV2({
						projectId: Number(projectId),
						datasetId: Number(projectId),
						categoryVariable: analysis.input.variables.categoryVariable,
						filters
					});

					dispatch(getFrequenciesActionV2({ dataset, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId, timeout: 3000000 });
		} finally {
			activity.end();
		}
	};

const getKaplanMeierActionV2 = (
	payload: ActionPayload<GetKaplanMeierActionV2>
): GetKaplanMeierActionV2 => ({
	type: ActionTypes.GET_KAPLAN_MEIER_V2,
	payload
});

export const getKaplanMeierV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_KAPLAN_MEIER_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as KaplanMeierAnalysisV2;

				const {
					input: {
						selectedDataModel,
						variables: {
							durationVariable,
							endDate,
							eventVariable,
							positiveEvent,
							startDate,
							timeUnit,
							autofillDate,
							groupVariable,
							endTimeCensorVariable
						}
					}
				} = analysis;

				if (selectedDataModel === KaplanMeierDataModels.duration) {
					const input: GetKaplanMeierDurationRequestInputV2 = {
						projectId: Number(projectId),
						durationVariable,
						events: positiveEvent,
						eventVariable,
						filters,
						...(groupVariable ? { groupVariables: [groupVariable] } : {})
					};

					const dataset = await context.api.data
						.analyses()
						.getKaplanMeierV2(input, selectedDataModel);

					dispatch(getKaplanMeierActionV2({ dataset, analysisId }));
				}

				if (selectedDataModel === KaplanMeierDataModels.timeRangeWithEvent) {
					const input: GetKaplanMeierTimeRangeRequestInputV2 = {
						projectId: Number(projectId),
						eventVariable,
						events: positiveEvent,
						startTimeVariable: startDate,
						endTimeVariable: endDate,
						endTimeFillValue: autofillDate,
						timeUnit,
						filters,
						endTimeCensorVariable,
						...(groupVariable ? { groupVariables: [groupVariable] } : {})
					};

					const dataset = await context.api.data
						.analyses()
						.getKaplanMeierV2(input, selectedDataModel);

					dispatch(getKaplanMeierActionV2({ dataset, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getPlotNumericColumnsActionV2 = (
	payload: ActionPayload<GetPlotNumericColumnsActionV2>
): GetPlotNumericColumnsActionV2 => ({
	type: ActionTypes.GET_PLOT_NUMERIC_COLUMNS_V2,
	payload
});

export const getPlotNumericColumnsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_PLOT_NUMERIC_COLUMNS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as PlotNumericAnalysisV2;

				const { numericVariable, categoryVariable, groupingVariable } =
					analysis.input.variables;

				if (numericVariable && categoryVariable) {
					const dataset = (
						await context.api.data.analyses().getPlotNumericV2(
							{
								projectId: Number(projectId),
								datasetId: Number(projectId),
								numericVariable,
								groupVariables: [
									categoryVariable,
									...(groupingVariable ? [groupingVariable] : [])
								],
								filters
							},
							0
						)
					).columns as AnalysisOutputData<PlotNumericColumnsV2>;

					dispatch(getPlotNumericColumnsActionV2({ dataset, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getPlotNumericBoxplotActionV2 = (
	payload: ActionPayload<GetPlotNumericBoxplotActionV2>
): GetPlotNumericBoxplotActionV2 => ({
	type: ActionTypes.GET_PLOT_NUMERIC_BOXPLOT_V2,
	payload
});

export const getPlotNumericBoxplotV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_PLOT_NUMERIC_BOXPLOT_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as PlotNumericAnalysisV2;

				const { categoryVariable, numericVariable, groupingVariable } =
					analysis.input.variables;

				if (categoryVariable && numericVariable) {
					const dataset = (
						await context.api.data.analyses().getPlotNumericV2(
							{
								projectId: Number(projectId),
								datasetId: Number(projectId),
								numericVariable,
								groupVariables: [
									categoryVariable,
									...(groupingVariable ? [groupingVariable] : [])
								],
								filters
							},
							1
						)
					).boxplot as AnalysisOutputData<PlotNumericBoxPlotsV2>;

					dispatch(getPlotNumericBoxplotActionV2({ dataset, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getPlotNumericScatterActionV2 = (
	payload: ActionPayload<GetPlotNumericScatterActionV2>
): GetPlotNumericScatterActionV2 => ({
	type: ActionTypes.GET_PLOT_NUMERIC_SCATTER_V2,
	payload
});

export const getPlotNumericScatterV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_PLOT_NUMERIC_SCATTER_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as PlotNumericAnalysisV2;

				const { categoryVariable, numericVariable } = analysis.input.variables;

				if (numericVariable && categoryVariable) {
					const dataset = (
						await context.api.data.analyses().getPlotNumericV2(
							{
								projectId: Number(projectId),
								datasetId: Number(projectId),
								numericVariable,
								groupVariables: [categoryVariable],
								filters
							},
							2
						)
					).scatter as AnalysisOutputData<PlotNumericScatterV2>;

					dispatch(getPlotNumericScatterActionV2({ dataset, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getDensityPlotActionV2 = (
	payload: ActionPayload<GetDensityPlotActionV2>
): GetDensityPlotActionV2 => ({
	type: ActionTypes.GET_DENSITY_PLOT_V2,
	payload
});

export const getDensityPlotV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_DENSITY_PLOT_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as DensityPlotAnalysisV2;

				const dataset = await context.api.data.analyses().getDensityPlotV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					...analysis.input.variables,
					filters
				});

				dispatch(getDensityPlotActionV2({ dataset, analysisId }));
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getTimeCourseActionV2 = (
	payload: ActionPayload<GetTimeCourseActionV2>
): GetTimeCourseActionV2 => ({
	type: ActionTypes.GET_TIME_COURSE_V2,
	payload
});

export const getTimeCourseV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_TIME_COURSE_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as TimeCourseAnalysisV2;

				const dataset = await context.api.data.analyses().getTimeCourseV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					timeFormat: 'unix',
					...analysis.input.variables,
					filters
				});

				dispatch(getTimeCourseActionV2({ dataset, analysisId }));
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getComparePairedActionV2 = (
	payload: ActionPayload<GetComparePairedActionV2>
): GetComparePairedActionV2 => ({
	type: ActionTypes.GET_COMPARE_PAIRED_V2,
	payload
});

export const getComparePairedV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_COMPARE_PAIRED_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as ComparePairedAnalysisV2;

				const {
					afterVariable,
					beforeVariable,
					groupVariable,
					numericVariable,
					pairIdentifier
				} = analysis.input.variables;

				const { dataModel } = analysis.input;

				const isSingleEntryPerSubject =
					dataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT;
				const usingSeries = dataModel === ComparePairedDataModels.USING_SERIES;

				if (usingSeries && numericVariable && groupVariable) {
					const dataset = await context.api.data.analyses().getComparePairedV2({
						projectId: Number(projectId),
						filters,
						numericVariable,
						groupVariable
					});
					dispatch(getComparePairedActionV2({ dataset, analysisId }));
				} else if (isSingleEntryPerSubject && beforeVariable && afterVariable) {
					const dataset = await context.api.data.analyses().getComparePairedV2(
						{
							projectId: Number(projectId),
							filters,
							afterVariable,
							beforeVariable
						},
						true
					);

					dispatch(getComparePairedActionV2({ dataset, analysisId }));
				} else if (numericVariable && groupVariable && pairIdentifier) {
					const dataset = await context.api.data.analyses().getComparePairedV2({
						projectId: Number(projectId),
						filters,
						numericVariable,
						groupVariable,
						pairingVariable: pairIdentifier
					});

					dispatch(getComparePairedActionV2({ dataset, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getLogisticRegressionActionV2 = (
	payload: ActionPayload<GetLogisticRegressionActionV2>
): GetLogisticRegressionActionV2 => ({
	type: ActionTypes.GET_LOGISTIC_REGRESSION_V2,
	payload
});

export const getLogisticRegressionV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_LOGISTIC_REGRESSION_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as LogisticRegressionAnalysisV2;

				const { yVariable, xVariable, outcomes, groupVariables } = analysis.input.variables;

				if (yVariable && xVariable && outcomes.length > 0) {
					const dataset = await context.api.data.analyses().getLogisticReggresionV2({
						projectId: Number(projectId),
						filters,
						yVariable,
						xVariable,
						outcomes,
						groupVariables
					});

					dispatch(getLogisticRegressionActionV2({ dataset, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(setRefetchedAnalyses());
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

const getChiSquareStatisticsActionV2 = (
	payload: ActionPayload<GetChiSquareStatisticsActionV2>
): GetChiSquareStatisticsActionV2 => ({
	type: ActionTypes.GET_CHI_SQUARE_STATISTICS_V2,
	payload
});

export const getChiSquareStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_CHI_SQUARE_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CrosstabAnalysisV2;

				const {
					input: {
						variables: { rowVariable, columnVariable }
					}
				} = analysis;

				if (rowVariable && columnVariable) {
					const values = await context.api.data.analyses().getChiStatisticsV2({
						projectId: Number(projectId),
						rowVariable,
						columnVariable,
						filters
					});

					dispatch(getChiSquareStatisticsActionV2({ values, analysisId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getFisherStatisticsActionV2 = (
	payload: ActionPayload<GetFisherStatisticsActionV2>
): GetFisherStatisticsActionV2 => ({
	type: ActionTypes.GET_FISHER_STATISTICS_V2,
	payload
});

export const getFisherStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_FISHER_STATISTICS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CrosstabAnalysisV2;

				const {
					input: {
						variables: { rowVariable, columnVariable }
					}
				} = analysis;

				if (columnVariable && rowVariable) {
					const values = await context.api.data.analyses().getFisherStatisticsV2({
						projectId: Number(projectId),
						rowVariable,
						columnVariable,
						filters
					});

					dispatch(getFisherStatisticsActionV2({ values, analysisId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getShapiroStatisticsActionV2 = (
	payload: ActionPayload<GetShapiroStatisticsActionV2>
): GetShapiroStatisticsActionV2 => ({
	type: ActionTypes.GET_SHAPIRO_STATISTICS_V2,
	payload
});

export const getShapiroStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_SHAPIRO_STATISTICS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const {
					categoryVariable,
					categoryVariableTwo,
					exploreVariable,
					exploreVariableTwo
				} = analysis.input.variables;

				const values = await context.api.data.analyses().getShapiroStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					groupVariables:
						categoryVariable && categoryVariableTwo
							? [categoryVariable, categoryVariableTwo]
							: categoryVariable
							? [categoryVariable]
							: [],
					numericVariables:
						exploreVariableTwo && exploreVariable
							? [exploreVariable, exploreVariableTwo]
							: exploreVariable
							? [exploreVariable]
							: [],
					filters
				});

				dispatch(getShapiroStatisticsActionV2({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getMannWhitneyStatisticsActionV2 = (
	payload: ActionPayload<GetMannWhitneyStatisticsActionV2>
): GetMannWhitneyStatisticsActionV2 => ({
	type: ActionTypes.GET_MANN_WHITNEY_STATISTICS_V2,
	payload
});

export const getMannWhitneyStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_MANN_WHITNEY_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const { categoryVariable, exploreVariable } = analysis.input.variables;

				if (categoryVariable && exploreVariable) {
					const values = await context.api.data.analyses().getMannWhitneyStatisticsV2({
						projectId: Number(projectId),
						datasetId: Number(projectId),
						groupVariable: categoryVariable,
						numericVariable: exploreVariable,
						filters
					});

					dispatch(getMannWhitneyStatisticsActionV2({ values, analysisId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getIndependentStatisticsActionV2 = (
	payload: ActionPayload<GetIndependentStatisticsActionV2>
): GetIndependentStatisticsActionV2 => ({
	type: ActionTypes.GET_INDEPENDENT_STATISTICS_V2,
	payload
});

export const getIndependentStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_INDEPENDENT_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const {
					input: {
						variables: { categoryVariable, exploreVariable }
					}
				} = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				if (!categoryVariable && !exploreVariable) return;

				const values = await context.api.data.analyses().getIndependentStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					filters,
					groupVariable: categoryVariable as AnalysisVariable,
					numericVariable: exploreVariable as AnalysisVariable
				});

				dispatch(getIndependentStatisticsActionV2({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getPearsonStatisticsV2Action = (
	payload: ActionPayload<GetPearsonStatisticsV2Action>
): GetPearsonStatisticsV2Action => ({
	type: ActionTypes.GET_PEARSON_STATISTICS_V2,
	payload
});
export const getPearsonStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_PEARSON_STATISTICS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CorrelationsAnalysis;

				const values = await context.api.data.analyses().getPearsonStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					type: 'pearson',
					...analysis.input.variables,
					filters
				});

				dispatch(getPearsonStatisticsV2Action({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getSpearmanStatisticsV2Action = (
	payload: ActionPayload<GetSpearmanStatisticsV2Action>
): GetSpearmanStatisticsV2Action => ({
	type: ActionTypes.GET_SPEARMAN_STATISTICS_V2,
	payload
});

export const getSpearmanStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_SPEARMAN_STATISTICS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CorrelationsAnalysis;

				const values = await context.api.data.analyses().getSpearmanStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					type: 'spearman',
					...analysis.input.variables,
					filters
				});

				dispatch(getSpearmanStatisticsV2Action({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getLinearRegressionStatisticsV2Action = (
	payload: ActionPayload<GetLinearRegressionStatisticsV2Action>
): GetLinearRegressionStatisticsV2Action => ({
	type: ActionTypes.GET_LINEAR_REGRESSION_STATISTICS_V2,
	payload
});

export const getLinearRegressionStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_LINEAR_REGRESSION_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CorrelationsAnalysis;

				const values = await context.api.data.analyses().getLinearRegressionStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					...analysis.input.variables,
					filters
				});

				dispatch(getLinearRegressionStatisticsV2Action({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getOneWayAnovaStatisticsActionV2 = (
	payload: ActionPayload<GetOneWayAnovaStatisticsActionV2>
): GetOneWayAnovaStatisticsActionV2 => ({
	type: ActionTypes.GET_ONE_WAY_ANOVA_STATISTICS_V2,
	payload
});

export const getOneWayAnovaStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_ONE_WAY_ANOVA_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const {
					input: {
						variables: { categoryVariable, exploreVariable }
					}
				} = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				if (!categoryVariable && !exploreVariable) return;

				const values = await context.api.data.analyses().getOneWayAnovaStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					groupVariables: [categoryVariable] as AnalysisVariable[],
					numericVariable: exploreVariable as AnalysisVariable,
					filters
				});

				dispatch(getOneWayAnovaStatisticsActionV2({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getLogRankStatisticsActionV2 = (
	payload: ActionPayload<GetLogRankTestStatisticsActionV2>
): GetLogRankTestStatisticsActionV2 => ({
	type: ActionTypes.GET_LOG_RANK_TEST_STATISTICS_V2,
	payload
});

export const getLogRankStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_LOG_RANK_TEST_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as KaplanMeierAnalysisV2;
				const {
					input: {
						selectedDataModel: dataModel,
						variables: {
							durationVariable,
							eventVariable,
							positiveEvent: events,
							endDate: endTimeVariable,
							startDate: startTimeVariable,
							groupVariable,
							endTimeCensorVariable,
							timeUnit,
							autofillDate
						}
					}
				} = analysis as KaplanMeierAnalysisV2;
				const isDurationModel =
					analysis.input.selectedDataModel === KaplanMeierDataModels.duration;

				if (groupVariable && dataModel) {
					if (isDurationModel && durationVariable && eventVariable) {
						const input: GetLogRankDurationStatisticsV2 = {
							events,
							durationVariable,
							eventVariable,
							groupVariable
						};

						const values = await context.api.data.analyses().getLogRankStatisticsV2(
							{
								...input,
								projectId: Number(projectId),
								datasetId: Number(projectId),
								filters
							},
							dataModel
						);

						dispatch(getLogRankStatisticsActionV2({ values, analysisId }));
					} else if (
						!isDurationModel &&
						startTimeVariable &&
						endTimeVariable &&
						eventVariable
					) {
						const input: GetLogRankTimeRangeStatisticsV2 = {
							events,
							eventVariable,
							endTimeVariable,
							startTimeVariable,
							timeUnit,
							endTimeCensorVariable,
							endTimeFillValue: autofillDate,
							groupVariable
						};

						const values = await context.api.data.analyses().getLogRankStatisticsV2(
							{
								...input,
								projectId: Number(projectId),
								datasetId: Number(projectId),
								filters
							},
							dataModel
						);

						dispatch(getLogRankStatisticsActionV2({ values, analysisId }));
					}
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getTukeyStatisticsActionV2 = (
	payload: ActionPayload<GetTukeyStatisticsActionV2>
): GetTukeyStatisticsActionV2 => ({
	type: ActionTypes.GET_TUKEY_STATISTICS_V2,
	payload
});

export const getTukeyStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_TUKEY_STATISTICS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const { categoryVariable, exploreVariable } = analysis.input.variables;

				if (!categoryVariable && !exploreVariable) return;

				const values = await context.api.data.analyses().getTukeyStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					groupVariable: categoryVariable as AnalysisVariable,
					numericVariable: exploreVariable as AnalysisVariable,
					filters
				});

				dispatch(getTukeyStatisticsActionV2({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getPairedTTestStatisticsActionV2 = (
	payload: ActionPayload<GetPairedTTestStatisticsActionV2>
): GetPairedTTestStatisticsActionV2 => ({
	type: ActionTypes.GET_PAIRED_TTEST_STATISTICS_V2,
	payload
});

export const getPairedTTestStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_PAIRED_TTEST_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const {
					input: {
						variables: {
							afterVariable,
							beforeVariable,
							numericVariable,
							groupVariable,
							pairIdentifier
						},
						dataModel
					}
				} = analysesById[analysisId] as ComparePairedAnalysisV2;

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				if (
					dataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
					afterVariable &&
					beforeVariable
				) {
					const values = await context.api.data.analyses().getPairedTTestStatisticsV2({
						input: {
							afterVariable,
							beforeVariable,
							projectId: Number(projectId),
							filters
						},
						dataModel: dataModel
					});

					dispatch(getPairedTTestStatisticsActionV2({ analysisId, values }));
				}
				if (
					dataModel === ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT &&
					groupVariable &&
					numericVariable &&
					pairIdentifier
				) {
					const values = await context.api.data.analyses().getPairedTTestStatisticsV2({
						input: {
							groupVariable,
							numericVariable,
							pairingVariable: pairIdentifier,
							projectId: Number(projectId),
							filters
						},
						dataModel: dataModel
					});

					dispatch(getPairedTTestStatisticsActionV2({ analysisId, values }));
				}

				if (
					dataModel === ComparePairedDataModels.USING_SERIES &&
					groupVariable &&
					numericVariable
				) {
					const values = await context.api.data.analyses().getPairedTTestStatisticsV2({
						input: {
							groupVariable,
							numericVariable,
							projectId: Number(projectId),
							filters
						},
						dataModel: dataModel
					});

					dispatch(getPairedTTestStatisticsActionV2({ analysisId, values }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getPairedWilcoxonStatisticsActionV2 = (
	payload: ActionPayload<GetPairedWilcoxonStatisticsActionV2>
): GetPairedWilcoxonStatisticsActionV2 => ({
	type: ActionTypes.GET_PAIRED_WILCOXON_STATISTICS_V2,
	payload
});

export const getPairedWilcoxonStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_PAIRED_WILCOXON_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as ComparePairedAnalysisV2;

				const {
					variables: {
						afterVariable,
						beforeVariable,
						numericVariable,
						groupVariable,
						pairIdentifier
					},
					dataModel
				} = analysis.input;

				if (
					dataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
					afterVariable &&
					beforeVariable
				) {
					const values = await context.api.data.analyses().getPairedWilcoxonStatisticsV2({
						input: {
							afterVariable,
							beforeVariable,
							projectId: Number(projectId),
							filters
						},
						dataModel: dataModel
					});

					dispatch(getPairedWilcoxonStatisticsActionV2({ analysisId, values }));
				}
				if (
					dataModel === ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT &&
					groupVariable &&
					numericVariable &&
					pairIdentifier
				) {
					const values = await context.api.data.analyses().getPairedWilcoxonStatisticsV2({
						input: {
							groupVariable,
							numericVariable,
							pairingVariable: pairIdentifier,
							projectId: Number(projectId),
							filters
						},
						dataModel
					});

					dispatch(getPairedWilcoxonStatisticsActionV2({ analysisId, values }));
				}

				if (
					dataModel === ComparePairedDataModels.USING_SERIES &&
					groupVariable &&
					numericVariable
				) {
					const values = await context.api.data.analyses().getPairedWilcoxonStatisticsV2({
						input: {
							groupVariable,
							numericVariable,
							projectId: Number(projectId),
							filters
						},
						dataModel
					});

					dispatch(getPairedWilcoxonStatisticsActionV2({ analysisId, values }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getKruskalStatisticsActionV2 = (
	payload: ActionPayload<GetKruskalStatisticsActionV2>
): GetKruskalStatisticsActionV2 => ({
	type: ActionTypes.GET_KRUSKAL_STATISTICS_V2,
	payload
});

export const getKruskalStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_KRUSKAL_STATISTICS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const {
					input: {
						variables: { categoryVariable, exploreVariable }
					}
				} = analysis;

				if (!exploreVariable && !categoryVariable) return;

				const values = await context.api.data.analyses().getKruskalStatisticsV2({
					projectId: Number(projectId),
					datasetId: Number(projectId),
					groupVariable: categoryVariable as AnalysisVariable,
					numericVariable: exploreVariable as AnalysisVariable,
					filters
				});

				dispatch(getKruskalStatisticsActionV2({ values, analysisId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getMcNemarStatisticsActionV2 = (
	payload: ActionPayload<GetMcNemarStatisticsActionV2>
): GetMcNemarStatisticsActionV2 => ({
	type: ActionTypes.GET_MCNEMAR_STATISTICS_V2,
	payload
});

export const getMcNemarStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_MCNEMAR_STATISTICS_V2, dispatch });

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CrosstabAnalysisV2;

				const {
					input: {
						variables: { rowVariable, columnVariable }
					}
				} = analysis;

				if (rowVariable && columnVariable) {
					const values = await context.api.data.analyses().getMcNemarStatisticsV2({
						projectId: Number(projectId),
						datasetId: Number(projectId),
						rowVariable,
						columnVariable,
						filters
					});

					dispatch(getMcNemarStatisticsActionV2({ values, analysisId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getTwoWayManovaStatisticsActionV2 = (
	payload: ActionPayload<GetTwoWayManovaStatisticsActionV2>
): GetTwoWayManovaStatisticsActionV2 => ({
	type: ActionTypes.GET_TWO_WAY_MANOVA_STATISTICS_V2,
	payload
});

export const getTwoWayManovaStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_TWO_WAY_MANOVA_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const {
					categoryVariable,
					categoryVariableTwo,
					exploreVariable,
					exploreVariableTwo
				} = analysis.input.variables;

				if (categoryVariableTwo !== undefined && exploreVariableTwo !== undefined) {
					const values = await context.api.data.analyses().getTwoWayManovaStatisticsV2({
						projectId: Number(projectId),
						datasetId: Number(projectId),
						groupVariables: [
							categoryVariable,
							categoryVariableTwo
						] as AnalysisVariable[],
						numericVariables: [
							exploreVariable,
							exploreVariableTwo
						] as AnalysisVariable[],
						filters
					});

					dispatch(getTwoWayManovaStatisticsActionV2({ values, analysisId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getTwoWayAnovaStatisticsActionV2 = (
	payload: ActionPayload<GetTwoWayAnovaStatisticsActionV2>
): GetTwoWayAnovaStatisticsActionV2 => ({
	type: ActionTypes.GET_TWO_WAY_ANOVA_STATISTICS_V2,
	payload
});

export const getTwoWayAnovaStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_TWO_WAY_ANOVA_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const { categoryVariable, categoryVariableTwo, exploreVariable } =
					analysis.input.variables;

				if (categoryVariableTwo !== undefined) {
					const values = await context.api.data.analyses().getTwoWayAnovaStatisticsV2({
						projectId: Number(projectId),
						datasetId: Number(projectId),
						groupVariables: [
							categoryVariable,
							categoryVariableTwo
						] as AnalysisVariable[],
						numericVariable: exploreVariable as AnalysisVariable,
						filters
					});

					dispatch(getTwoWayAnovaStatisticsActionV2({ values, analysisId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};

const getOneWayManovaStatisticsActionV2 = (
	payload: ActionPayload<GetOneWayManovaStatisticsActionV2>
): GetOneWayManovaStatisticsActionV2 => ({
	type: ActionTypes.GET_ONE_WAY_MANOVA_STATISTICS_V2,
	payload
});

export const getOneWayManovaStatisticsV2 =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_ONE_WAY_MANOVA_STATISTICS_V2,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				projects: { projectId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				analyses: { byId: analysesById }
			} = getState().data;

			if (projectId) {
				const variablesStoreData = getState().data.variables.byProjectId[projectId].current;
				const { variablesMap, variableSetsMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);
				const filters = parseApiEntryFilters(allFilters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				const analysis = { ...analysesById[analysisId] } as CompareNumericAnalysisV2;

				const { categoryVariable, exploreVariable, exploreVariableTwo } =
					analysis.input.variables;

				if (exploreVariableTwo !== undefined) {
					const values = await context.api.data.analyses().getOneWayManovaStatisticsV2({
						projectId: Number(projectId),
						datasetId: Number(projectId),
						groupVariables: [categoryVariable] as AnalysisVariable[],
						numericVariables: [
							exploreVariable,
							exploreVariableTwo
						] as AnalysisVariable[],
						filters
					});

					dispatch(getOneWayManovaStatisticsActionV2({ values, analysisId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: analysisId });
		} finally {
			activity.end();
		}
	};
