import { addTimeUnits } from './formatMicroSeconds';
import { Input } from 'components/UI/Inputs/Input';
import { InputType } from 'types';
import { OptionalDescriptionTooltip } from '../../component/OptionalDescriptionTooltip';
import { TimeUnit } from '../../types';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { TIME_UNIT_DICT, TimeUnitKey } from './dict';

interface Props {
	value: string | null;
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
	label: string;
	required?: boolean;
	onChange: (value: string | null) => void;
	onBlur: () => void;
	error?: string;
	onError: (error: string | undefined) => void;
	description?: string;
}

export const TimeDurationInput = forwardRef<HTMLInputElement, Props>(
	(
		{
			value,
			maxTimeUnit,
			minTimeUnit,
			label,
			required,
			onChange,
			onBlur,
			error,
			onError,
			description
		},
		ref
	) => {
		const [editing, setEditing] = useState(false);

		if (editing) {
			return (
				<EditTimeDurationInput
					value={value}
					label={label}
					onCancel={() => setEditing(false)}
					maxTimeUnit={maxTimeUnit}
					minTimeUnit={minTimeUnit}
					required={required}
					onSubmit={value => {
						onChange(value);
						setEditing(false);
						onBlur();
					}}
					onError={onError}
					error={error}
					description={description}
				/>
			);
		}

		return (
			<Input
				label={label}
				ref={ref}
				LabelSuffix={<OptionalDescriptionTooltip description={description} />}
				type={InputType.Text}
				labelHint={formatLabelHint({
					maxTimeUnit,
					minTimeUnit
				})}
				required={required}
				value={addTimeUnits({ colonSeparatedValues: value, maxTimeUnit, minTimeUnit })}
				onFocus={() => {
					setEditing(true);
				}}
				error={error}
				onChange={() => {
					// do nothing just stop complaining
					// the actual edit is handled by rendering a different input
				}}
			/>
		);
	}
);

TimeDurationInput.displayName = 'TimeDurationInput';

const EditTimeDurationInput = ({
	value,
	label,
	maxTimeUnit,
	minTimeUnit,
	onCancel,
	onSubmit,
	required,
	onError,
	error,
	description
}: {
	value: string | null;
	label: string;
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
	onCancel: () => void;
	onSubmit: (value: string | null) => void;
	required?: boolean;
	onError: (error: string | undefined) => void;
	error: string | undefined;
	description?: string;
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (ref.current) {
			ref.current.focus();
			ref.current.value = value || '';
		}
	}, [ref]);

	return (
		<Input
			type={InputType.Text}
			label={label}
			ref={ref}
			labelHint={formatLabelHint({
				maxTimeUnit,
				minTimeUnit
			})}
			LabelSuffix={<OptionalDescriptionTooltip description={description} />}
			error={error}
			onChange={() => {
				onError(undefined);
			}}
			required={required}
			onBlur={() => {
				onError(undefined);

				const value = ref.current?.value;

				if (!value) {
					onCancel();
					return;
				}

				const input = {
					input: value,
					maxTimeUnit,
					minTimeUnit
				};

				const error = validateInput(input);

				if (error) {
					onError(error);
					return;
				}

				onSubmit(value);
			}}
		/>
	);
};

const formatLabelHint = ({
	maxTimeUnit,
	minTimeUnit
}: {
	maxTimeUnit: TimeUnit;
	minTimeUnit: TimeUnit;
}) => {
	const intervals: TimeUnit[] = [];

	const intervalKeys = Object.keys(TIME_UNIT_DICT.full);

	const startIndex = intervalKeys.indexOf(maxTimeUnit);
	const endIndex = intervalKeys.indexOf(minTimeUnit);

	for (let i = startIndex; i <= endIndex; i++) {
		intervals.push(intervalKeys[i] as TimeUnit);
	}

	return `(${intervals.map(interval => TIME_UNIT_DICT.prefix[interval]).join(':')})`;
};

export function validateInput({
	input,
	maxTimeUnit,
	minTimeUnit
}: {
	input: string;
	maxTimeUnit: TimeUnitKey;
	minTimeUnit: TimeUnitKey;
}): string | undefined {
	if (input === '0') {
		return undefined;
	}

	// Validate input is only numbers and colons
	if (!/^(\d+:)*\d+$/.test(input)) {
		return 'Input must contain only numbers and colons';
	}

	const parts = input.split(':');
	const TIME_UNITS: TimeUnitKey[] = [
		'weeks',
		'days',
		'hours',
		'minutes',
		'seconds',
		'milliseconds',
		'microseconds'
	];

	const maxIndex = TIME_UNITS.indexOf(maxTimeUnit);
	const minIndex = TIME_UNITS.indexOf(minTimeUnit);

	if (maxIndex === -1 || minIndex === -1) {
		return 'Invalid time unit specified';
	}

	if (maxIndex > minIndex) {
		return 'maxTimeUnit must be larger than minTimeUnit';
	}

	const expectedUnits = minIndex - maxIndex + 1;
	if (parts.length !== expectedUnits) {
		return `Expected ${expectedUnits} units, got ${parts.length}`;
	}

	return undefined;
}
