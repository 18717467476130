export function isValidDate(value: any): value is Date {
	// Handle null and undefined
	if (value == null) return false;

	// Handle existing Date objects
	if (value instanceof Date) {
		return !isNaN(value.getTime());
	}

	// Handle numbers (timestamps)
	if (typeof value === 'number') {
		const date = new Date(value);
		return !isNaN(date.getTime());
	}

	// Handle strings
	if (typeof value === 'string') {
		// Remove common date string variations
		const cleanStr = value.trim().replace(/\s+/g, ' ');

		// Try parsing the string
		const date = new Date(cleanStr);
		if (!isNaN(date.getTime())) {
			return true;
		}

		// Try parsing ISO format specifically
		const isoDate = Date.parse(cleanStr);
		return !isNaN(isoDate);
	}

	// Handle arrays [year, month, day, hour, minute, second]
	if (Array.isArray(value)) {
		const array = value as Array<any>;

		if (array.length === 0) return false;

		// @ts-ignore
		const date = new Date(...array);
		return !isNaN(date.getTime());
	}

	// All other types are invalid
	return false;
}
