import { format, isAfter, isBefore, isEqual, isWithinInterval, subDays } from 'date-fns';
import { FieldErrors, SetValueConfig } from 'react-hook-form';
import { HeaderGroup } from 'react-table';
import { cloneDeep, isEmpty, keys, isEqual as isObjectEqual, omit } from 'lodash';
import * as yup from 'yup';

import { ApiEntryValues, ApiEntryValue, ApiEntry, ApiEntryStatus, ApiSort } from 'api/data/entries';
import { EntryFilter, Operator } from 'api/data/filters';
import {
	Variable,
	VariableSet,
	VariableUniquenessType,
	TimeDurationFormat
} from 'api/data/variables';
import {
	systemGeneratedVariables,
	SYSTEM_GENERATED_STATUS_NAME,
	DATE_REGEX,
	DATE_TIME_REGEX,
	STATUS_COLUMN,
	ENTRY_METADATA_FIELD_NAMES
} from 'consts';
import {
	TIME_DURATION_TRANSLATION_MAP,
	TIME_DURATION_OPTIONS_PREFIX_KEY_MAP,
	ORDERED_TIME_DURATION_KEYS,
	excludeTimeDurationLetterRegex,
	forbiddenSymbols
} from 'timeDurationConsts';
import { MAX_SAFE_INTEGER_VARIABLE_VALUE } from 'statics';
import {
	EntriesById,
	DynamicFormValues,
	Entry,
	EntryValue,
	Entries,
	DynamicFormValue,
	EntryValues,
	StatusObservationData,
	EntryStatus,
	EntriesErrors,
	EntriesErrorsFilter,
	EntryStatusValue,
	ConflictedData,
	EntryNamesFromUserIds
} from 'store/data/entries';
import { StatusesMap } from 'store/data/statuses';
import { VariablesMap, VariablesData } from 'store/data/variables';
import { StringMap, GenericMap } from 'types/index';

import { TranslateFunction, getTranslation } from 'hooks/store/ui/useTranslation';

import { customEventFactory } from './events';
import { parseFilterNumber, matchTextFilter } from './filters';
import { buildVariablesRichData, variablesDataArrayIterator } from './variables';
import { objectDifference } from './objects';
import { type ActiveSort, SortingType } from 'store/ui/tables/types';
import { absWithNegative } from 'api/utils/helpers';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';

export function generateStatusObservationData(
	entryStatus: EntryStatus,
	oldEntryStatus?: EntryStatus
) {
	const observationData: StatusObservationData = {};

	if (entryStatus) {
		const { variableName, comment } = entryStatus;

		observationData[variableName] = {
			value: true,
			comment
		};
	}

	if (oldEntryStatus) {
		const { variableName, comment } = oldEntryStatus;

		if (entryStatus?.variableName !== variableName) {
			observationData[variableName] = {
				value: false,
				comment
			};
		}
	}

	return observationData;
}

type StatusObj = {
	hasStatusColumn: boolean;
};

export function buildEntriesTableColumns(
	variablesData: VariablesData,
	statusObj?: StatusObj
): string[] {
	const columns: string[] = [];

	const { variablesDataArray } = buildVariablesRichData(variablesData);

	const { hasStatusColumn } = statusObj ?? { hasStatusColumn: false };

	// HANDLE STATUS COLUMN (AT POSITION 0)
	if (hasStatusColumn) {
		columns.push(STATUS_COLUMN.name);
	}

	variablesDataArrayIterator(
		variablesDataArray,
		variable => columns.push(variable.name),
		groupData => {
			const { groupVariables } = groupData;

			groupVariables.forEach(variable => columns.push(variable.name));
		},
		variableSetData => {
			const { aggregationRules } = variableSetData;

			aggregationRules.forEach(aggregationRule => columns.push(aggregationRule.name));
		}
	);

	// ADD SYSTEM GENERATED VARIABLES
	systemGeneratedVariables.forEach(variableName => columns.push(variableName));

	return columns;
}

export function getEntriesTableColumnSortType(rowA: any, rowB: any, columnId: string): number {
	const valueA: string | undefined = rowA.values[columnId];
	const valueB: string | undefined = rowB.values[columnId];

	if (valueA && valueB) {
		const dateValueA = new Date(valueA).getTime();
		const dateValueB = new Date(valueB).getTime();

		if (dateValueA === dateValueB) return 0;

		return dateValueA > dateValueB ? 1 : -1;
	}

	if (valueA && !valueB) return 1;
	if (valueB && !valueA) return -1;

	return -1;
}

export function matchesFilter(value: EntryValue, filter: EntryFilter) {
	if (filter.invalid) return true;

	value = value as string;

	const isStatusFilter = filter.columnName === STATUS_COLUMN.name;

	if (isStatusFilter) {
		// fallback to `SYSTEM_GENERATED_STATUS_NAME` when value is `null`
		const parsedValue = value ?? SYSTEM_GENERATED_STATUS_NAME;

		if (filter.values) return filter.values.includes(parsedValue);

		return false;
	}

	if (value === null) {
		if (
			filter.filterType === VariableType.Category ||
			filter.filterType === VariableType.CategoryMultiple
		) {
			if (!filter.values?.length) return true;
		}
		return false;
	}

	switch (filter.filterType) {
		case VariableType.Category: {
			if (filter.values) return filter.values.includes(value);

			return false;
		}

		case VariableType.CategoryMultiple: {
			if (filter.values) {
				const filterValues = filter.values.filter(v => v === null || v.length > 0);

				let matches = false;

				filterValues.forEach(filterValue => {
					if (filterValue === null) {
						if (value === null) matches = true;
						return;
					}

					const found = value?.includes(filterValue);

					if (found) matches = true;
				});

				return matches;
			}

			return false;
		}

		case VariableType.Date:
		case VariableType.DateTime: {
			switch (filter.operator) {
				case Operator.Between: {
					if (filter.from && filter.to) {
						const date = new Date(value);
						const start = new Date(filter.from.toString());
						const end = new Date(filter.to.toString());

						return isWithinInterval(date, { start, end });
					}

					return false;
				}

				case Operator.Equals: {
					if (filter.value) {
						const left = new Date(value);
						const right = new Date(filter.value.toString());

						return isEqual(left, right);
					}

					return false;
				}

				case Operator.LessThan: {
					if (filter.value) {
						const left = new Date(value);
						const right = new Date(filter.value.toString());

						return isBefore(left, right);
					}

					return false;
				}

				case Operator.GreaterThan: {
					if (filter.value) {
						const left = new Date(value);
						const right = new Date(filter.value.toString());

						return isAfter(left, right);
					}

					return false;
				}

				default:
					return false;
			}
		}

		case VariableType.Float: {
			switch (filter.operator) {
				case Operator.Between: {
					if (filter.from !== undefined && filter.to !== undefined) {
						const start = parseFilterNumber(filter.from.toString(), VariableType.Float);
						const end = parseFilterNumber(filter.to.toString(), VariableType.Float);
						const val = parseFilterNumber(value, VariableType.Float);
						return val > start && val < end;
					}

					return false;
				}

				case Operator.Equals: {
					if (filter.value !== undefined) {
						return parseFilterNumber(value, VariableType.Float) === filter.value;
					}

					return false;
				}

				case Operator.LessThan: {
					if (
						filter.value !== undefined &&
						filter.value !== null &&
						!isNaN(Number(filter.value))
					) {
						return (
							parseFilterNumber(value, VariableType.Float) < (filter.value as number)
						);
					}

					return false;
				}

				case Operator.GreaterThan: {
					if (
						filter.value !== undefined &&
						filter.value !== null &&
						!isNaN(Number(filter.value))
					) {
						return (
							parseFilterNumber(value, VariableType.Float) > (filter.value as number)
						);
					}

					return false;
				}

				default:
					return false;
			}
		}

		case VariableType.Unique: {
			if (filter.filterSubType === VariableUniquenessType.Sequence) {
				switch (filter.operator) {
					case Operator.Between: {
						if (filter.from !== undefined && filter.to !== undefined) {
							const start = parseFilterNumber(filter.from.toString());
							const end = parseFilterNumber(filter.to.toString());
							const val = parseFilterNumber(value);
							return val > start && val < end;
						}

						return false;
					}

					case Operator.Equals: {
						if (filter.value !== undefined) {
							return parseFilterNumber(value) === filter.value;
						}

						return false;
					}

					case Operator.LessThan: {
						if (
							filter.value !== undefined &&
							filter.value !== null &&
							!isNaN(Number(filter.value))
						) {
							return parseFilterNumber(value) < (filter.value as number);
						}

						return false;
					}

					case Operator.GreaterThan: {
						if (
							filter.value !== undefined &&
							filter.value !== null &&
							!isNaN(Number(filter.value))
						) {
							return parseFilterNumber(value) > (filter.value as number);
						}

						return false;
					}

					default:
						return false;
				}
			}
			if (
				filter.filterSubType === VariableUniquenessType.Manual ||
				filter.filterSubType === VariableUniquenessType.UUID
			) {
				if (filter.value !== undefined && filter.value !== null) {
					return matchTextFilter(filter.value, value);
				}

				return false;
			}

			break;
		}

		case VariableType.Integer: {
			switch (filter.operator) {
				case Operator.Between: {
					if (filter.from !== undefined && filter.to !== undefined) {
						const start = parseFilterNumber(filter.from.toString());
						const end = parseFilterNumber(filter.to.toString());
						const val = parseFilterNumber(value);
						return val > start && val < end;
					}

					return false;
				}

				case Operator.Equals: {
					if (filter.value !== undefined) {
						return parseFilterNumber(value) === filter.value;
					}

					return false;
				}

				case Operator.LessThan: {
					if (
						filter.value !== undefined &&
						filter.value !== null &&
						!isNaN(Number(filter.value))
					) {
						return parseFilterNumber(value) < (filter.value as number);
					}

					return false;
				}

				case Operator.GreaterThan: {
					if (
						filter.value !== undefined &&
						filter.value !== null &&
						!isNaN(Number(filter.value))
					) {
						return parseFilterNumber(value) > (filter.value as number);
					}

					return false;
				}

				default:
					return false;
			}
		}

		case VariableType.String: {
			if (filter.value !== undefined && filter.value !== null) {
				return matchTextFilter(filter.value, value);
			}

			return false;
		}

		default:
			return false;
	}
}

export function getEntriesRows(input: {
	entries: Entries;
	filters?: EntryFilter[];
	statusesMap: StatusesMap;
	variablesMap: VariablesMap;
	errors: {
		data: EntriesErrors | null;
		filter: EntriesErrorsFilter;
	};
}) {
	const { entries, errors } = input;
	const filters = input.filters ?? [];

	let filteredEntries = cloneDeep(entries);

	// APPLY FILTERS
	filters.forEach(filter => {
		filteredEntries = filteredEntries.filter(entry =>
			matchesFilter(entry[filter.columnName], filter)
		);
	});

	// FILTER ERRORED ROWS
	if (errors.data && errors.filter.rows) {
		const entriesErrors = errors.data.rows;

		filteredEntries = filteredEntries.filter(entry => entry.datasetentryid in entriesErrors);
	}

	return filteredEntries;
}

export function buildEntriesById(entries: Entries): EntriesById {
	const entriesById: EntriesById = {};

	entries.forEach(row => {
		entriesById[row.datasetentryid] = row;
	});

	return entriesById;
}

interface GetEntriesValidationSchemaProps {
	variables: Variable[];
	translate: TranslateFunction;
}

export function getEntriesValidationSchema({
	variables,
	translate
}: GetEntriesValidationSchemaProps) {
	const greaterThan = translate(dict => dict.validation.formVariables.greaterThan);
	const lowerThan = translate(dict => dict.validation.formVariables.lowerThan);
	const mustBeBetween = translate(dict => dict.validation.formVariables.mustBeBetween);
	const and = translate(dict => dict.validation.formVariables.and);
	const fieldRequired = translate(dict => dict.validation.formVariables.fieldRequired);
	const mustBeNumeric = translate(dict => dict.validation.formVariables.numeric);
	const noDecimalsAllowed = translate(dict => dict.validation.formVariables.noDecimals);
	const mustBeUnique = translate(dict => dict.radioGroups.uniqueValue);
	const invalidDate = translate(dict => dict.validation.formVariables.invalidDate);
	const invalidDateTime = translate(dict => dict.validation.formVariables.invalidDateTime);
	const maxValueMessage = `Value is too big. Max value is ${MAX_SAFE_INTEGER_VARIABLE_VALUE}.`;

	const schema: GenericMap<any> = {};

	variables.forEach(variable => {
		const {
			name,
			type,
			obligatory,
			fixedCategories,
			categories,
			validationRange,
			validationCases,
			uniquenessType
		} = variable;

		const yupString = yup.string().trim();

		const yupFloat = yup
			.number()
			.nullable()
			.transform((v: number, o: string) => (o === '' ? null : v))
			.typeError(mustBeNumeric);

		const yupInteger = yup
			.string()
			.trim()
			.nullable()
			.transform((v: number, o: string) => (o === '' ? null : v))
			.test(
				'isDecimal',
				noDecimalsAllowed,
				value =>
					!(value + '').match(
						/(^[+-]?[1-9]\d*[,.]{1}\d*$)|(^[+-]?[1-9]\d*[,.]{1}\d*([Ee][+-]?\d+)+?$)/
					)
			)
			.matches(/(^[+-]?[1-9]\d*$)|(^[+-]?[1-9]\d*([Ee][+-]?\d+)+?$)|(^0$)/, mustBeNumeric)
			// TODO: Remove validation for max safe integer after backend extends 'int' type to support bigger numbers
			.test(
				'isMaxValue',
				maxValueMessage,
				value => value === null || Number(value) <= MAX_SAFE_INTEGER_VARIABLE_VALUE
			);

		const yupDate = yup
			.string()
			.trim()
			.nullable()
			.transform((v: number, o: string) => (o === '' ? null : v))
			.matches(DATE_REGEX, invalidDate)
			.test(
				'isBefore1000',
				getTranslation(({ validation }) => validation.formVariables.dateOutOfBounds),
				value => {
					return (
						new Date(value as string).getFullYear() >
						new Date('0999-01-01').getFullYear()
					);
				}
			);

		const yupDateTime = yup
			.string()
			.trim()
			.nullable()
			.transform((v: number, o: string) => (o === '' ? null : v))
			.matches(DATE_TIME_REGEX, invalidDateTime)
			.test(
				'isBefore1000',
				getTranslation(({ validation }) => validation.formVariables.dateOutOfBounds),
				value => {
					return (
						new Date(value as string).getFullYear() >
						new Date('0999-01-01').getFullYear()
					);
				}
			);

		const yupArray = yup.array();

		const hasValidation = validationRange && validationCases;

		const minValidationExists =
			validationCases?.minValue !== undefined && validationCases.minValue !== '';
		const maxValidationExists =
			validationCases?.maxValue !== undefined && validationCases.maxValue !== '';

		const isBetweenValidation = minValidationExists && maxValidationExists;
		const isMinValidation = minValidationExists && !maxValidationExists;
		const isMaxValidation = maxValidationExists && !minValidationExists;

		switch (type) {
			case VariableType.Date: {
				if (hasValidation) {
					const DATE_FORMAT_SHORT = 'd MMM yyyy';

					const minDate = new Date(validationCases?.minValue as string);
					const maxDate = new Date(validationCases?.maxValue as string);

					// BETWEEN
					if (isBetweenValidation) {
						const message = `${mustBeBetween} ${format(
							minDate,
							DATE_FORMAT_SHORT
						)} ${and} ${format(maxDate, DATE_FORMAT_SHORT)}`;

						const yupDateBetween = yupDate.test('isBetween', message, value => {
							if (value !== null && value !== undefined) {
								return isWithinInterval(new Date(value), {
									start: minDate,
									end: maxDate
								});
							}

							return true;
						});

						schema[name] = obligatory
							? yupDateBetween.required(fieldRequired)
							: yupDateBetween;
					}
					// MIN
					else if (isMinValidation) {
						const message = `${greaterThan} ${format(minDate, DATE_FORMAT_SHORT)}`;

						const yupDateMin = yupDate.test('isMin', message, value => {
							if (value !== null && value !== undefined) {
								return isAfter(new Date(value), subDays(minDate, 1));
							}

							return true;
						});

						schema[name] = obligatory ? yupDateMin.required(fieldRequired) : yupDateMin;
					}
					// MAX
					else if (isMaxValidation) {
						const message = `${lowerThan} ${format(maxDate, DATE_FORMAT_SHORT)}`;

						const yupDateMax = yupDate.test('isMax', message, value => {
							if (value !== null && value !== undefined) {
								return isBefore(new Date(value), maxDate);
							}

							return true;
						});

						schema[name] = obligatory ? yupDateMax.required(fieldRequired) : yupDateMax;
					}
				} else {
					schema[name] = obligatory ? yupDate.required(fieldRequired) : yupDate;
				}

				break;
			}

			case VariableType.DateTime: {
				if (hasValidation) {
					const DATE_TIME_FORMAT_SHORT = 'd MMM HH:mm';

					const minDateTime = new Date(validationCases?.minValue as string);
					const maxDateTime = new Date(validationCases?.maxValue as string);

					// BETWEEN
					if (isBetweenValidation) {
						const message = `${mustBeBetween} ${format(
							minDateTime,
							DATE_TIME_FORMAT_SHORT
						)} ${and} ${format(maxDateTime, DATE_TIME_FORMAT_SHORT)}`;

						const yupDateTimeBetween = yupDateTime.test('isBetween', message, value => {
							if (value !== null && value !== undefined) {
								return isWithinInterval(new Date(value), {
									start: minDateTime,
									end: maxDateTime
								});
							}

							return true;
						});

						schema[name] = obligatory
							? yupDateTimeBetween.required(fieldRequired)
							: yupDateTimeBetween;
					}
					// MIN
					else if (isMinValidation) {
						const message = `${greaterThan} ${format(
							minDateTime,
							DATE_TIME_FORMAT_SHORT
						)}`;

						const yupDateTimeMin = yupDateTime.test('isMin', message, value => {
							if (value !== null && value !== undefined) {
								return isAfter(new Date(value), minDateTime);
							}

							return true;
						});

						schema[name] = obligatory
							? yupDateTimeMin.required(fieldRequired)
							: yupDateTimeMin;
					}
					// MAX
					else if (isMaxValidation) {
						const message = `${lowerThan} ${format(
							maxDateTime,
							DATE_TIME_FORMAT_SHORT
						)}`;

						const yupDateTimeMax = yupDateTime.test('isMax', message, value => {
							if (value !== null && value !== undefined) {
								return isBefore(new Date(value), maxDateTime);
							}

							return true;
						});

						schema[name] = obligatory
							? yupDateTimeMax.required(fieldRequired)
							: yupDateTimeMax;
					}
				} else {
					schema[name] = obligatory ? yupDateTime.required(fieldRequired) : yupDateTime;
				}

				break;
			}

			case VariableType.Float: {
				if (hasValidation) {
					const minValue = Number(validationCases?.minValue);
					const maxValue = Number(validationCases?.maxValue);

					// BETWEEN
					if (isBetweenValidation) {
						const message = `${mustBeBetween} ${minValue} ${and} ${maxValue}`;

						const yupFloatBetween = yupFloat
							.min(minValue, message)
							.max(maxValue, message);

						schema[name] = obligatory
							? yupFloatBetween.required(fieldRequired)
							: yupFloatBetween;
					}
					// MIN
					else if (isMinValidation) {
						const message = `${greaterThan} ${minValue}`;

						const yupFloatMin = yupFloat.min(minValue, message);

						schema[name] = obligatory
							? yupFloatMin.required(fieldRequired)
							: yupFloatMin;
					}
					// MAX
					else if (isMaxValidation) {
						const message = `${lowerThan} ${maxValue}`;

						const yupFloatMax = yupFloat.max(maxValue, message);

						schema[name] = obligatory
							? yupFloatMax.required(fieldRequired)
							: yupFloatMax;
					}
				} else {
					schema[name] = obligatory ? yupFloat.required(fieldRequired) : yupFloat;
				}

				break;
			}

			case VariableType.Integer: {
				if (hasValidation) {
					const minValue = Number(validationCases?.minValue);
					const maxValue = Number(validationCases?.maxValue);

					// BETWEEN
					if (isBetweenValidation) {
						const message = `${mustBeBetween} ${minValue} ${and} ${maxValue}`;

						const yupIntegerBetween = yupInteger.test(
							'isBetween',
							message,
							value =>
								value === null ||
								(Number(value) >= minValue && Number(value) <= maxValue)
						);

						schema[name] = obligatory
							? yupIntegerBetween.required(fieldRequired)
							: yupIntegerBetween;
					}
					// MIN
					else if (isMinValidation) {
						const message = `${greaterThan} ${minValue}`;

						const yupIntegerMin = yupInteger.test(
							'isMin',
							message,
							value => value === null || Number(value) >= minValue
						);

						schema[name] = obligatory
							? yupIntegerMin.required(fieldRequired)
							: yupIntegerMin;
					}
					// MAX
					else if (isMaxValidation) {
						const message = `${lowerThan} ${maxValue}`;

						const yupIntegerMax = yupInteger.test(
							'isMax',
							message,
							value => value === null || Number(value) <= maxValue
						);

						schema[name] = obligatory
							? yupIntegerMax.required(fieldRequired)
							: yupIntegerMax;
					}
				} else {
					schema[name] = obligatory ? yupInteger.required(fieldRequired) : yupInteger;
				}

				break;
			}

			case VariableType.Category:
			case VariableType.CategoryMultiple: {
				const hasMultipleValues = type === VariableType.CategoryMultiple;
				const allowCreate = !fixedCategories;

				const categoriesToMatch = categories.map(c => c.value.trim());

				// CUSTOM VALUES
				if (allowCreate) {
					const customName = withCustomSuffix(name);

					schema[customName] = yupString.notOneOf(categoriesToMatch, mustBeUnique);

					// CATEGORY MULTIPLE
					if (hasMultipleValues) {
						schema[name] = yupArray.when(customName, {
							is: (customValue?: string) =>
								customValue ? customValue?.trim().length > 0 : false,
							then: yupArray,
							otherwise: obligatory ? yupArray.required(fieldRequired) : yupArray
						});
					}
					// CATEGORY
					else {
						schema[name] = yupString.when(customName, {
							is: (customValue?: string) =>
								customValue ? customValue?.trim().length > 0 : false,
							then: yupString,
							otherwise: obligatory ? yupString.required(fieldRequired) : yupString
						});
					}
				}
				// FIXED VALUES
				else {
					// CATEGORY MULTIPLE
					if (hasMultipleValues) {
						// STRING ARRAY SCHEMA
						schema[name] = obligatory ? yupArray.required(fieldRequired) : yupArray;
					}
					// CATEGORY
					else {
						// STRING SCHEMA
						schema[name] = obligatory ? yupString.required(fieldRequired) : yupString;
					}
				}

				break;
			}

			case VariableType.File: {
				schema[name] = obligatory ? yupString.required(fieldRequired) : yupString;

				break;
			}

			case VariableType.String: {
				schema[name] = obligatory ? yupString.required(fieldRequired) : yupString;

				break;
			}

			case VariableType.Unique: {
				const isUniqueManual = uniquenessType === VariableUniquenessType.Manual;

				schema[name] = isUniqueManual ? yupString.required(fieldRequired) : yupString;

				break;
			}

			default: {
				schema[name] = obligatory ? yupString.required(fieldRequired) : yupString;
				break;
			}
		}
	});

	const validationSchema = yup.object(schema);

	return { validationSchema };
}

/**
 * - parse custom values of `category` and `categoryMultiple` variable type
 * - trim values and invalidate if empty after trim
 *
 * @param values
 */
export function parseFormValues(values: DynamicFormValues): EntryValues {
	const parsedValues: EntryValues = {};

	const ignoredKeys: string[] = [];

	// PARSE CUSTOM VALUES
	Object.entries(values).forEach(([key, val]) => {
		if (ignoredKeys.includes(key)) return;

		const value = val ?? '';

		const customKey = withCustomSuffix(key);
		const customValue = values[customKey] as string | undefined;
		// HAS CUSTOM VALUE
		if (customValue !== undefined) {
			ignoredKeys.push(customKey);

			parsedValues[key] = parseValue(value, customValue);
		}
		// NO CUSTOM VALUE
		else {
			parsedValues[key] = parseValue(value);
		}
	});

	function parseValue(value: DynamicFormValue, customValue: string | null = null): EntryValue {
		let parsed: EntryValue = cloneDeep(value);
		/**
		 * trim values
		 */
		if (Array.isArray(parsed)) {
			parsed = parsed.map(v => v.trim());
		} else {
			parsed = parsed.trim();
		}
		if (customValue !== null) customValue = customValue.trim();

		// INVALIDATE CUSTOM VALUE IF EMPTY
		if (customValue !== null) {
			const isCustomValid = customValue.length > 0;

			if (!isCustomValid) customValue = null;
		}

		// MULTIPLE CATEGORY ENTRY (string[])
		if (Array.isArray(parsed)) {
			// APPEND CUSTOM VALUE
			if (customValue !== null) parsed.push(customValue);

			parsed = parsed.length ? parsed : null;
		}
		// NORMAL ENTRY (string)
		else {
			if (customValue !== null) parsed = customValue;

			parsed = parsed.length ? parsed : null;
		}

		return parsed;
	}

	return parsedValues;
}

/**
 * - parses all string numbers to real numbers (integers and floats)
 * - parses time duration entries to object type {days: number, ...}
 * @param values entry input
 * @param variablesMap variables by name
 */
export function parseFormValuesForAPI(
	values: EntryValues,
	variablesMap: VariablesMap
): ApiEntryValues {
	const parsedValues: ApiEntryValues = cloneDeep(values);

	Object.keys(parsedValues).forEach(variableName => {
		const variable = variablesMap[variableName];
		const value = parsedValues[variableName];

		if (!variable || value === null) return;

		const isInt = variable.type === VariableType.Integer;
		const isFloat = variable.type === VariableType.Float;
		const isDateTime = variable.type === VariableType.DateTime;
		const isTime = variable.type === VariableType.TimeDuration;

		if (isInt) parsedValues[variableName] = parseInt(value as string);
		if (isFloat) parsedValues[variableName] = parseFloat(value as string);
		if (isDateTime) parsedValues[variableName] = parseDateToAPIStorage(value as string);
		if (isTime && variable.durationFormat) {
			parsedValues[variableName] = getMicrosecondsFromTimeDurationString(
				value as string,
				variable.durationFormat
			);
		}
	});

	return parsedValues;
}

// (value: 11:30, format: ['hour', 'minute']) => '11h:30m'
export function parseTimeDurationEntryCell(
	value: string | undefined,
	format: TimeDurationFormat,
	translateMap?: StringMap
) {
	let parsed = value;
	if (value !== undefined) {
		parsed = value.replaceAll(excludeTimeDurationLetterRegex, '');
	}
	const prefixMap = translateMap ?? TIME_DURATION_OPTIONS_PREFIX_KEY_MAP;

	const negative = parsed?.startsWith('-');

	const values = parsed?.split(':').map(value => (!isNaN(Number(value)) ? Number(value) : null));
	const entryValues: ApiEntryValue = [];

	format.forEach((timeKey, index) => {
		// fallback to 0 here in case input is partially input (eg format = hours:minutes, value = 12 => {hours: 12})
		const value = values?.[index] ?? 0;
		entryValues.push(value + prefixMap[timeKey]);
	});

	const result = entryValues.join(':');
	// handle edge case: -0 === 0
	if (negative && !result.startsWith(`-`)) return '-' + result;
	return result;
}
/**
 * Converts from microseconds to formatted string
 * @param microseconds number
 * @param format shape of formatting rule (["hours", "minutes"])
 * @returns formatted string ("12:30")
 */
export function getTimeDurationStringFromMicroseconds(
	microseconds: number | null,
	format: TimeDurationFormat,
	squared = false
): string | null {
	const result: string[] = [];
	if (microseconds === null) {
		return null;
	}
	const negative = microseconds < 0;
	format.forEach(timeKey => {
		const unitValue = squared
			? TIME_DURATION_TRANSLATION_MAP[timeKey] * TIME_DURATION_TRANSLATION_MAP[timeKey]
			: TIME_DURATION_TRANSLATION_MAP[timeKey];
		const unitsAmount = absWithNegative((microseconds as number) / unitValue);
		result.push(unitsAmount.toString());
		(microseconds as number) -= unitsAmount * unitValue;
	});

	if (negative && Number(result[0]) >= 0) {
		result[0] = '-' + result[0];
	}

	return result.map((val, index) => (index ? Math.abs(Number(val)) : val)).join(':');
}

/**
 * Converts time duration string format value to microseconds eg: args: "12:30"  ['hours','minutes'] => number
 * @param value string in timeDuration format ("12:30");
 * @param format shape of formatting rule (["hours", "minutes"]
 * @returns number representing microseconds
 */
export function getMicrosecondsFromTimeDurationString(
	value: string,
	format: TimeDurationFormat
): number {
	let microseconds = 0;
	let negative = false;
	if (value.includes('-')) {
		negative = true;
		value = value.replaceAll('-', '');
	}
	const parsed = value.replaceAll(excludeTimeDurationLetterRegex, '');
	parsed.split(':').forEach((val, index) => {
		if (!negative) {
			return (microseconds += Number(val) * TIME_DURATION_TRANSLATION_MAP[format[index]]);
		}
		return (microseconds -= Number(val) * TIME_DURATION_TRANSLATION_MAP[format[index]]);
	});
	return microseconds;
}

/**
 *
 * @param format
 * @returns time duration format with +1 higher unit (eg: ["hours", "minutes"] => ["days", "hours", "minutes"])
 */
export function getTimeDurationFormatForAnalysis(format: TimeDurationFormat): TimeDurationFormat {
	const idx = ORDERED_TIME_DURATION_KEYS.findIndex(key => key === format[format.length - 1]);
	// should not be possible to reach here, but better be safe;
	if (idx === -1) {
		throw new Error('Received timeDuration variable type without field "durationFormat"');
	}
	// if there's no higher unit avaiable return the same format;
	if (idx === ORDERED_TIME_DURATION_KEYS.length - 1) return format;
	return [...format, ORDERED_TIME_DURATION_KEYS[idx + 1]];
}

interface GetFormInputsValidationProps {
	variableNames: string[];
	values: DynamicFormValues;
	variablesMap: VariablesMap;
	validationErrors?: StringMap;
	errors?: FieldErrors<DynamicFormValues>;
}

export function getFormInputsValidation({
	variableNames,
	values,
	variablesMap,
	validationErrors = {},
	errors = {}
}: GetFormInputsValidationProps) {
	let hasErrors = false;
	let areInputsFilled = true;

	// check if radio group has valid custom value
	// IF TRUE => don't take into consideration the radio group main value and validate the custom
	variableNames.forEach(variableName => {
		// EXTRA CHECK IN CASE OF VARIABLE SET AS PERSONAL DATA BUT COLLABORATOR DOESN'T SEE IT
		if (!variablesMap[variableName]) return;
		// INITIAL VALUES ARE NOT INITIALIZED PROPERLY
		if (!(variableName in values)) return;

		const variable = variablesMap[variableName];

		const isCalculated = variable.entryType === EntryVariableType.Calculated;

		if (isCalculated) return;

		const customName = withCustomSuffix(variableName);
		const customValue = values[customName];
		const isCustomValueValid =
			customValue !== undefined ? customValue.toString().trim() !== '' : false;

		if (isCustomValueValid) variableName = customName;

		const isValueEmpty = values[variableName]?.toString().trim() === '';

		if (isValueEmpty) areInputsFilled = false;
		if (errors[variableName] || validationErrors[variableName]) hasErrors = true;
	});

	return { hasErrors, areInputsFilled };
}

interface GetFormInputsToWatchProps {
	variableNames: string[];
	variablesMap: VariablesMap;
}

export function getFormInputsToWatch({
	variableNames,
	variablesMap
}: GetFormInputsToWatchProps): string[] {
	const inputsToWatch: string[] = [];

	variableNames.forEach(variableName => {
		// EXTRA CHECK IN CASE OF VARIABLE SET AS PERSONAL DATA BUT COLLABORATOR DOESN'T SEE IT
		if (!variablesMap[variableName]) return;

		const variable = variablesMap[variableName];

		const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(
			variable.type
		);
		const allowCreate = !variable.fixedCategories;

		inputsToWatch.push(variableName);

		if (isCategory && allowCreate) inputsToWatch.push(withCustomSuffix(variableName));
	});

	return inputsToWatch;
}

/**
 * - remove extra BE fields
 * - parse entry values
 */
export function parseApiEntry(apiEntry: ApiEntry): Entry {
	const entry = initEntry();

	// A LIST OF KEYS TO BE IGNORED FROM RESPONSE
	const keysToIgnore = [
		'active',
		'draft',
		'original_id',
		'parentsetrevisionid',
		'lastmodifiedbyuser'
	];

	for (const key in apiEntry) {
		if (keysToIgnore.includes(key)) continue;

		const apiEntryValue = apiEntry[key];

		entry[key] = parseApiEntryValue(apiEntryValue);
	}

	return entry;
}

/**
 * - stringify values
 * - null empties
 * - time duration type entry will be converted from object to string eg: {hours: 12, minutes: 30} => "12:30"
 */
export function parseApiEntryValue(apiEntryValue: ApiEntryValue): EntryValue {
	let draftValue = cloneDeep(apiEntryValue);

	let entryValue: EntryValue = null;

	// TODO: REMOVE WHEN FIXED IN BE
	// parse empty values to `null`
	if (draftValue === '') draftValue = null;

	// NUMBER
	if (typeof draftValue === 'number') {
		entryValue = draftValue.toString();
	} else {
		entryValue = draftValue;
	}

	return entryValue;
}

/**
 * transforms time duration entries: from "xxx µs" => "12h:30m";
 *
 * @param entries
 * @param variablesMap
 */
export function parseEntries(
	apiEntries: Entries,
	variablesMap: VariablesMap,
	translateMap?: StringMap
) {
	const timeDurationNames = Object.keys(variablesMap).filter(
		key => variablesMap[key].type === VariableType.TimeDuration
	);

	const dateTimeNames = Object.keys(variablesMap).filter(
		key => variablesMap[key].type === VariableType.DateTime
	);
	const prefixMap = translateMap ?? TIME_DURATION_OPTIONS_PREFIX_KEY_MAP;

	const entries = cloneDeep(apiEntries);
	entries.forEach(entry => {
		for (const key of timeDurationNames) {
			// some keys can be missing from series api calls;
			if (entry[key] !== null && entry[key] !== undefined) {
				const stringFormat = getTimeDurationStringFromMicroseconds(
					entry[key] as unknown as number | null,
					variablesMap[key].durationFormat as TimeDurationFormat
				);

				entry[key] = stringFormat;

				const stringPreview: string[] = [];

				stringFormat?.split(':').forEach((value, index) => {
					const formatKey = variablesMap[key].durationFormat?.[index];
					if (formatKey) {
						stringPreview.push(`${value}${prefixMap[formatKey]}`);
					}
				});
				if (!stringPreview.length) {
					entry[key] = '';
					return;
				}
				entry[key] = stringPreview.join(':');
			}
		}

		for (const key of dateTimeNames) {
			if (entry[key] !== null && entry[key] !== undefined) {
				entry[key] = parseDateToAPIStorage(entry[key] as string);
			}
		}
	});
	return entries;
}

/**
 * Appends the suffix `__custom` to the passed value
 *
 * @param value string to append the suffix
 */
export function withCustomSuffix(value: string): string {
	return `${value}__custom`;
}

export const entryFormFieldChangeEvent = () =>
	customEventFactory<{
		fieldName: string;
		fieldValue: DynamicFormValue;
	}>('entry-form-field-change');

export const entryFormDependenciesCheckEvent = () =>
	customEventFactory<{
		fieldNames: string[];
	}>('entry-form-dependencies-check');

export const entryFormChangePageEvent = () =>
	customEventFactory<{
		pageIndex: number;
	}>('entry-form-change-page');

export const entryFormChangePageSetEvent = () =>
	customEventFactory<{
		pageIndex: number;
	}>('entry-form-change-page-set');

export const entryFormReadOnlyModalEvent = () =>
	customEventFactory<true>('entry-form-read-only-modal');

/**
 * Parse a date object to return a string with ISO format date representing actual user input with time zone difference appended at the end.
 * This is required in order to make sure we send the new formatted date to backend as described in https://ledidi.atlassian.net/browse/PRJCTS-8776
 *
 * @param {Date} string - the date string
 * @return {string} the parsed date string
 *
 * @example
 * parseDateToAPIStorage("2024-01-17T13:35:00Z")) // set from Romania when GMT+2
 *
 * > "2024-01-18T13:35:00+02:00"
 */
export function parseDateToAPIStorage(dateInput: string) {
	if (!dateInput) return '';

	const date = new Date(zeroPadDate(dateInput));
	const year = date.getFullYear();
	const month = ('0' + (date.getMonth() + 1)).slice(-2);
	const day = ('0' + date.getDate()).slice(-2);

	const hours = ('0' + date.getHours()).slice(-2);
	const minutes = ('0' + date.getMinutes()).slice(-2);
	const seconds = ('0' + date.getSeconds()).slice(-2);

	const offsetMinutes = date.getTimezoneOffset();
	const offsetSign = offsetMinutes > 0 ? '-' : '+';
	const offsetHours = ('0' + Math.floor(Math.abs(offsetMinutes) / 60)).slice(-2);
	const offsetMinutesStr = ('0' + (Math.abs(offsetMinutes) % 60)).slice(-2);

	const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutesStr}`;

	return formattedDateTime;
}

export function setEntryFormFieldValue(
	name: string,
	value: DynamicFormValue,
	setValue: (name: string, value: DynamicFormValue, config?: SetValueConfig) => void,
	opts = {
		shouldDirty: true,
		shouldValidate: true
	}
) {
	setValue(name, value, {
		shouldDirty: opts.shouldDirty,
		shouldValidate: opts.shouldValidate
	});

	// USED TO LISTEN INDIVIDUALLY TO FIELD CHANGES
	// entryFormFieldChangeEvent().dispatch({
	// 	fieldName: name,
	// 	fieldValue: value
	// });
}

/**
 * Build a safe entry according to `columns`
 *
 * - initialize non-existing fields with `null`
 * - inject status field, if exists
 */
export function buildSafeEntry(input: {
	entry: Entry;
	entryStatus: EntryStatus;
	columns: string[];
}): Entry {
	const { entry, entryStatus, columns } = input;

	const safeEntry = initEntry();

	columns.forEach(column => {
		let entryValue = entry[column];

		// key does NOT EXIST
		if (!(column in entry)) entryValue = null;

		// set status value
		if (column === STATUS_COLUMN.name) entryValue = entryStatus?.variableName ?? null;

		safeEntry[column] = entryValue;
	});

	return safeEntry;
}

export function variableSetHasDefinedAggregation(variableSet: VariableSet): boolean {
	return variableSet.aggregationRules.length > 0;
}

export function extractStatusFromEntry(entry: EntryValues | ApiEntryValues): EntryStatus {
	let entryStatus: EntryStatus = null;

	Object.entries(entry).forEach(([key, value]) => {
		/**
		 * Javascript has a bug, `typeof null` will equal "object"
		 * so we gotta hack our way through
		 */
		const isObject = typeof value === 'object' && value !== null;

		if (isObject) {
			if (isApiEntryStatus(value)) {
				const apiEntryStatus = value as ApiEntryStatus;

				entryStatus = parseApiEntryStatus(apiEntryStatus, key);
			}
		}
	});

	return entryStatus;
}

function isApiEntryStatus(item: any): item is ApiEntryStatus {
	const variableKeys: (keyof ApiEntryStatus)[] = ['value', 'comment'];

	return variableKeys.every(key => key in item);
}

export function extractVariableNamesFromEntry(entry: Entry) {
	const variableNames: string[] = [];

	Object.entries(entry).forEach(([key, value]) => {
		if (systemGeneratedVariables.includes(key)) return;

		/**
		 * Javascript has a bug, `typeof null` will equal "object"
		 * so we gotta hack our way through
		 */
		const isObject = typeof value === 'object' && !Array.isArray(value) && value !== null;

		if (isObject) {
			if (isApiEntryStatus(value)) return;

			// TODO: maybe we'll have objects in entry values
		} else {
			variableNames.push(key);
		}
	});

	return variableNames;
}

/**
 * Build a dictionary from list of lists
 */
export function parseApiEntries(
	apiEntries: ApiEntry[],
	entriesStatuses?: GenericMap<EntryStatusValue>
): Entries {
	return apiEntries.map(apiEntry => {
		const entry = parseApiEntry(apiEntry);

		if (entriesStatuses) {
			const status = entriesStatuses[entry.datasetentryid];

			const statusValue = status ? status.variableName : null;

			entry[STATUS_COLUMN.name] = statusValue;
		}

		return entry;
	});
}

export function withoutEntryMetadata(entry: Entry): EntryValues {
	return omit(entry, ENTRY_METADATA_FIELD_NAMES);
}

export function initEntry(fields: Partial<Entry> = {}): Entry {
	return {
		enteredbyuser: fields.enteredbyuser ?? '',
		ownedbyuser: fields.ownedbyuser ?? '',
		creationdate: fields.creationdate ?? '',
		lastmodifieddate: fields.lastmodifieddate ?? '',
		userProjectOrgId: fields.userProjectOrgId ?? '',
		datasetentryid: fields.datasetentryid ?? '',
		enteredbyuserwithname: fields.enteredbyuserwithname ?? '',
		ownedbyuserwithname: fields.ownedbyuserwithname ?? ''
	};
}

/**
 * In case of grouped header and using `hiddenColumns` the user hides all the grouped headers
 * -> do not render the empty `tr.th` placeholders (cleaner UI)
 */
export function filterEmptyHeaderGroups<T extends object>(
	headerGroups: HeaderGroup<T>[]
): HeaderGroup<T>[] {
	return headerGroups.flatMap(headerGroup => {
		const filteredHeaderGroup = headerGroup.headers.filter(column => !column.placeholderOf);

		if (filteredHeaderGroup.length === 0) return [];

		return headerGroup;
	});
}

/**
 * Extracts only the fields that changed
 *
 * @param initial old entry values
 * @param current new entry values
 */
export function preparePartialEntryValues(initial: EntryValues, current: EntryValues): EntryValues {
	const updatedFields = objectDifference(initial, current);

	const partial: EntryValues = {};

	for (const key in updatedFields) {
		const element = updatedFields[key];

		if (element !== undefined) partial[key] = element;
	}

	return partial;
}

/**
 * Extracts only the valid fields
 *
 * @param entryValues entry values
 */
export function prepareValidEntryValues(entryValues: EntryValues): EntryValues {
	const valid: EntryValues = {};

	for (const key in entryValues) {
		const element = entryValues[key];

		if (element !== null) valid[key] = element;
	}

	return valid;
}

export function parseApiEntryStatus(
	apiEntryStatus: ApiEntryStatus,
	variableName: string
): EntryStatus {
	const { dueTimeStamp = null, comment = '' } = apiEntryStatus;

	const entryStatus: EntryStatus = {
		variableName,
		dueTimeStamp,
		comment
	};

	return entryStatus;
}

export function getConflictedData(input: {
	prevValues: EntryValues;
	prevStatus?: EntryStatusValue | null;
	currentValues: EntryValues;
	currentStatus?: EntryStatusValue | null;
}) {
	const { prevValues, prevStatus, currentValues, currentStatus } = input;

	const conflicts: ConflictedData = {
		values: {},
		statuses: {}
	};

	keys(currentValues).forEach(key => {
		if (key in prevValues && currentValues[key] !== prevValues[key]) {
			conflicts.values[key] = {
				previous: prevValues[key],
				current: currentValues[key]
			};
		}
	});

	if (
		prevStatus !== undefined &&
		!isObjectEqual(withoutTimestamp(currentStatus), withoutTimestamp(prevStatus))
	) {
		conflicts.statuses = {
			current: currentStatus,
			previous: prevStatus
		};
	}

	if (!conflicts || (isEmpty(conflicts.statuses) && isEmpty(conflicts.values))) return null;

	return conflicts;
}

function withoutTimestamp(status?: EntryStatus) {
	if (!status) return null;
	const { dueTimeStamp, ...res } = status;

	return res;
}

/**
 * Returns a string list with all the ids of enteredbyuser and ownedbyuser from the given entries
 * that don't exist in the names Map.
 * @param entries
 */
export function extractIdsWithoutNames(entries: Entries, names: EntryNamesFromUserIds) {
	// calling getNamesFromUserIds to get user names that we don't already have in the store
	const existingNames = Object.keys(names);
	const userIds = new Set<string>();
	entries.forEach(entry => {
		if (!existingNames.includes(entry.enteredbyuser)) userIds.add(entry.enteredbyuser);
		if (!existingNames.includes(entry.ownedbyuser)) userIds.add(entry.ownedbyuser);
	});
	return Array.from(userIds);
}

export function parseActiveSortToApiSort(activeSort: ActiveSort): ApiSort {
	const { column, order } = activeSort;

	return {
		variableName: column,
		direction: order.toUpperCase() as 'ASC' | 'DESC'
	};
}

export function parseApiSortToActiveSort(apiSort: ApiSort): ActiveSort {
	const { variableName, direction } = apiSort;

	return {
		column: variableName,
		order: direction.toLowerCase() as SortingType
	};
}

export function validateTimeDurationInput(value: string, format: TimeDurationFormat) {
	// Check format overflow (more ':' than the format allows)
	const formatLength = format.length ?? 0;
	const currentLength = value.split(':').length;
	if (currentLength > formatLength) return false;
	// Check `-` sign - it can only be at the start of the string
	if (value.substring(1).includes('-')) return false;
	// check double `:`
	if (/:{2,}/g.test(value)) return false;
	if (forbiddenSymbols.test(value)) return false;

	return true;
}
/**
 * Used for time duration input FOCUS - we remove letters on focus for UX/UI purposes;
 * @param value input value
 * @param format TimeDurationFormat
 * @returns sanitized value (removes letters- although not disallowed here they're not useful when typing)
 */
export function sanitizeTimeDurationInput(value: string) {
	return value.replaceAll(excludeTimeDurationLetterRegex, '');
}

export function getTimeDurationInputPreview(
	value: string,
	format: TimeDurationFormat,
	translateMap?: StringMap
) {
	// filter non-number characters
	if (typeof value === 'string') {
		value = value.replaceAll(excludeTimeDurationLetterRegex, '');
	}
	if (!value) {
		return value;
	}
	const prefixMap = translateMap ?? TIME_DURATION_OPTIONS_PREFIX_KEY_MAP;
	const microsecondsFormat = getMicrosecondsFromTimeDurationString(value, format);
	const stringFormat = getTimeDurationStringFromMicroseconds(microsecondsFormat, format);

	const stringPreview: string[] = [];

	stringFormat?.split(':').forEach((value, index) => {
		const key = format[index];
		if (key) {
			stringPreview.push(`${value}${prefixMap[key]}`);
		}
	});

	// case null
	if (!stringPreview.length) {
		return '';
	}
	return stringPreview.join(':');
}

/**
 * Zero pad date / datetime values if year has less than 3 characters
 *
 * avoid page crashes caused by new Date('01-01-01')
 *
 * @param dateString - input date or datetime
 * @returns value with 4-digit year
 */
export function zeroPadDate(dateString: string) {
	if (dateString === '') return dateString;

	const parts = dateString.split('-');
	let year = parts[0];

	// Check if year is less than 4 characters
	if (year.length < 4) {
		// Pad with zeros at the beginning to make it 4 characters
		year = '0'.repeat(4 - year.length) + year;
	}

	// Reassemble the date string
	const zeroPaddedDate = year + '-' + parts[1] + '-' + parts[2];

	return zeroPaddedDate;
}
