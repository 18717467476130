import { useEffect, useRef } from 'react';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import {
	selectStatusesData,
	Status,
	getStatuses,
	ActionTypes,
	selectAreStatusesFetched,
	StatusesMap
} from 'store/data/statuses';
import { useDispatch, useSelector } from 'hooks/utils';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

export type StatusesData = {
	statuses: Status[];
	statusesMap: StatusesMap;
	hasStatuses: boolean;
};

interface Props {
	lazy?: boolean;
	omitSystemGenerated?: boolean;
}

export function useStatuses(options?: Props): OperationResult<StatusesData> {
	const dispatch = useDispatch();

	const abortController = useRef(new AbortController());

	const { lazy = false, omitSystemGenerated = true } = options ?? {};

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const data = useSelector(state =>
		selectStatusesData(state.data.statuses, { omitSystemGenerated })
	);
	const fetched = useSelector(state => selectAreStatusesFetched(state.data.statuses));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_STATUSES, {
		projectId
	});

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid && !lazy) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getStatuses(abortController.current));
	}

	return [{ data, loading, error, fetched, abortController: abortController.current }, handler];
}
