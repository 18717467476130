import { useState, useEffect } from 'react';
import { makeRequest } from './util';

export type GetProjectData = {
	project: {
		organizations: {
			organizationId: number;
			organizationName: string;
		}[];

		projectOwnerDetails: {
			organization: string;
		};
	};

	metadata?: Record<string, any>;
};

export const useGetProjectQuery = ({ projectId }: { projectId: string }) => {
	const [data, setData] = useState<GetProjectData>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [fetching, setFetching] = useState(false);

	async function fetch() {
		setFetching(true);

		try {
			const result: {
				httpStatusCode: number;
			} & GetProjectData = await makeRequest({
				method: 'getProject',
				service: 'projects',
				data: {
					project: {
						projectId: parseInt(projectId)
					},
					retrieveProjectOwnerDetails: true
				}
			});

			setData(result);
		} catch (e) {
			console.error(e);
			setError('Something unexpected happened, please try again later.');
		} finally {
			setLoading(false);
			setFetching(false);
		}
	}

	useEffect(() => {
		fetch();
	}, []);

	return { data, loading, error, fetching, refetch: fetch };
};
