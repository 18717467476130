import { Entry, Variable } from '../../types';
import { EntryValue } from '../formUtils';
import { backendValueToFormValue } from './backendValueToFormValue';

export const parseBackendValues = ({
	variables,
	entry
}: {
	variables: Variable[];
	entry: Entry;
}) => {
	const valuesByVariableName: Record<string, EntryValue> = {};

	Object.entries(entry).forEach(([variableName, value]) => {
		const variable = variables.find(variable => variable.variableName === variableName);
		if (variable) {
			valuesByVariableName[variableName] = backendValueToFormValue({
				value,
				variable
			});
		}
	});

	return valuesByVariableName;
};

const defaultEmptyValueForVariable = (variable: Variable) => {
	if (variable.variableType === 'categoryMultiple') {
		return [] as string[];
	}

	return '';
};

export const defaultEmptyValueForVariables = (variables: Variable[]) => {
	const result: Record<string, any> = {};
	variables.forEach(variable => {
		result[variable.variableName] = defaultEmptyValueForVariable(variable);
	});

	return result;
};
