import { HeaderMain } from 'components/Header/HeaderMain';
import { useNavigation } from 'hooks/navigation';
import { useSelector } from 'hooks/utils';
import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectLanguage } from 'store/ui/i18n';
import { StorageKeys } from 'types';

interface Props {
	src: string;
}

export const MicroApp: React.FC<Props> = ({ src }: Props) => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const { pathname } = useLocation();
	const microAppPathRef = useRef<string>();
	const { navigate, replace } = useNavigation();

	useEffect(() => {
		const onMessage = (e: Event) => {
			if (isMountEvent(e)) {
				// In order to know the next app is ready to receive messages we wait for the onMount event to inject the access token
				const idToken = localStorage.getItem(StorageKeys.IdToken);

				if (idToken) {
					iframeRef.current?.contentWindow?.postMessage(
						{ type: 'setAccessToken', accessToken: idToken },
						'*'
					);
				}
			}

			if (isNavigationEvent(e)) {
				microAppPathRef.current = e.data.path;
				navigate(e.data.path.replace(/\/(no|en)/g, '/v2'));
			}

			if (isReplaceNavigationEvent(e)) {
				microAppPathRef.current = e.data.path;
				replace(e.data.path.replace(/\/(no|en)/g, '/v2'));
			}
		};

		window.addEventListener('message', onMessage);

		return () => {
			window.removeEventListener('message', onMessage);
		};
	}, [src]);

	const language = useSelector(state => selectLanguage(state.ui.i18n));
	useEffect(() => {
		if (iframeRef.current) {
			let lang: 'en' | 'no' = 'en';

			if (['NO', 'NB'].includes(language!)) {
				lang = 'no';
			}

			const pathWithoutV2 = pathname.replace('/v2', `/${lang}`);
			if (microAppPathRef.current !== pathWithoutV2) {
				microAppPathRef.current = pathWithoutV2;
				iframeRef.current.src = `${src}/${pathWithoutV2}`;
			}
		}
	}, [pathname, language]);

	return (
		<div>
			<HeaderMain />

			<iframe
				ref={iframeRef}
				title="MicroApp"
				src={src}
				style={{
					width: '100%',
					border: 'none',
					overflow: 'hidden',
					height: `calc(100vh - 60px)`
				}}
			/>
		</div>
	);
};

function isMountEvent(e: unknown): e is { data: { type: 'onMount' } } {
	if (typeof e !== 'object' || e === null) {
		return false;
	}

	const data = (e as { data: unknown }).data;
	if (typeof data !== 'object' || data === null) {
		return false;
	}

	return 'type' in data && (data as { type: unknown }).type === 'onMount';
}

function isNavigationEvent(e: unknown): e is { data: { type: 'navigate'; path: string } } {
	if (typeof e !== 'object' || e === null) {
		return false;
	}

	const data = (e as { data: unknown }).data;
	if (typeof data !== 'object' || data === null) {
		return false;
	}

	return 'type' in data && (data as { type: unknown }).type === 'navigate';
}

function isReplaceNavigationEvent(
	e: unknown
): e is { data: { type: 'replace-navigation'; path: string } } {
	if (typeof e !== 'object' || e === null) {
		return false;
	}

	const data = (e as { data: unknown }).data;
	if (typeof data !== 'object' || data === null) {
		return false;
	}
	return 'type' in data && (data as { type: unknown }).type === 'replace-navigation';
}
