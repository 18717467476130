import { useRef, useState } from 'react';
import { GenericMap } from 'types';
import { useImport } from './useImport';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { VariableType } from 'types/data/variables/constants';
import { DATE_FORMATS } from 'types/data/projects/import/constants';
import type { SuggestedVariableTypes } from 'api/data/projects';
export function usePreviewVariablesElementRefs() {
	const { translate } = useTranslation();
	const dateInputRefs = useRef<GenericMap<HTMLInputElement>>({});
	const dateTimeInputRefs = useRef<GenericMap<HTMLInputElement>>({});
	const containerRefs = useRef<GenericMap<HTMLDivElement>>({});
	const customFormatInputRefs = useRef<GenericMap<HTMLInputElement>>({});
	const timeDurationSourceRefs = useRef<GenericMap<HTMLInputElement>>({});
	const timeDurationFormatRefs = useRef<GenericMap<HTMLDivElement>>({});
	const [currentlyModifyingVarID, setCurrentlyModifyingVarID] = useState<string | null>(null);
	const [lastRefs, setLastRefs] = useState<(HTMLInputElement | HTMLDivElement | null)[]>([null]);
	const [
		{
			data: { formattedVariables }
		},
		{ setFormattedVariables }
	] = useImport();
	function getRefs(
		variablesWithDateFormat: string[],
		initialSuggestions: SuggestedVariableTypes[] | null
	) {
		if (currentlyModifyingVarID) {
			const variable = formattedVariables.find(v => v.id === currentlyModifyingVarID);
			if (!variable) return null;

			if (variable.type === VariableType.TimeDuration) {
				const hasSource = !!variable.timeDurationSource;
				const hasDurationFormat =
					!!variable.durationFormat &&
					variable.durationFormat?.minTimeUnit &&
					variable.durationFormat?.maxTimeUnit;

				if (!hasSource) {
					const sourceRef = timeDurationSourceRefs.current[currentlyModifyingVarID];
					const containerRef = containerRefs.current[currentlyModifyingVarID + '-source'];
					if (sourceRef) {
						return [sourceRef, containerRef];
					}
				} else if (!hasDurationFormat) {
					const formatRef = timeDurationFormatRefs.current[currentlyModifyingVarID];
					const containerRef = containerRefs.current[currentlyModifyingVarID + '-format'];
					if (formatRef) {
						return [formatRef, containerRef];
					}
				}
			}

			const hasCustomFormat = variable.dateFormat === DATE_FORMATS.Custom;
			if (
				[VariableType.Date, VariableType.DateTime].includes(
					variable.type as VariableType
				) &&
				!hasCustomFormat
			) {
				const isDateInputDisabled =
					(variablesWithDateFormat.includes(variable.previewVariableLabel) ||
						variablesWithDateFormat.includes(variable.label)) &&
					initialSuggestions?.find(
						v => v.name === variable.previewVariableLabel || v.name === variable.label
					)?.suggestedVariableType === variable.type;

				const hasDateFormat =
					variable.dateFormat && variable.dateFormat !== DATE_FORMATS.Custom;

				if (variable.type === VariableType.Date && !isDateInputDisabled && !hasDateFormat) {
					const dateRef = dateInputRefs.current[currentlyModifyingVarID];
					const containerRef = containerRefs.current[currentlyModifyingVarID];
					if (dateRef) {
						return [dateRef, containerRef];
					}
				}
				if (
					variable.type === VariableType.DateTime &&
					!isDateInputDisabled &&
					!hasDateFormat
				) {
					const dateTimeRef = dateTimeInputRefs.current[currentlyModifyingVarID];
					const containerRef = containerRefs.current[currentlyModifyingVarID];
					if (dateTimeRef) {
						return [dateTimeRef, containerRef];
					}
				}
			}
			if (variable.dateFormat === DATE_FORMATS.Custom) {
				const customRef = customFormatInputRefs.current[currentlyModifyingVarID];
				if (customRef) {
					return [customRef];
				}
			}
		}
		return null;
	}
	function setErrors() {
		if (!currentlyModifyingVarID) {
			return;
		}
		const newFormattedVariables = formattedVariables.map(variable => {
			if (variable.id === currentlyModifyingVarID) {
				const updatedVariable = {
					...variable
				};
				if (
					[VariableType.Date, VariableType.DateTime].includes(
						variable.type as VariableType
					)
				) {
					if (!variable.dateFormat) {
						updatedVariable.dateFormatError =
							variable.type === VariableType.Date
								? translate(
										dict =>
											dict.projects.createAndImport.generics.previewVariables
												.missingDateFormat
								  )
								: translate(
										dict =>
											dict.projects.createAndImport.generics.previewVariables
												.missingDateAndTimeFormat
								  );
					} else {
						if (
							variable.dateFormat === DATE_FORMATS.Custom &&
							!variable.customDateFormat
						) {
							updatedVariable.dateFormatError = translate(
								dict =>
									dict.projects.createAndImport.generics.previewVariables
										.missingCustomDateFormat
							);
						}
					}
				}

				if (variable.type === VariableType.TimeDuration) {
					if (!variable.timeDurationSource) {
						updatedVariable.timeDurationSourceError = translate(
							dict =>
								dict.projects.createAndImport.generics.previewVariables
									.missingDurationSource
						);
					}
					if (
						!variable.durationFormat?.minTimeUnit ||
						!variable.durationFormat?.maxTimeUnit
					) {
						updatedVariable.durationFormatError = translate(
							dict =>
								dict.projects.createAndImport.generics.previewVariables
									.missingDurationFormat
						);
					}
				}
				return updatedVariable;
			}
			return variable;
		});
		setFormattedVariables(newFormattedVariables);
		setLastRefs([null]);
		setCurrentlyModifyingVarID(null);
	}
	return [
		{
			currentlyModifyingVarID,
			lastRefs,
			dateInputRefs,
			dateTimeInputRefs,
			containerRefs,
			customFormatInputRefs,
			timeDurationSourceRefs,
			timeDurationFormatRefs,
			setCurrentlyModifyingVarID,
			setLastRefs,
			getRefs,
			setErrors
		}
	];
}
