import { AnalysisV2 } from 'api/data/analyses';
import { Container, ErrorMessage, Icon } from './style';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
interface Props {
	analysis: AnalysisV2;
}

export function DeprecatedAnalysis({ analysis }: Props) {
	const { translate } = useTranslation();

	return (
		<Container>
			<Icon />
			<Typography.H3 alignCenter>{`${translate(() => analysis.type)} ${translate(
				dict => dict.analysis.analyses.deprecated
			)}`}</Typography.H3>
			<Spacer size={s => s.xs} />
			<ErrorMessage data-testid="deprecated-analysis-error-message">
				{translate(dict => dict.analysis.analyses.numberPlotXY.deprecatedAction)}
			</ErrorMessage>
		</Container>
	);
}
