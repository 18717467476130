import { InlineTextInputForm } from './InlineTextInputForm';
import clsx from 'clsx';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import { useState } from 'react';
import { FormControl } from '../EntryForm';
import { useController } from 'react-hook-form';
import { OptionalDescriptionTooltip } from './OptionalDescriptionTooltip';
import { Asterisk } from 'components/UI/Asterisk';
import { Variable } from '../types';

type Props = {
	categories: { label: string; value: string }[];
	allowCreate?: boolean;
	control: FormControl;
	className?: string;
	variable: Variable;
};

export const CheckboxGroup = ({ variable, categories, allowCreate, control, className }: Props) => {
	const {
		field: { value: selectedOptions, onChange },
		fieldState
	} = useController({ control, name: variable.variableName });

	const [addedOptions, setAddedOptions] = useState<Props['categories']>([]);

	const error = fieldState.error?.message?.toString();

	const allCategories = [...categories, ...addedOptions];

	return (
		<fieldset className={clsx('flex flex-col gap-3 flex-wrap mt-4', className)}>
			<div className="flex gap-1">
				<legend className="font-semibold text-base">{variable.variableLabel}</legend>

				{variable.obligatory && <Asterisk />}

				<OptionalDescriptionTooltip description={variable.description} />
			</div>

			<div className="mt-3 flex gap-4 flex-wrap">
				{allCategories.map(category => {
					const isSelected = selectedOptions?.includes(category.value);

					const id = variable.variableName + '_' + category.value;

					return (
						<div key={category.value} className="flex gap-1 items-center">
							<input
								{...control.register(variable.variableName)}
								type="checkbox"
								value={category.value}
								id={id}
								className="hidden"
							/>

							<label
								className={clsx(
									'relative flex gap-2 items-center hover:scale-105 transition-all text-base'
								)}
								htmlFor={id}
							>
								<div
									className={clsx(
										'w-8 h-8 rounded-md border-2 ',
										!isSelected && 'border-gray-600',
										isSelected && 'bg-primary-500 border-primary-500'
									)}
								>
									{isSelected && (
										<div className="inset-0 pt-[2px] flex items-center justify-center">
											<Icon
												size={s => s.s}
												svg={Svgs.Checkmark}
												colors={{ color: Colors.white }}
											/>
										</div>
									)}
								</div>

								{category.label}
							</label>
						</div>
					);
				})}

				{allowCreate && (
					<InlineTextInputForm
						onNewOptionSubmitted={addedOption => {
							if (addedOption === '') {
								return;
							}

							onChange([...(selectedOptions || []), addedOption]);

							setAddedOptions([
								...addedOptions,
								{ value: addedOption, label: addedOption }
							]);
						}}
						bannedOptions={allCategories.map(c => c.value)}
					/>
				)}
			</div>

			{error && <div className="text-error-500 text-sm">{error}</div>}
		</fieldset>
	);
};
