import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	&.dropdown-item:hover {
		color: ${Colors.white}!important;
	}
`;

export const ItemContainer = styled.div`
	display: flex;
	gap: 8px;

	&:hover {
		color: ${Colors.white} !important;
	}
`;

type TextProps = {
	selected?: boolean;
};

export const Text = styled.div<TextProps>`
	${getTypographyCSS(t => t.Paragraph)};
	color: ${({ selected }) => (selected ? Colors.primary.normal : Colors.text.main)};
	user-select: none;

	&:hover {
		color: ${Colors.white} !important;
	}
`;
