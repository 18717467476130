import { useState } from 'react';
import { makeRequest } from './util';
type FileResponse = {
	httpStatusCode: number;
	doc: {
		fileURL: string;
		metadata: {
			fileLabel: string;
			fileName: string;
		};
	};
};
export const useLazyFileInfoQuery = () => {
	const [data, setData] = useState<FileResponse['doc']>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>();
	async function query({
		entryId,
		projectId,
		fileId
	}: {
		entryId: string;
		projectId: string;
		fileId: string;
	}) {
		setLoading(true);
		setError(undefined);

		try {
			const result: FileResponse = await makeRequest({
				method: 'getFileInfo',
				service: 'data-entry',
				data: {
					datasetentryid: entryId,
					projectId,
					fileId
				}
			});

			setData(result.doc);

			return result.doc;
		} catch (e) {
			console.error(e);
			setError('Something unexpected happened, please try again later.');
		} finally {
			setLoading(false);
		}
	}
	return { data, loading, error, query };
};
