import clsx from 'clsx';
import { Asterisk } from 'components/UI/Asterisk';

interface Props {
	required: boolean;
	label: string;
	htmlFor: string;
	disabled?: boolean;
}

export const Label = ({ required, label, htmlFor, disabled }: Props) => {
	return (
		<label
			className={clsx('font-semibold text-sm flex gap-1', disabled && 'text-gray-500')}
			htmlFor={htmlFor}
		>
			{label}

			{required && <Asterisk />}
		</label>
	);
};
