import { FormControl } from '../EntryForm';
import { Variable } from '../types';
import { useController } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { InputError } from '../component/InputError';
import { Label } from '../component/Label';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';

interface Props {
	control: FormControl;
	variable: Variable;
}

export const StringVariableInput = ({ control, variable }: Props) => {
	const { field, fieldState } = useController({
		name: variable.variableName,
		control
	});

	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const adjustHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = '0px';
			const scrollHeight = textarea.scrollHeight;

			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			textarea.style.height = scrollHeight + 'px';
		}
	};

	// Adjust height on mount and when content changes
	useEffect(() => {
		adjustHeight();
	}, [field.value]);

	return (
		<div className="flex flex-col col-span-full">
			<div className="flex items-center gap-1">
				<Label
					htmlFor={variable.variableName}
					label={variable.variableLabel}
					required={variable.obligatory}
				/>
				<OptionalDescriptionTooltip description={variable.description} />
			</div>

			<textarea
				{...field}
				ref={textareaRef}
				id={variable.variableName}
				className="rounded-md border border-gray-400 p-3 pt-[12px] text-base resize-none min-h-[40px]"
				disabled={variable.entryType === 'calculated'}
				onChange={e => {
					field.onChange(e);
					adjustHeight();
				}}
			/>

			<InputError error={fieldState.error?.message?.toString()} />
		</div>
	);
};
