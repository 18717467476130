import { useDependencies, useForms, useStatuses, useVariablesData } from 'hooks/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DependenciesData } from 'store/data/dependencies';
import { Form } from 'store/data/forms';
import { VariablesData } from 'store/data/variables';

type Args = {
	dependenciesData: DependenciesData;
	hasStatuses: boolean;
	forms: Form[];
	variablesData: VariablesData;
};

export const isV1_5project = ({ dependenciesData, hasStatuses, forms, variablesData }: Args) => {
	// No statuses
	if (hasStatuses) {
		return false;
	}

	// No dependencies
	if (dependenciesData.active && dependenciesData.dependencies.length > 0) {
		return false;
	}

	// No custom forms
	for (const form of forms) {
		if (form.active) {
			return false;
		}
	}

	// No series
	if (Object.entries(variablesData.variableSetsMap).length > 0) {
		return false;
	}

	return true;
};

export const useShouldUseV1_5Routing = () => {
	const { entryV15 } = useFlags();

	// Adding lazy: true because we need to fetch variables first
	// In the context we are using this hook, variables and dependencies are fetched eventually which then means we get
	// the updated data in the end.
	const [{ data: dependenciesData }] = useDependencies({ lazy: true });
	const [{ data: statusesData }] = useStatuses();
	const [{ data: formsData }] = useForms();

	// honestly dont know what 'initial' is supposed to do here, just keeping it as it was like this in the Entries component.
	const variablesData = useVariablesData({ initial: true });

	if (!dependenciesData || !statusesData || !formsData || !variablesData) {
		return false;
	}

	return (
		entryV15 &&
		isV1_5project({
			dependenciesData,
			hasStatuses: statusesData.statuses.length > 0,
			forms: formsData,
			variablesData
		})
	);
};
