import { useTranslation } from 'hooks/store';
import { ApiErrorCountByType, VariableErrorValue } from 'types/data/projects/types';
import {
	ClickableBox,
	ResponsiveWrapper,
	TableRow,
	Table as TableContainer,
	TableHead,
	ErrorWrapper
} from './tablesByType/tableParts/tableParts.style';
import { useLayoutEffect, useMemo, useState } from 'react';
import { Colors, Svgs } from 'environment';
import { Tag } from 'components/UI/Tags';
import { Icon } from 'components/UI/Icons';
import { ApiImportErrorType } from 'store/data/projects/constants';
import { getErrorTypeLabel } from './helpers';
import { TableColumn } from './tablesByType/tableParts/tableParts';
import { CouldNotParseValueTable } from './tablesByType/CouldNotParseValueTable';
import { NotAllowedFixedCategoryValueTable } from './tablesByType/NotAllowedFixedCategoryValueTable';
import { MissingValueForUniqueVariableTable } from './tablesByType/MissingValueForUniqueVariableTable';
import { DuplicateValueForUniqueVariableTable } from './tablesByType/DuplicateValueForUniqueVariableTable';
import { NotAllowedToSetValueForUniqueVariableTable } from './tablesByType/NotAllowedToSetValueForUniqueVariableTable';
import { NonExistentDateTimeTable } from './tablesByType/NonExistentDateTimeTable';
import { DateTimeExistsTwiceTable } from './tablesByType/DateTimeExistsTwiceTable';
import { NoMatchingEntryTable } from './tablesByType/NoMatchingEntryTable';
import { DuplicateMatchingEntryTable } from './tablesByType/DuplicateMatchingEntryTable';
import { DuplicateMatchingValueTable } from './tablesByType/DuplicateMatchingValueTable';

type Props = {
	errorData: VariableErrorValue;
	countData: ApiErrorCountByType;
};

export function ImportErrorsTable({ errorData, countData }: Props) {
	const { translate } = useTranslation();

	const errorTypes = useMemo(
		() => Object.keys(errorData.errorsByType),
		[errorData, errorData.errorsByType]
	);

	const [activeErrorTypeIdx, setActiveErrorTypeIdx] = useState<number | null>(
		errorTypes.length === 1 ? 0 : null
	);

	useLayoutEffect(() => {
		if (errorTypes.length === 1) {
			setActiveErrorTypeIdx(0);
		} else {
			setActiveErrorTypeIdx(null);
		}
	}, [errorTypes]);

	function onHeaderClick(idx: number) {
		return () => {
			if (activeErrorTypeIdx === idx) {
				setActiveErrorTypeIdx(null);
			} else {
				setActiveErrorTypeIdx(idx);
			}
		};
	}

	function getInnerTable(type: ApiImportErrorType, idx: number) {
		switch (type) {
			case ApiImportErrorType.ImportCouldNotParseValue:
				return (
					<CouldNotParseValueTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			case ApiImportErrorType.NotAllowedFixedCategoryValue:
				return (
					<NotAllowedFixedCategoryValueTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			case ApiImportErrorType.MissingValueForUniqueVariable:
				return (
					<MissingValueForUniqueVariableTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			case ApiImportErrorType.DuplicateValueForUniqueVariable:
				return (
					<DuplicateValueForUniqueVariableTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			case ApiImportErrorType.NotAllowedToSetValueForUniqueVariable:
				return (
					<NotAllowedToSetValueForUniqueVariableTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			// TimeZone errors
			case ApiImportErrorType.NonExistentDatetime:
				return (
					<NonExistentDateTimeTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			case ApiImportErrorType.DatetimeExistsTwice:
				return (
					<DateTimeExistsTwiceTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			// Invalid matches errors
			case ApiImportErrorType.NoMatchingEntry:
				return (
					<NoMatchingEntryTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			case ApiImportErrorType.DuplicateMatchingEntry:
				return (
					<DuplicateMatchingEntryTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			case ApiImportErrorType.DuplicateMatchingValue:
				return (
					<DuplicateMatchingValueTable
						errors={errorData.errorsByType[type]}
						type={errorData.type}
						numberOfErrors={countData[type]}
						isOpen={activeErrorTypeIdx === idx}
					/>
				);
			default:
				return null;
		}
	}

	return (
		<ResponsiveWrapper noDesign id="import-review-errors-table">
			<TableContainer role="table" fullWidth>
				{errorTypes.map((type, idx) => {
					const header = (
						<TableHead data-role="head">
							<TableRow
								clickable
								flex
								data-role="row"
								style={{
									borderTop: 'none !important',
									borderBottom: 'none !important'
								}}
								borderRadius
							>
								<TableColumn
									data-role="column"
									css={`
										color: ${Colors.text.tableMain};
										width: 100%;
										padding: 0 !important;
										border-top: none !important;
										border-bottom: none !important;
									`}
								>
									<ClickableBox onClick={onHeaderClick(idx)}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											<Icon
												svg={
													activeErrorTypeIdx === idx
														? Svgs.ArrowDown
														: Svgs.ArrowRight
												}
												size={s => s.m}
											/>
											<span
												style={{
													color: Colors.text.tableMain,
													fontWeight: 'bold'
												}}
											>
												{getErrorTypeLabel(
													translate,
													type as ApiImportErrorType,
													errorData.type
												)}
											</span>
										</div>
										<Tag
											className="error-table-tag"
											title={`${countData[type]} ${
												countData[type] === 1
													? translate(
															dict => dict.import.importReview.error
													  )
													: translate(
															dict => dict.import.importReview.errors
													  )
											}`}
										/>
									</ClickableBox>
								</TableColumn>
							</TableRow>
						</TableHead>
					);

					return (
						<ErrorWrapper key={idx}>
							{header}
							{getInnerTable(type as ApiImportErrorType, idx)}
						</ErrorWrapper>
					);
				})}
			</TableContainer>
		</ResponsiveWrapper>
	);
}
