import { useEffect, useState } from 'react';
import { makeRequest } from './util';
import { Entry, EntryAccess, Status } from '../types';

export const useGetLatestDataEntryVersionQuery = ({
	entryId,
	projectId
}: {
	entryId: string;
	projectId: string;
}) => {
	const [data, setData] = useState<{
		entry: Entry;
		entryAccess: EntryAccess;
	}>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [fetching, setFetching] = useState(false);

	async function fetch() {
		setFetching(true);

		try {
			const result: {
				entry: Entry;
				entryAccess: EntryAccess;
				httpStatusCode: number;
				statuses?: Status;
				latestEntryId: string;
			} = await makeRequest({
				method: 'getLatestDataEntryVersion',
				service: 'data-entry',
				data: {
					datasetentryid: entryId,
					projectId
				}
			});

			setData({
				entry: result.entry,
				entryAccess: result.entryAccess
			});
		} catch (e) {
			console.error(e);
			setError('Something unexpected happened, please try again later.');
		} finally {
			setLoading(false);
			setFetching(false);
		}
	}

	useEffect(() => {
		fetch();
	}, []);

	return { data, loading, error, refetch: fetch, fetching };
};
