import { useEffect, useState } from 'react';

import { Svgs } from 'environment';
import {
	FormFieldPageLocationByName,
	FormGroupPaginationDataByname,
	SetGroupPaginationDataInput,
	VariableFilteringMap
} from 'store/data/entries';
import { BooleanMap } from 'types/index';
import {
	AddEditInputsAndGroups,
	AddEditInputsAndGroupsFormDesigner,
	DependenciesMapChecker,
	OpenCustomsMapChecker
} from 'components/Dataset/AddEditForm';
import { Container, Content } from 'components/Dataset/AddEditForm/AddEditForm.style';
import { useFormsDrawer } from 'components/Dataset/AddEditForm/FormsListDrawer';
import { PartiallyFilledChecker } from './PartiallyFilledChecker';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { Tooltip } from 'components/UI/Interactables/Tooltip';
import { Dropdown } from 'components/UI/Dropdown';
import { Button } from 'components/UI/Interactables/Button';
import { parseFormValues } from 'helpers/entries';
import {
	useTranslation,
	useVariablesData,
	useUpdateEntry,
	useConfirmStartedFillingForm,
	useConfirmFinishedForm,
	useFormId,
	useForms,
	useEntriesForm,
	useEntryGoToFirstFieldError,
	useEntryFiles,
	useEntryFormGroupsState,
	useForm
} from 'hooks/store';
import { useAlerts, useReactForm } from 'hooks/ui';
import { useStatic, useCompletedAction, usePrevious } from 'hooks/utils';

interface Props {
	renderFormsDrawer: boolean;
	handleThankYouMessage: () => void;
}

export function PatientEditEntryForm({ renderFormsDrawer, handleThankYouMessage }: Props) {
	const { setNotification } = useAlerts();
	const { translate } = useTranslation();

	const [tooltipContainer, setTooltipContainer] = useState<HTMLDivElement | null>(null);
	const getContainerRef = (node: HTMLDivElement | null) => {
		node && setTooltipContainer(node);
	};

	const variablesData = useVariablesData({ initial: true });

	const [{ loading: updatingEntry, error: errorUpdatingEntry }, updateEntry] = useUpdateEntry();
	const [{ loading: confirmingStartedFillingForm }, confirmStartedFillingForm] =
		useConfirmStartedFillingForm();
	const [
		{ loading: confirmingFinishedForm, error: errorConfirmingFinishedForm },
		confirmFinishedForm
	] = useConfirmFinishedForm();

	const formsDrawer = useFormsDrawer();
	const [, setFormId] = useFormId();
	const [{ data: form }] = useForm({ lazy: true });
	const [{ data: forms, fetched: areFormsFetched }] = useForms({
		lazy: true
	});

	const { values: initialValues, validationSchema, files } = useEntriesForm();
	const { goToFirstFieldError } = useEntryGoToFirstFieldError({ form });

	useEntryFiles({ entryFiles: files });

	const [partiallyFilled, setPartiallyFilled] = useState(false);
	const [openCustomsMap, setOpenCustomsMap] = useState<BooleanMap>({});
	const [variableVisibilityMap, setVariableVisibilityMap] = useState<BooleanMap>({});
	const [variableFilteringMap, setVariableFilteringMap] = useState<VariableFilteringMap>({});

	const [getFormFieldPageLocationByName, setFormFieldPageLocationByName] =
		useStatic<FormFieldPageLocationByName>({});
	const [getGroupPaginationDataByName, setGroupPaginationDataByName] =
		useStatic<FormGroupPaginationDataByname>({});

	const {
		Form,
		FormProvider,
		handleSubmit,
		getValues,
		formProviderProps,
		isDirtyAndValid: canSubmitForm,
		errors,
		isSubmitting
	} = useReactForm({
		initialValues,
		validationSchema,
		// set `shouldFocusError` to `false` to enable custom scroll-into-view with sorted errors
		shouldFocusError: false,
		enableReinitialize: true,
		registerInitialValues: true
	});

	const handleOnSubmit = handleSubmit(
		// successful submit => no errors
		() => {
			if (!canSubmitForm) return;

			const values = parseFormValues({ ...getValues() });

			updateEntry({ entryValues: values });
		},
		// failed submit => has errors
		errors =>
			goToFirstFieldError({
				fieldNames: Object.keys(errors),
				formFieldPageLocationByName: getFormFieldPageLocationByName()
			})
	);

	const { groupsExpanded, setGroupExpanded, collapseGroups, expandGroups, canExpand } =
		useEntryFormGroupsState({
			errors,
			isSubmitting
		});

	// UPDATE EFFECT
	useCompletedAction(updatingEntry, errorUpdatingEntry, () => {
		if (partiallyFilled) {
			confirmStartedFillingForm();
			setNotification({
				message: translate(dict => dict.promPatient.formUpdated)
			});
		} else {
			confirmFinishedForm();
		}
	});

	// CONFIRM COMPLETELY FILLED FORM EFFECT
	useCompletedAction(confirmingFinishedForm, errorConfirmingFinishedForm, handleThankYouMessage);

	// SWITCH TO FIRST FORM WITH ERRORS
	const wasSubmitting = usePrevious(isSubmitting);
	useEffect(() => {
		const fieldsWithErrors = Object.keys(errors);
		const hasError = fieldsWithErrors.length > 0;

		if (!(form && areFormsFetched && wasSubmitting && hasError)) return;

		const { usedVariables: formVariables } = form;

		const errorNotInCurrentForm =
			formVariables.filter(
				variableName => !!fieldsWithErrors.find(error => error === variableName)
			).length === 0;

		if (errorNotInCurrentForm) {
			const firstErroredField = fieldsWithErrors[0];
			const firstFormIdWithError = forms.find(
				({ active, usedVariables }) =>
					active && usedVariables.find(variableName => variableName === firstErroredField)
			)?.id;

			if (firstFormIdWithError) setFormId(firstFormIdWithError);
		}
	}, [form, areFormsFetched, errors, isSubmitting]);

	function setGroupPaginationData(input: SetGroupPaginationDataInput) {
		const { groupName, paginationData } = input;

		setGroupPaginationDataByName(state => {
			if (!state[groupName]) return;

			const groupPaginationData = state[groupName];

			const { pageIndex, pageSize } = paginationData;

			if (pageIndex !== undefined) groupPaginationData.pageIndex = pageIndex;
			if (pageSize !== undefined) groupPaginationData.pageSize = pageSize;
		});
	}

	const AddEditInputsAndGroupsGeneralProps = {
		...(tooltipContainer ? { tooltipContainer } : {}),
		openCustomsMap,
		variableVisibilityMap,
		variableFilteringMap,
		groupsExpanded,
		initialValues,
		setGroupExpanded,
		setFormFieldPageLocationByName,
		getGroupPaginationDataByName,
		setGroupPaginationData
	};

	const primaryButtonLoading =
		updatingEntry || confirmingStartedFillingForm || confirmingFinishedForm;

	return (
		<>
			<Header.Main
				leftComponent={
					<Flex align={a => a.center}>
						<Typography.H6 ellipsis>{`Survey`}</Typography.H6>
					</Flex>
				}
				rightComponent={
					<Flex align={a => a.center}>
						{/* FORMS DRAWER */}
						{formsDrawer.enabled && (
							<Flex
								marginOffset={{ right: 0.8 }}
								// tooltip props
								data-tip={`${formsDrawer.visible ? 'Hide' : 'Show'} forms`}
								data-for="forms-drawer"
							>
								<Icon
									title={`${formsDrawer.visible ? 'Hide' : 'Show'} forms`}
									svg={Svgs.FileText}
									variant={v => v.buttonActive}
									active={formsDrawer.visible}
									onClick={formsDrawer.toggleVisible}
								/>
								<Tooltip id="forms-drawer" delayShow={250} place="bottom" />
							</Flex>
						)}

						{/* OPTIONS */}
						{canExpand && (
							<Flex marginOffset={{ right: 2.4 }}>
								<Dropdown
									toggleComponent={({ ref, open, toggle }) => (
										<Icon
											variant={v => v.buttonActive}
											svg={Svgs.More}
											ref={ref}
											active={open}
											onClick={toggle}
										/>
									)}
									width={15}
									offset={{ top: 20, right: 0 }}
								>
									<Dropdown.Item
										title={translate(dict => dict.addEditEntry.expandGroups)}
										onClick={expandGroups}
									/>
									<Dropdown.Item
										title={translate(dict => dict.addEditEntry.collapseGroups)}
										onClick={collapseGroups}
									/>
								</Dropdown>
							</Flex>
						)}

						<Button
							title={translate(({ buttons }) =>
								partiallyFilled ? buttons.update : buttons.submit
							)}
							loading={primaryButtonLoading}
							onClick={handleOnSubmit}
						/>
					</Flex>
				}
				onLogoClick={() => null}
			/>

			{renderFormsDrawer && formsDrawer.component}

			<Container ref={getContainerRef}>
				<Content disabled={primaryButtonLoading}>
					<FormProvider {...formProviderProps}>
						<OpenCustomsMapChecker
							openCustomsMap={openCustomsMap}
							setOpenCustomsMap={setOpenCustomsMap}
						/>
						<DependenciesMapChecker
							variableVisibilityMapState={{
								variableVisibilityMap,
								setVariableVisibilityMap
							}}
							variableFilteringMapState={{
								variableFilteringMap,
								setVariableFilteringMap
							}}
							customFormContext={formProviderProps}
						/>
						<PartiallyFilledChecker setPartiallyFilled={setPartiallyFilled} />

						<Form onSubmit={handleOnSubmit}>
							{form ? (
								// CUSTOM FORM
								<AddEditInputsAndGroupsFormDesigner
									form={form}
									variablesData={variablesData}
									{...AddEditInputsAndGroupsGeneralProps}
								/>
							) : (
								// DEFAULT FORM
								<AddEditInputsAndGroups {...AddEditInputsAndGroupsGeneralProps} />
							)}
						</Form>
					</FormProvider>
				</Content>
			</Container>
		</>
	);
}
