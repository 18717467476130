import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { REVERSE_ORDER_TIME_DURATION_KEYS, TimeDurationKey } from 'timeDurationConsts';
import { SelectItem } from 'types';
import { PreviewVariable } from 'types/data/projects/import/types';
import { VariableType } from 'types/data/variables/constants';

export function handleDurationFormatChange(
	varId: string,
	variables: PreviewVariable[],
	selectItem: SelectItem,
	setVariables: (formattedVariables: PreviewVariable[]) => void,
	translate: TranslateFunction
) {
	const updatedVariables = variables.map(variable => {
		if (variable.id === varId) {
			const updatedVariable = { ...variable };
			const selectedValue = selectItem.value as TimeDurationKey;
			const selectedIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(selectedValue);

			if (!updatedVariable.durationFormat) {
				updatedVariable.durationFormat = { minTimeUnit: '', maxTimeUnit: '' };
			}

			let { minTimeUnit, maxTimeUnit } = updatedVariable.durationFormat;

			// Handle empty string case
			if (minTimeUnit === '' && maxTimeUnit === '') {
				minTimeUnit = maxTimeUnit = selectedValue;
			} else if (minTimeUnit === maxTimeUnit) {
				const currentIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(
					minTimeUnit as TimeDurationKey
				);
				if (selectedIndex > currentIndex) {
					maxTimeUnit = selectedValue;
				} else if (selectedIndex < currentIndex) {
					minTimeUnit = selectedValue;
				} else {
					// If the same value is selected again, reset to empty string
					minTimeUnit = maxTimeUnit = '';
				}
			} else {
				const minIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(
					minTimeUnit as TimeDurationKey
				);
				const maxIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(
					maxTimeUnit as TimeDurationKey
				);

				if (selectedIndex > maxIndex) {
					maxTimeUnit = selectedValue;
				} else if (selectedIndex < minIndex) {
					minTimeUnit = selectedValue;
				} else if (selectedIndex === maxIndex) {
					const newMaxIndex = Math.max(minIndex, maxIndex - 1);
					maxTimeUnit = REVERSE_ORDER_TIME_DURATION_KEYS[newMaxIndex];
				} else if (selectedIndex === minIndex) {
					const newMinIndex = Math.min(maxIndex, minIndex + 1);
					minTimeUnit = REVERSE_ORDER_TIME_DURATION_KEYS[newMinIndex];
				} else {
					minTimeUnit = REVERSE_ORDER_TIME_DURATION_KEYS[selectedIndex];
				}
			}

			updatedVariable.durationFormat = { minTimeUnit, maxTimeUnit };
			if (minTimeUnit === maxTimeUnit && minTimeUnit === '') {
				updatedVariable.durationFormatError = translate(
					dict =>
						dict.projects.createAndImport.generics.previewVariables
							.missingDurationFormat
				);
			} else {
				updatedVariable.durationFormatError = '';
			}

			return updatedVariable;
		}

		return variable;
	});

	setVariables(updatedVariables);
}
export function getTimeDurationValue(
	variable: PreviewVariable,
	translate: TranslateFunction
): string | null {
	if (variable.type === VariableType.TimeDuration && variable.durationFormat) {
		const { minTimeUnit, maxTimeUnit } = variable.durationFormat;

		const minIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(minTimeUnit as TimeDurationKey);
		const maxIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(maxTimeUnit as TimeDurationKey);

		if (minIndex === -1 || maxIndex === -1 || minIndex > maxIndex) {
			return null;
		}

		const relevantKeys = REVERSE_ORDER_TIME_DURATION_KEYS.slice(minIndex, maxIndex + 1);

		const translatedParts = relevantKeys
			.reverse()
			.map(key =>
				translate(
					dict =>
						dict.timeDurationPlaceholder.full[
							key as keyof typeof dict.timeDurationPlaceholder.full
						]
				)
			)
			.map((part: string) => part.charAt(0).toUpperCase() + part.slice(1));

		return translatedParts.join(':');
	}

	return null;
}

export function getDropdownTimeUnits(
	durationFormat: { minTimeUnit: string; maxTimeUnit: string } | undefined,
	selectItems: SelectItem[]
) {
	let relevantKeys: TimeDurationKey[] = [];
	if (durationFormat && durationFormat.minTimeUnit != '' && durationFormat.maxTimeUnit != '') {
		const minIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(
			durationFormat.minTimeUnit as TimeDurationKey
		);
		const maxIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(
			durationFormat.maxTimeUnit as TimeDurationKey
		);

		relevantKeys = REVERSE_ORDER_TIME_DURATION_KEYS.slice(minIndex, maxIndex + 1);
	}

	return selectItems.map(item => {
		return {
			...item,
			options: {
				...item.options,
				selected: relevantKeys.includes(item.value as TimeDurationKey)
			}
		};
	});
}

export function handleSourceUnitChange(
	varId: string,
	value: string,
	variables: PreviewVariable[],
	setVariables: (formattedVariables: PreviewVariable[]) => void,
	translate: TranslateFunction
) {
	const wasCleared = value === '';
	const updatedVariables = variables.map(variable => {
		if (variable.id === varId) {
			const updatedVariable = { ...variable };
			updatedVariable.timeDurationSource = value;

			if (wasCleared) {
				updatedVariable.timeDurationSourceError = translate(
					dict =>
						dict.projects.createAndImport.generics.previewVariables
							.missingDurationSource
				);
			} else {
				updatedVariable.timeDurationSourceError = '';
			}

			return updatedVariable;
		}

		return variable;
	});

	setVariables(updatedVariables);
}

export function hasPrecisionLossVariables(variables: PreviewVariable[]): boolean {
	let hasLoss = false;
	for (const variable of variables) {
		if (variable.type === VariableType.TimeDuration && variable.durationFormat) {
			const { minTimeUnit } = variable.durationFormat;
			const sourceUnit = variable.timeDurationSource;

			if (sourceUnit && minTimeUnit !== '') {
				const sourceIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(
					sourceUnit as TimeDurationKey
				);
				const maxIndex = REVERSE_ORDER_TIME_DURATION_KEYS.indexOf(
					minTimeUnit as TimeDurationKey
				);

				if (maxIndex > sourceIndex) {
					hasLoss = true;
					break;
				}
			}
		}
	}

	return hasLoss;
}
