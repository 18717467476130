import { UseFormReturn } from 'react-hook-form';

import { Variable } from 'api/data/variables';
import { VariableFilteringMap, DynamicFormValues, DynamicFormValue } from 'store/data/entries';
import { FormElement } from 'store/data/forms';
import { ElementType, BooleanMap } from 'types/index';
import { Revision } from 'store/data/revisions';

import { FormVariableInput } from './FormVariableInput';
import { FormVariableCategory } from './FormVariableCategory';
import { FormVariableNotFound } from './FormVariableNotFound';
import { FormVariableCalculated } from './FormVariableCalculated';
import { FormVariableFile } from './FormVariableFile';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';
import { TIME_DURATION_OPTIONS_PREFIX_KEY_MAP } from 'timeDurationConsts';
import { useTranslation } from 'hooks/store';
import { Dictionary } from 'environment';

export interface FormElementGeneralProps {
	// CORE
	element: FormElement;
	variable: Variable;
	value?: DynamicFormValue;
	initialValue?: DynamicFormValue;
	formContext?: UseFormReturn<DynamicFormValues>;
	borderError?: boolean;
	tooltipComponent?: JSX.Element;
	revision?: Revision;
	// FORM DESIGNER
	disabled: boolean;
	readOnly?: boolean;
	uniqueError?: string;
	canBeRemoved: boolean;
	isInGroup: boolean;
	usedInFormDesigner: boolean;
	dataTestIdEntryNumber?: number;
	/**
	 * Used for sequences of radio groups not sharing the same state due to `name` prop
	 */
	omitName?: boolean;
}

interface Props {
	element: FormElement;
	variable: Variable;
	value?: DynamicFormValue;
	initialValue?: DynamicFormValue;
	formContext?: UseFormReturn<DynamicFormValues>;
	borderError?: boolean;
	openCustomsMap?: BooleanMap;
	variableFilteringMap?: VariableFilteringMap;
	disabled?: boolean;
	revision?: Revision;
	isRevisionSelected?: boolean;
	readOnly?: boolean;
	uniqueError?: string;
	canBeRemoved?: boolean;
	isInGroup?: boolean;
	usedInFormDesigner?: boolean;
	tooltipContainer?: HTMLDivElement;
	dataTestIdEntryNumber?: number;
	/**
	 * Used for sequences of radio groups not sharing the same state due to `name` prop
	 */
	omitName?: boolean;
}

export function FormVariable({
	element,
	variable,
	value,
	initialValue,
	formContext,
	borderError,
	openCustomsMap = {},
	variableFilteringMap = {},
	disabled = false,
	readOnly,
	revision,
	isRevisionSelected,
	uniqueError,
	canBeRemoved = true,
	isInGroup = false,
	usedInFormDesigner = false,
	tooltipContainer,
	omitName,
	dataTestIdEntryNumber
}: Props) {
	const { translate } = useTranslation();
	const { elementId, elementType } = element;

	// NOT FOUND VARIABLE
	if (!variable) {
		// HIDE DELETED VARIABLE IN ADD / EDIT ENTRY FORM + IN FORM DESIGNER IF COLLABORATOR
		if (!usedInFormDesigner || (usedInFormDesigner && disabled)) return null;

		return <FormVariableNotFound elementId={elementId} />;
	}
	const tooltipComponent = !usedInFormDesigner ? (
		<InfoTooltip
			renderContext={tooltipContainer}
			iconVisible
			zIndex={100}
			marginOffset={{ bottom: 0.4 }}
			text={variable.description}
		/>
	) : undefined;

	const isCalculated = variable.entryType === EntryVariableType.Calculated;
	const isCategory = [
		ElementType.Checkboxes,
		ElementType.Radiobuttons,
		ElementType.Dropdown
	].includes(elementType);
	const isFile = elementType === ElementType.File;
	const isTimeDuration = variable.type === VariableType.TimeDuration && variable.durationFormat;
	let labelHint: string | undefined = undefined;

	const props: FormElementGeneralProps = {
		// CORE
		element,
		variable,
		value,
		formContext,
		borderError,
		initialValue,
		tooltipComponent,
		revision,
		// FORM DESIGNER
		isInGroup,
		disabled,
		readOnly,
		uniqueError,
		canBeRemoved,
		usedInFormDesigner,
		dataTestIdEntryNumber
	};

	// CALCULATED VARIABLE
	if (isCalculated) {
		if (isTimeDuration) {
			const placeholder =
				variable.durationFormat
					?.map(timeKey => {
						return translate(
							dict =>
								dict.timeDurationPlaceholder.prefix[
									timeKey as keyof typeof Dictionary.timeDurationPlaceholder.prefix
								]
						);
					})
					.join(':') ?? '';
			labelHint = `(${placeholder})`;
		}

		return <FormVariableCalculated {...props} labelHint={labelHint} />;
	}

	// CATEGORY VARIABLE
	if (isCategory) {
		return (
			<FormVariableCategory
				{...props}
				dataTestIdEntryNumber={dataTestIdEntryNumber}
				openCustomsMap={openCustomsMap}
				variableFilteringMap={variableFilteringMap}
				omitName={omitName}
				isRevisionSelected={isRevisionSelected}
			/>
		);
	}

	// FILE VARIABLE
	if (isFile) {
		return <FormVariableFile {...props} />;
	}

	if (isTimeDuration) {
		const placeholder =
			variable.durationFormat
				?.map(timeKey => TIME_DURATION_OPTIONS_PREFIX_KEY_MAP[timeKey])
				.join(':') ?? '';
		labelHint = `(${placeholder})`;
	}

	// TEXT, DATE, DATETIME, NUMBER (INTEGER / FLOAT), TIME DURATION, UNIQUE VARIABLE
	return <FormVariableInput {...props} hasHint={!!labelHint} />;
}
