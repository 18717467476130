export const TIME_UNIT_DICT = {
	full: {
		weeks: 'weeks',
		days: 'days',
		hours: 'hours',
		minutes: 'minutes',
		seconds: 'seconds',
		milliseconds: 'milliseconds',
		microseconds: 'microseconds'
	},
	short: {
		weeks: 'ww',
		days: 'dd',
		hours: 'hh',
		minutes: 'min',
		seconds: 'ss',
		milliseconds: 'ms',
		microseconds: 'mss'
	},
	prefix: {
		weeks: 'w',
		days: 'd',
		hours: 'h',
		minutes: 'm',
		seconds: 's',
		milliseconds: 'ms',
		microseconds: 'µs'
	}
};

export type TimeUnitKey = keyof typeof TIME_UNIT_DICT.full;
export type LabelFormat = keyof typeof TIME_UNIT_DICT;
