import {
	RequiredFieldsLabel,
	CookiesNotice,
	PatientFormTitle
} from 'components/Dataset/AddEditForm';
import {
	EntryStatus,
	VariableFilteringMap,
	FormFieldPageLocationByName,
	SetGroupPaginationDataInput,
	FormGroupPaginationDataByname,
	DynamicFormValue
} from 'store/data/entries';
import { Revision } from 'store/data/revisions';
import { BooleanMap, SetState, SetMutableState, GetMutableState } from 'types/index';

import { AddEditStatusInputs } from '../AddEditStatusInputs';

import { AddEditInputs } from './AddEditInputs';

import { Wrapper, Container } from './AddEditInputsAndGroups.style';

interface Props {
	tooltipContainer?: HTMLDivElement;
	groupsExpanded: BooleanMap;
	openCustomsMap: BooleanMap;
	variableVisibilityMap: BooleanMap;
	variableFilteringMap: VariableFilteringMap;
	initialValues: Record<string, DynamicFormValue>;
	revision?: Revision;
	currentChange?: number;
	readOnly?: boolean;
	isRevisionSelected?: boolean;
	uniqueFieldsError?: string[];
	selectedStatus?: EntryStatus;
	isHistoryPagination?: boolean;
	setSelectedStatus?: SetState<EntryStatus>;
	setGroupExpanded: (groupName: string, value?: boolean) => void;
	setFormFieldPageLocationByName?: SetMutableState<FormFieldPageLocationByName>;
	getGroupPaginationDataByName: GetMutableState<FormGroupPaginationDataByname>;
	setGroupPaginationData: (input: SetGroupPaginationDataInput) => void;
	setStayOnSamePage?: (flag: boolean) => void;
}

export function AddEditInputsAndGroups({
	tooltipContainer,
	groupsExpanded,
	openCustomsMap,
	variableVisibilityMap,
	variableFilteringMap,
	initialValues,
	revision,
	currentChange,
	readOnly,
	isRevisionSelected,
	uniqueFieldsError,
	selectedStatus = null,
	isHistoryPagination,
	setSelectedStatus = () => undefined,
	setGroupExpanded,
	setFormFieldPageLocationByName = () => undefined,
	getGroupPaginationDataByName,
	setGroupPaginationData,
	setStayOnSamePage
}: Props) {
	return (
		<Wrapper isHistoryPagination={isHistoryPagination}>
			<Container>
				<PatientFormTitle />

				<AddEditStatusInputs
					selectedStatus={selectedStatus}
					setSelectedStatus={setSelectedStatus}
					isRevisionSelected={isRevisionSelected}
					setStayOnSamePage={setStayOnSamePage}
				/>

				<AddEditInputs
					initialValues={initialValues}
					tooltipContainer={tooltipContainer}
					openCustomsMap={openCustomsMap}
					variableVisibilityMap={variableVisibilityMap}
					variableFilteringMap={variableFilteringMap}
					revision={revision}
					currentChange={currentChange}
					readOnly={readOnly}
					isRevisionSelected={isRevisionSelected}
					uniqueFieldsError={uniqueFieldsError}
					groupsExpanded={groupsExpanded}
					setGroupExpanded={setGroupExpanded}
					setFormFieldPageLocationByName={setFormFieldPageLocationByName}
					getGroupPaginationDataByName={getGroupPaginationDataByName}
					setGroupPaginationData={setGroupPaginationData}
				/>

				<RequiredFieldsLabel />
				<CookiesNotice />
			</Container>
		</Wrapper>
	);
}
