import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';

export const OptionalDescriptionTooltip = ({ description }: { description?: string }) => {
	if (!description) {
		return null;
	}

	return (
		<div className="group relative ml-1 mb-1">
			<Icon size={s => s.m} svg={Svgs.Information} />

			<p className="absolute z-50 left-0 top-full mt-2 p-2 bg-gray-800 text-white text-sm rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 shadow-lg w-max max-w-[30vw]">
				{description}
			</p>
		</div>
	);
};
