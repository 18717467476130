import { Dropdown } from 'components/UI/Dropdown';
import { SelectItem } from 'types';
import { Container, ItemContainer, Text } from './IntervalDropdown.style';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { useTranslation } from 'hooks/store';

type Props = {
	id?: string;
	items: SelectItem[];
	value: string | null;
	disabled?: boolean;
	error?: string;
	dropdownRef?:
		| ((instance: HTMLDivElement | null) => void)
		| React.RefObject<HTMLDivElement>
		| null;
	containerRef?:
		| ((instance: HTMLDivElement | null) => void)
		| React.RefObject<HTMLDivElement>
		| null;
	style?: React.CSSProperties;
	onSelect: (item: SelectItem) => void;
};

export function IntervalDropdown({
	value,
	error,
	disabled,
	id,
	items,
	dropdownRef,
	containerRef,
	style,
	onSelect
}: Props) {
	const { translate } = useTranslation();
	return (
		<Container id={id} ref={containerRef} style={style}>
			<Dropdown
				_buttonRef={dropdownRef as React.RefObject<HTMLDivElement>}
				disabled={disabled}
				button
				error={error}
				title={value ?? ''}
				scrollable
				placeholder={translate(
					dict =>
						dict.projects.createAndImport.generics.previewVariables
							.durationFormatPlaceholder
				)}
				toggleId={'interval-dropdown-toggle'}
			>
				{items.map((item, index) => {
					return (
						<Dropdown.Item
							key={index}
							onClick={() => onSelect(item)}
							keepOpen
							active={item.options?.selected}
							className="dropdown-item"
							id={`interval-dropdown-item-${index}`}
						>
							<ItemContainer>
								<Checkbox
									onClick={() => onSelect(item)}
									checked={item.options.selected}
								/>
								<Text
									style={{
										alignSelf: 'center'
									}}
									selected={item.options.selected}
								>
									{item.label}
								</Text>
							</ItemContainer>
						</Dropdown.Item>
					);
				})}
			</Dropdown>
		</Container>
	);
}
