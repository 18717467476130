import { logWithDetails } from 'sentry';
import { DateTime } from 'luxon';
import { isValidDate } from '../isValidDate';
import { Variable } from '../../types';
import { formatMicroseconds } from '../../inputs/time-duration/formatMicroSeconds';
import { FormFile } from '../zodUtils';

export const backendValueToFormValue = ({
	value,
	variable
}: {
	value: any;
	variable: Variable;
}) => {
	switch (variable.variableType) {
		case 'date': {
			if (value === null || value === undefined) {
				return '';
			}

			if (typeof value === 'string' && isValidDate(value)) {
				return DateTime.fromISO(value).toFormat('yyyy-MM-dd');
			}

			logWithDetails(new Error('Invalid date value'), { value, variable });

			return '';
		}

		case 'datetime': {
			if (value === null || value === undefined) {
				return '';
			}

			if (typeof value === 'string' && isValidDate(value)) {
				return DateTime.fromISO(value).toFormat("yyyy-MM-dd'T'HH:mm");
			}

			logWithDetails(new Error('Invalid date value'), { value, variable });

			return '';
		}
		case 'categoryMultiple': {
			if (
				value === null ||
				value === undefined ||
				value === '' ||
				// the backend allows us to create categories with value false when using non fixed categories, and for a brief period of time it was possible through a frontend bug, so we need this
				value === false
			) {
				return []; // we need to return an empty array to indicate to react hook form that we want to use empty array for noting selected
			}
			return value;
		}
		case 'category': {
			if (
				value === null ||
				value === undefined ||
				value === '' ||
				// the backend allows us to create categories with value false when using non fixed categories, and for a brief period of time it was possible through a frontend bug, so we need this
				value === false
			) {
				return null;
			}

			return value;
		}

		case 'file': {
			// not sure if null and undefined is needed here or if backend always gives empty string
			if (value === null || value === undefined || value === '') {
				return null;
			}

			return { type: 'backend-file', fileId: value } as FormFile;
		}

		case 'float':
		case 'integer':
		case 'string':
			if (value === null || value === undefined) {
				return '';
			}

			return value.toString();

		case 'timeDuration': {
			if (value === null || value === undefined) {
				return '';
			}

			return formatMicroseconds({
				microseconds: value,
				showUnitLabel: false,
				maxTimeUnit: variable.durationFormat.maxTimeUnit,
				minTimeUnit: variable.durationFormat.minTimeUnit
			});
		}

		default: {
			return value;
		}
	}
};
