import { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

import ReactDOM, { unstable_batchedUpdates } from 'react-dom';

import { VariableType } from 'types/data/variables/constants';

import { Button } from 'components/UI/Interactables/Button';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { Header } from 'components/Header';
import { Icon } from 'components/UI/Icons';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Input } from 'components/UI/Inputs/Input';

import { SplitButton } from 'components/UI/Interactables/SplitButton';
import { Tooltip } from 'components/UI/Interactables/Tooltip';
import { Typography } from 'components/UI/Typography';

import { PermissionTag } from 'components/UI/PermissionTag';
import { dateTimeFormatMap, PICKER_DATE_FORMAT, VERSION_DATE_YEAR_TIME_FORMAT } from 'consts';
import { Colors, Svgs } from 'environment';

import { buildVariableSetVariablesData } from 'helpers/variables';
import { buildVariablesRichData } from 'helpers/variables';
import { entryFormDependenciesCheckEvent, entryFormReadOnlyModalEvent } from 'helpers/entries';
import { loseFocus } from 'helpers/generic';
import { parseFormValues } from 'helpers/entries';
import { useCustomEventListener } from 'helpers/events';
import { printEntry } from 'helpers/printEntry';

import { selectUserDateTimeFormat } from 'store/account/subscription';
import {
	DynamicFormValues,
	Entry,
	FormFieldPageLocationByName,
	FormGroupPaginationDataByname,
	SetGroupPaginationDataInput,
	VariableFilteringMap
} from 'store/data/entries';
import {
	BooleanMap,
	EntryDrawerType,
	InputType,
	SeriesFormState,
	SetState,
	StringArrayMap
} from 'types/index';

import {
	CookiesNotice,
	DependenciesMapChecker,
	OpenCustomsMapChecker,
	RequiredFieldsLabel
} from '..';
import { AddEditInputs } from '../AddEditInputsAndGroups/AddEditInputs';
import { AddEditInputsFormDesigner } from '../AddEditInputsAndGroupsFormDesigner/AddEditInputsFormDesigner';

import { FormTitle } from '../AddEditInputsAndGroupsFormDesigner/AddEditInputsAndGroupsFormDesigner.style';

import { AggregationRulesTable } from './AggregationRulesTable';

import { useFormsDrawer } from '../FormsListDrawer/useFormsDrawer';

import {
	AggregationContainer,
	AggregationWrapper,
	ContainerGridView,
	Content,
	DisableBodyScroll,
	EntryCount,
	FlexContainer,
	FlexWrapper,
	FormWrapper,
	LoaderContainer,
	NarrowContainer,
	NavigationArrow,
	NavigationWrapper
} from './SeriesEntryForm.style';
import { RevisionChangesModal } from 'components/Dataset/Revisions/Modal';
import { useSetRevisionId } from 'hooks/store/data/revisions/useSetRevisionId';
import { useReactSeriesForm } from 'hooks/ui/useReactSeriesForm';
import { SeriesTableView } from '../SeriesTableView/SeriesTableView';
import { UnrestoredRevisionModal } from 'components/Dataset/Modals/UnrestoredRevisionModal/UnrestoredRevisionModal';
import { updateFiltersSeriesName } from 'store/data/filters';
import { setSeriesViews, setUpdateColumnsSettingsSeriesName } from 'store/data/series';

import {
	useCompletedAction,
	useDeepCompareEffect,
	useDispatch,
	useKeyPress,
	usePrevious,
	useSelector,
	useStatic
} from 'hooks/utils';
import { useNavigation, usePrompt } from 'hooks/navigation';

import {
	useSetRevision,
	useSetRevisions,
	useTranslation,
	useProject,
	useEntry,
	useUpdateSeriesEntry,
	useForms,
	useSeriesEntries,
	useCreateSeriesEntry,
	useDeleteSeriesEntry,
	useSelectedSeriesEntry,
	useVariablesData,
	useFormsBySetName,
	useSeriesEntriesForm,
	useEntryGoToFirstFieldError,
	useEntryFormGroupsState,
	useForm,
	useStatuses,
	useSeriesConflictedData,
	useForbidPersonalData,
	useProjectId
} from 'hooks/store';
import { useModalState, useWindowSize } from 'hooks/ui';
import { FieldValues, FormProviderProps, UseFormReturn } from 'react-hook-form';
import { PaginationCentered } from 'components/UI/Pagination/PaginationCentered';
import { isValidDate } from 'helpers/isValidDate';
import { getActiveForms, getFirstActiveForm } from 'helpers/forms';
import { Loader } from 'components/UI/Loader';
import { useNavigate as useHistory, useLocation } from 'react-router-dom';
import { EntryDrawer } from '../EntryDrawer';
import { EntryMobileDrawer } from '../EntryDrawer/EntryMobileDrawer';
import { ConflictsModal, MergedFormValues } from '../ConflictsModal/ConflictsModal';
import { isEmpty, keys } from 'lodash';
import PersonalDataWarning from 'components/Projects/PersonalDataWarning/PersonalDataWarning';
import { useTracking } from 'app/tracking/TrackingProvider';

type SeriesConflictDraft = Omit<MergedFormValues, 'statuses'>;

enum PromptToSaveActions {
	NEW_ENTRY = 'NEW_ENTRY',
	PREV_ENTRY = 'PREV_ENTRY',
	NEXT_ENTRY = 'NEXT_ENTRY',
	CLOSE_MODAL = 'CLOSE_MODAL',
	NAVIGATE_ENTRY = 'NAVIGATE_ENTRY',
	ROUTER_NAVIGATION = 'ROUTER_NAVIGATION',
	TO_SERIES_TABLE_VIEW = 'TO_SERIES_TABLE_VIEW',
	TO_DATASET_TABLE_VIEW = 'TO_DATASET_TABLE_VIEW'
}

type ActionOptions = {
	entryId?: string | null;
	nextPath?: string;
};

enum VIEWS {
	Details = 'details',
	History = 'revisions',
	Status = 'status'
}

interface Props {
	subEntryId: string | null;
	hasWriteAccess: boolean;
	hasDeleteAccess: boolean;
	canModify: boolean;
	isRevisionSelected?: boolean;
}

// FORM PROVIDER DATA
interface FormDataType {
	id: number;
	data: UseFormReturn<DynamicFormValues>;
	count: number;
	identifier: string | null | undefined;
	type: string;
	variableFilteringMapState: {
		variableFilteringMap: StringArrayMap;
		setVariableFilteringMap: SetState<VariableFilteringMap>;
	};
	variableVisibilityMapState: {
		variableVisibilityMap: BooleanMap;
		setVariableVisibilityMap: SetState<BooleanMap>;
	};
	FormProvider: <TFieldValues extends FieldValues, TContext = any>(
		props: FormProviderProps<TFieldValues, TContext>
	) => React.ReactElement;
}

export function SeriesEntryForm({
	subEntryId,
	hasWriteAccess,
	hasDeleteAccess,
	canModify,
	isRevisionSelected
}: Props) {
	const dispatch = useDispatch();
	const [selectedSeriesEntry, setSelectedSeriesEntry] = useSelectedSeriesEntry();
	const variablesData = useVariablesData({ initial: true });
	const variableSet = variablesData.variableSetsMap[selectedSeriesEntry.setName ?? ''];
	const { setName, setLabel, identifier, aggregationRules } = variableSet;
	const variableSetVariablesData = useMemo(
		() =>
			buildVariableSetVariablesData({
				setName,
				variablesData
			}),
		[setName, variablesData]
	);

	const { track } = useTracking();

	//DEVICE SIZES
	const { width } = useWindowSize();

	const [projectId] = useProjectId();
	const { forbidPersonalData } = useForbidPersonalData(projectId ?? '');

	const isMobileDevice = width < 768;
	const isTabletDevice = width > 768 && width < 1024;
	const isDesktopDevice = width > 1024;
	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);

	const [revisionId, setRevisionId] = useSetRevisionId();
	const [{ data: revision }] = useSetRevision({ lazy: true });
	const { translate } = useTranslation();
	const { navigate, routes, promOrProject } = useNavigation();
	const navigateToPreviousPage = useHistory();

	const [lazyRevisions, setLazyRevisions] = useState(true);

	const [{ data: revisions, loading: loadingRevisions, fetched: areRevisionsFetched }] =
		useSetRevisions({
			lazy: lazyRevisions
		});

	const [
		{
			data: { statusesMap, statuses }
		}
	] = useStatuses();

	const [{ data: project }] = useProject();

	const [{ data: mainEntry }] = useEntry({ lazy: true });

	const formsDrawer = useFormsDrawer({ setName });
	const [{ data: mainForm }] = useForm({ lazy: true, initial: true });

	const [{ fetched: areFormsFetched }] = useForms({
		lazy: true
	});

	const [
		{
			data: { entries },
			loading: loadingSeriesEntries,
			fetched: areEntriesFetched
		}
	] = useSeriesEntries(setName);

	const [{ loading: creatingSeriesEntry, error: errorCreatingSeriesEntry }, createSeriesEntry] =
		useCreateSeriesEntry();
	const [{ loading: updatingSeriesEntry, error: errorUpdatingSeriesEntry }, updateSeriesEntry] =
		useUpdateSeriesEntry();
	const [{ loading: deletingSeriesEntry, error: errorDeletingSeriesEntry }, deleteSeriesEntry] =
		useDeleteSeriesEntry();

	const { variables, variablesMap, variablesDataArray } = useMemo(
		() => buildVariablesRichData(variableSetVariablesData),
		[variableSetVariablesData]
	);
	const entryIndex = useMemo(
		() => entries.findIndex(entry => entry.datasetentryid === subEntryId),
		[entries, subEntryId]
	);

	// ENTRY INDEX =>
	// consider entryIndex = -1 for NEW ENTRY
	const { entry, prevEntry, nextEntry, lastEntry } = useMemo(() => {
		const entry = entries[entryIndex] ?? null;
		const nextEntry = entries[entryIndex + 1] ?? null;
		const lastEntry = entries[entries.length - 1] ?? null;

		let prevEntry: Entry | null = null;
		if (entries && entries.length) {
			if (entryIndex > 0) {
				prevEntry = entries[entryIndex - 1] ?? null;
			} else if (entryIndex === -1) {
				prevEntry = entries[entries.length - 1] ?? null;
			}
		}
		return { entry, prevEntry, nextEntry, lastEntry };
	}, [entryIndex, entries]);

	const formsBySetName = useFormsBySetName();

	const forms = useMemo(() => formsBySetName[setName] ?? [], [formsBySetName, setName]);
	const form = useMemo(
		() => forms.find(setForm => setForm.id === selectedSeriesEntry.subFormId),
		[forms, selectedSeriesEntry.subFormId]
	);

	// CURRENT ENTRY VALUES
	const { values: initialValues, validationSchema } = useSeriesEntriesForm(
		{
			forms,
			variables,
			variablesMap
		},
		entry
	);

	const {
		Form,
		FormProvider,
		handleSubmit,
		getValues,
		setValue,
		formProviderProps,
		isDirty: formHasChanges,
		isDirtyAndValid: canSubmitForm,
		isSubmitting,
		errors,
		reset
	} = useReactSeriesForm(
		{
			validationSchema,
			shouldFocusError: false,
			enableReinitialize: true,
			registerInitialValues: true
		},
		initialValues
	);

	const registeredValues = getValues();
	useDeepCompareEffect(() => {
		const allFields = keys(variablesData.variablesMap);
		entryFormDependenciesCheckEvent().dispatch({ fieldNames: allFields });
	}, [entryIndex, registeredValues, variablesData.variablesMap]);

	useCompletedAction(creatingSeriesEntry, errorCreatingSeriesEntry, () => {
		const allFields = keys(variablesData.variablesMap);
		entryFormDependenciesCheckEvent().dispatch({ fieldNames: allFields });
	});

	const { goToFirstFieldError } = useEntryGoToFirstFieldError({ setName, form });
	const [uniqueFieldsError, setUniqueFieldsError] = useState<string[]>([]);
	const [openCustomsMap, setOpenCustomsMap] = useState<BooleanMap>({});

	const [variableVisibilityMap, setVariableVisibilityMap] = useState<BooleanMap>({});
	const [variableFilteringMap, setVariableFilteringMap] = useState<VariableFilteringMap>({});

	const [tooltipContainer, setTooltipContainer] = useState<HTMLDivElement | null>(null);
	const getTooltipContainer = (node: HTMLDivElement | null) => {
		node && setTooltipContainer(node);
	};
	const [drawerHistoryView, setDrawerHistoryView] = useState<VIEWS | null>(null);

	// DEVICE SIZES
	function isTabletOrMobileDevice() {
		if (isTabletDevice || isMobileDevice) {
			return true;
		} else return false;
	}

	// DELETE VARIABLE SET MODAL STATE
	const [deleteEntryModalVisible, setDeleteModalVisible] = useState(false);
	const deleteEntryModal = {
		visible: deleteEntryModalVisible,
		open: () => setDeleteModalVisible(true),
		close: () => setDeleteModalVisible(false)
	};

	// PROMPT TO SAVE MODAL STATE
	const [promptToSave, setPromptToSave] = useState<PromptToSaveActions | null>(null);
	const [actionOptionsForPrompt, setActionOptionsForPrompt] = useState<ActionOptions>({});

	const promptToSaveModal = {
		action: promptToSave,
		actionOptions: actionOptionsForPrompt,
		open: (action: PromptToSaveActions, actionOptions: ActionOptions) => {
			setPromptToSave(action);
			setActionOptionsForPrompt(actionOptions);
		},
		close: () => {
			setPromptToSave(null);
			setActionOptionsForPrompt({});
		}
	};

	// RESTORE ENTRY MODAL STATE
	const [restoreEntryModalVisible, setRestoreEntryModalVisible] = useState(false);
	const restoreEntryModal = {
		visible: restoreEntryModalVisible,
		open: () => setRestoreEntryModalVisible(true),
		close: () => setRestoreEntryModalVisible(false)
	};
	const [confirmDeleteEntryInput, setConfirmDeleteEntryInput] = useState('');

	const deleteEntryConfirmationTarget = translate(
		dict => dict.dataset.seriesEntryForm.deleteEntryConfirmationTarget
	);
	const isValidDeleteConfirmation = confirmDeleteEntryInput === deleteEntryConfirmationTarget;

	// REVISIONS STATES
	const [revisionsModalVisible, setRevisionsModalVisible] = useState(false);
	const [targetRevisionId, setTargetRevisionId] = useStatic<string | null>(null);

	const readOnly = isRevisionSelected || !canModify;

	/**
	 * USED FOR:
	 * - STAYING ON THE SAME PAGE AFTER CREATE / UPDATE
	 * - CREATE ENTRY WITHOUT REDIRECT + RESET FORM
	 */
	const [shouldStayOnSamePage, setStayOnSamePage] = useStatic(false);
	const [getFormFieldPageLocationByName, setFormFieldPageLocationByName] =
		useStatic<FormFieldPageLocationByName>({});
	const [getGroupPaginationDataByName, setGroupPaginationDataByName] =
		useStatic<FormGroupPaginationDataByname>({});

	const isEdit = subEntryId !== null;
	const handleOnSubmit = handleSubmit(
		// successful submit => no errors
		async () => {
			if (!isRevisionSelected) {
				if (!canSubmitForm || !hasWriteAccess) {
					resetStayOnSamePage();
					return;
				}

				if (!canSubmitForm) return;
			}

			if (uniqueFieldsError.length > 0) setUniqueFieldsError([]);

			// UPDATE ENTRY
			if (subEntryId !== null) {
				await handleUpdateEntry();
			}
			// CREATE ENTRY
			else {
				await handleCreateEntry();
			}
		},
		// failed submit => has errors
		errors => {
			resetStayOnSamePage();

			goToFirstFieldError({
				fieldNames: Object.keys(errors),
				formFieldPageLocationByName: getFormFieldPageLocationByName()
			});
		}
	);

	const { groupsExpanded, setGroupExpanded } = useEntryFormGroupsState({
		errors,
		isSubmitting,
		setName
	});

	useCustomEventListener(entryFormReadOnlyModalEvent, {
		onListen: () => {
			if (!isRevisionSelected) readOnlyModal.open();
		}
	});

	// READ ONLY MODAL STATE
	const readOnlyModal = useModalState({
		onOpen: loseFocus
	});

	/**
	 * CONFLICTS
	 */

	const [conflictedData, setConflictedData] = useSeriesConflictedData();
	const [conflictModalVisible, setConflictModalVisible] = useState(false);

	const [conflictDraft, setConflictDraft] = useState<SeriesConflictDraft>({
		values: entry
	});

	useEffect(() => {
		if (!conflictModalVisible) setConflictModalVisible(true);
	}, [conflictedData]);

	function handleMergeConflicts(data: SeriesConflictDraft) {
		const values = data.values as DynamicFormValues;
		setStayOnSamePage(true);
		reset({ ...getValues(), ...values });
		handleOnSubmit();
	}

	// DELETE ENTRY EFFECT
	useCompletedAction(deletingSeriesEntry, errorDeletingSeriesEntry, deleteEntryModal.close);

	// UPDATE ENTRY EFFECT
	useCompletedAction(
		updatingSeriesEntry ||
			!(isEmpty(conflictedData?.values) && isEmpty(conflictedData?.statuses)),

		errorUpdatingSeriesEntry,
		// SUCCESS CALLBACK
		() => {
			resetStayOnSamePage();
			restoreEntryModal.close();
		},
		// ERROR CALLBACK
		() => {
			resetStayOnSamePage();
		}
	);

	// CREATE ENTRY EFFECT
	useCompletedAction(
		creatingSeriesEntry,
		errorCreatingSeriesEntry,
		// SUCCESS CALLBACK
		() => {
			resetStayOnSamePage();
		},
		// ERROR CALLBACK
		() => {
			resetStayOnSamePage();
		}
	);

	const handleEscapePress = () => {
		if (isRevisionSelected) {
			return;
		}
		handleActions(PromptToSaveActions.CLOSE_MODAL);
	};

	useKeyPress(
		{
			onEscapeKeyPress: handleEscapePress,
			onDeleteKeyPress: isEdit && hasWriteAccess ? deleteEntryModal.open : undefined,
			onArrowLeftKeyPress: canNavigate().prev
				? () => handleActions(PromptToSaveActions.PREV_ENTRY)
				: undefined,
			onArrowRightKeyPress: canNavigate().next
				? () => handleActions(PromptToSaveActions.NEXT_ENTRY)
				: undefined
		},
		{ noModalsOpened: true }
	);

	//SERIES SCREEN VIEWS
	const { seriesViews } = useSelector(state => state.data.series);

	// SWITCH TO FIRST FORM WITH ERRORS
	const wasSubmitting = usePrevious(isSubmitting);
	useEffect(() => {
		const fieldsWithErrors = Object.keys(errors);
		const hasError = fieldsWithErrors.length > 0;

		if (!(form && wasSubmitting && areFormsFetched && hasError)) return;

		const { usedVariables: formVariables } = form;

		const errorNotInCurrentForm =
			formVariables.filter(
				variableName => !!fieldsWithErrors.find(error => error === variableName)
			).length === 0;

		if (errorNotInCurrentForm) {
			const firstErroredField = fieldsWithErrors[0];
			const firstFormIdWithError = forms.find(
				({ active, usedVariables }) =>
					active && usedVariables.find(variableName => variableName === firstErroredField)
			)?.id;

			if (firstFormIdWithError) {
				setSelectedSeriesEntry({
					...selectedSeriesEntry,
					subFormId: firstFormIdWithError
				});
			}
		}
	}, [form, errors, isSubmitting]);

	// UNMOUNT
	useEffect(() => {
		return () => setRevisionId(null);
	}, []);

	const isLatestRevisionSelected = useMemo(() => {
		return (
			revisions?.length &&
			revision?.creationDate === revisions[revisions.length - 1].creationDate
		);
	}, [revision, revisions]);

	async function handleCreateEntry() {
		const values = parseFormValues({ ...getValues() });

		const shouldSetEntryId = shouldStayOnSamePage();

		await createSeriesEntry(
			{
				setName,
				entryValues: values,
				options: {
					setEntryId: shouldSetEntryId
				}
			},
			{
				onUniqueError: variableNames => setUniqueFieldsError(variableNames)
			}
		);
	}

	async function handleUpdateEntry() {
		const values = parseFormValues({ ...getValues() });

		const shouldSetEntryId = fullScreenSeriesName ? true : shouldStayOnSamePage();

		if (subEntryId)
			await updateSeriesEntry(
				{
					setName,
					entryId: subEntryId,
					entryValues: values,
					options: {
						setEntryId: shouldSetEntryId
					}
				},
				{
					onUniqueError: variableNames => setUniqueFieldsError(variableNames)
				}
			);
	}

	function handleDeleteEntry() {
		setConfirmDeleteEntryInput('');
		if (subEntryId) {
			deleteSeriesEntry({
				setName,
				entryId: subEntryId
			});
		}
	}

	function handleAddNewSet() {
		setSelectedSeriesEntry({
			...selectedSeriesEntry,
			subEntryId: null
		});

		dispatch(
			setSeriesViews({
				seriesViews: {
					gridView: true,
					narrowTableView: false,
					tableFullSizeView: false
				}
			})
		);
	}

	const fullScreenSeriesName = useSelector(state => state.data.series.fullScreenSeriesName);

	const [unrestoredRevisionModalVisible, setUnrestoredRevisionModalVisible] = useState(false);

	function handleCloseEntry(isFullScreenSeriesName?: string | undefined | null) {
		if (revisionId && !unrestoredRevisionModalVisible && !isLatestRevisionSelected) {
			return setUnrestoredRevisionModalVisible(true);
		}

		if (isFullScreenSeriesName) {
			handleActions(PromptToSaveActions.TO_DATASET_TABLE_VIEW);
		} else {
			handleActions(PromptToSaveActions.CLOSE_MODAL);
		}
	}

	async function handleSaveAndClose(isFullScreenSeriesName?: string | undefined | null) {
		if (
			(!isFullScreenSeriesName && !formHasChanges) ||
			(!isFullScreenSeriesName && !hasWriteAccess)
		) {
			goBackToEntry();
		}

		if (isFullScreenSeriesName && !formHasChanges) {
			navigateToPreviousPage(-1);
		}
		if (isFullScreenSeriesName && formHasChanges) {
			if (!isEmpty(conflictedData?.values) || !isEmpty(conflictedData?.statuses)) {
				return handleMergeConflicts(conflictDraft);
			}
			await handleOnSubmit();
			navigateToPreviousPage(-1);
		} else {
			if (!isEmpty(conflictedData?.values) || !isEmpty(conflictedData?.statuses)) {
				return handleMergeConflicts(conflictDraft);
			}
			handleOnSubmit();
		}
	}

	function onSaveClick() {
		setStayOnSamePage(true);
		if (!isEmpty(conflictedData?.values) || !isEmpty(conflictedData?.statuses)) {
			return handleMergeConflicts(conflictDraft);
		}
		handleOnSubmit();
	}

	function resetStayOnSamePage() {
		setStayOnSamePage(false);
	}

	const goBackToEntry = () => {
		setSelectedSeriesEntry({
			setName: null,
			subEntryId: null,
			subFormId: null
		});
	};
	const goToSeriesTableView = () => {
		if (entries.length > 0) {
			dispatch(updateFiltersSeriesName({ seriesName: setName }));
			dispatch(setUpdateColumnsSettingsSeriesName({ seriesName: setName }));

			if (isDesktopDevice && !seriesViews.narrowTableView && !seriesViews.tableFullSizeView) {
				dispatch(
					setSeriesViews({
						seriesViews: {
							narrowTableView: true,
							gridView: false,
							tableFullSizeView: false
						}
					})
				);
			} else if (isTabletDevice || isMobileDevice) {
				dispatch(
					setSeriesViews({
						seriesViews: {
							narrowTableView: false,
							gridView: false,
							tableFullSizeView: true
						}
					})
				);
			} else {
				dispatch(
					setSeriesViews({
						seriesViews: {
							gridView: false
						}
					})
				);
			}

			setSelectedSeriesEntry({
				...selectedSeriesEntry,
				subEntryId: null,
				subFormId: null
			});
		}
	};

	const goToDatasetTableView = () => {
		navigateToPreviousPage(-1);
	};

	function navigateEntry() {
		function prev() {
			if (!canNavigate().prev) return;

			let prevEntryId: string | null = null;

			// CREATE MODE
			if (subEntryId === null) {
				if (lastEntry) prevEntryId = lastEntry.datasetentryid;
			}
			// UPDATE MODE
			else {
				if (prevEntry) prevEntryId = prevEntry.datasetentryid;
			}

			setSelectedSeriesEntry({
				...selectedSeriesEntry,
				subEntryId: prevEntryId
			});
		}

		function next() {
			if (!canNavigate().next) return;

			let nextEntryId: string | null = null;

			if (nextEntry) nextEntryId = nextEntry.datasetentryid;

			setSelectedSeriesEntry({
				...selectedSeriesEntry,
				subEntryId: nextEntryId
			});
		}

		function to(entryId: string | null) {
			if (!canNavigate().to) return;

			setSelectedSeriesEntry({
				...selectedSeriesEntry,
				subEntryId: entryId
			});
		}

		return { prev, next, to };
	}

	function canNavigate() {
		const loading = creatingSeriesEntry || updatingSeriesEntry || deletingSeriesEntry;

		const prev = subEntryId !== null ? prevEntry !== null : lastEntry !== null;
		const next = subEntryId !== null;

		return { prev: !loading && prev, next: !loading && next, to: !loading };
	}

	function getIdentifyingVariableEntryValue(entry: Entry | null) {
		if (entry === null) return;

		if (identifier.variableName === null) return;
		if (!(identifier.variableName in entry)) return;

		let value = entry[identifier.variableName];

		const identifierVariable = variablesMap[identifier.variableName];

		if (value) {
			// `date`
			if (identifierVariable.type === VariableType.Date) {
				value = format(new Date(value as string), PICKER_DATE_FORMAT);
			}

			// `datetime`
			if (identifierVariable.type === VariableType.DateTime) {
				value = format(new Date(value as string), dateTimeFormatMap[dateTimeFormat]);
			}
		}

		return value && value.toString();
	}

	function closeRevisionModal() {
		setTargetRevisionId(null);
		if (setRevisionsModalVisible) setRevisionsModalVisible(false);
	}

	function handleRevisionModalPrimaryClick() {
		reset();
		setRevisionId(targetRevisionId());
		closeRevisionModal();
	}

	async function handlePromptToSaveConfirm() {
		if (!promptToSaveModal.action) return;

		await handleOnSubmit();

		const actionToFunctionCallback = getActionToFunctionCallback();
		const functionCallback = actionToFunctionCallback[promptToSaveModal.action];

		// if there is conflicts to deal with stop execution;
		if (!isEmpty(conflictedData?.values)) {
			return promptToSaveModal.close();
		}

		functionCallback(promptToSaveModal.actionOptions);
		promptToSaveModal.close();
	}

	function handlePromptToSaveDiscard() {
		if (!promptToSaveModal.action) return;

		const actionToFunctionCallback = getActionToFunctionCallback();
		const functionCallback = actionToFunctionCallback[promptToSaveModal.action];

		functionCallback(promptToSaveModal.actionOptions);
		promptToSaveModal.close();
	}

	const currentLocation = useLocation();
	const [isDiscardingChanges, setIsDiscardingChanges] = useState<boolean>(false);

	usePrompt(nextLocation => {
		const sameLocation = currentLocation.pathname === nextLocation.pathname;

		if (sameLocation) return true;

		if (formHasChanges) {
			setIsDiscardingChanges(true);
			handleActions(PromptToSaveActions.ROUTER_NAVIGATION, {
				nextPath: nextLocation.pathname
			});
			return false;
		}

		return true;
	}, formHasChanges && !isDiscardingChanges);

	function handleActions(action: PromptToSaveActions, options?: ActionOptions) {
		if (formHasChanges && !isTableView()) return promptToSaveModal.open(action, options ?? {});

		const actionToFunctionCallback = getActionToFunctionCallback();
		const functionCallback = actionToFunctionCallback[action];
		functionCallback(options ?? {});
	}

	function getActionToFunctionCallback() {
		return {
			[PromptToSaveActions.NEW_ENTRY]: handleAddNewSet,
			[PromptToSaveActions.PREV_ENTRY]: navigateEntry().prev,
			[PromptToSaveActions.NEXT_ENTRY]: () => {
				navigateEntry().next();
			},
			[PromptToSaveActions.CLOSE_MODAL]: goBackToEntry,
			[PromptToSaveActions.NAVIGATE_ENTRY]: ({ entryId }: ActionOptions) =>
				entryId && navigateEntry().to(entryId),
			[PromptToSaveActions.ROUTER_NAVIGATION]: hadleRouterNavigation,
			[PromptToSaveActions.TO_SERIES_TABLE_VIEW]: goToSeriesTableView,
			[PromptToSaveActions.TO_DATASET_TABLE_VIEW]: goToDatasetTableView
		};
	}

	function goToHomepage() {
		navigate(routes[promOrProject].list);
	}
	function hadleRouterNavigation(options: ActionOptions) {
		const { nextPath } = options;
		if (nextPath) {
			navigate(nextPath);
		}
	}

	function setGroupPaginationData(input: SetGroupPaginationDataInput) {
		const { groupName, paginationData } = input;

		setGroupPaginationDataByName(state => {
			state[groupName] = paginationData;
		});
	}

	function handlePrintEntry() {
		void track({
			eventName: 'print_entry_clicked',
			data: {
				entryType: 'entry'
			}
		});

		printEntry({ entry, form, project, variablesData: variableSetVariablesData });
	}

	const revisionDate = useMemo(() => {
		if (!revision) return undefined;
		if (revision.creationDate) {
			const creationValidDate = isValidDate(revision.creationDate)
				? revision.creationDate.replace(/\s/g, 'T')
				: revision.creationDate;

			return format(new Date(creationValidDate), VERSION_DATE_YEAR_TIME_FORMAT);
		}
	}, [revision]);
	const identifyingVariableEntryPrevValue = getIdentifyingVariableEntryValue(prevEntry);
	const entryCount = isEdit ? entryIndex + 1 : entries.length + 1;
	const entryPrevCount = isEdit ? entryIndex : entries.length;
	const parentFormName = mainForm?.name ?? translate(dict => dict.dataset.seriesEntryForm.entry);

	const loadingAction = creatingSeriesEntry || updatingSeriesEntry;
	const primaryButtonLoading = loadingAction && !shouldStayOnSamePage();
	const saveButtonLoading = loadingAction && shouldStayOnSamePage();

	function handleTableNavigation() {
		if (formHasChanges) {
			return handleActions(PromptToSaveActions.TO_SERIES_TABLE_VIEW);
		}
		if (!formHasChanges && entries.length > 0) {
			dispatch(updateFiltersSeriesName({ seriesName: setName }));
			dispatch(setUpdateColumnsSettingsSeriesName({ seriesName: setName }));

			if (isMobileDevice || isTabletDevice) {
				//FROM MOBILE OR TABLET ONLY FULL TABLE VIEW

				return dispatch(
					setSeriesViews({
						seriesViews: {
							narrowTableView: false,
							gridView: false,
							tableFullSizeView: true
						}
					})
				);
			}

			if (seriesViews.narrowTableView || seriesViews.tableFullSizeView) {
				return dispatch(
					setSeriesViews({
						seriesViews: {
							gridView: false
						}
					})
				);
			} else {
				//BY DEFAULT NARROW TABLE

				return dispatch(
					setSeriesViews({
						seriesViews: {
							narrowTableView: true,
							gridView: false,
							tableFullSizeView: false
						}
					})
				);
			}
		}
	}

	const activeForms = useMemo(() => getActiveForms(forms), [forms]);
	const firstActiveForm = useMemo(() => getFirstActiveForm(forms), [activeForms]);

	function getFirstFormId() {
		return firstActiveForm?.id ?? null;
	}

	function handleGridNavigation() {
		setSelectedSeriesEntry({
			setName,
			subEntryId: entries.length > 0 ? entries[0].datasetentryid : null,
			subFormId: getFirstFormId()
		});

		dispatch(
			setSeriesViews({
				seriesViews: {
					gridView: true
				}
			})
		);
	}

	function handleSizeTableNavigation() {
		if (seriesViews.narrowTableView) {
			dispatch(
				setSeriesViews({
					seriesViews: {
						tableFullSizeView: true,
						narrowTableView: false,
						gridView: false
					}
				})
			);
		} else {
			dispatch(
				setSeriesViews({
					seriesViews: {
						narrowTableView: true,
						tableFullSizeView: false,
						gridView: false
					}
				})
			);
		}
	}

	function isTableView() {
		const isTable = seriesViews.narrowTableView || seriesViews.tableFullSizeView;
		if (isTable && !seriesViews.gridView) {
			return true;
		}
		return false;
	}

	const formProps: FormDataType = {
		id: 1,
		variableFilteringMapState: {
			variableFilteringMap,
			setVariableFilteringMap
		},
		variableVisibilityMapState: {
			variableVisibilityMap,
			setVariableVisibilityMap
		},

		data: {
			...formProviderProps,
			setValue: setValue
		},
		FormProvider,
		type: SeriesFormState.Current,
		count: entryPrevCount,
		identifier: identifyingVariableEntryPrevValue
	};

	function onSelectRevision(id: string) {
		const nextRevisionId = id === revisionId ? null : id;

		if (!nextRevisionId) {
			return;
		}

		if (formHasChanges) {
			setTargetRevisionId(nextRevisionId);
			setRevisionsModalVisible(true);
		} else {
			setRevisionId(nextRevisionId);
		}
	}

	const getHeaderLabel = () => {
		if (isRevisionSelected && revision && revision.creationDate) {
			const headerValidDate = isValidDate(revision.creationDate)
				? revision.creationDate.replace(/\s/g, 'T')
				: revision.creationDate;

			return format(new Date(headerValidDate), VERSION_DATE_YEAR_TIME_FORMAT);
		}

		return '';
	};

	function isCurrentFormRevision(formType: string) {
		if (formType === SeriesFormState.Current) {
			return revision;
		}

		return undefined;
	}

	const AddEditInputsProps = {
		readOnly,
		isRevisionSelected,
		openCustomsMap,
		variableVisibilityMap,
		variableFilteringMap,
		groupsExpanded,
		uniqueFieldsError,
		initialValues,
		setGroupExpanded,
		setFormFieldPageLocationByName,
		getGroupPaginationDataByName,
		setGroupPaginationData,
		setName
	};

	const onResetFields = useCallback(
		(values: DynamicFormValues) => {
			unstable_batchedUpdates(() => {
				Object.entries(values).forEach(([key, value]) => setValue(key, value));
			});
		},
		[initialValues, reset]
	);

	return ReactDOM.createPortal(
		<FlexContainer direction="column">
			{!drawerHistoryView && (
				<Header.Main
					onLogoClick={() => goToHomepage()}
					leftComponent={
						<Flex>
							{isRevisionSelected && (
								<Flex align={a => a.center}>
									<Typography.H6 ellipsis>{getHeaderLabel()}</Typography.H6>
								</Flex>
							)}

							{!isRevisionSelected && (
								<>
									{/* DESKTOP DEVICE  */}
									{isDesktopDevice && (
										<>
											<NavigationWrapper
												onClick={
													loadingAction && !primaryButtonLoading
														? undefined
														: () =>
																handleCloseEntry(
																	fullScreenSeriesName
																)
												}
											>
												<Typography.H6
													style={{ maxWidth: '24rem' }}
													title={parentFormName}
													ellipsis
												>
													{parentFormName}
												</Typography.H6>
											</NavigationWrapper>

											<Icon
												svg={Svgs.ChevronRight}
												marginOffset={{ x: 0.8 }}
												colors={{ color: Colors.text.disabled }}
											/>

											<NavigationWrapper onClick={handleTableNavigation}>
												<Flex>
													<Icon
														onClick={handleTableNavigation}
														svg={Svgs.SetBig}
														marginOffset={{ right: 0.8 }}
													/>
													<Typography.H6 title={setLabel} ellipsis>
														{setLabel}
													</Typography.H6>
												</Flex>
											</NavigationWrapper>

											{seriesViews.gridView && (
												<>
													<Icon
														svg={Svgs.ChevronRight}
														marginOffset={{ x: 0.8 }}
														colors={{ color: Colors.text.disabled }}
													/>
													<NavigationWrapper
														onClick={handleGridNavigation}
													>
														<Typography.H6>
															{translate(
																dict =>
																	dict.seriesTable.seriesHeader
																		.updateEntry
															)}
														</Typography.H6>
													</NavigationWrapper>
												</>
											)}
										</>
									)}
									{/* TABLET OR MOBILE DEVICE */}
									{isTabletOrMobileDevice() && (
										<>
											{isTableView() && (
												<Flex>
													<Icon
														svg={Svgs.SetBig}
														marginOffset={{ right: 0.8 }}
													/>

													<Typography.H6
														style={{ maxWidth: '50vw' }}
														paddingOffset={{ right: 5.4 }}
														title={setLabel}
														ellipsis
													>
														{setLabel}
													</Typography.H6>
												</Flex>
											)}

											{seriesViews.gridView && (
												<Typography.H6>
													{translate(
														dict =>
															dict.seriesTable.seriesHeader
																.updateEntry
													)}
												</Typography.H6>
											)}
										</>
									)}
								</>
							)}
						</Flex>
					}
					rightComponent={
						<Flex align={a => a.center}>
							{/* DESKTOP DEVICE */}
							{isDesktopDevice && (
								<>
									{/* TABLE VIEW */}
									{isTableView() && (
										<>
											<Icon
												dataTestId={setLabel}
												disabled={!areEntriesFetched}
												svg={Svgs.ViewGrid}
												marginOffset={{ right: 2.4 }}
												variant={v => v.buttonActive}
												onClick={
													loadingAction && !primaryButtonLoading
														? undefined
														: handleGridNavigation
												}
											/>

											<Icon
												dataTestId={setLabel}
												disabled={!areEntriesFetched}
												svg={
													seriesViews.tableFullSizeView
														? Svgs.Minimize
														: Svgs.Maximize
												}
												size={s => s.l}
												marginOffset={{ right: 2.4 }}
												variant={v => v.buttonActive}
												onClick={
													loadingAction && !primaryButtonLoading
														? undefined
														: handleSizeTableNavigation
												}
											/>
										</>
									)}

									{/* GRID VIEW */}
									{entries.length > 0 &&
										seriesViews.gridView &&
										!isRevisionSelected && (
											<Icon
												dataTestId={setLabel}
												svg={Svgs.ViewTable}
												marginOffset={{ right: 2.4 }}
												variant={v => v.buttonActive}
												onClick={
													loadingAction && !primaryButtonLoading
														? undefined
														: handleTableNavigation
												}
											/>
										)}
								</>
							)}

							{/* READ ONLY PERMISSION TAG */}
							{!hasWriteAccess && (
								<Flex
									marginOffset={{ right: 2.4 }}
									// tooltip props
									data-tip={translate(
										dict => dict.dataset.seriesEntryForm.dataTip
									)}
									data-for="read-only-entry"
								>
									<PermissionTag
										title={translate(
											dict => dict.dataset.seriesEntryForm.viewOnly
										)}
									/>
									<Tooltip
										id="read-only-entry"
										delayShow={250}
										place="bottom"
										html
									/>
								</Flex>
							)}

							{/* FORMS DRAWER */}
							{seriesViews.gridView && formsDrawer.enabled && (
								<Flex
									marginOffset={{ right: 0.8 }}
									// tooltip props
									data-tip={`${
										formsDrawer.visible
											? translate(dict => dict.dataset.seriesEntryForm.hide)
											: translate(dict => dict.dataset.seriesEntryForm.show)
									} ${translate(dict => dict.dataset.seriesEntryForm.forms)}`}
									data-for="forms-drawer__series"
								>
									<Icon
										dataTestId={setLabel}
										title={translate(
											dict => dict.dataset.seriesEntryForm.formsDrawer
										)}
										svg={Svgs.FileText}
										marginOffset={{ right: 0.8 }}
										variant={v => v.buttonActive}
										active={formsDrawer.visible}
										onClick={
											loadingAction ? undefined : formsDrawer.toggleVisible
										}
									/>
									<Tooltip
										id="forms-drawer__series"
										delayShow={250}
										place="bottom"
									/>
								</Flex>
							)}

							{/* MORE OPTIONS */}
							<Flex marginOffset={!isMobileDevice && { right: 2.4 }}>
								<Dropdown
									toggleComponent={({ ref, open, toggle }) => (
										<Icon
											dataTestId={setLabel}
											disabled={!areEntriesFetched}
											variant={v => v.buttonActive}
											svg={Svgs.More}
											ref={ref}
											active={open}
											onClick={toggle}
										/>
									)}
									width={15}
									offset={{ top: 20, right: 0 }}
								>
									{/* MOBILE DEVICE ONLY, DROPDOWN */}
									{isMobileDevice && (
										<>
											{isEdit && hasWriteAccess && !isRevisionSelected && (
												<Dropdown.Item
													textColor={Colors.primary.normal}
													title={translate(
														dict =>
															dict.seriesTable.seriesHeader
																.createNewEntry
													)}
													onClick={
														loadingAction
															? undefined
															: () =>
																	handleActions(
																		PromptToSaveActions.NEW_ENTRY
																	)
													}
												/>
											)}

											{isRevisionSelected && hasWriteAccess && (
												<Dropdown.Item
													title={translate(
														({ buttons }) => buttons.restore
													)}
													onClick={restoreEntryModal.open}
												/>
											)}
											{seriesViews.gridView &&
												hasWriteAccess &&
												!isRevisionSelected && (
													<>
														<Dropdown.Item
															title={translate(
																({ buttons }) => buttons.save
															)}
															onClick={
																loadingAction && !saveButtonLoading
																	? undefined
																	: onSaveClick
															}
														/>
														<Dropdown.Item
															title={translate(
																dict =>
																	dict.dataset.addEntryForm
																		.saveAndClose
															)}
															onClick={
																loadingAction
																	? undefined
																	: () =>
																			handleSaveAndClose(
																				fullScreenSeriesName
																			)
															}
														/>
													</>
												)}
										</>
									)}
									{/* TABLET OR MOBILE DEVICE */}
									{isTabletOrMobileDevice() && (
										<>
											{/* TABLE VIEW */}
											{isTableView() && (
												<Dropdown.Item
													title={translate(
														dict =>
															dict.seriesTable.seriesHeader.gridView
													)}
													onClick={
														loadingAction && !primaryButtonLoading
															? undefined
															: handleGridNavigation
													}
												/>
											)}

											{/* GRID VIEW */}
											{seriesViews.gridView && !isRevisionSelected && (
												<>
													<Dropdown.Item
														title={translate(
															dict =>
																dict.seriesTable.seriesHeader
																	.tableView
														)}
														onClick={
															loadingAction && !primaryButtonLoading
																? undefined
																: handleTableNavigation
														}
													/>
												</>
											)}
										</>
									)}

									<Dropdown.Item
										title={translate(dict => dict.buttons.print)}
										onClick={handlePrintEntry}
									/>

									{isEdit &&
										hasWriteAccess &&
										seriesViews.gridView &&
										hasDeleteAccess && (
											<Dropdown.Item
												title={translate(
													dict => dict.dataset.seriesEntryForm.deleteEntry
												)}
												onClick={deleteEntryModal.open}
											/>
										)}
								</Dropdown>
							</Flex>

							{/* ADD NEW ENTRY IN SET */}
							{(isEdit || isTableView()) &&
								hasWriteAccess &&
								!isRevisionSelected &&
								isDesktopDevice && (
									<Button
										dataTestId={setLabel}
										variant={v => v.outline}
										title={translate(
											dict => dict.seriesTable.seriesHeader.createNewEntry
										)}
										marginOffset={{ right: 2.4 }}
										onClick={
											loadingAction
												? undefined
												: () => handleActions(PromptToSaveActions.NEW_ENTRY)
										}
									/>
								)}

							{/* ADD NEW ENTRY FOR TABLET  DEVICE */}
							{isEdit && hasWriteAccess && !isRevisionSelected && isTabletDevice && (
								<Icon
									svg={Svgs.AddSeries}
									size={s => s.xl}
									onClick={
										loadingAction
											? undefined
											: () => handleActions(PromptToSaveActions.NEW_ENTRY)
									}
									colors={{
										hover: Colors.primary.hover,
										color: Colors.primary.normal
									}}
									marginOffset={isTableView() ? undefined : { right: 2.4 }}
								/>
							)}

							{/* SAVE */}
							{hasWriteAccess &&
								!isRevisionSelected &&
								seriesViews.gridView &&
								!isMobileDevice && (
									<>
										<SplitButton
											dataTestId={setLabel}
											menuWidth={16}
											title={translate(({ buttons }) => buttons.save)}
											loading={loadingAction}
											onClick={
												loadingAction && !saveButtonLoading
													? undefined
													: onSaveClick
											}
											options={[
												{
													label: translate(
														dict =>
															dict.dataset.addEntryForm.saveAndClose
													),
													disabled: loadingAction,
													dataTestId: 'save-and-close' + '_' + setLabel,
													onClick: loadingAction
														? undefined
														: () =>
																handleSaveAndClose(
																	fullScreenSeriesName
																)
												}
											].flat()}
										/>
									</>
								)}

							{!isMobileDevice &&
								isRevisionSelected &&
								hasWriteAccess &&
								!isLatestRevisionSelected && (
									<Button
										dataTestId={setLabel}
										onClick={restoreEntryModal.open}
										title={translate(({ buttons }) => buttons.restore)}
										disabled={!!isLatestRevisionSelected}
									/>
								)}
							{/* CLOSE */}
							{
								<Icon
									dataTestId={'series-entry-form-close-icon_' + setLabel}
									svg={Svgs.Close}
									marginOffset={{ left: 2.4 }}
									variant={v => v.buttonActive}
									onClick={
										loadingAction && !primaryButtonLoading
											? undefined
											: () => handleCloseEntry(fullScreenSeriesName)
									}
								/>
							}
						</Flex>
					}
				/>
			)}

			{seriesViews.gridView && formsDrawer.component}

			<FlexWrapper ref={getTooltipContainer} gridView={seriesViews.gridView}>
				{!seriesViews.gridView && (
					<>
						{!areEntriesFetched ? (
							<LoaderContainer>
								<Loader />
							</LoaderContainer>
						) : (
							<SeriesTableView
								currentSeriesIndex={entryCount}
								variableSet={variableSet}
							/>
						)}
					</>
				)}

				<DisableBodyScroll />

				{seriesViews.gridView && (
					<FormWrapper isOpenMobileDrawerHistoryView={!!drawerHistoryView}>
						{isMobileDevice &&
							seriesViews.gridView &&
							selectedSeriesEntry.setName &&
							isEdit && (
								<>
									<EntryMobileDrawer
										entryType={EntryDrawerType.SeriesEntryForm}
										historyView={{
											disabled: false,
											loading: loadingRevisions,

											revisions: revisions ?? [],
											revisionId,
											onSelect: onSelectRevision,
											onOpen: () => {
												setLazyRevisions(false);
											},
											onClose: () => {
												setLazyRevisions(true);
												setRevisionId(null);
											},

											view: drawerHistoryView,
											setView: setDrawerHistoryView,
											formsDrawer: formsDrawer
										}}
										statusView={{ statuses }}
										statusesMap={statusesMap}
										detailsView={{ entry }}
										disableModal={!!isLatestRevisionSelected}
									/>
									<DisableBodyScroll />
								</>
							)}
						{forbidPersonalData && (
							<Flex justify={j => j.center} paddingOffset={{ top: 1.6 }}>
								<PersonalDataWarning bgType={t => t.grey} width={65.2} />
							</Flex>
						)}
						<AggregationContainer>
							<AggregationWrapper>
								{/* HEADER OF EACH FORM */}

								{aggregationRules.length > 0 ? (
									<>
										<Spacer size={s => s.xl} />

										<AggregationRulesTable
											aggregationRules={aggregationRules}
											mainEntry={mainEntry}
											variablesMap={variablesMap}
										/>

										<Spacer size={s => s.l} />
									</>
								) : (
									<Spacer size={s => s.xl} />
								)}

								<Flex>
									<Typography.Caption>
										{translate(dict => dict.seriesTable.showing)}{' '}
										{entries.length}{' '}
										{entries.length === 1
											? translate(dict => dict.seriesTable.entry)
											: translate(dict => dict.seriesTable.entries)}
									</Typography.Caption>
								</Flex>

								<Spacer size={s => s.xs} />

								<Flex justify={space => space.center}>
									<PaginationCentered
										canNavigate={canNavigate().next}
										isRevisionSelected={isRevisionSelected}
										pageIndex={entryCount - 1}
										pagesSize={entries.length}
										entriesData={entries}
										navigateTo={(entryId: string | null) =>
											handleActions(PromptToSaveActions.NAVIGATE_ENTRY, {
												entryId
											})
										}
										navigateNext={() =>
											handleActions(PromptToSaveActions.NEXT_ENTRY)
										}
										navigatePrev={() =>
											handleActions(PromptToSaveActions.PREV_ENTRY)
										}
									/>
								</Flex>
							</AggregationWrapper>
						</AggregationContainer>
						<Flex justify={space => space.center}>
							<ContainerGridView
								isFormVisible={formsDrawer.enabled && formsDrawer.visible}
							>
								<NarrowContainer>
									<div data-testid={`grid-view-container-${formProps.id}`}>
										<Flex
											align={a => a.center}
											marginOffset={{ bottom: 1.4, top: 3.2 }}
										>
											<EntryCount formType={SeriesFormState.Current}>
												<Flex>
													{formProps.identifier ?? formProps.count}
												</Flex>
											</EntryCount>
										</Flex>
										<Content
											isSeriesEntryForm={true}
											disabled={loadingSeriesEntries}
										>
											<FormProvider {...formProps.data}>
												<OpenCustomsMapChecker
													openCustomsMap={openCustomsMap}
													setOpenCustomsMap={setOpenCustomsMap}
													setName={setName}
												/>
												<DependenciesMapChecker
													onResetFields={onResetFields}
													dataTestIdEntryNumber={formProps.count}
													customFormContext={{
														getValues: formProps.data.getValues,
														setValue: (name, value) => {
															return formProps.data.setValue(
																name,
																value
															);
														}
													}}
													variableVisibilityMapState={{
														variableVisibilityMap,
														setVariableVisibilityMap
													}}
													variableFilteringMapState={{
														variableFilteringMap,
														setVariableFilteringMap
													}}
													setData={{
														name: setName,
														id:
															selectedSeriesEntry.subEntryId ||
															'new_entry'
													}}
												/>

												<Form
													dataTestId={`series-form-${formProps.id}`}
													onSubmit={handleOnSubmit}
												>
													{form ? (
														<>
															{form.titleEnabled && form.title && (
																<FormTitle title={form.title}>
																	{form.title}
																</FormTitle>
															)}

															<AddEditInputsFormDesigner
																form={form}
																variablesData={variablesData}
																dataTestIdEntryNumber={
																	formProps.count
																}
																{...AddEditInputsProps}
																revision={isCurrentFormRevision(
																	formProps.type
																)}
															/>
														</>
													) : (
														<AddEditInputs
															tooltipContainer={
																tooltipContainer ?? undefined
															}
															dataTestIdEntryNumber={formProps.count}
															{...AddEditInputsProps}
															isSeriesEntryForm={true}
															customVariablesDataArray={
																variablesDataArray
															}
															revision={isCurrentFormRevision(
																formProps.type
															)}
														/>
													)}

													<RequiredFieldsLabel
														customVariables={variables}
													/>
													<CookiesNotice />
												</Form>
											</FormProvider>
										</Content>
									</div>
								</NarrowContainer>
							</ContainerGridView>
						</Flex>
					</FormWrapper>
				)}

				{!isMobileDevice &&
					seriesViews.gridView &&
					selectedSeriesEntry.setName &&
					isEdit && (
						<EntryDrawer
							entryType={EntryDrawerType.SeriesEntryForm}
							historyView={{
								disabled: false,
								loading:
									!!(areRevisionsFetched && loadingRevisions) ||
									updatingSeriesEntry,
								revisions: revisions ?? [],
								revisionId,
								onSelect: onSelectRevision,
								onOpen: () => {
									setLazyRevisions(false);
								},
								onClose: () => {
									setLazyRevisions(true);
									setRevisionId(null);
								}
							}}
							statusView={{ statuses }}
							statusesMap={statusesMap}
							detailsView={{ entry }}
							disableModal={!!isLatestRevisionSelected}
						/>
					)}

				{/* PREV - ARROW NAVIGATION */}
				{seriesViews.gridView && (
					<>
						<NavigationArrow>
							<Icon
								dataTestId="prev-series-entry"
								svg={Svgs.ChevronLeft}
								colors={{ color: Colors.text.hint }}
								style={{
									opacity:
										canNavigate().prev && !revision && entryIndex !== 0
											? 1
											: 0.1
								}}
								onClick={
									canNavigate().prev && !revision && entryIndex !== 0
										? () => handleActions(PromptToSaveActions.PREV_ENTRY)
										: undefined
								}
								customSize={9.6}
							/>
						</NavigationArrow>
						{/* NEXT - ARROW NAVIGATION */}
						<NavigationArrow rightArrow>
							<Icon
								dataTestId="next-series-entry"
								svg={Svgs.ChevronRight}
								colors={{ color: Colors.text.hint }}
								style={{ opacity: canNavigate().next && !revision ? 1 : 0.1 }}
								onClick={
									canNavigate().next && !revision
										? () => handleActions(PromptToSaveActions.NEXT_ENTRY)
										: undefined
								}
								customSize={9.6}
							/>
						</NavigationArrow>
					</>
				)}

				{/* 
				==============
					MODALS 
				==============
				*/}

				{/* CONFLICTS MODAL */}
				{conflictedData &&
					(!isEmpty(conflictedData.statuses) || !isEmpty(conflictedData.values)) && (
						<ConflictsModal
							setConflictDraft={setConflictDraft}
							conflictDraft={conflictDraft}
							onClose={() => {
								setConflictModalVisible(false);
								setConflictedData(null);
							}}
							onContinue={handleMergeConflicts}
							open={conflictModalVisible}
							conflictedData={conflictedData}
						/>
					)}

				{/* DELETE ENTRY MODAL */}
				<Modal
					size={s => s.s}
					visible={deleteEntryModal.visible}
					title={translate(dict => dict.promptToDelete.title)}
					primary={{
						label: translate(dict => dict.buttons.delete),
						loading: deletingSeriesEntry,
						warning: true,
						disabled: !isValidDeleteConfirmation,
						onClick: handleDeleteEntry
					}}
					neutral={{
						label: translate(dict => dict.buttons.cancel),
						onClick: deleteEntryModal.close
					}}
					onClose={deleteEntryModal.close}
					enterAsPrimaryOnClick
					close
				>
					<Typography.Paragraph>
						{translate(dict => dict.promptToDelete.description.entry)}
					</Typography.Paragraph>
					<Typography.Paragraph
						marginOffset={{ top: 2.4, bottom: 2.4 }}
						color={Colors.text.error}
						fontweight={w => w.bold}
					>
						{translate(dict => dict.promptToDelete.description.undoneWarning)}
					</Typography.Paragraph>
					<Input
						type={InputType.Text}
						label={translate(
							dict => dict.dataset.seriesEntryForm.deleteEntryConfirmationLabel
						).replace(
							'__deleteEntryConfirmationTarget__',
							deleteEntryConfirmationTarget
						)}
						value={confirmDeleteEntryInput}
						onChange={e => setConfirmDeleteEntryInput(e.target.value)}
						placeholder={deleteEntryConfirmationTarget}
					/>
				</Modal>
				{/* PROMPT TO SAVE MODAL */}
				{promptToSaveModal.action && (
					<Modal
						size={s => s.s}
						title={translate(dict => dict.promptToSave.title)}
						primary={{
							label: translate(dict => dict.buttons.save),
							loading: loadingAction,
							onClick: handlePromptToSaveConfirm
						}}
						neutral={{
							label: translate(dict => dict.buttons.discard),
							onClick: handlePromptToSaveDiscard
						}}
						onClose={promptToSaveModal.close}
						enterAsPrimaryOnClick
						visible
						close
					>
						<Typography.Paragraph>
							{translate(dict => dict.promptToSave.unsavedChanges)}
						</Typography.Paragraph>
					</Modal>
				)}
				{/* RESTORE ENTRY MODAL */}
				<Modal
					size={s => s.s}
					visible={restoreEntryModal.visible}
					title={translate(dict => dict.versionChanges.restoreVersion)}
					primary={{
						label: translate(dict => dict.buttons.restore),
						loading: loadingAction,
						onClick: onSaveClick
					}}
					neutral={{
						label: translate(dict => dict.buttons.cancel),
						onClick: restoreEntryModal.close
					}}
					onClose={restoreEntryModal.close}
					enterAsPrimaryOnClick
					close
				>
					<Typography.Paragraph>
						{translate(dict => dict.versionChanges.restoreWarning)} {revisionDate}
					</Typography.Paragraph>
				</Modal>
				<RevisionChangesModal
					visible={!!revisionsModalVisible}
					onPrimaryClick={handleRevisionModalPrimaryClick}
					onNeutralClick={closeRevisionModal}
				/>

				<UnrestoredRevisionModal
					visible={!!unrestoredRevisionModalVisible}
					onPrimaryClick={goBackToEntry}
					onNeutralClick={() => setUnrestoredRevisionModalVisible(false)}
					latestRevisionDate={
						revisions && revisions[0]
							? revisions[revisions.length - 1].creationDate
							: ''
					}
				/>
			</FlexWrapper>
		</FlexContainer>,
		document.body
	);
}
