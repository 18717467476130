import { FileForUpload, storeBase64Files } from './useStoreBase64FileMutation';

export const submitFiles = async ({
	entryId,
	files,
	projectId
}: {
	files: FileForUpload[];
	projectId: string;
	entryId: string;
}) => {
	const filePromises = files.map(file =>
		storeBase64Files({
			projectId,
			files: [
				{
					base64Bytes: file.base64Bytes,
					fileName: file.fileName,
					variableName: file.variableName
				}
			],
			entryId
		})
	);

	return await Promise.all(filePromises);
};
