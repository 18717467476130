import { Icon } from 'components/UI/Icons';
import { Container } from './PrecissionLossWarning.style';
import { Svgs } from 'environment';
import { Typography } from '../../../../UI/Typography';
import { useTranslation } from 'hooks/store';

export function PrecissionLossWarning() {
	const { translate } = useTranslation();
	return (
		<Container id={'precision-loss-warning'}>
			<Icon svg={Svgs.Information} style={{ gridArea: 'icon', color: '#b54708' }} />
			<Typography.Paragraph style={{ gridArea: 'text', color: '#b54708' }}>
				{translate(
					dict =>
						dict.projects.createAndImport.generics.previewVariables.precisionLossWarning
				)}
			</Typography.Paragraph>
		</Container>
	);
}
